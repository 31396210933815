import React, { useEffect, useState } from "react";
import Group_Setting_Navigation from "../Group_Setting_Navigation/App";
import { Button, Form, Image, InputGroup } from "react-bootstrap";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../../../../Main/Components/TextEditor/App";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupIdAsync,
  groupAsync,
  groupState,
} from "../../MyGroupOnBoard/reduxSlice";
import {
  clearGroup,
  clearPreviewFiles,
  clearSelectedFiles,
  createGroupState,
  setDataToEdit,
  setDescription,
  setEditGroup,
  setGroupName,
  setGroupType,
  setLocation,
  setPreviewFiles,
  setSelectedFiles,
  updateGroupAsync,
} from "../../../../../Main/Components/Groups/CreateGroup/reduxSlice";
import { FiAlertCircle } from "@react-icons/all-files/fi/FiAlertCircle";
// import {setDescription} from "../../Schedule_Event/reduxSlice";
import Placeholder from "react-bootstrap/Placeholder";
import { Icon } from "@iconify/react";

const BasicSetting = () => {
  const { groupId } = useParams();
  const editMode = useMatch("/groups/g/:groupId/setting");
  const { groupData, generatedGroupId } = useSelector(groupState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //loading
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    if (editMode) {
      if (Object.keys(groupData).length === 0) {
        dispatch(groupAsync({ groupId }));
      }
      dispatch(setEditGroup({ value: true }));
      dispatch(setDataToEdit({ groupData }));
    }

    return () => {
      dispatch(clearGroup({}));
    };
  }, [dispatch, editMode, groupData, groupId]);

  const {
    loading,

    groupName,
    categories,
    groupGallery,
    description,
    groupType,
    location,
    preViewFiles,
    selectedFiles,
  } = useSelector(createGroupState);
  console.log(groupData);
  //GALLERY
  const previewFiles = (evt) => {
    dispatch(clearSelectedFiles({}));
    dispatch(clearPreviewFiles({}));

    var files = evt.target.files;

    for (let i = 0; i < evt.target.files.length; i++) {
      const newImage = evt.target.files[i];

      newImage["id"] = Math.random();
      dispatch(setSelectedFiles({ newImage }));
    }

    for (var i = 0, len = files.length; i < len; i++) {
      var file = files[i];

      var reader = new FileReader();

      reader.onload = (function (f) {
        return function (e) {
          // Here you can use `e.target.result` will give you <img src={e.target.result} link
          // and `f.name` will give you file name

          dispatch(setPreviewFiles({ newPreview: e.target.result }));
        };
      })(file);

      reader.readAsDataURL(file);
    }
  };
  //Submit Update
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateGroupAsync({ groupId, navigate }));
  };
  return (
    <div className="container py-5 min-vh-100 ">
      <div className="row gx-5">
        <div className="col-lg-3">
          <Group_Setting_Navigation />
        </div>

        <div className="col-12 mt-lg-5 col-lg-8">
          <form onSubmit={handleSubmit}>
            <h5 className="mb-4  mt-4 fw-bold ">Basic Settings</h5>

            <div className="form-group mb-4">
              <label className="form-label">Group Name</label>
              <input
                value={groupName}
                onChange={(e) => {
                  dispatch(setGroupName({ value: e.target.value }));
                }}
                className="form-control bg-dark text-light"
                type="text"
              />
            </div>

            <div className="form-group mb-4">
              <label className="form-label bg-dark text-light">
                Group Description
              </label>
              <TextEditor
                editorState={description}
                setEditorState={(newState) => {
                  dispatch(setDescription({ value: newState }));
                }}
              />
            </div>

            <div className="form-group place_autocomplete_container mb-4">
              <label className="form-label">Location</label>
              <input
                value={location}
                onChange={(e) =>
                  dispatch(setLocation({ value: e.target.value }))
                }
                className="form-control bg-dark text-light"
                type="text"
              />
            </div>

            <div className="form-group mb-4">
              <div className="">
                <div className="col-md-8">
                  <h4 className="fs-5">Group Gallery</h4>
                  <div className="input-icon mb-3 place_autocomplete_container">
                    <Form validated className="d-none d-md-flex d-lg-flex">
                      <InputGroup>
                        <Form.Control
                          id="images_group"
                          onChange={previewFiles}
                          type="file"
                          multiple={true}
                          placeholder="Upload Images, videos, gif"
                          aria-label="Search"
                          aria-describedby="basic-addon1"
                          className="border-start-0 bg-dark text-light d-none "
                        ></Form.Control>
                        <label htmlFor="images_group" className="w-100">
                          {preViewFiles?.length <= 0 ? (
                            <div className="img-content  ">
                              {groupData.groupGallery?.map((image) => (
                                <Image
                                  draggable={false}
                                  style={{
                                    aspectRatio: "16 / 10",
                                  }}
                                  className={`rounded w-100 ${
                                    imgLoading ? "d-none" : null
                                  }`}
                                  onLoad={() => {
                                    setImgLoading(false);
                                  }}
                                  src={image}
                                  alt="Group image"
                                />
                              ))}

                              {imgLoading ? (
                                <Placeholder animation="glow">
                                  <Placeholder
                                    className="rounded w-100 h-100 "
                                    style={{
                                      aspectRatio: "16 / 10 ",
                                    }}
                                  />
                                </Placeholder>
                              ) : null}
                            </div>
                          ) : null}

                          <div className="img-wrap ratio-16-9 w-100">
                            {preViewFiles.map((tempImg) => (
                              <div className="img-content ">
                                <Image fluid src={tempImg} />
                              </div>
                            ))}
                          </div>
                        </label>
                      </InputGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            {/*GROUP VISIBILITY*/}
            <h5 className="mb-4 fw-bold mt-3">Group Visibility</h5>
            <p>
              When you change your group's content visibility settings, all
              members and organizers of the group will receive an email
              notification.
            </p>

            <div
              className="alert alert-dark  bg-dark text-light border mb-4"
              role="alert"
            >
              <span className="mx-2">
                <FiAlertCircle />
              </span>
              Once you make a group private, it cannot be changed back. This
              ensures that members who join private groups can maintain their
              privacy.
            </div>

            <div
              className="form-group"
              onChange={(e) => {
                dispatch(setGroupType({ value: e.target.value }));
              }}
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  value="public"
                  type="radio"
                  checked={groupType === "public"}
                />
                <label className="form-check-label fw-bold">Public</label>
              </div>
              <p className="fs-7">
                This group's content, including its members and event details,
                is visible to public.
              </p>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="private"
                  checked={groupType === "private"}
                />
                <label className="form-check-label fw-bold">Private</label>
              </div>
              <p className="fs-7">
                Only members of this group can see its full content, including
                details about its members and events. Some information about the
                group is public.
              </p>
              <p>
                These basic details about groups and events are always public,
                regardless of your content visibility setting:
              </p>
              <ul className="fs-7">
                <li>
                  <p className="mb-0">Basic group information</p>
                  <p className="text-muted">
                    Group name, featured photo, description, number of members,
                    date founded
                  </p>
                </li>
                <li>
                  <p className="mb-0">Basic organizer information</p>
                  <p className="text-muted">Organizer name and profile photo</p>
                </li>
                <li>
                  <p className="mb-0">Basic event details</p>
                  <p className="text-muted">
                    Titles, date, time and number of RSVPs
                  </p>
                </li>
              </ul>
            </div>
            <div className="text-end">
              <Link
                to="#"
                className="text-danger text-decoration-underline me-3"
              >
                Delete Group
              </Link>
              <Button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {loading ? (
                  <Icon icon="eos-icons:bubble-loading" />
                ) : (
                  "Update settings"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BasicSetting;
