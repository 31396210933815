import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser, rememberAfterLogin } from "./reduxAPI";
import { pushErrorNotification } from "../../Error/reduxSlice";
import { getDocIdofUserAsync, setUserAvl } from "../../User/reduxSlice";

// icon
import { Icon } from "@iconify/react";

const initialState = {
  email: "",
  password: "",
  rememberMe: true,
  loading: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const loginUserAsync = createAsyncThunk(
  "login/loginUser",
  async (
    { email, password, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { rememberMe } = getState().login;
      const userCredential = await loginUser(email, password);
      const user = userCredential.user;
      if (user) {
        dispatch(setUserAvl({ userAvl: true, user }));
        await dispatch(getDocIdofUserAsync({ uid: user.uid }));
      }

      const { data } = await rememberAfterLogin(
        userCredential.user.uid,
        rememberMe
      );

      localStorage.setItem("preEventToken", data.token);
      if (navigate) {
        if (!(window.location.hostname !== "localhost")) {
          navigate("/");
        } else {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEmail: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.email = action.payload.email;
    },
    setPassword: (state, action) => {
      state.password = action.payload.password;
    },
    toggleRememberMe: (state, action) => {
      state.rememberMe = !state.rememberMe;
    },

    autoLoginState: (state, action) => {
      state.loading = true;
    },

    clearLoadingState: (state, action) => {
      state.loading = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setEmail,
  setPassword,
  toggleRememberMe,
  autoLoginState,
  clearLoadingState,
} = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const loginState = (state) => state.login;

export default loginSlice.reducer;
