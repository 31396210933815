import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { ReactFormGenerator, ElementStore  } from "react-form-builder2";
import "./PreviewForm.css";

const PreviewForm = ({ variables }) => {
  // todo remove useState
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    ElementStore.subscribe((state) => _onChange(state.data));
  }, []);

  const _onChange = (data) => {
    setData(data);
  };

  const _onSubmit = (data) => {
    console.log("onSubmit", data);
    // Place code to post json data to server here
  };

  return (
    <div className="clearfix  " style={{ margin: "10px 0px" }}>
      <button
        className="btn btn-primary"
        style={{ marginRight: "10px" }}
        onClick={() => {
          setShow(true);
        }}
      >
        Preview Form
      </button>

      <Modal
        className="registrationForm_biz preview-form"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Body>
          <ReactFormGenerator
            download_path=""
            back_action="/"
            back_name="Back"
            answer_data={{}}
            action_name="Save"
            form_action="/"
            form_method="POST"
            onSubmit={_onSubmit}
            variables={variables}
            data={data}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PreviewForm;
