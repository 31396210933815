import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db_firestore, functions } from "../../../../firebase.config";

export async function getDocIdofUserAPI(uid) {
  const getDocIdofUser = httpsCallable(functions, "getDocIdofUser");
  return getDocIdofUser({ uid });
}
export async function getEventbyIdAPI(eventId) {
  const getEventbyId = httpsCallable(functions, "getEventbyId");
  return getEventbyId({ eventId });
}

export async function getDataofUser(uid) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let data;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    data = { id: doc.id, ...doc.data() };
  });

  return data;
}
export async function getFriendsIdAPI(docId) {
  const getFriendsId = httpsCallable(functions, "getFriendsId");
  return getFriendsId({ docId });
}

export async function getCompanyNameofUser(uid) {
  let { data: docId } = await getDocIdofUserAPI(uid);
  const userRef = doc(db_firestore, "users", docId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().companyName;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function getSavedEventsOfUser(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().savedEvents;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function getUidofUser(companyName) {
  let uid;
  const userDocq = query(
    collection(db_firestore, "users"),
    where("companyName", "==", companyName)
  );
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    uid = doc.data().uid;
  });
  return uid;
}

export async function getUserInfo(uid) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });

  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}
export async function getUserInfoDocId(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}
