import React from 'react';
import {Link} from "react-router-dom";
import {AdminNavigation} from "../../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../../CommonComponents/Footer/App";

const Createblog = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">

                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <Link to="#" className="page-pretitle text-decoration-none text-light fw-bold">
                                   <small> Blogs</small>
                                </Link>
                                <h1 className="page-title h5 fw-bolder">
                                    Create Blog
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <form>
                                   
                                        <div className="card bg-dark text-light border p-3 mb-4">
                                            <div className="row">
                                                <div className="col-12 card-body">
                                                    <div className="form-group mb-4 bg-dark text-light">
                                                        <label className="form-label"  >Featured
                                                            Image</label>
                                                        <input className="form-control bg-dark text-light "
                                                               type="file" accept="image/png, image/jpeg"/>
                                                    </div>

                                                    <div className="form-group mb-4 required">
                                                        <label className="mb-1">Blog
                                                            Category</label>
                                                        <select   className="form-control bg-dark text-light">
                                                            <option value="">Please select</option>
                                                            <option value="1">natus</option>
                                                            <option value="2">qui</option>
                                                            <option value="3">veritatis</option>
                                                            <option value="4">dolorum</option>
                                                            <option value="5">necessitatibus</option>
                                                            <option value="6">odio</option>
                                                            <option value="7">dolor</option>
                                                            <option value="8">dolorum</option>
                                                            <option value="9">quaerat</option>
                                                            <option value="10">alias</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group mb-4 required">
                                                        <label className="mb-1"  >Name</label>
                                                        <input  type="text" className="form-control bg-dark text-light"
                                                               placeholder="Name" value="" required=""/>
                                                    </div>


                                                    <div className="form-group mb-4 required">
                                                        <label className="mb-1">Description</label>
                                                        <input type="text" className="form-control bg-dark text-light"
                                                               placeholder="Description" value="" required=""/>
                                                    </div>

                                                    <div className="form-group mb-4 required">
                                                        <label className="mb-1">Content</label>
                                                        <textarea  className="form-control tinymce bg-dark text-light"
                                                                  id="mce_0" aria-hidden="true"
                                                                 ></textarea>


                                                    </div>

                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
 
            </div>
            <Admin_Footer/>
        </div>
    );
};

export default Createblog;