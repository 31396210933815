import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
// Bootstrap
import { Container, Form, Row, Col } from "react-bootstrap";
//Icons
import { FiChevronLeft } from "react-icons/fi";
//redux
import { useSelector, useDispatch } from "react-redux";
import { eventState } from "../../../../Main/Components/Events/Page Component/reduxSlice";

import FooterComponent from "../../FooterComponent/App";

export default function EditEvent() {
  return (
    <>
      <Container className="my-4">
        <div className="row">
          <div className="col">
            <h3>{"data.title"}</h3>
            <Link
              className="text-decoration-none p-0 text-light d-flex align-items-center"
              to={""}
            >
              <FiChevronLeft className="me-1" />
              {"data.hostBy"}
            </Link>
            <hr className="my-5" />
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control value={"title"} />
              </Form.Group>
              <Row className="mb-3 ">
                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Start</Form.Label>
                  <Form.Control type="date" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control type="time" />
                </Form.Group>
                <div className="col d-flex align-items-end">
                  <p className="my-0 mb-2">GMT +05:30</p>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}
