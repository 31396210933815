// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

import { auth, functions } from "../../../../../firebase.config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
// import axios from "../../../../axios/axiosInstance";

export function loginUser(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function rememberAfterLogin(uid , rememberMe) {
  const rememberAfterLogin = httpsCallable(functions, "rememberAfterLogin");
  return rememberAfterLogin({ uid , rememberMe})

}
