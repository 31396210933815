import React from 'react';
import {Link} from "react-router-dom";

const Admin_Footer = () => {
    return (

        <div className="">
        <div>
            <footer className="footer " >
                <div className="container ">
                    <div className="row text-center align-items-center flex-row-reverse">
                        <div className="col-lg-auto ms-lg-auto">
                            <ul className="list-inline list-inline-dots mb-0">
                                <li className="list-inline-item"><Link to = "#" className=" " target="_blank">Documentation</Link></li>
                                <li className="list-inline-item"><Link to = "#" className="" target="_blank">Support</Link></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                            <ul className="list-inline list-inline-dots">
                                <li className="list-inline-item m-2">
                                    Copyright © 2022
                                    <Link  to = "#" className="m-2">Bizconnect</Link>
                                </li>
                                <li className="list-inline-item m-2">
                                    Version 1.0.0
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </div>
    );
};

export default Admin_Footer;