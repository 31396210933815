import React from 'react';

const Footer = () =>{

    return(
        <div className='main-footer bg-secondary text-white'>
            <div className='container m-5'>
                <div className='row'>
                    <div className='d-flex flex-row flex-column flex-lg-row'>
                    <div className=''>
                        <h4>YOUR ACCOUNT</h4>
                        <ul className='list-unstyled'>
                            <li>Sign up</li>
                            <li>Log in</li>
                        </ul>
                       
                       <div className=''>
                       <h4>DISCOVER</h4>
                        <ul className='list-unstyled'>
                            <li>groups</li>
                            <li>Events</li>
                            <li>Online Events</li>
                            <li>Browse Topics</li>
                        </ul>
                       </div>
                        <div className=''>
                        <h4>NETWORK</h4>
                        <ul className='list-unstyled'>
                            <li>Blog</li>
                        </ul>
                        </div>
                   
                       </div>
                    </div>
                </div>
                <div className='footer-bottom'>
                    <p className='text-xs-center'>
                        &copy;{new Date().getFullYear()} Network. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer;