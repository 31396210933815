import { db_firestore, auth } from "../../../../../firebase.config";

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getCompanyNameofUser } from "../../User/reduxAPI";

export async function getOrganizedEventsAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);
  let user;
  if (userSnap.exists()) {
    user = userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (user.createdEventsId.length <= 0) {
    return [];
  }

  let events = [];

  for (let index = 0; index < user.createdEventsId.length; index++) {
    const eventRef = doc(db_firestore, "events", user.createdEventsId[index]);
    

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      events.push({
        id: user.createdEventsId[index],
        ...eventSnap.data(),
      });
    } else {
      console.log("This Event id  is not exist in db");
    }
  }

  for (let index = 0; index < events.length; index++) {
    const companyName = await getCompanyNameofUser(events[index].subscriberUid);
    events[index].companyName = companyName;
  }

  return events;
}

export async function getJoinedEventsAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);
  let user;
  if (userSnap.exists()) {
    user = userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (user.joinedEventsId.length <= 0) {
    return [];
  }

  let events = [];

  for (let index = 0; index < user.joinedEventsId.length; index++) {
    const eventRef = doc(db_firestore, "events", user.joinedEventsId[index]);

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      events.push({
        id: user.joinedEventsId[index],
        ...eventSnap.data(),
      });
    } else {
      console.log("This Event id  is not exist in db");
    }
  }

  for (let index = 0; index < events.length; index++) {
    const companyName = await getCompanyNameofUser(events[index].subscriberUid);
    events[index].companyName = companyName;
  }

  return events;
}

export async function getGroupsEventsAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);
  let user;
  if (userSnap.exists()) {
    user = userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (user.joinedGroupsId.length <= 0) {
    return [];
  }

  let eventId = [];

  for (let index = 0; index < user.joinedGroupsId.length; index++) {
    const groupRef = doc(db_firestore, "groups", user.joinedGroupsId[index]);
    const groupSnap = await getDoc(groupRef);

    if (groupSnap.exists()) {
      eventId = [...eventId, ...groupSnap.data().eventUid];
    } else {
      console.log("this Group id is not exist in db");
    }
  }

  let events = [];

  for (let index = 0; index < eventId.length; index++) {
    const eventRef = doc(db_firestore, "events", eventId[index]);
    const companyName = await getCompanyNameofUser(events[index].subscriberUid);

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      events.push({
        id: eventId[index],
        ...eventSnap.data(),
        companyName,
      });
    } else {
      console.log("This Event id  is not exist in db");
    }
  }

  return events;
}

export async function getEventsAPI() {
  const groupRef = collection(db_firestore, "events");

  const q = query(groupRef);

  const querySnapshot = await getDocs(q);

  let events = [];

  querySnapshot.forEach(async (doc) => {
    // doc.data() is never undefined for query doc snapshots
    const id = doc.id;
    events.push({ id, ...doc.data() });
  });
  for (let index = 0; index < events.length; index++) {
    const companyName = await getCompanyNameofUser(events[index].subscriberUid);
    events[index].companyName = companyName;
  }

  return events;
}
