import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../../../firebase.config";

export async function uploadDocumentFormAPI(eventId, formData, heading) {
  const uploadDocumentForm = httpsCallable(functions, "uploadDocumentForm");
  return uploadDocumentForm({
    eventId,
    formData,
    heading,
  });
}
