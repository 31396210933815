import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pushErrorNotification } from "../../Error/reduxSlice";
import { setJoinedAttendees } from "../Page Component/reduxSlice";
import { eventAttendeeMatchMakingAPI, getuserDetailsAPI } from "./reduxAPI";

const initialState = {
  profileDetails: null,

  matchAttendee: false,
  findingMatch: false,
};

export const eventAttendeeMatchMakingAsync = createAsyncThunk(
  "attendees/eventAttendeeMatchMaking",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      const {
        eventData: { id: eventId },
      } = getState().event;
      const response = await eventAttendeeMatchMakingAPI(docId, eventId);

      if (response.data.myMatches.length > 0) {
        dispatch(setJoinedAttendees({ value: response.data.myMatches }));
      } else {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "No Match Found",
              errorMessage:
                "Currently No Match Found to Your Interest Please Try Again Once Before The Event Start",
              theme: "dark",
              time: "now",
              autoHideTime: 5000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const eventAttendeeMatchMakingAPICase = (builder) => {
  builder
    .addCase(eventAttendeeMatchMakingAsync.pending, (state, action) => {
      state.findingMatch = true;
    })
    .addCase(eventAttendeeMatchMakingAsync.fulfilled, (state, action) => {
      state.findingMatch = false;
      state.matchAttendee = true;
    })

    .addCase(eventAttendeeMatchMakingAsync.rejected, (state, action) => {
      state.findingMatch = false;
    });
};

export const getProfileDataAsync = createAsyncThunk(
  "attendees/getProfileDataAsync",
  async ({ docId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getuserDetailsAPI(docId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const attendeeSlice = createSlice({
  name: "attendee",
  initialState,
  reducers: {
    SetMatchAttendee: (state, action) => {
      state.matchAttendee = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDataAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.profileDetails = action.payload;
      })
      .addCase(getProfileDataAsync.rejected, (state, action) => {
        state.loading = false;
      });
    eventAttendeeMatchMakingAPICase(builder);
  },
});

// Action creators are generated for each case reducer function
export const { SetMatchAttendee } = attendeeSlice.actions;

// create state here and export
export const attendeeState = (state) => state.attendee;

export default attendeeSlice.reducer;
