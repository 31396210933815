import React from "react";

// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Card, Placeholder } from "react-bootstrap";

// Icons
import { FaShareAlt } from "react-icons/fa";
import { BsStar } from "react-icons/bs";

const EventSkeleton = () => {
  return (
    <div className="event_list_img">
      <Placeholder className="" animation="glow">
        <Card className="bg-dark text-light border-0 mx-2">
          <Link draggable={false} to={``}>
            {/* <Placeholder animation="glow "> */}
            <Placeholder
              className="rounded-3 w-100   h-100"
              style={{
                aspectRatio: "16 / 10 ",
              }}
            />
            {/* </Placeholder> */}
          </Link>
          <Card.Body className="mb-0 px-0 py-3">
            <time className="text-light text-uppercase tracking-tight fs-6 py-1">
              <Placeholder as={Card.Title} xs={8} />
            </time>

            <Link to={"/"} className="text-decoration-none" draggable="false">
              <h6 className="fw-bold text-light  ">
                <Placeholder
                  as={Card.Text}
                  size={"lg"}
                  className="d-block mb-2"
                  xs={10}
                />
                <Placeholder
                  as={Card.Text}
                  size={"lg"}
                  className="d-block"
                  xs={10}
                />
                {/* dhruv */}
              </h6>
            </Link>
            <p className="mb-1">
              <Placeholder xs={12} />
              <Placeholder xs={12} />
            </p>
            {/* car */}

            <div className="d-flex flex-row align-items-center justify-content-between text-muted">
              <Placeholder xs={5} />
              {/* attendees */}
              <div className="">
                <span className="mx-2 fs-5 ">
                  <BsStar />
                </span>
                <span className=" fs-5">
                  <FaShareAlt />
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Placeholder>
    </div>
  );
};

export default EventSkeleton;
