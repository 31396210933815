import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postFormData, updateFormData } from "./reduxAPI";

const initialState = {
  loading: false,
  previewData: [],
  formId: "1",
};

export const postFormDataAsync = createAsyncThunk(
  "registrationForm/postFormData",
  async (
    { groupId, eventId, uid },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { previewData, formId } = getState().registrationForm;

      const response = await postFormData(
        previewData,
        formId,
        groupId,
        eventId,
        uid
      );

      // console.log(response);
    } catch (error) {
      // if (error.name === "FirebaseError") {
      //   dispatch(
      //     pushErrorNotification({
      //       notify: {
      //         iconifyIconLink: (
      //           <Icon icon="bx:notification" className="rounded" />
      //         ),
      //         errorTitle: error.code,
      //         errorMessage: error.message,
      //         theme: "danger",
      //         time: "now",
      //         autoHideTime: 3000,
      //         statusCode: 200,
      //         show: true,
      //       },
      //     })
      //   );
      // }
      return rejectWithValue(error);
    }
  }
);

export const updatePostFormDataAsync = createAsyncThunk(
  "registrationForm/updatePostFormDataAsync",
  async ({ formDocId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { previewData } = getState().registrationForm;

      const response = await updateFormData(previewData, formDocId);

      // console.log(response);
    } catch (error) {
      // if (error.name === "FirebaseError") {
      //   dispatch(
      //     pushErrorNotification({
      //       notify: {
      //         iconifyIconLink: (
      //           <Icon icon="bx:notification" className="rounded" />
      //         ),
      //         errorTitle: error.code,
      //         errorMessage: error.message,
      //         theme: "danger",
      //         time: "now",
      //         autoHideTime: 3000,
      //         statusCode: 200,
      //         show: true,
      //       },
      //     })
      //   );
      // }
      return rejectWithValue(error);
    }
  }
);

export const registrationFormSlice = createSlice({
  name: "registrationForm",
  initialState,
  reducers: {
    setData: (state, action) => {
      let formDataBuff = action.payload.previewData.task_data;

      console.log(formDataBuff);
      state.previewData = [...formDataBuff];
    },

    setFormId: (state, action) => {
      state.formId = action.payload.value;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postFormDataAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postFormDataAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postFormDataAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setFormId, setData } = registrationFormSlice.actions;

export const registrationFormState = (state) => state.registrationForm;

export default registrationFormSlice.reducer;
