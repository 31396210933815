import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GeneralPanel from "./components/GeneralPanel";
import { pushErrorNotification } from "../../../Main/Components/Error/reduxSlice";
import { Icon } from "@iconify/react/dist/iconify";
import {
  addInterestsAPI,
  addNewInterestAPI,
  algoliaInterestAPI,
  getInterestsAPI,
  getRecommendedInterestAPI,
  getUserProfileAPI,
  removeInterestAPI,
  updateLocationInfo,
  updateProfileUrlAPI,
  updateSocialInfo,
  uploadFiles,
  userProfile,
} from "./reduxAPI";

const initialState = {
  userProfilePannel: "General",
  //PROFILE URL
  selectedFiles: null,
  preViewFiles: null,
  profileUrl: "",
  //PROFILE URL
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  birthDate: "",
  gender: "",
  timeZone: "",
  headLine: "",
  bio: "",
  city: "",
  country: "",
  address: "",
  facebook: "",
  instagram: "",
  twitter: "",
  linkedin: "",
  interests: [],
  recommendedInterests: [],

  loadingSearchedInterests: false,
  foundSearchedInterest: true,
  searchedInterests: [],

  serachInterestQuery: "",

  heighLightInterestId: "",

  creatingNewInterestLoading: false,

  //Fetched Data
  userDataLoading: false,
  userDataFound: true,
  userData: {},

  //EDIT
  editProfile: false,
};
//Upload userprofile

export const uploadAsync = createAsyncThunk(
  "updateUser/uploadFiles",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles } = getState().userProfile;
      const { docId } = getState().user;
      // eslint-disable-next-line array-callback-return
      if (selectedFiles) {
        const downloadURL = await uploadFiles(selectedFiles, docId);
        await updateProfileUrlAPI(docId, downloadURL);
        dispatch(setProfileUrl({ value: downloadURL }));
      }
    } catch (err) {
      console.log(err);
    }
  }
);

//Profile Update Async
export const updateUserAsync = createAsyncThunk(
  "updateUser/updateUserDB",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      let {
        profileUrl,
        userName,
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        bio,
        timeZone,
        birthDate,
        userData
      } = getState().userProfile;

      const response = await userProfile(
        (profileUrl ? profileUrl : userData.profileUrl),
        userName,
        firstName,
        lastName,
        email,
        phoneNumber,
        gender,
        bio,
        timeZone,
        birthDate
      );

      dispatch(uploadAsync({}));
      if (response.data.error) {
        throw response.data;
      }
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
    }
  }
);

//Social update Async
export const socialUpdateUserAsync = createAsyncThunk(
  "updateUser/updateSocial",
  async (
    { facebook, instagram, twitter, linkedin },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      await updateSocialInfo(facebook, instagram, twitter, linkedin);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//Location update Async
export const locationUpdateUserAsync = createAsyncThunk(
  "updateUser/updateLocation",
  async (
    { city, country, address },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      await updateLocationInfo(city, country, address);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const addNewInterestAsync = createAsyncThunk(
  "userProfile/addNewInterest",
  async ({ interestId }, { dispatch, getState, rejectWithValue }) => {
    const { docId } = getState().user;
    try {
      await addNewInterestAPI(interestId, docId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const addNewInterestAPICase = (builder) => {
  builder
    .addCase(addNewInterestAsync.pending, (state, action) => { })
    .addCase(addNewInterestAsync.fulfilled, (state, action) => { })
    .addCase(addNewInterestAsync.rejected, (state, action) => { });
};

export const removeInterestAsync = createAsyncThunk(
  "userProfile/removeInterest",
  async ({ interestId }, { dispatch, getState, rejectWithValue }) => {
    const { docId } = getState().user;
    try {
      await removeInterestAPI(interestId, docId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const removeInterestAPICase = (builder) => {
  builder
    .addCase(removeInterestAsync.pending, (state, action) => { })
    .addCase(removeInterestAsync.fulfilled, (state, action) => { })
    .addCase(removeInterestAsync.rejected, (state, action) => { });
};

export const getInterestsAsync = createAsyncThunk(
  "userProfile/getInterests",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    const { docId } = getState().user;
    try {
      return await getInterestsAPI(docId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const getInterestsAPICase = (builder) => {
  builder
    .addCase(getInterestsAsync.pending, (state, action) => { })
    .addCase(getInterestsAsync.fulfilled, (state, action) => { })
    .addCase(getInterestsAsync.rejected, (state, action) => { });
};

export const getRecommendedInterestAsync = createAsyncThunk(
  "userProfile/getRecommendedInterest",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    const { docId } = getState().user;
    try {
      return await getRecommendedInterestAPI(docId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const getRecommendedInterestAPICase = (builder) => {
  builder
    .addCase(getRecommendedInterestAsync.pending, (state, action) => { })
    .addCase(getRecommendedInterestAsync.fulfilled, (state, action) => {
      state.recommendedInterests = action.payload.data.recommendedInterests;
    })
    .addCase(getRecommendedInterestAsync.rejected, (state, action) => { });
};

export const algoliaInterestAsync = createAsyncThunk(
  "userProfile/algoliaInterestAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { serachInterestQuery } = getState().userProfile;
      let result = await algoliaInterestAPI(serachInterestQuery, "interests");
      result.hits.map(async (hit, index) => {
        hit["id"] = hit["objectID"];
        delete (await hit["objectID"]);
        return hit;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const algoliaInterestAPICase = (builder) => {
  builder
    .addCase(algoliaInterestAsync.pending, (state, action) => {
      state.loadingSearchedInterests = true;
    })
    .addCase(algoliaInterestAsync.fulfilled, (state, action) => {
      state.loadingSearchedInterests = false;

      state.searchedInterests = [...action.payload.hits];
      if (action.payload.hits.length <= 0) {
        state.foundSearchedInterest = false;
      }
    })
    .addCase(algoliaInterestAsync.rejected, (state, action) => {
      state.loadingSearchedInterests = false;
    });
};

export const addInterestsAsync = createAsyncThunk(
  "userProfile/addInterests",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    const { docId } = getState().user;
    const { serachInterestQuery } = getState().userProfile;
    try {
      const response = await addInterestsAPI(serachInterestQuery, docId);

      if (response.data.error) {
        throw response.data;
      }

      return response;
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
const addInterestsAPICase = (builder) => {
  builder
    .addCase(addInterestsAsync.pending, (state, action) => {
      state.creatingNewInterestLoading = true;
    })
    .addCase(addInterestsAsync.fulfilled, (state, action) => {
      state.creatingNewInterestLoading = false;
    })
    .addCase(addInterestsAsync.rejected, (state, action) => {
      state.creatingNewInterestLoading = false;
    });
};

//FETCH THE DATA
export const getUserProfileAsync = createAsyncThunk(
  "userProfile/fetchUserData",
  async ({ docId }, { dispatch, getState, rejectWithValue }) => {
    try {
      return await getUserProfileAPI(docId);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfilePannel: (state, action) => {
      state.userProfilePannel = action.payload.newPannel;
    },
    //form
    setProfileUrl: (state, action) => {
      state.profileUrl = action.payload.value;
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload.files;
    },

    clearSelectedFiles: (state, action) => {
      state.selectedFiles = "";
    },
    setPreviewFiles: (state, action) => {
      state.preViewFiles = action.payload.newPreview;
    },

    clearPreviewFiles: (state, action) => {
      state.preViewFiles = "";
    },

    setFirstName: (state, action) => {
      state.firstName = action.payload.value;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.value;
    },
    setEmail: (state, action) => {
      state.email = action.payload.value;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload.value;
    },
    setBirthDate: (state, action) => {
      state.birthDate = action.payload.value;
    },
    setGender: (state, action) => {
      state.gender = action.payload.value;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload.value;
    },
    setHeadLine: (state, action) => {
      state.headLine = action.payload.value;
    },
    setBio: (state, action) => {
      state.bio = action.payload.value;
    },
    setCountry: (state, action) => {
      state.country = action.payload.value;
    },
    setCity: (state, action) => {
      state.city = action.payload.value;
    },
    setAddress: (state, action) => {
      state.address = action.payload.value;
    },
    setFacebook: (state, action) => {
      state.facebook = action.payload.value;
    },
    setInstagram: (state, action) => {
      state.instagram = action.payload.value;
    },
    setTwitter: (state, action) => {
      state.twitter = action.payload.value;
    },
    setLinkedin: (state, action) => {
      state.linkedin = action.payload.value;
    },
    setInterests: (state, action) => {
      state.interests = action.payload.interests;
    },
    setUserName: (state, action) => {
      state.userName = action.payload.value;
    },

    setSerachInterestQuery: (state, action) => {
      state.serachInterestQuery = action.payload.value;
    },

    setFoundSearchedInterest: (state, action) => {
      state.foundSearchedInterest = action.payload.value;
    },

    setHeighLightInterestId: (state, action) => {
      state.heighLightInterestId = action.payload.value;
    },

    addInterestToUserInterest: (state, action) => {
      let id = action.payload.id;
      let interest = action.payload.interest;
      let from = action.payload.from;

      let data = [...state.interests];

      let found = data.find((e) => {
        return e.id === id;
      });

      if (!found) {
        data.push({ id, interest, from });

        state.interests = data;
      } else {
        //
        state.heighLightInterestId = id;
        console.log("already interest exsists");
      }
    },

    removeInterestToUserInterest: (state, action) => {
      let index = action.payload.index;

      let data = [...state.interests];
      data.splice(index, 1);
      state.interests = data;
    },

    addInterestFromRecommendedInterest: (state, action) => {
      let id = action.payload.id;
      let interest = action.payload.interest;

      let data = [...state.recommendedInterests];
      data.push({ id, interest });
      state.recommendedInterests = data;
    },

    removeInterestFromRecommendedInterest: (state, action) => {
      let index = action.payload.index;

      let data = [...state.recommendedInterests];
      data.splice(index, 1);
      state.recommendedInterests = data;
    },

    addInterestFromSearchInterest: (state, action) => {
      let id = action.payload.id;
      let interest = action.payload.interest;

      let data = [...state.searchedInterests];
      data.push({ id, interest });
      state.searchedInterests = data;
    },

    removeInterestFromSearchInterest: (state, action) => {
      let index = action.payload.index;

      let data = [...state.searchedInterests];
      data.splice(index, 1);
      state.searchedInterests = data;
    },

    //EDIT PROFILE
    setEditProfile: (state, action) => {
      state.editProfile = action.payload.value;
    },
    setDataToEdit: (state, action) => {
      if (state.editProfile) {
        let {
          userName,
          firstName,
          lastName,
          email,
          phoneNumber,
          birthDate,
          gender,
          timeZone,
          bio,
          country,
          address,
          facebook,
          instagram,
          twitter,
          linkedin,
          interests,
        } = action.payload.userData;

        //ADDRESS
        let addressLine = address;
        let sentences = addressLine.split(/[!,?,.]/);
        state.address = sentences[0];
        state.city = sentences[1];
        state.country = country;

        //SOCIAL LINKS
        state.facebook = facebook;
        state.instagram = instagram;
        state.twitter = twitter;
        state.linkedin = linkedin;

        //personal information
        state.userName = userName;
        state.firstName = firstName;
        state.lastName = lastName;
        state.phoneNumber = phoneNumber;
        state.bio = bio;
        state.timeZone = timeZone;
        state.gender = gender;
        state.email = email;
        state.birthDate = birthDate;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //USER DATA
      .addCase(getUserProfileAsync.pending, (state, action) => {
        state.userDataLoading = true;
      })
      .addCase(getUserProfileAsync.fulfilled, (state, action) => {
        state.userDataLoading = false;
        state.userData = action.payload;
        console.log(action.payload);
        if (action.payload === null) {
          state.userDataFound = false;
        }
      })
      .addCase(getUserProfileAsync.rejected, (state, action) => {
        state.userDataLoading = false;
      });

    addNewInterestAPICase(builder);
    removeInterestAPICase(builder);
    getInterestsAPICase(builder);
    getRecommendedInterestAPICase(builder);
    algoliaInterestAPICase(builder);
    addInterestsAPICase(builder);
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserProfilePannel,
  //form
  setUserName,

  setProfileUrl,
  setSelectedFiles,
  clearSelectedFiles,
  setPreviewFiles,
  clearPreviewFiles,

  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setBirthDate,
  setGender,
  setTimeZone,
  setHeadLine,
  setBio,
  setCity,
  setAddress,
  setCountry,
  setFacebook,
  setInstagram,
  setTwitter,
  setLinkedin,
  setInterests,
  setSerachInterestQuery,
  removeInterestFromRecommendedInterest,
  addInterestToUserInterest,
  removeInterestToUserInterest,
  addInterestFromRecommendedInterest,
  setFoundSearchedInterest,
  removeInterestFromSearchInterest,
  addInterestFromSearchInterest,
  setHeighLightInterestId,

  //EDIT
  setDataToEdit,
  setEditProfile,
} = userProfileSlice.actions;

// create state here and export
// userProfile
export const userProfileState = (state) => state.userProfile;

// this default you have to import in store.js
export default userProfileSlice.reducer;
