import { atcb_action } from "add-to-calendar-button";
import { Button } from "react-bootstrap";
import "add-to-calendar-button/assets/css/atcb.css";
import { useSelector } from "react-redux";
import { sessionFormState } from "../Form/reduxSlice";
import { eventState } from "../../../../reduxSlice";

function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

export const Calender = ({ isEventCalender = false, index, selectedDate }) => {
  // const dispatch = useDispatch();

  const { agendaData } = useSelector(sessionFormState);
  let eventDate;
  let month, day, year;
  let eventDateISO;
  let title, description, startAt, endAt;

  const {
    eventData: {
      id,
      location,
      eventStartsAt,
      eventName,
      description: eventDescription,
    },
  } = useSelector(eventState);

  if (!isEventCalender) {
    eventDate = new Date(agendaData[selectedDate].date);
    month = eventDate.getUTCMonth() + 1;
    day = eventDate.getUTCDate() + 1;
    year = eventDate.getUTCFullYear();
    eventDateISO = year + "-" + month + "-" + day;
    title = agendaData[selectedDate]?.sessions[index].title;
    description = agendaData[selectedDate]?.sessions[index].description;
    startAt = agendaData[selectedDate]?.sessions[index].startAt;
    endAt = agendaData[selectedDate]?.sessions[index].endAt;
  } else {
    if (!id) {
      return;
    }

    eventDate = eventStartsAt.toDate();
    month = eventDate.getUTCMonth() + 1;
    day = eventDate.getUTCDate();
    year = eventDate.getUTCFullYear();

    eventDateISO = year + "-" + month + "-" + day;
    title = eventName;
    description = eventDescription;
    let hour = eventDate.getHours();

    if (hour < 10) {
      hour = `0${hour}`;
    }

    let minute = eventDate.getMinutes();

    if (minute < 10) {
      minute = `0${minute}`;
    }

    startAt = hour + ":" + minute;

    const endTimeDate = addHours(eventDate, 1);

    let endHour = endTimeDate.getHours();

    if (endHour < 10) {
      endHour = `0${endHour}`
    }

    let endMinute = endTimeDate.getMinutes();

    if (endMinute < 10) {
      endMinute = `0${endMinute}`
    }

    endAt = endHour + ":" + endMinute;


  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        console.log(eventDateISO);
        atcb_action({
          name: title,
          description: description,

          startDate: eventDateISO,
          endDate: eventDateISO,

          startTime: startAt,
          endTime: endAt,
          timeZone: "Asia/Kolkata",
          location: location,
          options: [
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "MicrosoftTeams",
            "Outlook.com",
            "Yahoo",
          ],
          iCalFileName: "Reminder-Event",
        });
      }}
    >
      <Button
        type="submit"
        value="save"
        variant="link"
        className="px-0 ms-2 text-decoration-none text-light shadow-none"
      >
        Add to Calendar
      </Button>
    </form>
  );
};
