import React from 'react';
import {Button} from "react-bootstrap";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";


const LocationSetting = () => {
    return (
<div className="min-vh-100">
    <AdminNavigation/>

            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                   <small> Settings</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Location Settings
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container">

                        <div className="row">
                            <div className="col-12 card bg-dark text-light border">

                                <form className="card-body">

                                        <h3>Geocode &amp; Autocomplete</h3>
                                        <div className="form-group mb-3">
                                            <label>Google Places API Key</label>
                                            <input  type="text" className="form-control bg-dark text-light"
                                                   placeholder="Enter the api key" />
                                        </div>
                                        <hr></hr>

                                            <h3>Default Location</h3>
                                            <div className="form-group mb-3">
                                                <label>City</label>

                                                <input type="text"
                                                       className="form-control bg-dark text-light" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>Country</label>
                                                <input  type="text"
                                                       className="form-control bg-dark text-light" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>Latitude</label>
                                                <input  type="text"
                                                       className="form-control bg-dark text-light" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label >Longitude</label>
                                                <input  type="text"
                                                       className="form-control bg-dark text-light" />
                                            </div>

                                            <div className="text-end">
                                                <Button type="submit" className="btn btn-primary">Update Settings
                                                </Button>
                                            </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>

    <Admin_Footer/>
</div>
            

    );
};

export default LocationSetting;