import { db_firestore, auth } from "../../../../../firebase.config";

import {
  doc,
  updateDoc,
  getDoc,
  FieldValue,
  arrayUnion,
  arrayRemove,
  query,
  collection,
  where,
  getDocs,
  increment,
  deleteDoc,
  Timestamp,
  addDoc,
} from "firebase/firestore";
import { async } from "@firebase/util";
import {
  getDocIdofUserAPI,
  getUserInfo,
} from "../../../../Main/Components/User/reduxAPI";
import { interestState } from "../../CommonComponents/Interest/reduxlice";
import group from "../../search/Group";

export async function getEachGroupAPI(groupId) {
  const docRef = doc(db_firestore, "groups", groupId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let doc = { ...docSnap.data(), id: docSnap.id };

    return doc;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function joinGroupAPI(groupId, uid) {
  const groupRef = doc(db_firestore, "groups", groupId);

  await updateDoc(groupRef, {
    memberUid: arrayUnion(uid),
    totalMember: increment(1),
  });
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    joinedGroupsId: arrayUnion(groupId),
  });
}

export async function leaveGroupAPI(groupId, uid) {
  const groupRef = doc(db_firestore, "groups", groupId);

  await updateDoc(groupRef, {
    memberUid: arrayRemove(uid),
    totalMember: increment(-1),
  });
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    joinedGroupsId: arrayRemove(groupId),
  });
}

//MEMBER FETCH
export async function getGroupMemberAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (group.memberUid.length <= 0) {
    return [];
  }

  let member = [];

  for (let index = 0; index < group.memberUid.length; index++) {
    const userDocq = query(
      collection(db_firestore, "users"),
      where("uid", "==", group.memberUid[index])
    );
    let docId;
    const querySnapshot = await getDocs(userDocq);
    querySnapshot.forEach((doc) => {
      docId = doc.id;
    });
    const userRef = doc(db_firestore, "users", docId);

    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      member.push({
        id: group.memberUid[index],
        ...userSnap.data(),
      });
    } else {
      console.log(group.memberUid[0]);
    }
  }

  return member;
}

//Group Events
export async function getGroupOnlyEventAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (group.eventUid.length <= 0) {
    return [];
  }

  let events = [];

  for (let index = 0; index < group.eventUid.length; index++) {
    const eventRef = doc(db_firestore, "events", group.eventUid[index]);

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      events.push({
        id: group.eventUid[index],
        ...eventSnap.data(),
      });
    } else {
      console.log(eventSnap);
    }
  }

  return events;
}

//GET GROUP ORGANIZER DETAIL
export async function getGroupOrganizerAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (!group.subscriberUid) {
    return null;
  }

  let organizer;

  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", group.subscriberUid)
  );
  let userId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    userId = doc.id;
  });
  const userRef = doc(db_firestore, "users", userId);

  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    organizer = userSnap.data();
  } else {
    console.log("Not found");
  }

  return organizer;
}

//GET UPCOMING EVENTS
export async function getGroupUpcomingEventAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (group.eventUid.length <= 0) {
    return [];
  }

  let events = [];

  for (let index = 0; index < group.eventUid.length; index++) {
    const eventRef = doc(db_firestore, "events", group.eventUid[index]);

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      let todayTime = new Date().getTime();
      let eventDateTime = eventSnap.data().eventStartsAt.toDate().getTime();

      if (todayTime <= eventDateTime && eventSnap.data().draft === false) {
        events.push({
          id: group.eventUid[index],
          ...eventSnap.data(),
        });
        console.log(eventSnap.data());
      }
    } else {
      console.log(events);
    }
  }

  return events;
}

//GET PAST EVENTS
export async function getGroupPastEventAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (group.eventUid.length <= 0) {
    return [];
  }

  let events = [];

  for (let index = 0; index < group.eventUid.length; index++) {
    const eventRef = doc(db_firestore, "events", group.eventUid[index]);

    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      let todayTime = new Date().getTime();
      let eventDateTime = eventSnap.data().eventStartsAt.toDate().getTime();

      if (todayTime >= eventDateTime && eventSnap.data().draft === false) {
        events.push({
          id: group.eventUid[index],
          ...eventSnap.data(),
        });
        console.log(eventSnap.data());
      }
    } else {
      console.log(events);
    }
  }

  return events;
}

//GET GROUP INTEREST
export async function getGroupInterestAPI(docId) {
  const groupRef = doc(db_firestore, "groups", docId);

  const groupSnap = await getDoc(groupRef);
  let group;
  if (groupSnap.exists()) {
    group = groupSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  if (group.categories.length <= 0) {
    return [];
  }

  let interest = [];

  for (let index = 0; index < group.categories.length; index++) {
    const interestRef = doc(db_firestore, "interests", group.categories[index]);

    const interestSnap = await getDoc(interestRef);

    if (interestSnap.exists()) {
      interest.push({
        id: group.categories[index],
        ...interestSnap.data(),
      });
    } else {
      console.log("NOT FOUND");
    }
  }
  console.log(interest);

  return interest;
}
/**
 * NOT_FOUND: no entity to update: app: "dev~networking-pre-event-app" path < Element { type: "events" name: "undefined" } >
 */

export async function getGroupId(companyName, groupName) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("companyName", "==", companyName)
  );
  let uid;

  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    uid = doc.data().uid;
  });

  const groupDocq = query(
    collection(db_firestore, "groups"),
    where("subscriberUid", "==", uid),
    where("groupName", "==", groupName)
  );

  let groupId;
  const groupSnapShot = await getDocs(groupDocq);
  groupSnapShot.forEach((doc) => {
    groupId = doc.id;
  });

  return groupId;
}

export async function getCmpandGrpfromGroupId(groupId) {
  let groupName;
  let companyName;

  const groupRef = doc(db_firestore, "groups", groupId);

  const groupSnap = await getDoc(groupRef);

  let subscriberUid;
  if (groupSnap.exists()) {
    groupName = groupSnap.data().groupName;
    subscriberUid = groupSnap.data().subscriberUid;
  }

  const response = await getDocIdofUserAPI(subscriberUid);

  const userRef = doc(db_firestore, "users", response.data);

  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    companyName = userSnap.data().companyName;
  }
  return { groupName, companyName };
}

//CREATE DISCUSSION
export async function createDiscussion(title, discussionDescription, groupId) {
  // Add a new document with a generated id.
  const docRef = await addDoc(
    collection(db_firestore, `groups/${groupId}/discussionData`),

    {
      title,
      discussionDescription,
      createdAt: Timestamp.fromDate(new Date()).toDate(),
    }
  );
  console.log(docRef);
  if (docRef.id) {
    return docRef;
  } else {
    return { notSubmitted: true };
  }
}

export async function getDiscussionAPI(groupId) {
  try {
    const groupDocq = query(
      collection(db_firestore, `groups/${groupId}/discussionData`)
    );
    const discussionArr = [];
    let docId = [];
    const querySnapshot = await getDocs(groupDocq);
    querySnapshot.forEach((doc) => {
      docId.push(doc.id);
    });

    for (let i = 0; i < docId?.length; i++) {
      const discussionRef = await doc(
        db_firestore,
        `groups/${groupId}/discussionData`,
        docId[i]
      );
      const discussionSnap = await getDoc(discussionRef);
      discussionArr.push({ id: docId[i], ...discussionSnap.data() });
    }
    return discussionArr;
  } catch (e) {
    console.log(e);
  }
}

export async function getOnlyDiscussionAPI(groupId, groupDiscussionId) {
  try {
    const discussionRef = doc(
      db_firestore,
      `groups/${groupId}/discussionData`,
      groupDiscussionId
    );
    const discussionSnap = await getDoc(discussionRef);

    const commentsQuery = query(
      collection(
        db_firestore,
        `groups/${groupId}/discussionData/${groupDiscussionId}/comments`
      )
    );

    const commentsSnapShot = await getDocs(commentsQuery);
    let comments = [];
    commentsSnapShot.forEach((doc) => {
      comments.push({ id: doc.id, ...doc.data() });
    });

    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];

      const userQuery = query(
        collection(db_firestore, `users`),
        where("uid", "==", comment.userId)
      );

      const userSnapShot = await getDocs(userQuery);
      let userData;

      userSnapShot.forEach((doc) => {
        userData = doc.data();
      });

      comments[i] = {
        ...comment,
        name: userData.firstName + " " + userData.lastName,
        profileUrl: userData.profileUrl,
      };
    }

    const response = { ...discussionSnap.data(), comments };
    return response;
  } catch (e) {
    console.log(e);
  }
}

//Add Comment
export async function AddCommentDiscussionAPI(
  groupId,
  groupDiscussionId,
  comment,
  userId
) {
  try {
    const docRef = await addDoc(
      collection(
        db_firestore,
        `groups/${groupId}/discussionData/${groupDiscussionId}/comments`
      ),
      {
        comment,
        userId,
      }
    );
  } catch (e) {
    console.log(e);
  }
}
