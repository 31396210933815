import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { xlsxSheetToJsonAPI } from "./reduxAPI";

import { read, utils } from "xlsx";
import { pushErrorNotification } from "../../../Error/reduxSlice";
import { Icon } from "@iconify/react";
import { sendMailAPI } from "../JoinEvent/reduxAPI";
import { eventDataByCompanyandEventname } from "../../Page Component/reduxAPI";
import { template3 } from "./template3";
// import { _ } from "./reduxAPI";

const initialState = {
  emailListFile: null,
  emailListJson: [],
};

export const sheetToJsonExcelEmailAsync = createAsyncThunk(
  "eventInvitation/sheetToJsonExcelEmail",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { emailListFile } = getState().eventInvitation;
      const f = await xlsxSheetToJsonAPI(emailListFile);

      const wb = read(f);
      const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendMailInvitationAsync = createAsyncThunk(
  "eventInvitation/sendMailInvitation",
  async (
    { companyName, eventName },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { emailListJson } = getState().eventInvitation;

      if (emailListJson.length !== 0) {
        let eventData = await eventDataByCompanyandEventname(
          companyName,
          eventName
        );

        for (let email = 0; email < emailListJson.length; email++) {
          const inviteEmail = emailListJson[email].Email;
          const inviteName = emailListJson[email].Name;

          console.log(inviteEmail, inviteName)

          const html = template3(eventData, inviteName);

          await sendMailAPI(
            inviteEmail,
            eventName,
            "Invitation to our Event",
            html
          );



        }
      } else {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Empty file",
              errorMessage: "Email File must be not empty",
              theme: "dark",
              time: "now",
              autoHideTime: 4000,
              statusCode: 404,
              show: true,
            },
          })
        );
      }

      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const eventInvitationSlice = createSlice({
  name: "eventInvitation",
  initialState,
  reducers: {
    setEmailListFile: (state, action) => {
      state.emailListFile = action.payload.file;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sheetToJsonExcelEmailAsync.pending, (state, action) => { })
      .addCase(sheetToJsonExcelEmailAsync.fulfilled, (state, action) => {
        state.emailListJson = action.payload;
      })
      .addCase(sheetToJsonExcelEmailAsync.rejected, (state, action) => { });
  },
});

export const { setEmailListFile } = eventInvitationSlice.actions;

export const eventInvitationState = (state) => state.eventInvitation;

export default eventInvitationSlice.reducer;
