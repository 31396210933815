import React,{useState} from 'react';
import {Link} from "react-router-dom";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import {Dropdown, Form, Pagination} from "react-bootstrap";

//icon
import {BsThreeDotsVertical} from  'react-icons/bs'
import Admin_Footer from "../../CommonComponents/Footer/App";




const ManageGroups = () => {
    return (
        <div className='min-vh-100'>
            <AdminNavigation/>
           

       
        <div className="page">
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center ">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle ">
                                   <small>Overview</small> 
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Groups
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12">
                                <form className="card border bg-dark text-light">
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">
                                            <div className="form-group">
                                                <input type="text" className="bg-dark text-light form-control" value=""
                                                       placeholder="Name" />
                                            </div>
                                            <div className="form-group ms-2">
                                                <input   type="text" className="bg-dark text-light form-control"
                                                        placeholder="Organizer" />
                                            </div>
                                            <div className="form-group ms-2 bg-dark text-light">
                                                <select
                                                        className="bg-dark text-light form-control">
                                                    <option selected="" value="">Select Status</option>
                                                    <option value="public">Public</option>
                                                    <option value="closed">Closed</option>
                                                    <option value="deleted">Deleted</option>
                                                </select>
                                            </div>
                                            <button type="submit" className="btn btn-link">
                                                <i className="fa fa-filter me-2"></i>
                                                Filter
                                            </button>
                                            <a className="btn btn-link"
                                               >
                                                <i className="fa fa-redo me-2"></i>
                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="card border bg-dark text-light">
                                    <div className="card-header">
                                        <h3 className="card-title">Groups</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table border bg-dark text-light card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Organizer</th>
                                                <th>Status</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="groups">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">103</p>
                                                </td>
                                                <td>
                                                    <Link className="mb-0" target="_blank" to = "">Berge LLC</Link>
                                                </td>
                                                <td>
                                                    <p className="mb-0">Jenifer Hills</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success">Public Group</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2022-07-10</p>
                                                </td>
                                                <td className="text-end">


                                                    <div className="dropdown">
                                                        <a   className=" " data-bs-toggle="dropdown"
                                                           aria-expanded="true">
                                                          <BsThreeDotsVertical/>

                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item"
                                                        >
                                                                Login &amp; Manage
                                                            </a>
                                                            <a className="dropdown-item text-danger delete-confirm"
                                                               
                                                               data-title="Are you sure?"
                                                               data-text="This record will be deleted.">
                                                                Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-center">
                                        <nav>
                                            <ul className="pagination">

                                                <li className="page-item disabled" aria-disabled="true"
                                                    aria-label="« Previous">
                                                    <span className  = "page-link bg-dark text-light bg-primary" aria-hidden="true">‹</span>
                                                </li>


                                                <li className="page-item active" aria-current="page"><span
                                                    className="page-link page-link bg-dark text-light bg-primary">1</span></li>
                                                <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                >2</a>
                                                </li>
                                                <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                >3</a>
                                                </li>
                                                <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                >4</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                >5</a>
                                                </li>
                                                <li className="page-item">
                                                    <a className  = "page-link bg-dark text-light bg-primary"

                                                       rel="next" aria-label="Next »">›</a>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 
            </div>
            
        </div>

            <Admin_Footer/>
        </div>
    );
};

export default ManageGroups;