import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {GrCircleAlert} from "react-icons/gr";

const AlertModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='px-0'
        >

            <Modal.Body>
                <div className="text-center">
                    <h1 className="fs-1"><GrCircleAlert/></h1>
                    <h4>Are you sure?</h4>
                    <p>This record will be deleted</p>
                    <div className="">
                        <Button className="m-2 bg-danger" onClick={props.onHide}>Delete</Button>
                        <Button className="m-2" onClick={props.onHide}>Close</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AlertModal;