import React from "react";

// Routes
import { useParams } from "react-router-dom";

// Icons
import { Icon } from "@iconify/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { loginState, loginUserAsync } from "../reduxSlice";
import {
  setFirstName,
  setLastName,
  registerState,
  setEmail,
  setPassword,
} from "../../Register/reduxSlice";
import {
  eventState,
  newUserCreateAsync,
  setLoginUserViaModal,
  setShowRegistrationForm,
} from "../../../Events/Page Component/reduxSlice";

const EventREgistrationInDb = () => {
  // Variable
  const dispatch = useDispatch();
  const { companyName, eventNameParams } = useParams();

  // State
  const {
    firstName,
    lastName,
    email,
    password,

    loading,
  } = useSelector(registerState);

  const {
    joinuser,
    formData,
    eventData: { subscriberUid },
  } = useSelector(eventState);
  const { loading: loginLoading } = useSelector(loginState);

  // Functions
  const registerUserHandler = async (e) => {
    e.preventDefault();

    dispatch(
      newUserCreateAsync({
        subscriberUid,
        companyName,
        eventNameParams,
      })
    );
  };

  const loginUserHandler = async (e) => {
    dispatch(setShowRegistrationForm({ value: false }));
    await dispatch(loginUserAsync({ email, password }));
    dispatch(setLoginUserViaModal({ value: false }));
  };

  return (
    <form className={` d-block mx-2  mx-sm-auto    rounded-3 `}>
      <div className="">
        {!joinuser ? (
          <div className="row mb-3">
            <div className="col-sm-6 col-12">
              {/* First Name */}
              <label htmlFor="firstName" className="form-label">
                First name
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => {
                  dispatch(setFirstName({ firstName: e.target.value }));
                }}
                required
                className="form-control bg-dark text-light "
                id="firstName"
                placeholder="First Name"
              />
            </div>

            <div className="col-sm-6 col-12">
              {/* Last Name */}
              <label htmlFor="lastName" className="form-label">
                Last name
              </label>
              <input
                type="text"
                value={lastName}
                required
                onChange={(e) => {
                  dispatch(setLastName({ lastName: e.target.value }));
                }}
                className="form-control bg-dark text-light "
                id="lastName"
                placeholder="Last Name"
              />
            </div>
          </div>
        ) : null}
        <div className="row mb-3">
          <div>
            {/* Email */}
            <label htmlFor="userEmail" className="form-label">
              Email
            </label>
            <input
              type="email"
              value={email}
              required
              onChange={(e) => {
                dispatch(setEmail({ email: e.target.value }));
              }}
              className="form-control bg-dark text-light "
              id="userEmail"
              aria-describedby="emailHelp"
              placeholder="name@address.com"
            />
          </div>
        </div>
      </div>
      {/* password */}
      <div className="mb-3">
        <label htmlFor="userPassword" className="form-label">
          Password
        </label>

        <input
          value={password}
          onChange={(e) => {
            dispatch(setPassword({ password: e.target.value }));
          }}
          required
          type="password"
          placeholder="password"
          className="form-control bg-dark text-light  "
          id="userPassword"
        />
      </div>
      <>
        {joinuser ? (
          <>
            <button
              type="button"
              onClick={loginUserHandler}
              disabled={loading || loginLoading}
              className="btn btn-primary w-100 d-block mx-auto my-1"
            >
              {loading || loginLoading ? (
                <Icon icon="eos-icons:bubble-loading" />
              ) : (
                "Join"
              )}
            </button>
          </>
        ) : (
          <>
            {!formData.formData ? (
              <button
                type="button"
                onClick={registerUserHandler}
                disabled={loading || loginLoading}
                className="btn btn-primary w-100 d-block mx-auto my-1"
              >
                {loading || loginLoading ? (
                  <Icon icon="eos-icons:bubble-loading" />
                ) : (
                  "Register"
                )}
              </button>
            ) : null}
          </>
        )}
      </>
    </form>
  );
};

export default EventREgistrationInDb;
