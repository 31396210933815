import React from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import {
  sendMailAsync,
  setTo,
  setSubject,
  setText,
  setHtml,
  mailState,
  clearInputs,
} from "./reduxSlice";

// icons
import { AiOutlineMail } from "react-icons/ai";

// Css
import "./App.css";

const MailButton = () => {
  const { to, subject, text, html } = useSelector(mailState);
  const dispatch = useDispatch();

  const SubmitMailHandler = (e) => {
    e.preventDefault();
    dispatch(
      sendMailAsync({
        to,
        subject,
        text,
        html,
      })
    );
    dispatch(clearInputs());
  };

  return (
    <div className=" col-md-5 col-10 d-block mx-auto vh-100 mt-5">
      <form onSubmit={SubmitMailHandler}>
        <div class="form-group">
          <label className="mb-1" for="exampleInputEmail1">
            To
          </label>
          <input
            type="email"
            value={to}
            onChange={(e) => {
              dispatch(setTo({ to: e.target.value }));
            }}
            required
            class="form-control mb-3 u bg-dark text-light "
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
        </div>
        <div class="form-group">
          <label className="mb-1" for="subject">
            Subject
          </label>
          <input
            required
            value={subject}
            onChange={(e) => {
              dispatch(setSubject({ subject: e.target.value }));
            }}
            type="text"
            class="form-control mb-3 u bg-dark text-light "
            id="subject"
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <label className="mb-1" for="text">
            Text
          </label>
          <input
            required
            value={text}
            onChange={(e) => {
              dispatch(setText({ text: e.target.value }));
            }}
            type="text"
            class="form-control mb-3 u bg-dark text-light "
            id="text"
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <label className="mb-1" for="html">
            Html
          </label>
          <input
            required
            value={html}
            onChange={(e) => {
              dispatch(setHtml({ html: e.target.value }));
            }}
            type="text"
            class="form-control mb-3 u bg-dark text-light "
            id="html"
            placeholder="Password"
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary d-block mx-auto px-3 py-2 "
        >
          Send Mail <AiOutlineMail className="mx-1" />
        </button>
      </form>
    </div>
  );
};

export default MailButton;
