import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { algoliaAPI } from "./reduxAPI";

// icon
// import { Icon } from "@iconify/react";

const initialState = {
  showInstallButton: false,
  supportsInstallButton: false,
  promptToInstallCb: undefined,

  searchLoading: false,
  searchFound: true,
  navigatePath: "/find/event",
  searchQuery: "",
  algoliaIndex: "events",
  result: [],
  stickyTop: "true",

  logoLink:
    "https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Flogo512.png?alt=media&token=ebab4817-f456-4398-bc63-13ff120be3b9",
  redirectLink: "/",

  hideLogin_Register: false,
};

export const algoliaAsync = createAsyncThunk(
  "navbar/algoliaAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { searchQuery, algoliaIndex } = getState().navBar;
      let result = await algoliaAPI(searchQuery, algoliaIndex);
      result.hits.map(async (hit, index) => {
        hit["id"] = hit["objectID"];
        delete (await hit["objectID"]);
        return hit;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const navBarSlice = createSlice({
  name: "navbar",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload.value;
    },
    setStickyTop: (state, action) => {
      state.stickyTop = action.payload.value;
    },

    setHideLogin_Register: (state, action) => {
      state.hideLogin_Register = action.payload.value;
    },

    setAlgoliaIndex: (state, action) => {
      state.algoliaIndex = action.payload.value;
    },
    setResult: (state, action) => {
      state.result = [...action.payload.result];
    },
    setSearchFound: (state, action) => {
      state.searchFound = action.payload.value;
    },
    setNavigatePath: (state, action) => {
      state.navigatePath = action.payload.navigate;
    },
    setLogoLink: (state, action) => {
      state.logoLink = action.payload.value;
    },
    setRedirectLink: (state, action) => {
      state.redirectLink = action.payload.value;
    },
    setInstallBtn: (state, action) => {
      state.supportsInstallButton = action.payload.value;
      state.promptToInstallCb = action.payload.cb;
    },
    resetEventLogonLink: (state, action) => {
      state.logoLink =
        "https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Flogo512.png?alt=media&token=ebab4817-f456-4398-bc63-13ff120be3b9";
      state.redirectLink = "/";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(algoliaAsync.pending, (state, action) => {
        state.searchLoading = true;
      })
      .addCase(algoliaAsync.fulfilled, (state, action) => {
        state.searchLoading = false;

        state.result = [...action.payload.hits];
        if (action.payload.hits.length <= 0) {
          state.searchFound = false;
        }
      })
      .addCase(algoliaAsync.rejected, (state, action) => {
        state.searchLoading = false;
        state.searchFound = false;
      });
  },
});

export const {
  setSearchQuery,
  setAlgoliaIndex,
  setResult,
  setSearchFound,
  setNavigatePath,
  setStickyTop,
  setHideLogin_Register,
  setLogoLink,
  setRedirectLink,
  resetEventLogonLink,
  setInstallBtn,
} = navBarSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const navBarState = (state) => state.navBar;

export default navBarSlice.reducer;
