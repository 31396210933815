import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Editor from "../../../../../Main/Components/TextEditor/App";
import { useDispatch, useSelector } from "react-redux";
import {
  createDiscussionAsync,
  getGroupDiscussionAsync,
  groupState,
  setDiscussionDescription,
  setTitle,
} from "../../MyGroupOnBoard/reduxSlice";
import { setMeetingDescription } from "../../../Calendar/reduxSlice";
import { pushErrorNotification } from "../../../../../Main/Components/Error/reduxSlice";
import { Icon } from "@iconify/react";

//text editor

const Add_Discussion = (props) => {
  const { groupData, generatedGroupId, title, discussionDescription } =
    useSelector(groupState);
  let groupId = generatedGroupId;
  const dispatch = useDispatch();
  const handleSubmitData = (e) => {
    e.preventDefault();

    if (!discussionDescription || !title) {
      dispatch(
        pushErrorNotification({
          notify: {
            iconifyIconLink: (
              <Icon icon="bx:notification" className="rounded" />
            ),
            errorTitle: "Invalid Input",
            errorMessage: "Please Enter content to display",
            theme: "danger",
            time: "now",
            autoHideTime: 3000,
            statusCode: 200,
            show: true,
          },
        })
      );
      return;
    }

    dispatch(createDiscussionAsync({ discussionDescription, title, groupId }));
    props.onHide();
    dispatch(getGroupDiscussionAsync({groupId}))

  };
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size="lg"
        className="px-0"
      >
        <Modal.Header closeButton className="bg-dark text-light border">
          <Modal.Title id="contained-modal-title-vcenter">
            CREATE A DISCUSSION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light border">
          <div className="mb-4">
            <label className="form-label">Title</label>
            <input
              className="form-control bg-dark text-light"
              name="title"
              type="text"
              placeholder="Title"
              required=""
              value={title}
              onChange={(e) => {
                dispatch(setTitle({ value: e.target.value }));
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="content">
              Content
            </label>
            <Form.Control
              className="bg-dark text-light border"
              as="textarea"
              rows={3}
              value={discussionDescription}
              onChange={(e) => {
                dispatch(setDiscussionDescription({ value: e.target.value }));
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light border">
          <Button onClick={handleSubmitData}>Upload</Button>
          <Button className="bg-dark text-light border" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Add_Discussion;
