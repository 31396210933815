import {BsImages} from "@react-icons/all-files/bs/BsImages";
import React from "react";

export default function NoPhoto() {
    return (
        <div>
            <div className="mb-4">
                <div className="col-12 d-flex flex-column align-items-center  border rounded p-5 mb-4">
                    <BsImages/>
                    <small className="mb-0 mt-2">No photos yet</small>
                </div>
            </div>

        </div>
    )
}