import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../firebase.config";

export function addInterest(interest, docId) {
  const addInterestfn = httpsCallable(functions, "addInterests");
  return addInterestfn({
    interest,
    docId,
  });
}
