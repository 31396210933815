import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

// Bootstrap
import { Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { pushErrorNotification } from "../../../Error/reduxSlice";
import { setHideFooter } from "../../../Footer/reduxSlice";
import { eventState } from "../../Page Component/reduxSlice";
import { algoliaUsersAsync } from "../../Page Component/Speakers/reduxSlice";

// Css
import "./App.css";
import { getUpdatedEventRoleAPI } from "./reduxAPI";
import {
  addRoleInEventUidAsync,
  eventNetworkingMangState,
  getUpdatedEventRoleAsync,
  removeRoleInEventUidAsync,
  roleManagementEventAsync,
  setRole,
  setUserNameQuery,
} from "./reduxSlice";

const NetworkingManagement = () => {
  const dispatch = useDispatch();

  const { userNameQuery, searchedUsers, role } = useSelector(
    eventNetworkingMangState
  );

  const {
    eventData: { sponsorsId, boothOwnersId, speakersId },
  } = useSelector(eventState);

  useEffect(() => {
    dispatch(getUpdatedEventRoleAsync({}));

    return () => {};
  }, [role, dispatch]);

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));

    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, [dispatch]);

  const selectRoleHandler = (e) => {
    dispatch(setRole({ value: e.target.value }));
  };

  const pushSelectRoleNotification = () => {
    dispatch(
      pushErrorNotification({
        notify: {
          iconifyIconLink: <Icon icon="bx:notification" className="rounded" />,
          errorTitle: "Select role",
          errorMessage: "Please select role to add this user",
          theme: "dark",
          time: "now",
          autoHideTime: 3000,
          statusCode: 200,
          show: true,
        },
      })
    );
  };

  return (
    <div class="container mt-3 ">
      <div class="row">
        <div class="col-3 d-flex justify-content-center align-items-center  ">
          <Form.Label htmlFor="userName">Search User To Assign Role</Form.Label>
        </div>
        <div class="col-6">
          <Form.Control
            type="search"
            className="bg-dark text-light col-9 "
            value={userNameQuery}
            onChange={(e) => {
              if (!role) {
                pushSelectRoleNotification();
                return;
              }

              dispatch(setUserNameQuery({ value: e.target.value }));
              dispatch(roleManagementEventAsync({}));
            }}
            id="userName"
            aria-describedby="passwordHelpBlock"
          />
        </div>
        <div class="col-3">
          <select
            onChange={selectRoleHandler}
            class="form-select bg-dark text-light "
            aria-label="Default select example"
            value={role}
          >
            <option value="" selected>
              Select Role
            </option>
            <option value="sponsor">Sponsor</option>
            <option value="boothOwner">Booth Owner</option>
            <option value="speaker">Speaker</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-3 d-flex justify-content-center align-items-center  "></div>
        <div class="col-6">
          {searchedUsers.map(
            ({ firstName, lastName, profileUrl, email, uid }) => {
              return (
                <UserCard
                  firstName={firstName}
                  email={email}
                  lastName={lastName}
                  profileUrl={profileUrl}
                  uid={uid}
                />
              );
            }
          )}
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  );
};

export default NetworkingManagement;

const UserCard = ({ firstName, lastName, email, profileUrl, uid }) => {
  const dispatch = useDispatch();
  const ref = useRef();

  const { userNameQuery, searchedUsers, role } = useSelector(
    eventNetworkingMangState
  );
  const {
    eventData: { sponsorsId, boothOwnersId, speakersId },
  } = useSelector(eventState);

  const [alreadyAssigned, setalreadyAssigned] = useState(false);

  // let alreadyAssigned = false;

  useEffect(() => {
    switch (role) {
      case "sponsor":
        if (sponsorsId) {
          // alreadyAssigned = sponsorsId.includes(uid);
          setalreadyAssigned(sponsorsId.includes(uid));
        } else {
          setalreadyAssigned(false);
        }
        break;

      case "boothOwner":
        if (boothOwnersId !== null) {
          // alreadyAssigned = boothOwnersId.includes(uid);
          setalreadyAssigned(boothOwnersId.includes(uid));
        } else {
          setalreadyAssigned(false);
        }
        break;

      case "speaker":
        if (speakersId) {
          // alreadyAssigned = speakersId.includes(uid);
          setalreadyAssigned(speakersId.includes(uid));
        } else {
          setalreadyAssigned(false);
        }
        break;

      default:
        break;
    }

    return () => {};
  }, [role, boothOwnersId, speakersId, sponsorsId, uid]);

  useEffect(() => {
    if (alreadyAssigned) {
      ref.current.checked = true;
    } else {
      ref.current.checked = false;
    }

    return () => {};
  }, [alreadyAssigned]);

  const pushSelectRoleNotification = () => {
    dispatch(
      pushErrorNotification({
        notify: {
          iconifyIconLink: <Icon icon="bx:notification" className="rounded" />,
          errorTitle: "Select role",
          errorMessage: "Please select role to add this user",
          theme: "dark",
          time: "now",
          autoHideTime: 3000,
          statusCode: 200,
          show: true,
        },
      })
    );
  };

  return (
    <div className="row  my-1   justify-content-center align-items-center   ">
      <div className="col-2  ">
        <img
          width={50}
          className={"rounded-circle profileImg_User "}
          src={profileUrl}
          alt={firstName}
        />
      </div>

      <span className={"col-4 text-capitalize "}>
        {firstName} {lastName}
      </span>
      <span className={"col-5"}>{email}</span>

      <div class="form-check col-auto">
        <input
          class="form-check-input"
          type="checkbox"
          ref={ref}
          onChange={(e) => {
            if (!role) {
              e.target.checked = !e.target.checked;
              pushSelectRoleNotification();
              return;
            }

            if (alreadyAssigned) {
              dispatch(removeRoleInEventUidAsync({ uid }));
              setalreadyAssigned(false);
            } else {
              dispatch(addRoleInEventUidAsync({ uid }));
              setalreadyAssigned(true);
            }
          }}
          value=""
          id="flexCheckDefault"
        />
      </div>
    </div>
  );
};
