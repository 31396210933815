import React, { useEffect, useState, Suspense } from "react";

// Routes
import { useParams } from "react-router-dom";

// Css
import "../../../Test/components/MyGroup/RegistrationForm/components/react-form-builder/App.css";
import "./EventPage.css";

// Bootstrap
import { Container, Modal, Image, Placeholder, Button, Carousel } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
// Components
import Banner from "../../Components/Events/Page Component/Banner/App";
import EventPageSkeleton from "../../Components/Events/PlaceHolder/EventPageSkeleton";
import ShareModel from "../../Components/ShareModal/App";
import Details from "../../Components/Events/Page Component/Details/App";

import Poll from "../../Components/Events/Page Component/Poll/App";
import Chat from "../../Components/Events/Page Component/Chat/App";
import QNA from "../../Components/Events/Page Component/Qna/App";
import Documents from "../../Components/Events/Page Component/Documents/App";
import EventREgistrationInDb from "../../Components/auth/Login/EventLogin/App";
import EventTabs from "../../Components/Events/Page Component/Tabs/App";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  eventState,
  eventAsync,
  setShowRegistrationForm,
  clearEventState,
  newUserCreateAsync,
  eventRegistrationFormCheck,
  setIsEventPage,
} from "../../Components/Events/Page Component/reduxSlice";
import { userState } from "../../Components/User/reduxSlice";

/**
 * Other laibrary
 */

// React Form Builder
import { ReactFormGenerator } from "react-form-builder2";
import * as variables from "../../../Test/components/MyGroup/RegistrationForm/components/variables";
import {
  clearForm,
  getSpeakersAsync,
} from "../../Components/Events/Page Component/Speakers/reduxSlice";
import {
  resetEventLogonLink,
  setHideLogin_Register,
  setLogoLink,
  setRedirectLink,
} from "../../Components/Navbar/reduxSlice";
import {
  clearFormGeneratorData,
  getFormDataAsync,
} from "../../Components/Events/Page Component/Documents/components/FormGenerator/reduxSlice";
import { getAgendaPdfUrlAsync } from "../../Components/Events/Page Component/Schedule/reduxSlice";
import { getSponsorAsync } from "../../Components/Events/Page Component/Sponsors/reduxSlice";
import { getBoothsAsync } from "../../Components/Events/Page Component/Booths/reduxSlice";
import { getQnAAsync } from "../../Components/Events/Page Component/WhyAttend/reduxSlice";
import { getAgendaByEventIdAsync } from "../../Components/Events/Page Component/Schedule/components/sessions/Form/reduxSlice";
import { getFaqAsync } from "../../Components/Events/Page Component/FAQ/reduxSlice";
import { getVenueInformationAsync } from "../../Components/Events/Page Component/Details/venueInfo/reduxSlice";
import { getMyStatusAsync } from "../../Components/Events/Page Component/CheckInCheckOut/reduxSlice";
import CheckInCheckOut from "../../Components/Events/Page Component/CheckInCheckOut/App";

// React lazy
const Speakers = React.lazy(() =>
  import("../../Components/Events/Page Component/Speakers/App")
);
const Sponsors = React.lazy(() =>
  import("../../Components/Events/Page Component/Sponsors/App")
);
const Booths = React.lazy(() =>
  import("../../Components/Events/Page Component/Booths/App")
);
const Schedule = React.lazy(() =>
  import("../../Components/Events/Page Component/Schedule/App")
);
const WhyAttend = React.lazy(() =>
  import("../../Components/Events/Page Component/WhyAttend/App")
);
const FAQ = React.lazy(() =>
  import("../../Components/Events/Page Component/FAQ/App")
);

export default function EventPage() {
  const { companyName, eventNameParams } = useParams();

  const {
    isEventPage,
    joinuser,
    eventPageNav,
    // eventActionLoading,
    loading,
    eventData: {
      id: eventId,
      attendeesId,
      subscriberUid,
      displayPdf,
      eventLogo,
      eventLogoRedirectLink,
      //   groupId: groupIdDb,
      //   description,
      //   draft,
      //   eventEndsAt,
      //   eventMode,
      //   eventName,
      //   eventStartsAt,
      //   eventType,
      //   link,
      //   location,

      tabsManagement,
    },
    // groupData: { groupGallery, groupName, description: groupDescription },
    showRegistrationForm,
    formData,

    // foundForm,
  } = useSelector(eventState);

  const { userAvl, user, docId } = useSelector(userState);

  // Image Loading
  const [imgLoading, setImgLoading] = useState(true);

  const [showShare, setShowShare] = useState(false);
  const [poll, setPollShow] = useState(false);
  const [qa, setQAShow] = useState(false);
  const [popUp, setPopUpShow] = useState(false);
  const [chat, setChatShow] = useState(false);
  const [allTabsHidden, setAllTabsHidden] = useState(false);

  const handleClose = () => setShowShare(false);
  const handleShareShow = () => setShowShare(true);

  const handlePollShow = () => setPollShow(true);
  const handlePollClose = () => setPollShow(false);

  const handleQAShow = () => setQAShow(true);
  const handleQAClose = () => setQAShow(false);

  const handleChatShow = () => setChatShow(true);
  const handleChatClose = () => setChatShow(false);
  const handlePopUpShow = () => setPopUpShow(true);
  const handlePopUpClose = () => setPopUpShow(false);

  let going;
  if (userAvl) {
    going = attendeesId?.includes(user?.uid);
  }

  useEffect(() => {
    let HiddenCount = 0;

    for (let i = 0; i < tabsManagement?.length; i++) {
      if (tabsManagement[i].show) {
        break;
      } else {
        HiddenCount++;
      }
    }
    if (HiddenCount === tabsManagement?.length) {
      setAllTabsHidden(true);
    }

    return () => { };
  }, [tabsManagement]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHideLogin_Register({ value: true }));
    (async () => {
      await dispatch(eventAsync({ companyName, eventNameParams }));

      dispatch(getFormDataAsync({}));
      dispatch(getSpeakersAsync());
    })();

    return () => {
      dispatch(clearEventState({}));
      dispatch(clearForm({}));
      dispatch(clearFormGeneratorData({}));
      dispatch(setHideLogin_Register({ value: false }));
    };
  }, [dispatch, companyName, eventNameParams]);

  useEffect(() => {
    dispatch(setIsEventPage({ value: true }));

    return () => {
      dispatch(setIsEventPage({ value: false }));
    };
  }, [dispatch, isEventPage]);

  useEffect(() => {
    if (isEventPage == true && eventId == 'wg9ZthfosdfQ3ywVd0ii') {
      setPopUpShow(true);
    }
  }, [isEventPage]);

  useEffect(() => {
    if (eventId) {
      dispatch(getSponsorAsync({}));
      dispatch(getBoothsAsync({}));
      dispatch(getQnAAsync({}));
      dispatch(getAgendaByEventIdAsync({}));
      dispatch(getFaqAsync({}));
      dispatch(getVenueInformationAsync({}));

    }
  }, [dispatch, eventId]);
  useEffect(() => {
    if (eventId && docId) {
      dispatch(getMyStatusAsync({}));
    }
  }, [dispatch, eventId, docId]);

  useEffect(() => {
    if (eventLogo && eventLogoRedirectLink) {
      dispatch(setLogoLink({ value: eventLogo }));
      dispatch(setRedirectLink({ value: eventLogoRedirectLink }));
    }

    return () => {
      dispatch(resetEventLogonLink({}));
    };
  }, [dispatch, eventLogo, eventLogoRedirectLink]);

  useEffect(() => {
    if (!going) {
      dispatch(eventRegistrationFormCheck({ companyName, eventNameParams }));
    }
  }, [going, companyName, eventNameParams, dispatch]);

  useEffect(() => {
    if (displayPdf) {
      dispatch(getAgendaPdfUrlAsync({}));
    } else {
      // get session data
    }
    return () => { };
  }, [dispatch, displayPdf]);

  const registrationFormDataHandler = async (data) => {
    dispatch(
      newUserCreateAsync({
        subscriberUid,
        companyName,
        eventNameParams,
        data,
      })
    );
  };

  return (
    <>

      {loading ? (
        <EventPageSkeleton />
      ) : (
        <>
          <Banner handleShareShow={handleShareShow} />
          {!allTabsHidden ? (
            <EventTabs
              handleShareShow={handleShareShow}
              handlePollShow={handlePollShow}
              handlePollClose={handlePollClose}
              handleQAShow={handleQAShow}
              handleQAClose={handleQAClose}
              handleChatShow={handleChatShow}
              handleChatClose={handleChatClose}
            />
          ) : null}

          <ShareModel show={showShare} handleClose={handleClose} />
          <Poll show={poll} onHide={handlePollClose} />
          <Chat show={chat} onHide={handleChatClose} />
          <QNA show={qa} onHide={handleQAClose} />

          <Documents />
          <Container className="my-3">
            {(eventPageNav === "Event Details" && <Details />) ||
              (eventPageNav === "Speakers" && (
                <Suspense fallback={null}>
                  <Speakers />
                </Suspense>
              )) ||
              (eventPageNav === "Sponsors" && (
                <Suspense fallback={null}>
                  <Sponsors />
                </Suspense>
              )) ||
              (eventPageNav === "Booths" && (
                <Suspense fallback={null}>
                  <Booths />
                </Suspense>
              )) ||
              (eventPageNav === "Agenda" && (
                <Suspense fallback={null}>
                  <Schedule />
                </Suspense>
              )) ||
              (eventPageNav === "Why attend" && (
                <Suspense fallback={null}>
                  <WhyAttend />
                </Suspense>
              )) ||
              (eventPageNav === "FAQ" && (
                <Suspense fallback={null}>
                  <FAQ />
                </Suspense>
              )) ||
              (eventPageNav === "Poll" && <Details />) ||
              (eventPageNav === "Q&A" && <Details />) ||
              (eventPageNav === "General Chat" && <Details />) ||
              (eventPageNav === "Upload" && <Details />)}
          </Container>

          {/* Pop Up */}

          <Modal
            className="text-light p-0 border border-0"
            show={popUp}
            onHide={handlePopUpClose}
            centered
            id="popUp"
          >
            <Modal.Body className="bg-transparent text-light p-0 rounded overflow-hidden border border-0">
              <Button
                variant="link"
                className="p-0 fs-4 position-absolute end-0 text-white shadow-none
                "
                style={{ top: "-10px", zIndex: '10000' }}
                onClick={handlePopUpClose}
              >
                <AiOutlineCloseCircle />
              </Button>
              <Carousel interval={3000}>
                <Carousel.Item>
                  <Image
                    draggable={false}
                    style={{
                      aspectRatio: "4/5",
                      objectFit: "contain",
                    }}
                    className={` img-fluid w-100 ${imgLoading ? "d-none" : null
                      }`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FElecramaPop1.jpeg?alt=media&token=a6992c1c-f6e1-4ae6-b8a0-dd3cf456e19e"
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="w-100 h-100"
                        style={{
                          aspectRatio: "4/5 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    draggable={false}
                    style={{
                      aspectRatio: "4/5",
                      objectFit: "contain",
                    }}
                    className={` img-fluid w-100 ${imgLoading ? "d-none" : null
                      }`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FElecramaPop2.jpeg?alt=media&token=fa8acedd-a6fc-4db3-b813-0ffa8f9f243c"
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="w-100 h-100"
                        style={{
                          aspectRatio: "4/5 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </Carousel.Item>
                <Carousel.Item>
                  <Image
                    draggable={false}
                    style={{
                      aspectRatio: "4/5",
                      objectFit: "contain",
                    }}
                    className={` img-fluid w-100 ${imgLoading ? "d-none" : null
                      }`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FElecramaPop3.jpeg?alt=media&token=48feb139-bf95-452e-b6ca-7e2fa7924251"
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="w-100 h-100"
                        style={{
                          aspectRatio: "4/5 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </Carousel.Item>
              </Carousel>
            </Modal.Body>
          </Modal>
        </>
      )}
      {/* Model 1 */}
      <Modal
        className="eventPageRegistrationForm "
        show={showRegistrationForm}
        onHide={() => {
          dispatch(setShowRegistrationForm({ value: false }));
        }}
      >
        <Modal.Header className="bg-dark text-light">
          <Modal.Title>Please fill the form to join Event.</Modal.Title>
        </Modal.Header>
        <Modal.Body
          id="attendEvent"
          className="registrationForm_biz bg-dark text-light"
        >
          {!userAvl ? <EventREgistrationInDb /> : null}

          {formData.formData && !joinuser ? (
            <ReactFormGenerator
              download_path=""
              back_action="/"
              back_name="Back"
              answer_data={{}}
              action_name="Save"
              form_action="/"
              form_method="POST"
              onSubmit={registrationFormDataHandler}
              variables={variables}
              data={formData.formData}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
