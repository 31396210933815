import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { ItemTypes } from "./ItemTypes.js";
import { BiMove } from "react-icons/bi";
import { Form } from "react-bootstrap";
import {
  handleLabelChange,
  handleMultipleChange,
  handleRemoveFields,
  handleRequiredChange,
} from "./reduxSlice.js";
const style = {
  border: "1px dashed gray",
  cursor: "default",
};
const handleStyle = {
  cursor: "move",
  border: "1px dashed gray",
};
export const Card = ({ _id, label, type, required, index, moveCard }) => {
  const dispatch = useDispatch();

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { _id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <div
      className="w-100 p-4 pt-5 position-relative mb-3 text-light bg-dark shadow-sm"
      ref={drop}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
    >
      <div key={index} className=" w-100 row mx-0 px-0">
        <div className="col-12 col-lg-8 px-0 mb-2 mb-lg-0">
          <Form.Control
            type={"text"}
            value={label}
            onChange={(event) => {
              dispatch(handleLabelChange({ index, value: event.target.value }));
            }}
            className="p-2 bg-dark text-light "
          />
        </div>
        <div className="col-12 col-lg-4 d-flex gap-3 justify-content-end px-0 align-items-center">
          <div className=" d-flex align-items-center justify-content-center ">
            <Form.Check
              id={"required_" + _id}
              name={"required"}
              checked={required}
              onChange={(event) => {
                dispatch(handleRequiredChange({ index }));
              }}
              label="Required"
            />
          </div>
          <button
            onClick={(e) => {
              dispatch(handleRemoveFields({ index }));
            }}
            className="btn btn-primary "
          >
            Remove
          </button>
        </div>
      </div>
      <div
        ref={ref}
        style={handleStyle}
        className="position-absolute end-0 top-0 p-2 d-flex justify-content-center align-items-center bg-dark text-light "
      >
        <BiMove />
      </div>
    </div>
  );
};
