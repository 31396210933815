import React from 'react' 

// Bootstrap
import {Container, Form, Button } from 'react-bootstrap';

//Icon 

// Css
import styles from "../App.module.css";
import '../App.css'

export default function PrivacyPanel(){
    return (
        <>
            <Container className='mt-3 px-lg-5 px-2 vh-100'>
                <h4>Notification Settings</h4>
                <p>Manage your email notification settings</p>
                <hr/>
                <Form>
                    <Container className='d-flex justify-content-between'>
                        <p className='lh-sm'>New event announcements from the groups that you are member of
                            <br />
                            <span className='fw-light'><small>On your profile, anyone can see all the Network groups you belong to.</small></span>
                        </p>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                        />
                    </Container>
                    <hr/>
                    <Container className='d-flex justify-content-between'>
                        <p className='lh-sm'>New event announcements from the groups that you are member of
                            <br />
                            <span className='fw-light'><small>On your profile, anyone can see all the Network groups you belong to.</small></span>
                        </p>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                        />
                    </Container>
                    <Button variant="primary" className='d-block ms-auto mt-4' type="submit">
                        Submit
                    </Button>
                </Form>
                
            </Container>
        </>
    )
}