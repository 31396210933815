import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
//Components
import NoPhoto from "../Photo/NoPhoto/App";

//Bootstrap Icon
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

import Disscusion_Card from "../MyGroup_Props/Discussion_Card/App";
import { RiAccountCircleFill } from "@react-icons/all-files/ri/RiAccountCircleFill";
import "./App.css";

//Redux
import {
  getGroupIdAsync,
  groupInterestAsync,
  groupMemberAsync,
  groupOrganizerDetailsAsync,
  groupState,
  groupUpcomingEventAsync,
  setGroupPage,
} from "../MyGroupOnBoard/reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EventCard from "../MyGroup_Props/EventCard/App";
import Upcoming from "../Event/Upcoming/App";
import EventGroupCard from "../Event/EventGroupCard/App";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { MdCheck, MdContentCopy } from "react-icons/md";

const MemberDisplay = () => {
  const [copied, setCopied] = useState(false);
  let { companyName, groupNameParams } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (companyName && groupNameParams) {
      dispatch(groupMemberAsync({ companyName, groupNameParams }));
    }
  }, [dispatch, companyName, groupNameParams]);
  const { groupMemberLoading, groupMemberDataArr, foundGroupMember } =
    useSelector(groupState);

  //COPY GROUP LINK
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <>
      {groupMemberDataArr?.length > 0 ? (
        groupMemberDataArr.map(
          (member) =>
            (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {member.firstName} {member.lastName}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <div
                    variant="light"
                    {...triggerHandler}
                    className="d-inline-flex align-items-center"
                  >
                    <div ref={ref}>
                      <RiAccountCircleFill />
                    </div>
                  </div>
                )}
              </OverlayTrigger>
            ) || "not found"
        )
      ) : (
        <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-2 border mb-4">
          <div className="d-flex align-items-center">
            <div onClick={copy} className=" fs-6 mb-2 mt-2">
              <small>
                No member found{" "}
                <span className="text-tertiary fw-bold">Share</span>
              </small>
            </div>
            <div onClick={copy} className="">
              {copied ? (
                <div className="border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2">
                  <MdCheck />
                </div>
              ) : (
                <div className="border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2">
                  <MdContentCopy />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/*        {foundGroupMember ?*/}
      {/*            groupMemberDataArr.map(member => (*/}
      {/*                <OverlayTrigger*/}
      {/*                    placement="bottom"*/}
      {/*                    overlay={<Tooltip id="button-tooltip-2">{member.firstName} {member.lastName}</Tooltip>}*/}
      {/*                >*/}
      {/*                    {({ref, ...triggerHandler}) => (*/}
      {/*                        <div*/}
      {/*                            variant="light"*/}
      {/*                            {...triggerHandler}*/}
      {/*                            className="d-inline-flex align-items-center"*/}
      {/*                        >*/}
      {/*                            <div ref={ref}>*/}
      {/*                                <RiAccountCircleFill/>*/}
      {/*                            </div>*/}
      {/*                        </div>*/}
      {/*                    )}*/}
      {/*                </OverlayTrigger>) || "not found"*/}
      {/*            )*/}
      {/*            : (*/}

      {/*                <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-2 border mb-4">*/}
      {/*                    <div className='d-flex align-items-center'>*/}
      {/*                        <div onClick={copy} className=" fs-6 mb-2 mt-2"><small>Share your Group</small> </div>*/}
      {/*                        <div  onClick={copy}  className=''>{copied ?<div className='border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2'> <MdCheck /></div> : <div className='border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2'> <MdContentCopy /> </div>}</div>*/}
      {/*                    </div>*/}

      {/*                </div>*/}
      {/*            )}*/}
      {/*    </>*/}
      {/*)}*/}
      {/*{*/}
      {/*    groupMemberDataArr.map(member => (*/}
      {/*        <OverlayTrigger*/}
      {/*            placement="bottom"*/}
      {/*            overlay={<Tooltip id="button-tooltip-2">{member.firstName} {member.lastName}</Tooltip>}*/}
      {/*        >*/}
      {/*            {({ref, ...triggerHandler}) => (*/}
      {/*                <div*/}
      {/*                    variant="light"*/}
      {/*                    {...triggerHandler}*/}
      {/*                    className="d-inline-flex align-items-center"*/}
      {/*                >*/}
      {/*                    <div ref={ref}>*/}
      {/*                        <RiAccountCircleFill/>*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            )}*/}
      {/*        </OverlayTrigger>) || "not found"*/}
    </>
  );
};

//Read More Function
export const ReadMore = ({ description }) => {
  const Text = parse(description || "Not Found");
  const [isReadMore, setIsReadMore] = useState(true);
  const str = parse(description.slice(0, 500) + "..." || "Not Found");
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text">
      {isReadMore ? str : Text}
      <span onClick={toggleReadMore} className="text-primary expand_collapse">
        {isReadMore ? "read more" : " show less"}
      </span>
    </p>
  );
};

const About = () => {
  const { companyName, groupNameParams } = useParams();

  const refContainer = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (refContainer.current) {
      setDimensions({
        width: refContainer.current.offsetWidth,
        height: refContainer.current.offsetHeight,
      });
    }
  }, []);

  const {
    generatedGroupId,
    //Organizer details
    groupOrganizerDetailsLoading,
    groupOrganizerDetails,
    foundGroupOrganizerDetails,
    //Organizer details
    upcomingEventDataArr,
    groupPage,
    loading: fetchLoading,
    groupActionLoading,
    groupData: {
      groupName,
      groupType,
      subscriberUid,
      location,
      groupGallery,
      description,
      memberUid,
      totalMember,
      categories,
    },

    //interest
    groupInterestLoading,
    groupInterestArr,
  } = useSelector(groupState);

  const Text = parse(description || "Not Found");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupOrganizerDetailsAsync({ companyName, groupNameParams }));
    dispatch(groupUpcomingEventAsync({ companyName, groupNameParams }));
    dispatch(groupInterestAsync({ companyName, groupNameParams }));
  }, [dispatch, companyName, groupNameParams]);

  return (
    <div>
      {fetchLoading ? null : (
        <div id="about" className="container py-5">
          <div className="row align-items-start">
            <div className="col-lg-7">
              <h5 className="mb-3 fw-bold">About</h5>

              <div className="mb-4">
                {dimensions.height <= 200 ? (
                  <div ref={refContainer}>{Text}</div>
                ) : (
                  <ReadMore description={description}></ReadMore>
                )}
              </div>

              <div className="row mb-3">
                <div className="col">
                  <h5 className="fw-bold">
                    Upcoming Events ({upcomingEventDataArr?.length})
                  </h5>
                </div>
                <div className="col col-auto">
                  <span
                    className="cursor-event"
                    onClick={(e) => dispatch(setGroupPage({ newPage: "2" }))}
                  >
                    See all
                  </span>
                </div>
              </div>
              <div className="mb-4">
                <Upcoming />
              </div>

              <div className="row mb-3">
                <div className="col mb-3">
                  <h5 className="fw-bold">Photos ({groupGallery?.length})</h5>
                </div>
                <div className="col col-auto">
                  <span
                    className="cursor-event"
                    onClick={(e) => dispatch(setGroupPage({ newPage: "4" }))}
                  >
                    See all
                  </span>
                </div>

                <div className="row">
                  {/*PHOTO DISPLAY*/}
                  <div className="row">
                    {fetchLoading ? (
                      <h1>Loading</h1>
                    ) : groupGallery ? (
                      groupGallery.map((images, index) => (
                        <div className="col-md-8" key={index}>
                          <Image
                            className="p-1"
                            style={{ width: "100%" }}
                            Fuild
                            src={images}
                          />
                        </div>
                      ))
                    ) : (
                      <NoPhoto />
                    )}
                  </div>
                  {/*PHOTO DISPLAY*/}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <h5 className="fw-bold">Discussions (2)</h5>
                </div>
                <div className="col col-auto">
                  <span
                    className="cursor-event"
                    onClick={(e) => dispatch(setGroupPage({ newPage: "5" }))}
                  >
                    See all
                  </span>
                </div>
              </div>
              <div className="mb-4">
                <div className="row">
                  <Disscusion_Card />
                </div>
              </div>

              <h5 className="mb-3 fw-bold">Related topics</h5>
              <ul className="list-inline mb-4">
                {groupInterestArr?.map((data) => (
                  <li className="list-inline-item mb-2" key={data.id}>
                    <a>
                      <span className="badge p-2  fs-6  bg-primary">
                        {data.interest}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-4 offset-lg-1 sticky-lg-top sticky-side">
              <div className="card  bg-dark text-light border mb-4">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <span className=" fs-1">
                        {" "}
                        <RiAccountCircleFill />
                      </span>
                    </div>
                    <div className="col">
                      <div className="fs-6 text-muted">Organized by</div>
                      <div className="fs-6 fw-bold">
                        {groupOrganizerDetails.firstName +
                          " " +
                          groupOrganizerDetails.lastName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <h5 className="mb-3 fw-bold">Members ({totalMember})</h5>
                </div>
                <div className="col col-auto">
                  <span
                    className="cursor-event"
                    onClick={(e) => dispatch(setGroupPage({ newPage: "3" }))}
                  >
                    See all
                  </span>
                </div>
              </div>
              <div className="avatar-list d-flex fs-1">{<MemberDisplay />}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
