import { db_firestore } from "../../../../../firebase.config";

import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { getCompanyNameofUser, getUserInfo } from "../../User/reduxAPI";

export async function getGroupsAPI(
  organizer = false,
  uid,
  all = false,
  publicOnly = false
) {
  const groupRef = collection(db_firestore, "groups");

  let customQuery;

  if (all && !publicOnly) {
    customQuery = query(groupRef);
  } else if (all && publicOnly) {
    customQuery = query(groupRef, where("groupType", "==", "public"));
  } else {
    customQuery = query(
      groupRef,
      where(
        organizer ? "subscriberUid" : "memberUid",
        organizer ? "==" : "array-contains",
        uid
      )
    );
  }

  const querySnapshot = await getDocs(customQuery);

  let groups = [];

  querySnapshot.forEach(async (doc) => {
    // doc.data() is never undefined for query doc snapshots
    const id = doc.id;
    groups.push({
      id,
      ...doc.data(),
    });
  });

  for (let index = 0; index < groups.length; index++) {
    const user = await getUserInfo(groups[index].subscriberUid);
    const companyName = await getCompanyNameofUser(groups[index].subscriberUid);
    groups[index].subscriberName = user.firstName + " " + user.lastName;
    groups[index].companyName = companyName;
  }

  return groups;
}

export async function getJoinedGroupsAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);

  const userSnap = await getDoc(userRef);

  let groupsId = [];

  if (userSnap.exists()) {
    groupsId = userSnap.data().joinedGroupsId;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }

  let groups = [];

  for (let index = 0; index < groupsId.length; index++) {
    const groupRef = doc(db_firestore, "groups", groupsId[index]);

    const groupSnap = await getDoc(groupRef);

    if (groupSnap.exists()) {
      const user = await getUserInfo(groupSnap.data().subscriberUid);
      const companyName = await getCompanyNameofUser(
        groupSnap.data().subscriberUid
      );
      groups.push({
        id: groups[index],
        ...groupSnap.data(),
        subscriberName: user.firstName + " " + user.lastName,
        companyName,
      });
    } else {
      console.log("This Event id  is not exist in db");
    }
  }

  return groups;
}
