import React, { useEffect, useState } from "react";
import Disscusion_Card from "../MyGroup_Props/Discussion_Card/App";
import { Button } from "react-bootstrap";
import Add_Discussion from "./Modal/App";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupDiscussionAsync,
  groupState,
} from "../MyGroupOnBoard/reduxSlice";
import { Link } from "react-router-dom";
import { userState } from "../../../../Main/Components/User/reduxSlice";

const Discussion = () => {
  const [modalShow, setModalShow] = useState(false);
  const {
    groupData,
    generatedGroupId,
    groupOrganizerDetails,
    title,
    discussionDescription,
    discussionArr,
  } = useSelector(groupState);
  const { user } = useSelector(userState);
  const {
    description,
    groupGallery,
    groupName,
    groupType,
    id,
    totalMember,
    subscriberName,
    companyName,
    // memberUid,
    subscriberUid,
    // location,
    // eventUid,
  } = groupData;

  const { firstName, lastName } = groupOrganizerDetails;

  let groupId = generatedGroupId;
  const dispatch = useDispatch();

  let isMyGroup = user?.uid === subscriberUid;

  useEffect(() => {
    dispatch(getGroupDiscussionAsync({ groupId }));
  }, [dispatch, groupId]);
  return (
    <div>
      <Add_Discussion show={modalShow} onHide={() => setModalShow(false)} />
      <div id="discussions" className="container py-5">
        <div className="row">
          <div className="row mb-4">
            <div className="col">
              <h2>Discussions</h2>
            </div>
            {isMyGroup ? (
              <div className="col col-auto">
                <Button
                  onClick={() => setModalShow(true)}
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#addNewDiscussion"
                >
                  Add new discussion
                </Button>
              </div>
            ) : null}
          </div>

          <div className="row">
            <div>
              {discussionArr?.map((data) => (
                <Link
                  className="text-decoration-none"
                  to={`discussion/${data.id}`}
                >
                  <Disscusion_Card
                    DiscussionTitle={data.title}
                    DiscussionDescription={data.discussionDescription}
                    createdAt={data.createdAt}
                    startedBy={firstName + " " + lastName}
                  />
                </Link>
              ))}

              {discussionArr.length <= 0 ? (
                <div className="text-center">Not Found</div>
              ) : null}
            </div>
          </div>
          <div className="d-none"></div>
        </div>
      </div>
    </div>
  );
};

export default Discussion;
