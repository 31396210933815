import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import SetHours from "./SetHours";
import {useDispatch, useSelector} from "react-redux";
import {
    calendarState, createCalendarAsync, setCustomDuration,
    setDuration,
    setMeetingDescription,
    setMeetingLink,
    setMeetingLocation,
    setMeetingName
} from "../reduxSlice";

//weekDays
const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const CustomHours = ({cusDuration, setCusDuration}) => {
    return (<>
        <Form.Group className="mb-3  d-flex" controlId="exampleForm.ControlInput1">
            <Form.Control type="text" className='bg-dark text-light border m-2' value={cusDuration}
                          onChange={setCusDuration}/>
            <Form.Select className='bg-dark text-light border m-2' aria-label="min">
                <option value="1">min</option>
            </Form.Select>
        </Form.Group>
    </>)
}

const CreateYourCalendar = () => {

    const dispatch = useDispatch();
    const {
        meetingName,
        meetingLocation,
        meetingDescription,
        meetingLink,
        duration,
        customDuration
    } = useSelector(calendarState);

    //Test

    const displayCustomHrs = () => {
        if (duration === '') {
            return <CustomHours cusDuration={customDuration}
                                setCusDuration={(e) => dispatch(setCustomDuration({value: e.target.value}))}/>
        }
    }

    //Inputs
    const handleSubmit = () => {
        dispatch(createCalendarAsync({
            meetingName,
            meetingLocation,
            meetingDescription,
            meetingLink,
            duration,
            customDuration
        }))

        console.log(meetingName,
            meetingLocation,
            meetingDescription,
            meetingLink,
            duration,
            customDuration)
    }
    return (
        <div>
            <div className='w-100 align-items-center container-sm'>

                <Form className='align-items-center'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Event name</Form.Label>
                        <Form.Control value={meetingName} onChange={(e) => {
                            dispatch(setMeetingName({value: e.target.value}))
                        }} className='bg-dark text-light border' type="text"/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Location</Form.Label>
                        <Form.Select className='bg-dark text-light border' aria-label="Default select example"
                                     value={meetingLocation} onChange={(e) => {
                            dispatch(setMeetingLocation({value: e.target.value}))
                        }}>
                            <option>Select meeting location</option>
                            <option value="google meet">Google meet</option>
                            <option value="zoom">Zoom</option>
                            <option value="skype">Skype</option>
                            <option value="slack">Slack</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className='bg-dark text-light border' as="textarea" rows={3}
                                      value={meetingDescription} onChange={(e) => {
                            dispatch(setMeetingDescription({value: e.target.value}))
                        }}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Add Link to meet</Form.Label>
                        <Form.Control className='bg-dark text-light border' type="text" value={meetingLink}
                                      onChange={(e) => {
                                          dispatch(setMeetingLink({value: e.target.value}))
                                      }}/>
                    </Form.Group>
                </Form>
                <hr></hr>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Duration</Form.Label>
                        <Form.Select className='bg-dark text-light border' value={duration} onChange={(e) => {
                            dispatch(setDuration({value: e.target.value}))
                        }} aria-label="Default select example">
                            <option>Select duration for meeting</option>
                            <option value="15">15 min</option>
                            <option value="30">30 min</option>
                            <option value="45">45 min</option>
                            <option value="60">60 min</option>
                            <option value="">custom</option>
                        </Form.Select>
                        {displayCustomHrs()}
                    </Form.Group>

                    <div className=" flex mb-3 align-items-center" controlId="exampleForm.ControlInput1">
                        <Form.Label>How do you want to offer your availability for this event
                            type?</Form.Label><br></br>
                        <Form.Label>Set hours</Form.Label>
                        {weekDays.map(day => (
                            <div className='align-items-center' key={day}>
                                <SetHours Days={day}/>
                            </div>

                        ))}

                    </div>

                    <Button onClick={handleSubmit}>Create Calendar</Button>
                </Form>

            </div>
        </div>
    );
};

export default CreateYourCalendar;