import React, { useEffect } from "react";

// Bootstrap
import { Offcanvas } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotification,
  notificationState,
  setNotification,
  setShowNotification,
} from "./reduxSlice";
import { darkModeState } from "../../DarkMode/reduxSlice";

// Css
// import styles from "./App.module.css";
import "./App.css";

// Component
import FriendReq from "./components/FriendReq";

// Firebase
import { collection, onSnapshot, query } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import {
  getDocIdofUserAsync,
  getEventbyIdAsync,
  getUserInfoDocIdAsync,
  userState,
} from "../reduxSlice";
import EventUpdateNotify from "./components/EventUpdateNotify";
import Announcement from "./components/Announcement";

export async function getNotificationsSnapshot(docId, dispatch) {
  const notificationsRef = collection(
    db_firestore,
    `users/${docId}/notifications`
  );

  const notificationQuery = query(notificationsRef);

  return onSnapshot(notificationQuery, async (querySnapshot) => {
    dispatch(clearNotification({}));
    querySnapshot.forEach(async (doc) => {
      let action;
      switch (doc.data().type) {
        case "networkingReq":
          action = await dispatch(
            getUserInfoDocIdAsync({ docId: doc.data().docId })
          );
          if (action.meta.requestStatus === "fulfilled") {
            dispatch(
              setNotification({
                value: {
                  ...doc.data(),
                  id: doc.id,
                  name:
                  action.payload.firstName + " " + action.payload.lastName,
                  headLine: action.payload.headLine,
                  profileURL: action.payload.profileUrl,
                },
              })
            );
          }
          break;
        case "eventUpdate":
          action = await dispatch(
            getEventbyIdAsync({ eventId: doc.data().eventId })
          );
          if (action.meta.requestStatus === "fulfilled") {
            let eventData = action.payload.data.eventData;
            dispatch(
              setNotification({
                value: {
                  ...doc.data(),
                  id: doc.id,
                  eventId: doc.data().eventId,
                  eventName: eventData.eventName,
                },
              })
            );
          }
          break;
        case "announcement":
          action = await dispatch(
            getUserInfoDocIdAsync({ docId: doc.data().subscriberDocId })
          );
          if (action.meta.requestStatus === "fulfilled") {
            let name = action.payload.firstName + " " + action.payload.lastName;
            let profileUrl = action.payload.profileUrl;
            dispatch(
              setNotification({
                value: {
                  ...doc.data(),
                  id: doc.id,
                  name: name,
                  date: doc.data().date.toDate(),
                  profileUrl,
                },
              })
            );
          }
          break;
        default:
          break;
      }
    });
  });
}

export default function NotificationContainer() {
  const dispatch = useDispatch();
  const { showNotification, notifications } = useSelector(notificationState);
  const { dark } = useSelector(darkModeState);
  const { docId } = useSelector(userState);

  useEffect(() => {
    const unSub = getNotificationsSnapshot(docId, dispatch);
    return () => {
      // unSub();
    };
  }, [docId, dispatch]);

  return (
    <Offcanvas
      placement="end"
      show={showNotification}
      onHide={() => {
        dispatch(setShowNotification({ value: false }));
      }}
    >
      <Offcanvas.Header className="bg-dark text-light">
        <Offcanvas.Title>Notification</Offcanvas.Title>
        <CloseButton
          variant={`${dark ? "white" : ""}`}
          onClick={() => {
            dispatch(setShowNotification({ value: false }));
          }}
        />
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-dark text-light p-0">
        {notifications.map((noty, index) => {
          switch (noty.type) {
            case "networkingReq":
              return <FriendReq index={index} />;
            case "eventUpdate":
              return <EventUpdateNotify index={index} />;
            case "announcement":
              return <Announcement index={index} />;
            default:
              break;
          }
          return null;
        })}
      </Offcanvas.Body>
    </Offcanvas>
  );
}
