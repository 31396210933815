import React, { useState, useEffect } from "react";

// State management (Redux)
import { useSelector, useDispatch } from "react-redux";
import {
  loginState,
  setEmail,
  setPassword,
  toggleRememberMe,
  loginUserAsync,
  clearLoadingState,
} from "./reduxSlice";

// other laibrary
import { Link, useNavigate } from "react-router-dom";

// css
import styles from "./App.module.css";
import "./App.css";

// icon
import { Icon } from "@iconify/react";
// bootstrap
import { Image, Placeholder } from "react-bootstrap";

export function Login() {
  const { email, password, rememberMe, loading } = useSelector(loginState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearLoadingState({}));
  }, [dispatch]);

  //loading
  const [imgLoading, setImgLoading] = useState(true);

  // functions
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginUserAsync({ email, password, navigate }));
  };

  return (
    <div className="bg-dark text-light h-100 ">
      <div className="text-center  justify-content-center align-items-center ">
        <Image
          fluid
          draggable={false}
          src={
            "https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Flogo512.png?alt=media&token=ebab4817-f456-4398-bc63-13ff120be3b9"
          }
        
          style={{
            aspectRatio: "1 /1 ",
          }}
          className={`col-4 col-md-2 m-1 col-lg-1 ${imgLoading ? "d-none" : null
            }`}
          onLoad={() => {
            setImgLoading(false);
          }}
          alt="Runolfsdottir Group"
        />
        {imgLoading ? (
          <Placeholder animation="glow">
            <Placeholder
              className=" rounded-3 col-4 col-md-2 m-1 col-lg-1  "
              style={{
                aspectRatio: "1 / 1 ",
              }}
            />
          </Placeholder>
        ) : null}

        <h5 className={`ms-2 text-tertiary ${styles.branding_biz} `}>
          Biz Pre-Event and Networking
        </h5>
      </div>
      <form
        onSubmit={submitHandler}
        className={` d-block px-2 border shadow-sm rounded-3 col-11 col-md-8 col-lg-6 mx-auto `}
      >
        <div className="mb-3 p-2">
          <h5 className="my-4 text-primary ">Login to your account</h5>

          {/* Email of User */}
          <label htmlFor="userEmail" className="form-label">
            Email
          </label>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => {
              dispatch(setEmail({ email: e.target.value }));
            }}
            className="form-control bg-dark text-light "
            id="userEmail"
            aria-describedby="emailHelp"
            placeholder="name@address.com"
          />

          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3 p-2">
          {/* Password of User */}
          <label htmlFor="userPassword" className="form-label">
            Password
          </label>
          <a href="##" tabIndex={-1} className="float-end resetLink ">
            Forgot your password?
          </a>
          <input
            value={password}
            required
            onChange={(e) => {
              dispatch(setPassword({ password: e.target.value }));
            }}
            type="password"
            placeholder="password"
            className="form-control bg-dark text-light "
            id="userPassword"
          />
        </div>
        <div className="mb-3 form-check m-2">
          <input
            checked={rememberMe}
            onChange={() => {
              dispatch(toggleRememberMe());
            }}
            type="checkbox"
            className="form-check-input  "
            id="exampleCheck1"
          />
          <label className="form-check-label  " htmlFor="exampleCheck1">
            Remember me on this device
          </label>
        </div>
        <div className="mb-3 p-2 pt-0">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary w-100 d-block mx-auto my-1 shadow-sm"
          >
            {loading ? <Icon icon="eos-icons:bubble-loading" /> : "Login"}
          </button>
        </div>
      </form>
      <div className="text-center my-2">
        <span className=" fs-4 ">Don't have an account yet? </span>
        <Link className="register_link mx-2 fw-bold fs-4 " to={{ pathname: "/register" }}>
          Register
        </Link>
      </div>
    </div>
  );
}
