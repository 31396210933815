// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

import { functions } from "../../../../firebase.config";
import { httpsCallable } from "firebase/functions";

export function sendMailAPI(to, subject, text, html) {
  const sendMail = httpsCallable(functions, "sendMail");
  return sendMail({ to, subject, text, html });
}
