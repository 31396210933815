import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addFromCompanyAPI,
  alreadyJoinedEvent,
  getAttendeesAPI,
  getCompanyName,
  getEachEventAPI,
  getGroupandEventId,
  getGroupDetailsFromId,
  getRegistrationForm,
  joinEventAPI,
  leaveEventAPI,
  networkingReqAPI,
  saveEventAPI,
  submitRegistrationFormData,
  unSaveEventAPI,
} from "./reduxAPI";
import { pushErrorNotification } from "../../Error/reduxSlice";
import { Icon } from "@iconify/react";
import { getDocIdofUserAPI, getUidofUser } from "../../User/reduxAPI";
import { registerUserAsync } from "../../auth/Register/reduxSlice";
import { loginUserAsync } from "../../auth/Login/reduxSlice";
import { deleteDocumentsAPI } from "./Documents/components/FormGenerator/reduxAPI";
import { sendJoinEventMailAsync } from "../Emails/JoinEvent/reduxSlice";

const initialState = {
  isEventPage: false,

  registeringNewUser: false,

  sendRequestUserIndex: null,
  sendingRequest: false,

  loading: false,
  eventActionLoading: false,
  found: true,

  registrationFormCheckLoading: false,
  foundForm: true,
  formData: {},

  showRegistrationForm: false,
  eventData: {},
  groupData: {},
  eventPageNav: "Event Details",

  selectedFiles: [],
  preViewFiles: [],

  loadingAttendees: false,
  joinedAttendeesFound: true,
  joinedAttendees: [],

  joinuser: false,

  selectedAgendaDate: 0,

  agendaData: [],
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventpageNavPannel: (state, action) => {
      state.eventPageNav = action.payload.newPannel;
    },

    setShowRegistrationForm: (state, action) => {
      state.showRegistrationForm = action.payload.value;
    },
    setAgendaDate: (state, action) => {
      state.selectedAgendaDate = action.payload.agendaDate;
    },

    setSelectedSpeaker: (state, action) => {
      state.selectedSpeaker = action.payload.selectSpeaker;
    },

    setPreviewFiles: (state, action) => {
      state.preViewFiles = [...state.preViewFiles, action.payload.newPreview];
    },

    setSelectedFiles: (state, action) => {
      state.selectedFiles = [...state.selectedFiles, action.payload.newImage];
    },

    setSetSendRequestUserIndex: (state, action) => {
      let index = action.payload.index;
      state.sendRequestUserIndex = index;
      state.joinedAttendees[index].isRequestSent = true;
    },

    setJoinedAttendees: (state, action) => {
      state.joinedAttendees = action.payload.value;
    },

    setLoginUserViaModal: (state, action) => {
      state.joinuser = action.payload.value;
    },

    setRegisteringNewUser: (state, action) => {
      state.registeringNewUser = action.payload.value;
    },

    setdisplaypdfIneventData: (state, action) => {
      if (state.eventData.displayPdf !== undefined) {
        state.eventData.displayPdf = action.payload.value;
      }
    },

    setIsEventPage: (state, action) => {
      state.isEventPage = action.payload.value;
    },

    clearPreviewFiles: (state, action) => {
      state.preViewFiles = [];
    },

    clearSelectedFiles: (state, action) => {
      state.selectedFiles = [];
    },

    clearEventState: (state, action) => {
      state.formData = {};
    },
    clearAttendeesDetails: (state, action) => {
      state.joinedAttendees = [];
      state.joinedAttendeesFound = true;
      state.loadingAttendees = false;
    },

    updateEventrole: (state, action) => {
      const { sponsorsId, boothOwnersId, speakersId } = action.payload;

      state.eventData.sponsorsId = [...sponsorsId];
      state.eventData.boothOwnersId = [...boothOwnersId];
      state.eventData.speakersId = [...speakersId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(eventAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(eventAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.eventData = { ...action.payload.response };
        state.groupData = { ...action.payload.gropuData };
        if (!action.payload) {
          state.found = false;
        }
      })
      .addCase(eventAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(joinEventAsync.pending, (state, action) => {
        state.eventActionLoading = true;
      })
      .addCase(joinEventAsync.fulfilled, (state, action) => {
        state.eventActionLoading = false;
        // state.groupData = { ...action.payload };
        // if (!action.payload) {
        //   state.found = false;
        // }
      })
      .addCase(joinEventAsync.rejected, (state, action) => {
        state.eventActionLoading = false;
      })

      .addCase(leaveEventAsync.pending, (state, action) => {
        state.eventActionLoading = true;
      })
      .addCase(leaveEventAsync.fulfilled, (state, action) => {
        state.eventActionLoading = false;
        // state.groupData = { ...action.payload };
        // if (!action.payload) {
        //   state.found = false;
        // }
      })
      .addCase(leaveEventAsync.rejected, (state, action) => {
        state.eventActionLoading = false;
      })
      .addCase(eventRegistrationFormCheck.pending, (state, action) => {
        state.registrationFormCheckLoading = true;
      })
      .addCase(eventRegistrationFormCheck.fulfilled, (state, action) => {
        state.registrationFormCheckLoading = false;
        if (!action.payload.id) {
          state.foundForm = false;
        } else {
          state.formData = { ...action.payload };
        }
      })
      .addCase(eventRegistrationFormCheck.rejected, (state, action) => {
        state.registrationFormCheckLoading = false;
      })
      .addCase(registrationFormSubmitAsync.pending, (state, action) => {
        state.registrationFormCheckLoading = true;
      })
      .addCase(registrationFormSubmitAsync.fulfilled, (state, action) => {
        state.registrationFormCheckLoading = false;
        if (action.payload.notSubmitted) {
          // form is not submitted
          state.showRegistrationForm = true;
        }
      })
      .addCase(registrationFormSubmitAsync.rejected, (state, action) => {
        state.registrationFormCheckLoading = false;
      })
      .addCase(getAttendeesDetails.pending, (state, action) => {
        state.loadingAttendees = true;
      })
      .addCase(getAttendeesDetails.fulfilled, (state, action) => {
        state.loadingAttendees = false;
        state.joinedAttendees = action.payload;
        if (action.payload.length <= 0) {
          state.joinedAttendeesFound = false;
        }
      })
      .addCase(getAttendeesDetails.rejected, (state, action) => {
        state.loadingAttendees = false;
      })
      .addCase(sendFriendRequestToAttendeeAsync.pending, (state, action) => {
        state.sendingRequest = true;
      })
      .addCase(sendFriendRequestToAttendeeAsync.fulfilled, (state, action) => {
        state.sendingRequest = false;

        if (action.payload.id) {
        }
      })
      .addCase(sendFriendRequestToAttendeeAsync.rejected, (state, action) => {
        state.sendingRequest = false;
      });
  },
});

export const newUserCreateAsync = createAsyncThunk(
  "event/newUserCreate",
  async (
    { subscriberUid, companyName, eventNameParams, data },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      dispatch(setShowRegistrationForm({ value: false }));
      dispatch(setRegisteringNewUser({ value: true }));

      const { userAvl } = getState().user;

      if (!userAvl) {
        // Register User (new)
        const registered = await dispatch(
          registerUserAsync({
            hideEmailInUserError: true,
          })
        );

        if (registered.payload?.code === "auth/email-already-in-use") {
          const { email, password } = getState().register;
          await dispatch(loginUserAsync({ email, password }));
        }
      }

      // ADD FROMCOMPANY
      dispatch(addFromCompanyAsync({ subscriberUid }));

      // Join company
      const uid = await getUidofUser(companyName);
      const { groupId, eventId } = await getGroupandEventId(
        uid,
        eventNameParams
      );
      await dispatch(joinEventAsync({ companyName, eventNameParams }));

      // Submit form
      if (data) {
        dispatch(
          await registrationFormSubmitAsync({
            submittedData: data,
            groupId,
            eventId,
          })
        );
      }

      dispatch(setRegisteringNewUser({ value: false }));

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveEventAsync = createAsyncThunk(
  "event/saveEvent",
  async (
    { companyName, eventNameParams, searchEventId = undefined },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { userAvl, user } = getState().user;

      if (!userAvl) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Authentication required",
              errorMessage:
                "please login to your account to save this event. If you don't have account you can Register!",
              theme: "info",
              time: "now",
              autoHideTime: 4000,
              statusCode: 401,
              show: true,
            },
          })
        );
        return;
      }

      let eventId = searchEventId;

      if (companyName && !eventId) {

        const uid = await getUidofUser(companyName);
        const {
          // groupId,
          eventId: eId,
        } = await getGroupandEventId(uid, eventNameParams);

        eventId = eId;

      }

      await saveEventAPI(eventId, user.uid);

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const unSaveEventAsync = createAsyncThunk(
  "event/unSaveEvent",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const uid = await getUidofUser(companyName);
      const { eventId } = await getGroupandEventId(uid, eventNameParams);
      const { user } = getState().user;

      // const response =
      await unSaveEventAPI(eventId, user.uid);

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const leaveEventAsync = createAsyncThunk(
  "event/leaveEvent",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { userAvl, user, docId } = getState().user;

      if (!userAvl) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Authentication required",
              errorMessage:
                "please login to your account to leave this event. If you don't have account you can Register !",
              theme: "info",
              time: "now",
              autoHideTime: 4000,
              statusCode: 401,
              show: true,
            },
          })
        );
        return;
      }
      const uid = await getUidofUser(companyName);
      const {
        //  groupId ,
        eventId,
      } = await getGroupandEventId(uid, eventNameParams);
      await leaveEventAPI(eventId, user.uid);
      await deleteDocumentsAPI(eventId, docId);
      dispatch(eventAsync({ companyName, eventNameParams }));

      // return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const joinEventAsync = createAsyncThunk(
  "event/joinEvent",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { userAvl, user, docId } = getState().user;

      if (!userAvl) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Authentication required",
              errorMessage:
                "please login to your account to join this event. If you don't have account you can Register !",
              theme: "info",
              time: "now",
              autoHideTime: 4000,
              statusCode: 401,
              show: true,
            },
          })
        );
        return;
      }

      const uid = await getUidofUser(companyName);
      const { eventId } = await getGroupandEventId(uid, eventNameParams);

      let alreadyJoined = await alreadyJoinedEvent(eventId, user.uid);

      if (!alreadyJoined) {
        await dispatch(sendJoinEventMailAsync({}));
      }

      await joinEventAPI(eventId, user.uid);
      dispatch(eventAsync({ companyName, eventNameParams }));

      // return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const eventAsync = createAsyncThunk(
  "event/fetchEvent",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const uid = await getUidofUser(companyName);

      const { groupId, eventId } = await getGroupandEventId(
        uid,
        eventNameParams
      );
      const response = await getEachEventAPI(groupId, eventId);
      const gropuData = await getGroupDetailsFromId(groupId);

      return { response, gropuData };
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
export const addFromCompanyAsync = createAsyncThunk(
  "event/addFromCompanyAsync",
  async ({ subscriberUid }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { data: subscriberDocId } = await getDocIdofUserAPI(subscriberUid);
      const companyName = await getCompanyName(subscriberDocId);

      const { docId } = getState().user;
      const response = await addFromCompanyAPI(docId, companyName);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const eventRegistrationFormCheck = createAsyncThunk(
  "event/eventRegistrationFormCheck",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const uid = await getUidofUser(companyName);
      const { groupId, eventId } = await getGroupandEventId(
        uid,
        eventNameParams
      );
      const response = await getRegistrationForm(groupId, eventId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const registrationFormSubmitAsync = createAsyncThunk(
  "event/registrationFormSubmitAsync",
  async (
    { submittedData, groupId, eventId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { formData } = getState().event;
      const { docId } = getState().user;

      if (docId) {
        const response = await submitRegistrationFormData(
          formData.id,
          docId,
          submittedData,
          groupId,
          eventId
        );
        return response;
      }
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const getAttendeesDetails = createAsyncThunk(
  "event/getAttendeesDetails",
  async (
    { companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const uid = await getUidofUser(companyName);
      const { eventId } = await getGroupandEventId(uid, eventNameParams);

      return await getAttendeesAPI(eventId);

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendFriendRequestToAttendeeAsync = createAsyncThunk(
  "event/sendFriendRequestToAttendee",
  async ({ friendDocId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;

      const response = await networkingReqAPI(docId, friendDocId);

      return response.data;

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Action creators are generated for each case reducer function
export const {
  setShowRegistrationForm,
  setEventpageNavPannel,

  setSelectedSpeaker,
  setSelectedFiles,
  clearSelectedSpeaker,
  clearPreviewFiles,
  clearEventState,
  setPreviewFiles,
  clearAttendeesDetails,
  clearSelectedFiles,
  setAgendaDate,
  setSetSendRequestUserIndex,
  setJoinedAttendees,
  setLoginUserViaModal,
  setRegisteringNewUser,
  setdisplaypdfIneventData,
  updateEventrole,
  setIsEventPage,
} = eventSlice.actions;

// create state here and export
export const eventState = (state) => state.event;

export default eventSlice.reducer;
