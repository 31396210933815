import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../../../../firebase.config";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";

export function getAgendaPdfUrlAPI(eventId) {
  const getAgendaPdfUrl = httpsCallable(functions, "getAgendaPdfUrl");
  return getAgendaPdfUrl({
    eventId,
  });
}

export function setDisplayPdfAPI(eventId, displayPdf) {
  const setDisplayPdf = httpsCallable(functions, "setDisplayPdf");
  return setDisplayPdf({
    eventId,
    displayPdf,
  });
}

export function saveAgendaPdfUrlAPI(eventId, pdfUrl) {
  const saveAgendaPdfUrl = httpsCallable(functions, "saveAgendaPdfUrl");
  return saveAgendaPdfUrl({
    eventId,
    pdfUrl,
  });
}

export function uploadAgendaPdf(file, eventId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/agendaPdf/agendaPdf/` + file.name
    );
    const metadata = {
      contentType: "application/pdf",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteAgendaPdf(eventId) {
  // Create a reference to the file to delete
  const sponsorRef = ref(storage, `events/${eventId}/agendaPdf/agendaPdf`);

  let speakerImgList = [];
  // Find all the prefixes and items.
  listAll(sponsorRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        speakerImgList = [...speakerImgList, itemRef];
        // All the items under listRef.
      });
      if (speakerImgList.length > 0) {
        speakerImgList.map((value) =>
          deleteObject(
            ref(storage, `events/${eventId}/agendaPdf/agendaPdf/${value.name}`)
          )
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}
