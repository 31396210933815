import React from 'react'
import GeneralPanel from '../GeneralPanel'

// Bootstrap
import { Container, Form, Button } from 'react-bootstrap';

//Icon 
import { MdChevronLeft } from "react-icons/md";

// Css
import styles from "../../App.module.css";
import '../../App.css'

//redux 
import {  useDispatch } from "react-redux";
import {setUserProfilePannel} from "../../reduxSlice"

export default function PasswordUpdate() {

    const dispatch = useDispatch();
    const backButton = ()=>{
            dispatch(setUserProfilePannel({newPannel: "General"}));
    }

    return (
        <>
            <Container className='mt-3 mt-lg-0 px-lg-5 px-2 bg-dark text-light'>
                <Button variant="link" className='ps-0 text-decoration-none shadow-none' onClick={backButton}><MdChevronLeft /> Back</Button>
                <h4>Password & Security</h4>
                <hr />
                <Form>
                    <Container className='d-flex mb-1'>
                        <Form.Group className="mb-3 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" className='bg-dark text-light' required />
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" className='bg-dark text-light' required />
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" className='bg-dark text-light' required />
                        </Form.Group>
                    </Container>
                    <Container>
                        <Button type="submit" className=' d-block ms-auto me-0'>
                            Update
                        </Button>
                    </Container>
                </Form>
            </Container>
        </>
    )
}