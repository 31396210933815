import React from 'react';
import {Button, Form} from "react-bootstrap";
import PreviewModal from "../PreviewModal/App";

const AddYourOwnCalendar = ({addCal, setAddCal, onAdd}) => {
    //Preview Modal
    const [modalShow, setModalShow] = React.useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = () => setModalShow(false);

    return (
        <>
            <Form className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                <Form.Label className='bold fs-3'>Add your calender</Form.Label><br></br>
                <span><small>Add your calendly or google calendar.</small></span>
                <Form.Control type="text" required className='bg-dark text-light border' value={addCal}
                              onChange={setAddCal}/>
            </Form>
            <div className=''>
                {addCal ? <div>
                        <Button onClick={handleShow}>Preview</Button>
                        <PreviewModal show={modalShow} onHide={handleClose} CalendarLink={addCal} onAdd={onAdd}/>
                    </div>

                    : null}

            </div>
        </>
    );
};

export default AddYourOwnCalendar;