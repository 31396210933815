// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

import { auth, db_firestore, functions } from "../../../../../firebase.config";
import { httpsCallable } from "firebase/functions";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
// import axios from "../../../../axios/axiosInstance";

export function registerUser(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export async function validateCompanyNameAPI(companyName) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("companyName", "==", companyName)
  );

  const querySnapshot = await getDocs(userDocq);
  let companyNameValid = true;
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      companyNameValid = false;
    }
  });
  return companyNameValid;
}

export function assignRole(
  uid,
  firstName,
  lastName,
  email,
  rememberMe,
  role,
  companyName
) {
  const assignRole = httpsCallable(functions, "assignRole");
  return assignRole({
    uid,
    firstName,
    lastName,
    email,
    rememberMe,
    role,
    companyName,
  });

  // return axios.post("assignRole", {
  //   uid,
  //   firstName,
  //   lastName,
  //   email,
  //   rememberMe,
  // });
}
