import React, { useEffect, useState } from "react";
// other laibrary
import { Link, useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { rootPageState } from "../../Routes/reduxSlice";

import { userState } from "../User/reduxSlice";
import { logoutUserAsync } from "../auth/Logout/reduxSlice.js";
import {
  getNotificationAsync,
  notificationState,
  setShowNotification,
} from "../User/Notification/reduxSlice";

// Bootstrap
import {
  Badge,
  Button,
  Dropdown,
  Form,
  InputGroup,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// Icon
import {
  BsFillBellFill,
  BsFillPeopleFill,
  BsFillPersonFill,
  BsSearch,
} from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { IoIosLogOut, IoMdCalendar } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { Icon } from "@iconify/react";

// Animation
import { animated, useSpring } from "@react-spring/web";

// Css
// import styles from "./App.module.css";
import "./App.css";
import NotificationContainer from "../User/Notification/App";
import {
  algoliaAsync,
  navBarState,
  setResult,
  setSearchFound,
  setSearchQuery,
} from "./reduxSlice";
import { eventState } from "../Events/Page Component/reduxSlice";
import { darkModeState } from "../DarkMode/reduxSlice";
import { FaTelegramPlane } from "react-icons/fa";
import useAppBadge from "../hooks/useAppBadge";

export default function NavbarComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userAvl, docId, user } = useSelector(userState);
  const { dark } = useSelector(darkModeState);
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);
  const { notifications } = useSelector(notificationState);
  const {
    stickyTop,
    hideLogin_Register,
    logoLink,
    redirectLink,
    supportsInstallButton,
    promptToInstallCb,
  } = useSelector(navBarState);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });



  const {
    isEventPage,
    eventData: { subscriberUid },
  } = useSelector(eventState);

  // const searchHandler = (event) => {
  //   event.preventDefault();
  //   navigate("/find/event");
  // };

  const ActionMenu = () => {
    if (userAvl) {
      const dropDownHandler = (event, navigateStr) => {
        event.preventDefault();
        navigate(navigateStr);
      };

      const logoutHandler = (event) => {
        event.preventDefault();
        dispatch(logoutUserAsync());
      };

      const createGroupHandler = (event) => {
        event.preventDefault();

        navigate("/creategroup");
      };

      const notificationHandler = (event) => {
        event.preventDefault();

        dispatch(setShowNotification({ value: true }));
      };

      return (
        <Nav className="flex-row me-0 ">
          {isEventPage ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={`leadCapture-top`}>
                  Download Lead Capture Tool
                </Tooltip>
              }
            >
              <Nav.Link
                href="#"
                className=" rounded-circle p-0 text-light d-flex align-items-center nav-link  "
              >
                <div className="border p-2 rounded-circle d-flex justify-content-center align-items-center bg-dark text-light">
                  <img
                    height="15px"
                    width="15px"
                    src={`data:image/svg+xml, %3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg'
                     width='512.000000pt' height='513.000000pt' viewBox='0 0 512.000000 513.000000' 
                     preserveAspectRatio='xMidYMid meet' %3E%3Cg transform='translate(0.000000,513.000000) 
                     scale(0.100000,-0.100000)' fill='%23${dark ? "ffffff" : "000000"
                      }' stroke='none' %3E%3Cpath d='M2045 4810 l-320 -320 318 -317 317 -318 320 320 320 320 -318 318%0A-317 317 -320 -320z' /%3E%3Cpath d='M4115 4583 c-280 -140 -512 -257 -515 -259 -2 -3 91 -100 208 -217%0Al212 -212 -330 -165 c-181 -91 -330 -166 -330 -168 0 -8 131 -262 135 -262 3%0A0 237 116 520 257 l515 258 -212 212 -211 211 326 164 c180 89 327 169 327%0A176 0 15 -116 249 -127 255 -4 3 -237 -110 -518 -250z' /%3E%3Cpath d='M984 3748 c-544 -545 -606 -614 -714 -798 -182 -310 -270 -633 -270%0A-995 0 -179 17 -320 56 -477 153 -610 594 -1110 1184 -1343 113 -44 319 -98%0A445 -116 116 -17 414 -17 530 0 331 48 648 179 916 381 51 38 294 273 614 591%0Al530 529 -317 317 -318 317 -512 -510 c-540 -536 -574 -566 -741 -644 -225%0A-106 -488 -125 -732 -55 -306 89 -571 331 -685 626 -54 139 -65 208 -65 389 1%0A184 15 257 79 409 73 173 110 217 658 766 l508 511 -318 317 -317 317 -531%0A-532z' /%3E%3Cpath d='M4167 2682 l-317 -317 318 -318 317 -317 320 320 320 320 -315 315%0Ac-173 173 -317 315 -320 315 -3 0 -148 -143 -323 -318z' /%3E%3C/g%3E%3C/svg%3E`}
                  />
                </div>
              </Nav.Link>
            </OverlayTrigger>
          ) : isSubscriber || isAdmin || isBizAdmin ? (
            <Nav.Link
              href="/test/create-group"
              onClick={createGroupHandler}
              className="px-2 text-light"
            >
              <small>
                <u>Start a group</u>
              </small>
            </Nav.Link>
          ) : null}

          <Nav.Link
            href="/"
            onClick={notificationHandler}
            className=" rounded-circle p-0 text-light d-flex align-items-center nav-link position-relative  "
          >
            <BsFillBellFill className="notificationIcon_custom border p-2 mx-3 rounded-circle  " />{" "}
            {notifications.length > 0 ? (
              <small>
                <Badge pill bg="primary" className="notification_count">
                  {notifications.length}
                </Badge>
              </small>
            ) : null}
          </Nav.Link>
          <Dropdown className="w-auto h-auto m-auto px-3  border-start  shadow-none">
            <Dropdown.Toggle
              className="border  shadow-none"
              variant="bg-dark"
              id="dropdown-basic"
            >
              <animated.div style={fadeIn}>
                <BsFillPersonFill className="bg-dark text-light  " />
              </animated.div>
            </Dropdown.Toggle>
            <animated.div style={fadeIn}>
              <Dropdown.Menu
                className="bg-dark text-light shadow position-absolute border mt-2 py-0 overflow-hidden"
                style={{ zIndex: "10000000 !important" }}
              >
                <animated.div style={fadeIn}>
                  {isAdmin ? (
                    <>
                      <Dropdown.Item
                        href="/"
                        onClick={(e) => {
                          dropDownHandler(e, "/admin/dashboard");
                        }}
                        className="bg-dark text-light py-2"
                      >
                        <Icon
                          icon="fluent:desktop-32-regular"
                          className="me-3"
                        />
                        Dashboard
                      </Dropdown.Item>
                      <Dropdown.Divider className="p-0 m-0" />
                    </>
                  ) : null}

                  <Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      dropDownHandler(e, "/groups");
                    }}
                    className="bg-dark text-light py-2 "
                  >
                    <BsFillPeopleFill className="me-3" />
                    My Groups
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      dropDownHandler(e, "/events");
                    }}
                    className="bg-dark text-light "
                  >
                    <IoMdCalendar className="me-3" />
                    My Events
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      dropDownHandler(e, `/u/${docId}`);
                    }}
                    className="bg-dark text-light py-2"
                  >
                    <CgProfile className="me-3" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      dropDownHandler(e, `/u/setting/${docId}`);
                    }}
                    className="bg-dark text-light py-2"
                  >
                    <IoSettingsSharp className="me-3" />
                    Settings
                  </Dropdown.Item>
                  {!isAttendee ? (<Dropdown.Item
                    href="/"
                    onClick={(e) => {
                      dropDownHandler(e, `/_/checkIn`);
                    }}
                    className="bg-dark text-light py-2"
                  >
                    <Icon icon="material-symbols:patient-list-rounded" className="me-3" />
                    Check-In
                  </Dropdown.Item>) : null}
                  <Dropdown.Item
                    href="https://bizconnectshows.events/"
                    target="_blank"
                    className="bg-dark text-light py-2"
                  >
                    <Icon icon="ci:calendar-event" className="me-3" />
                    Event <small>Products</small>
                  </Dropdown.Item>
                  {supportsInstallButton ? (
                    <Dropdown.Item
                      onClick={promptToInstallCb}
                      className="bg-dark text-light py-2"
                    >
                      <FaDownload className="me-3" />
                      WebApp
                    </Dropdown.Item>
                  ) : null}
                  <Dropdown.Divider className="p-0 m-0" />
                  <Dropdown.Item
                    href="/"
                    onClick={logoutHandler}
                    className="bg-dark text-light py-2"
                  >
                    <IoIosLogOut className="me-3" />
                    Logout
                  </Dropdown.Item>
                </animated.div>
              </Dropdown.Menu>
            </animated.div>
          </Dropdown>
        </Nav>
      );
    } else if (!hideLogin_Register) {
      return (
        <Nav className="flex-row me-0">
          <Link
            to="/login"
            className="px-3 text-light text-decoration-none py-2"
          >
            Login
          </Link>
          <Link
            to="/register"
            className="px-3 text-white text-decoration-none bg-quaternary py-2"
          >
            Register
          </Link>
        </Nav>
      );
    } else {
      return (
        <>
          {supportsInstallButton ? ( //
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id={`photobooth-top`}>Download</Tooltip>}
            >
              <Button
                variant="primary"
                className="mx-0 px-2"
                onClick={promptToInstallCb}
              >
                <small>Web App</small>
                <Icon icon="ic:round-install-mobile" className="ms-1" />
              </Button>
            </OverlayTrigger>
          ) : null}
        </>
      );
    }
  };

  let ismyEvent;
  if (userAvl) {
    ismyEvent = user?.uid === subscriberUid;
  }

  return (
    <Navbar
      className={`py-0 px-3 d-flex justify-content-between bg-dark text-light shadow-sm mx-0 ${stickyTop ? "sticky-top" : null
        }`}
      expand="lg"
    >
      <Navbar id="basic-navbar-nav mx-0" className="py-0">
        <Nav className="ml-auto">
          <Navbar.Brand
            target={"_blank"}
            href={
              !(window.location.hostname !== "localhost") ? "/" : redirectLink
            }
            onClick={(e) => {
              if (!(window.location.hostname !== "localhost") || ismyEvent) {
                e.preventDefault();
                navigate("/");
              }
            }}
            className="ms-0 brand_pointer text-light"
          >
            {/* <Brand /> */}
            <img
              src={logoLink}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              style={{ height: "60px", width: "auto" }}
            />
          </Navbar.Brand>
          {isEventPage ? null : <Search />}
        </Nav>
      </Navbar>
      <NotificationContainer />
      <ActionMenu />
    </Navbar>
  );
}

const Search = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { searchQuery, navigatePath, stickyTop } = useSelector(navBarState);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(navigatePath);
    dispatch(algoliaAsync({}));
  };

  return (
    <>
      <Form
        onSubmit={handleSearch}
        className="d-none d-md-flex d-lg-flex "
        style={{ margin: "0.75rem 0" }}
      >
        <InputGroup className="border rounded " style={{ padding: "1px" }}>
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
            className="border-0 shadow-none  bg-dark text-light my-0 "
            value={searchQuery}
            onChange={(e) => {
              dispatch(setSearchQuery({ value: e.target.value }));

              if (e.target.value === "") {
                dispatch(setSearchFound({ value: true }));
                dispatch(algoliaAsync({}));
              }
            }}
          ></Form.Control>
          <Button
            variant="primary"
            className="border-0 shadow-sm rounded px-3 d-flex justify-content-center align-items-center"
            type="submit"
            id="button-addon2"
          >
            <BsSearch />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
};
