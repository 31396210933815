import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../firebase.config";

export async function acceptRequestHandlerAPI(friendDocId, docId) {
  const acceptRequestHandler = httpsCallable(functions, "acceptRequestHandler");
  return acceptRequestHandler({ friendDocId, docId });
}
export async function removeFriendHandlerAPI(friendDocId, docId) {
  const removeFriendHandler = httpsCallable(functions, "removeFriendHandler");
  return removeFriendHandler({ friendDocId, docId });
}
export async function removeNotificationAPI(notificationId) {
  const removeNotification = httpsCallable(functions, "removeNotification");
  return removeNotification({ notificationId });
}
