import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { pushErrorNotification } from "../../../../../Error/reduxSlice";
import { getDocumentFormInputDataAPI } from "../FormGenerator/reduxAPI";
import { uploadDocumentFormAPI } from "./reduxAPI";

const initialState = {
  inputFields: [
    {
      _id: uuidv4(),
      type: "file",
      multiple: true,
      required: true,
      label: "Passport",
    },
    {
      _id: uuidv4(),
      type: "file",
      required: false,
      label: "Aadhar card",
    },
  ],
  heading: "Upload max 5MB files..",
};

export const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setInputFields: (state, action) => {
      state.inputFields = action.payload.newInputFields;
    },

    setHeading: (state, action) => {
      state.heading = action.payload.value;
    },

    handleLabelChange: (state, action) => {
      const { index, value } = action.payload;
      let data = [...state.inputFields];
      data[index].label = value;
    },
    handleRequiredChange: (state, action) => {
      const { index } = action.payload;
      let data = [...state.inputFields];
      data[index].required = !data[index].required;
    },
    handleMultipleChange: (state, action) => {
      const { index } = action.payload;
      let data = [...state.inputFields];
      data[index].multiple = !data[index].multiple;
    },
    handleAddFields: (state, action) => {
      let newField = {
        _id: uuidv4(),
        type: "file",
        required: false,
        label: "Document type/text",
      };

      state.inputFields = [...state.inputFields, newField];
    },

    handleRemoveFields: (state, action) => {
      let { index } = action.payload;

      let data = [...state.inputFields];
      data.splice(index, 1);
      state.inputFields = data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDocumentFormInputDataAsync.pending, (state, action) => {})
      .addCase(getDocumentFormInputDataAsync.fulfilled, (state, action) => {
        if (action.payload.formInputData === null) {
        } else {
          state.inputFields = action.payload.formInputData.formData;
          state.heading = action.payload.formInputData.heading;
        }
      })
      .addCase(getDocumentFormInputDataAsync.rejected, (state, action) => {});
  },
});

export const submitFormInputsAsync = createAsyncThunk(
  "formBuilder/submitFormInputsAsync",
  async ({ eventId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { inputFields, heading } = getState().formBuilder;

      const response = await uploadDocumentFormAPI(
        eventId,
        inputFields,
        heading
      );
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const getDocumentFormInputDataAsync = createAsyncThunk(
  "formBuilder/getDocumentFormInputDataAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getDocumentFormInputDataAPI(eventId);
      return response.data;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const {
  handleLabelChange,
  handleRequiredChange,
  handleMultipleChange,
  handleAddFields,
  setInputFields,
  handleRemoveFields,
  setHeading,
} = formBuilderSlice.actions;

export const formBuilderState = (state) => state.formBuilder;

export default formBuilderSlice.reducer;
