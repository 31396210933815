import React from "react";


export function Booth({boothImage, boothLogo, title, desc}) {
  return (
    <div className=" py-4">

      <div className="w-100  position-relative">
        <img className="w-100  rounded-3" alt="img" src= {boothImage} />
        <div className="position-absolute bottom-0 text-white p-2">
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
        <div className="position-absolute top-0">
          <img className="w-75 rounded-2 p-2"
            alt="images"
            src={boothLogo}
          />
        </div>
      </div>
    </div>
  );
}
