import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDocIdofUserAPI,
  getEventbyIdAPI,
  getFriendsIdAPI,
  getSavedEventsOfUser,
  getUserInfo,
  getUserInfoDocId,
} from "./reduxAPI";

// icon
// import { Icon } from "@iconify/react";

const initialState = {
  loadingDocId: false,

  savedEvents: [],

  friendsId: [],

  userAvl: false,
  user: null,
  docId: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDocIdofUserAsync = createAsyncThunk(
  "user/getDocIdofUser",
  async ({ uid }, { dispatch, getState, rejectWithValue }) => {
    try {
      return await getDocIdofUserAPI(uid);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSavedEventsOfUserAsync = createAsyncThunk(
  "user/getSavedEventsOfUser",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      return await getSavedEventsOfUser(docId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFriendsIdAsync = createAsyncThunk(
  "user/getFriendsId",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      const response = await getFriendsIdAPI(docId);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getUserInfoDocIdAsync = createAsyncThunk(
  "user/getUserInfoDocId",
  async ({ docId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getUserInfoDocId(docId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getEventbyIdAsync = createAsyncThunk(
  "user/getEventbyId",
  async ({ eventId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getEventbyIdAPI(eventId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserAvl: (state, action) => {
      state.userAvl = action.payload.userAvl;
      state.user = action.payload.user;
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },

    setFriendId: (state, action) => {
      state.friendsId = [...state.friendsId, action.payload.value];
    },
    clearFriendIds: (state, action) => {
      state.friendsId = [];
    },
    clearUserdocId: (state, action) => {
      state.docId = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDocIdofUserAsync.pending, (state, action) => {
        state.loadingDocId = true;
      })
      .addCase(getDocIdofUserAsync.fulfilled, (state, action) => {
        state.loadingDocId = false;
        state.docId = action.payload.data;
      })
      .addCase(getDocIdofUserAsync.rejected, (state, action) => {
        state.loadingDocId = false;
      })
      .addCase(getSavedEventsOfUserAsync.pending, (state, action) => {
        // state.loadingEvents = true;
      })
      .addCase(getSavedEventsOfUserAsync.fulfilled, (state, action) => {
        // state.loadingEvents = false;
        state.savedEvents = action.payload;
      })
      .addCase(getSavedEventsOfUserAsync.rejected, (state, action) => {
        // state.loadingEvents = false;
      })
      .addCase(getFriendsIdAsync.pending, (state, action) => {
        // state.loadingEvents = true;
      })
      .addCase(getFriendsIdAsync.fulfilled, (state, action) => {
        // state.loadingEvents = false;
        if (action.payload.length <= 0) {
          // not found
        } else {
          state.friendsId = [...action.payload];
        }
      })
      .addCase(getFriendsIdAsync.rejected, (state, action) => {
        // state.loadingEvents = false;
      });
  },
});

export const { setUserAvl, clearUserdocId, setFriendId, clearFriendIds } =
  userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const userState = (state) => state.user;

export default userSlice.reducer;
