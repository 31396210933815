// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

import { db_firestore, functions } from "../../../../../firebase.config";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs, query, where } from "firebase/firestore";
// `https://safe-springs-35306.herokuapp.com/api/formdata?cid=${cid}`;

export async function getForm(formId, companyName, eventNameParams) {
  const getForm_fb = httpsCallable(functions, "getForm");
  return (await getForm_fb({ formId, companyName, eventNameParams })).data;
}

export async function getStaticFormData() {
  const getForm_fb = httpsCallable(functions, "getStaticFormData");
  return (await getForm_fb()).data;
}
export function postFormData(formData, formId, groupId, eventId, uid) {
  const postFormData_fb = httpsCallable(functions, "postFormData");
  return postFormData_fb({ formId, formData, groupId, eventId, uid });
}

export function updateFormData(formData, formDocId) {
  const updateFormData_fb = httpsCallable(functions, "updateFormData");
  console.log(formData, formDocId);
  return updateFormData_fb({ formData, formDocId });
}

export async function getFormDocId(formId, eventId, groupId) {
  const formDocument = query(
    collection(db_firestore, "registrationForms"),
    where("eventId", "==", eventId),
    where("groupId", "==", groupId),
    where("formId", "==", formId)
  );
  let docId;
  const querySnapshot = await getDocs(formDocument);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });

  return docId;
}
