import React, {useRef, useState} from 'react'
import { RegisterBox } from "./props/RegisterBox";
import {BsThreeDots, BsFlag} from 'react-icons/bs'


//test image
import image from './image.jpg'
import './App.css'
import {Button, Overlay, Tooltip} from "react-bootstrap";


export function Banner() {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <div>


            <div className="row d-flex  align-items-center g-4 mb-5 py-4" >

                <div className="col-md-6 position-relative">
                    <img className="w-100 rounded-3" src={image} alt="Card image" />
                    <div className="position-absolute top-0 right-0 end-0 p-4">
                        <span className="border rounded-circle bg-secondary p-2 text-black" ref={target} onClick={() => setShow(!show)}>
                            <BsThreeDots/>
                        </span>
                        <Overlay target={target.current} show={show} placement="bottom-end">
                            {(props) => (
                                <Tooltip id="overlay-example" {...props}>
                                  <BsFlag/>  report
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                </div>

                <div className="col-md-6">
                    <RegisterBox />

                </div>
            </div>
        </div>


    );
}
