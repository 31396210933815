import React from 'react';
import {Button, Modal} from "react-bootstrap";
import '../Form/App.css'

const PreviewModal = ({onHide, show, CalendarLink, onAdd}) => {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size='xl'
            className='px-0'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Your Calendar
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='containerTest'>
                    <iframe className='responsive-iframe' src={CalendarLink}></iframe>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Add</Button>
                <Button onClick={onAdd}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PreviewModal;