import React from "react";
import { Link } from "react-router-dom";
// Bootstrap
import { ListGroup } from "react-bootstrap";
//Icons
import { FaFilter } from "react-icons/fa";
// redux
import { useDispatch, useSelector } from "react-redux";
// Css
import "../App.css";
import {
  clearDataArr,
  eventsFilterListState,
  filteredEventAsync,
  setEventPost,
  setFound,
} from "../../../../Main/Components/Events/List/reduxSlice";
import { rootPageState } from "../../../../Main/Routes/reduxSlice";

export default function FilterNavbar() {
  const dispatch = useDispatch();
  const filterHandler = (value) => {
    dispatch(clearDataArr({}));
    dispatch(setEventPost({ filterValue: value }));
    dispatch(setFound({ value: true }));
    dispatch(filteredEventAsync({}));
  };

  const { filterEvent } = useSelector(eventsFilterListState);
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);

  return (
    <>
      <ListGroup className="settingNav bg-dark text-light ">
        <ListGroup.Item className="py-3 h6 my-0 bg-dark text-light border">
          <FaFilter className="me-2" /> Filters
        </ListGroup.Item>
        <ListGroup className="w-100 d-flex flex-row flex-lg-column overflow-auto border">
          <Link
            className={`text-decoration-none border-0  bg-dark ${
              filterEvent === "attennding" ? "text-quaternary" : "text-light"
            } text-nowrap  list-group-item  `}
            onClick={() => {
              filterHandler("attennding");
            }}
            to={""}
          >
            Attending
          </Link>
          <Link
            className={`text-decoration-none  border-0 ${
              filterEvent === "saved" ? "text-quaternary" : "text-light"
            } text-nowrap bg-dark  list-group-item`}
            onClick={() => {
              filterHandler("saved");
            }}
            to={""}
          >
            Bookmark
          </Link>
          {isAdmin || isSubscriber || isBizAdmin ? (
            <Link
              className={`text-decoration-none border-0 ${
                filterEvent === "from_groups_you_organize"
                  ? "text-quaternary"
                  : "text-light"
              } text-nowrap bg-dark  list-group-item`}
              onClick={() => {
                filterHandler("from_groups_you_organize");
              }}
              to={""}
            >
              From groups you organize
            </Link>
          ) : null}

          <Link
            className={`text-decoration-none border-0 ${
              filterEvent === "from_groups_you_joined"
                ? "text-quaternary"
                : "text-light"
            } text-nowrap bg-dark  list-group-item`}
            onClick={() => {
              filterHandler("from_groups_you_joined");
            }}
            to={""}
          >
            From groups you've joined
          </Link>
        </ListGroup>
      </ListGroup>
    </>
  );
}
