import React from "react";

// Bootstrap
import { Form, Button } from "react-bootstrap";

// Icons
import { Icon } from "@iconify/react";

const GroupType = ({ groupType, setGroupType, backPage, Submit, loading }) => {
  return (
    <div className="page-wrapper">
      <section className="">
        <form>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-8">
              <h1>Group Type</h1>
              <p>
                Select your group privacy.You can choose either public or
                private
              </p>
              <div className="input-icon mb-3 place_autocomplete_container">
                <Form validated className="">
                  <select
                    defaultValue=""
                    value={groupType}
                    required
                    onChange={setGroupType}
                    className="px-2 py-2 border bg-dark text-light rounded-3 w-100"
                  >
                    <option value="" selected className="px-5 py-2">
                      Select group type
                    </option>
                    <option value="public" className="px-5 py-2">
                      Public
                    </option>
                    <option value="private" className="px-5 py-2">
                      Private
                    </option>
                  </select>
                </Form>
                <div className="mt-3">
                  <Button onClick={backPage}>Back</Button>
                  <Button
                    disabled={loading}
                    className="btn btn-primary float-end"
                    onClick={Submit}
                  >
                    {loading ? (
                      <Icon icon="eos-icons:bubble-loading" />
                    ) : (
                      <>Create group</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupType;
