import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteAgendaPdf,
  getAgendaPdfUrlAPI,
  saveAgendaPdfUrlAPI,
  setDisplayPdfAPI,
  uploadAgendaPdf,
} from "./reduxAPI";

const initialState = {
  showCreateAgendaSessionForm: false,

  uploadPdf: false,
  displayPdf: false,

  uploadingPdf: false,

  agendaPdfUrl: "",
};
export const uploadAgendaPdfAsync = createAsyncThunk(
  "schedule/uploadAgendaPdf",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { selectedFiles } = getState().event;

      if (selectedFiles.length > 0) {
        let profileUrl = [];

        await deleteAgendaPdf(eventId);

        await Promise.all(
          // eslint-disable-next-line array-callback-return
          selectedFiles.map(async (file, index) => {
            if (file) {
              const downloadURL = await uploadAgendaPdf(file, eventId);
              console.log("**downloadUrl ", index, " ", downloadURL);
              profileUrl = [...profileUrl, downloadURL];
            }
          })
        );

        await saveAgendaPdfUrlAPI(eventId, profileUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const setDisplayPdfAsync = createAsyncThunk(
  "schedule/setDisplayPdf",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;
      const { displayPdf } = getState().schedule;

      const response = await setDisplayPdfAPI(eventId, displayPdf);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveAgendaPdfUrlAsync = createAsyncThunk(
  "schedule/saveAgendaPdfUrl",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { uploadAgendaPdfUrl } = getState().schedule;

      const response = await saveAgendaPdfUrlAPI(eventId, uploadAgendaPdfUrl);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAgendaPdfUrlAsync = createAsyncThunk(
  "schedule/getAgendaPdfUrl",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getAgendaPdfUrlAPI(eventId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUploadPdf: (state, action) => {
      state.uploadPdf = action.payload.value;
    },

    setDisplayPdf: (state, action) => {
      state.displayPdf = action.payload.value;
    },

    setUploadingPdf: (state, action) => {
      state.uploadingPdf = action.payload.value;
    },

    setShowCreateAgendaSessionForm: (state, action) => {
      state.showCreateAgendaSessionForm = action.payload.value;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(getAgendaPdfUrlAsync.pending, (state, action) => {})
      .addCase(getAgendaPdfUrlAsync.fulfilled, (state, action) => {
        let agendaPdfUrl = action.payload.data.pdfUrl;

        state.agendaPdfUrl = agendaPdfUrl;
      })
      .addCase(getAgendaPdfUrlAsync.rejected, (state, action) => {})
      .addCase(uploadAgendaPdfAsync.pending, (state, action) => {})
      .addCase(uploadAgendaPdfAsync.fulfilled, (state, action) => {})
      .addCase(uploadAgendaPdfAsync.rejected, (state, action) => {});
  },
});

export const {
  setUploadPdf,
  setPdfName,
  setDisplayPdf,
  setUploadingPdf,
  setShowCreateAgendaSessionForm,
} = scheduleSlice.actions;

export const scheduleState = (state) => state.schedule;

export default scheduleSlice.reducer;
