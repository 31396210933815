import React from "react";
import Placeholder from "react-bootstrap/Placeholder";

const EventListFilterSkeleton = ({ list }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "16 / 10 ",
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <div>
            <Placeholder animation="glow">
              <Placeholder xs={3} />
            </Placeholder>

            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            </div>

            <div>
              <Placeholder animation="glow">
                <Placeholder xl={10} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={4} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={2} /> <Placeholder xl={2} />
              </Placeholder>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mt-auto">
            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr></hr>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "16 / 10 ",
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <div>
            <Placeholder animation="glow">
              <Placeholder xs={3} />
            </Placeholder>

            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            </div>

            <div>
              <Placeholder animation="glow">
                <Placeholder xl={10} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={4} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={2} /> <Placeholder xl={2} />
              </Placeholder>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mt-auto">
            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr></hr>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "16 / 10 ",
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <div>
            <Placeholder animation="glow">
              <Placeholder xs={3} />
            </Placeholder>

            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} />
              </Placeholder>
            </div>

            <div>
              <Placeholder animation="glow">
                <Placeholder xl={10} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={4} />
              </Placeholder>
            </div>
            <div>
              <Placeholder animation="glow">
                <Placeholder xl={2} /> <Placeholder xl={2} />
              </Placeholder>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mt-auto">
            <div>
              <Placeholder animation="glow">
                <Placeholder xs={2} /> 
              </Placeholder>
            </div>
        
          </div>
        </div>
          <hr></hr>
      </div>
    </div>
  );
};

export default EventListFilterSkeleton;
