import "./components/react-form-builder/App.css";

import React, { useEffect, useState } from "react";
import { ReactFormBuilder } from "react-form-builder2";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";

import { getForm, getStaticFormData } from "./reduxAPI";
import { registrationFormState, setData } from "./reduxSlice";
import { darkModeState } from "../../../../Main/Components/DarkMode/reduxSlice";
import { useParams } from "react-router-dom";
import { createEventState } from "../Schedule_Event/reduxSlice";

const RegistrationForm = () => {
  const { companyName, eventNameParams } = useParams();
  const [preViewdataState, setPreViewdataState] = useState("");

  const { formId } = useSelector(registrationFormState);
  const { dark } = useSelector(darkModeState);
  const { editEvent } = useSelector(createEventState);

  const dispatch = useDispatch();

  useEffect(() => {
    var formBuilderComp = document.querySelector(".react-form-builder > div");
    if (formBuilderComp) {
      formBuilderComp.classList.add("row");
      formBuilderComp.classList.add("m-0");
    }
  }, []);

  useEffect(() => {
    let darkStyle = document.documentElement;
    let lightStyle = getComputedStyle(document.documentElement);
    if (dark) {
      darkStyle.style.setProperty(
        "--bizform-light-rgb",
        lightStyle.getPropertyValue("--bs-dark-rgb")
      );
      darkStyle.style.setProperty(
        "--bizform-dark-rgb",
        lightStyle.getPropertyValue("--bs-light-rgb")
      );
    } else {
      darkStyle.style.setProperty(
        "--bizform-light-rgb",
        lightStyle.getPropertyValue("--bs-light-rgb")
      );
      darkStyle.style.setProperty(
        "--bizform-dark-rgb",
        lightStyle.getPropertyValue("--bs-dark-rgb")
      );
    }
  }, [dark]);

  useEffect(() => {
    console.log("json form Data", preViewdataState);

    return () => {
      if (preViewdataState) {
        dispatch(setData({ previewData: JSON.parse(preViewdataState) }));
      }
    };
  }, [preViewdataState, dispatch]);

  const onLoad = () => {
    console.log("running");
    if (editEvent) {
      return getForm(formId, companyName, eventNameParams);
    } else {
      return getStaticFormData();
    }
  };

  const onPost = (data) => {
    setPreViewdataState(JSON.stringify(data));
  };

  return (
    <div className="">
      <ReactFormBuilder onLoad={onLoad} onPost={onPost} />
    </div>
  );
};

export default RegistrationForm;
