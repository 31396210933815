import React from 'react';
import {Link} from "react-router-dom";
import {AdminNavigation} from "../../../CommonComponents/AdminNavigation/App";

const CreateBlogCategories = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">

                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <Link to="#" className="page-pretitle text-decoration-none text-light  fw-bold">
                                   <small> Blog Categories</small>
                                </Link>
                                <h1 className="page-title h5 fw-bolder">
                                    Create Blog Category
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    
                                        <div className="card  bg-dark text-light border p-3 mb-4">
                                            <div className="row">
                                                <div className="col-12 card-body">
                                                    <div className="form-group mb-4 required">
                                                        <label className="mb-1">Name</label>
                                                        <input   type="text" className="form-control bg-dark text-light"
                                                               placeholder="Name" value="" required=""/>
                                                    </div>


                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>

            
        </div>
    );
};

export default CreateBlogCategories;