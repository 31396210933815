import React, {useState} from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

//test data
const content = [
    {id: 1,
        name: "UAE Dirham",
        code: "AED",
        symbol: "د.إ",
        precision: "2",
        thousands: ",",
        decimal: ".",
        status: "enabled",

    },
    {id: 2,
        name: "UAE Dirham",
        code: "AED",
        symbol: "د.إ",
        precision: "2",
        thousands: ",",
        decimal: ".",
        status: "enabled",

    },
    {id: 3,
        name: "UAE Dirham",
        code: "AED",
        symbol: "د.إ",
        precision: "2",
        thousands: ",",
        decimal: ".",
        status: "enabled",

    },
    {id: 4,
        name: "UAE Dirham",
        code: "AED",
        symbol: "د.إ",
        precision: "2",
        thousands: ",",
        decimal: ".",
        status: "enabled",

    },


]

const CurrenciesSetting = () => {

    const [disable, setDisable] = useState(false);
    const handleCondition = () => {
        setDisable(true)
    }
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                <small>Settings</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Currency Settings
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title">Currencies</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th className="word-break-keep">ID</th>
                                                <th className="word-break-keep">Name</th>
                                                <th className="word-break-keep">Code</th>
                                                <th className="word-break-keep">Symbol</th>
                                                <th className="word-break-keep">Precision</th>
                                                <th className="word-break-keep">Thousands Separator</th>
                                                <th className="word-break-keep">Decimal Separator</th>
                                                <th className="word-break-keep">Status</th>
                                                <th className="word-break-keep">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="currencies">


                                                {content.map((item) => (
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0 word-break-keep">{item.id}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.code}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.symbol}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.precision}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.thousands}</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0">{item.decimal}</p>
                                                        </td>
                                                        <td>
                                                            {disable ? <div className="badge bg-danger">Disabled</div>   :<div className="badge bg-success"> {item.status} </div>  }
                                                        </td>
                                                        <td>
                                                            {disable ? <div onClick={(e) => setDisable(false)} className=" text-primary"> Enable</div>   :  <div onClick={handleCondition} className=" text-danger">Disable</div> }
                                                        </td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<Admin_Footer/>
        </div>
    );
};

export default CurrenciesSetting;