import React, { useEffect, useState } from "react";

// Bootstrap
import {
  Container,
  Form,
  Button,
  InputGroup,
  Modal,
  CloseButton,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";

//Icon
import { BsSearch } from "react-icons/bs";
// Css
import styles from "../App.module.css";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";

import {
  addInterestFromRecommendedInterest,
  addInterestFromSearchInterest,
  addInterestsAsync,
  addInterestToUserInterest,
  addNewInterestAsync,
  algoliaInterestAsync,
  getInterestsAsync,
  getRecommendedInterestAsync,
  removeInterestAsync,
  removeInterestFromRecommendedInterest,
  removeInterestFromSearchInterest,
  removeInterestToUserInterest,
  setFoundSearchedInterest,
  setHeighLightInterestId,
  setInterests,
  setSerachInterestQuery,
  userProfileState,
} from "../reduxSlice";
import { darkModeState } from "../../../../Main/Components/DarkMode/reduxSlice";
import { Icon } from "@iconify/react";
import { rootPageState } from "../../../../Main/Routes/reduxSlice";

export default function InterestPanel() {
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);
  const {
    interests,
    recommendedInterests,
    searchedInterests,
    serachInterestQuery,
    loadingSearchedInterests,
    foundSearchedInterest,
    heighLightInterestId,
    creatingNewInterestLoading,
  } = useSelector(userProfileState);
  const { dark } = useSelector(darkModeState);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const action = await dispatch(getInterestsAsync({}));
      let interests = action.payload.data.interests;

      if (recommendedInterests.length <= 0) {
        dispatch(getRecommendedInterestAsync({}));
      }
      dispatch(algoliaInterestAsync({}));
      dispatch(setInterests({ interests: interests }));
    })();

    return () => {};
  }, [dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(setFoundSearchedInterest({ value: true }));
    dispatch(algoliaInterestAsync({}));
  };

  useEffect(() => {
    console.log("running....");

    const timer = setTimeout(() => {
      console.log("running....timer");
      dispatch(setHeighLightInterestId({ value: "" }));
    }, 300);
    return () => clearTimeout(timer);
  }, [dispatch, heighLightInterestId]);

  // temp
  const [showAddInterestModal, setShowAddInterestModal] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleClose = () => {
    setShowAddInterestModal(false);
    dispatch(setSerachInterestQuery({ value: "" }));
  };
  const handleShow = () => {
    setShowAddInterestModal(true);
  };

  const popover = (
    <Popover id="popover-basic" className="bg-dark text-light border">
      <Popover.Body className="text-light">
        Please try to add unique interest which are not available in our
        platform.
      </Popover.Body>
    </Popover>
  );

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
    }

    setValidated(true);
    if (form.checkValidity() === true) {
      setValidated(false);
      await dispatch(addInterestsAsync({}));
      dispatch(setSerachInterestQuery({ value: "" }));
      dispatch(getRecommendedInterestAsync({}));
      dispatch(algoliaInterestAsync({}));
      (async () => {
        const action = await dispatch(getInterestsAsync({}));
        let interests = action.payload.data.interests;
        dispatch(setInterests({ interests: interests }));
      })();
    }
  };

  return (
    <>
      <Container className="mt-3 px-lg-5 px-2 vh-100">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4>Interests</h4>
            <p>Manage your interests here</p>
          </div>

          {isSubscriber || isAdmin || isBizAdmin ? (
            <button className="btn btn-quaternary" onClick={handleShow}>
              Create Interest
            </button>
          ) : null}
        </div>

        <hr />
        <h6>Your Interests</h6>
        <Container className="px-0">
          <ul className="list-inline">
            {false ? null : (
              <>
                {interests.length > 0 ? (
                  <>
                    {interests.map(({ id, interest, from }, index) => {
                      return (
                        <li key={id} className="list-inline-item mb-2 ">
                          <a
                            href="/"
                            draggable={false}
                            onClick={(e) => {
                              e.preventDefault();

                              dispatch(removeInterestToUserInterest({ index }));

                              if (from === "recommend") {
                                dispatch(
                                  addInterestFromRecommendedInterest({
                                    id,
                                    interest,
                                  })
                                );
                              } else if (from === "search") {
                                dispatch(
                                  addInterestFromSearchInterest({
                                    id,
                                    interest,
                                  })
                                );
                              }

                              dispatch(
                                removeInterestAsync({
                                  interestId: id,
                                })
                              );
                            }}
                            className={`badge ${
                              heighLightInterestId !== id
                                ? "bg-primary"
                                : "bg-tertiary"
                            } fs-6 text-white p-2 text-capitalize text-decoration-none cursor-event`}
                          >
                            {interest}
                          </a>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <div className="text-center">No Interest</div>
                )}
              </>
            )}
          </ul>
        </Container>

        <Container
          fluid
          className="d-flex justify-content-between px-0 align-items-center"
        >
          {recommendedInterests.length > 0 ? <h6>Recommended</h6> : null}
        </Container>
        <Container className="px-0">
          <ul className="list-inline">
            {recommendedInterests.map(({ id, interest }, index) => {
              return (
                <li key={id} className="list-inline-item mb-2 ">
                  <a
                    href="/"
                    draggable={false}
                    onClick={(e) => {
                      e.preventDefault();

                      dispatch(
                        removeInterestFromRecommendedInterest({ index })
                      );
                      dispatch(
                        addInterestToUserInterest({
                          id,
                          interest,
                          from: "recommend",
                        })
                      );

                      dispatch(
                        addNewInterestAsync({
                          interestId: id,
                        })
                      );
                    }}
                    className="badge bg-primary text-capitalize fs-6 text-white p-2 text-decoration-none cursor-event"
                  >
                    {interest}
                  </a>
                </li>
              );
            })}
          </ul>

          <div
            className={`d-flex mb-2 justify-content-between align-items-center ${
              showAddInterestModal ? "d-none" : null
            }  `}
          >
            <h6 className="mb-0">Search Result</h6>
            <Form
              className="d-none d-md-flex d-lg-flex"
              onSubmit={handleSearch}
            >
              <InputGroup>
                <InputGroup.Text
                  id="basic-addon1"
                  className="border-end-0 bg-dark text-light pe-0"
                >
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  value={!showAddInterestModal ? serachInterestQuery : ""}
                  onChange={(e) => {
                    dispatch(setSerachInterestQuery({ value: e.target.value }));
                  }}
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  className="border-start-0 bg-dark text-light"
                ></Form.Control>
              </InputGroup>
            </Form>
          </div>
          {showAddInterestModal ? null : (
            <>
              {loadingSearchedInterests ? null : (
                <>
                  {foundSearchedInterest ? (
                    <>
                      <div>
                        {searchedInterests.map(({ id, interest }, index) => {
                          return (
                            <li key={id} className="list-inline-item mb-2 ">
                              <a
                                href="/"
                                draggable={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(
                                    removeInterestFromSearchInterest({ index })
                                  );
                                  dispatch(
                                    addInterestToUserInterest({
                                      id,
                                      interest,
                                      from: "search",
                                    })
                                  );

                                  dispatch(
                                    addNewInterestAsync({
                                      interestId: id,
                                    })
                                  );
                                }}
                                className="badge bg-primary text-capitalize fs-6 text-white p-2 text-decoration-none cursor-event"
                              >
                                {interest}
                              </a>
                            </li>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">No Match Found</div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </Container>

      <Modal show={showAddInterestModal} onHide={handleClose}>
        <Modal.Header className="bg-dark text-light">
          <Modal.Title>Add New Interest</Modal.Title>
          <CloseButton
            className="shadow-none"
            variant={`${dark ? "white" : ""}`}
            onClick={handleClose}
          />
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body className="bg-dark text-light">
            <div className="mb-2">
              <Form.Control
                value={serachInterestQuery}
                onChange={(e) => {
                  setValidated(false);
                  dispatch(setSerachInterestQuery({ value: e.target.value }));

                  if (e.target.value === "") {
                    dispatch(setFoundSearchedInterest({ value: true }));
                  }

                  dispatch(algoliaInterestAsync({}));
                }}
                className="bg-dark text-light "
                placeholder="New Interest"
                minLength={3}
                maxLength={26}
                autoComplete={"off"}
                aria-label="New Interest"
                aria-describedby="basic-addon1"
                required
              />
              <Form.Control.Feedback type="invalid">
                value must between 3 to 26 characters
              </Form.Control.Feedback>
            </div>

            <div>
              <div>
                <p className="text-muted d-flex mb-1 justify-content-between align-items-center">
                  Interest that already exsists in our platform
                  <OverlayTrigger trigger="hover" overlay={popover}>
                    <Icon className="ms-2 " icon="entypo:info-with-circle" />
                  </OverlayTrigger>
                </p>
              </div>
              <div className="">
                <div>
                  {searchedInterests
                    .slice(0, 50)
                    .map(({ id, interest }, index) => {
                      return (
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm me-1 mb-1 "
                        >
                          {interest}
                        </button>
                      );
                    })}
                </div>

                {searchedInterests.length <= 0 ? (
                  <div className="text-center">
                    No Interest Exists{" "}
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm me-1 ms-2 mb-1 "
                    >
                      {serachInterestQuery}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-dark text-light">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={creatingNewInterestLoading}
              type="submit"
              variant="primary"
            >
              {creatingNewInterestLoading ? (
                <Icon icon="line-md:loading-twotone-loop" className="w-100" />
              ) : (
                "Add"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
