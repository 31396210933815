import React, { useState } from "react";
import { Button, Modal, CloseButton, Toast } from "react-bootstrap";
//redux
import { useSelector } from "react-redux";
import { darkModeState } from "../../../DarkMode/reduxSlice";
import isValidUrl from "../../../utils/isValidUrl";
import { eventState } from "../reduxSlice";
// import "../../App.css";

export default function Chat(props) {
  const {
    eventData: { generalChatLink },
  } = useSelector(eventState);
  const { dark } = useSelector(darkModeState);

  let isValidPollLink = isValidUrl(generalChatLink);

  return (
    <>
      {isValidPollLink ? (
        // <Modal
        //   {...props}
        //   size="sm"
        //   bottom
        //   className="px-0 start-0"
        //   backdrop="static"
        //   backdropClassName="preview-modal"
        //   id="tabsModal"
        // >
        //   <Modal.Header className="bg-dark text-light border-0 py-2">
        //     <Modal.Title className="h6">
        //       General Chat
        //     </Modal.Title>
        //     <CloseButton
        //       className="shadow-none small"
        //       variant={`${dark ? "white" : ""}`}
        //       onClick={props.onHide}
        //     />
        //   </Modal.Header>
        //   <Modal.Body
        //     className="position-relative px-0"
        //     style={{ height: "15rem" }}
        //   >
        //     <iframe
        //       id="modalIframe"
        //       title="chat"
        //       src={`${generalChatLink}${dark ? "?mode=dark" : "?mode=light"}`}
        //       height={"100%"}
        //       width={"100%"}
        //       style={{ overflowX: "hidden" }}
        //       className={`position-absolute top-0 left-0 h-100 ${
        //         dark ? "bg-light" : "bg-dark"
        //       }`}
        //     ></iframe>
        //   </Modal.Body>
        // </Modal>

        <Toast  {...props} onClose={props.onHide} className="position-fixed bottom-0" style={{zIndex:'10000000000000'}}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">General Chat</strong>
          </Toast.Header>
          <Toast.Body className="position-relative px-0"
            style={{ height: "25rem" }}>
            <iframe
              id="modalIframe"
              title="chat"
              src={`${generalChatLink}${dark ? "?mode=dark" : "?mode=light"}`}
              height={"100%"}
              width={"100%"}
              style={{ overflowX: "hidden" }}
              className={`position-absolute top-0 left-0 h-100 ${dark ? "bg-light" : "bg-dark"
                }`}
            ></iframe>
          </Toast.Body>
        </Toast>
      ) : (
        eval(generalChatLink)
      )}
    </>
  );
}
