import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendMailAPI } from "./reduxAPI";
import { pushErrorNotification } from "../../../Main/Components/Error/reduxSlice";
import { Icon } from "@iconify/react";

const initialState = {
  to: "",
  subject: "",
  text: "",
  html: "",
};

export const sendMailAsync = createAsyncThunk(
  "mail/sendMailToUser",
  async (
    { to, subject, text, html },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      await sendMailAPI(to, subject, text, html);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const mailSlice = createSlice({
  name: "mail",
  initialState,

  reducers: {
    setTo: (state, action) => {
      state.to = action.payload.to;
    },
    setSubject: (state, action) => {
      state.subject = action.payload.subject;
    },
    setText: (state, action) => {
      state.text = action.payload.text;
    },
    setHtml: (state, action) => {
      state.html = action.payload.html;
    },
    clearInputs: (state, action) => {
      state.text = "";
      state.to = "";
      state.subject = "";
      state.html = "";
    },
  },

  extraReducers: (builder) => {},
});

export const { setTo, setSubject, setText, setHtml, clearInputs } =
  mailSlice.actions;
export const mailState = (state) => state.mail;

export default mailSlice.reducer;
