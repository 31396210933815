import { doc, getDoc } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import { getDataofUser } from "../../User/reduxAPI";

export async function getCompanyNameFromEventId(eventId, eventNameAvl) {
  let companyName;
  let eventName;
  const eventRef = doc(db_firestore, "events", eventId);
  const eventSnap = await getDoc(eventRef);

  if (eventSnap.exists()) {
    if (!eventNameAvl) {
      eventName = eventSnap.data().eventName;
    }
    let data = await getDataofUser(eventSnap.data().subscriberUid);
    companyName = data.companyName
  }

  return { companyName, eventName };
}
