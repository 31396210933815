import React, { Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formBuilderState,
  handleAddFields,
  handleLabelChange,
  handleMultipleChange,
  handleRemoveFields,
  handleRequiredChange,
  submitFormInputsAsync,
} from "./reduxSlice";

// Drag and drop
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Container } from "./Container";
import { setFormData } from "../FormGenerator/reduxSlice";

// React lazy
const FormGenerator = React.lazy(() => import("../FormGenerator/App"));

const MultipleInputFormBuilder = () => {
  const dispatch = useDispatch();

  const addFields = () => {
    dispatch(handleAddFields({}));
  };


  return (
    <div className="App bg-dark text-light p-4">
      <form >
        <DndProvider backend={HTML5Backend}>
          <Container />
        </DndProvider>

        <button type={"button"} onClick={addFields} className="btn btn-primary">
          Add More..
        </button>
      </form>
    </div>
  );
};

export default MultipleInputFormBuilder;
