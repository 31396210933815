import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../components/Main/Components/auth/Login/reduxSlice";
import registerReducer from "../components/Main/Components/auth/Register/reduxSlice";
import logoutReducer from "../components/Main/Components/auth/Logout/reduxSlice";
import darkModeReducer from "../components/Main/Components/DarkMode/reduxSlice";
import errorReducer from "../components/Main/Components/Error/reduxSlice";
import rootPageReducer from "../components/Main/Routes/reduxSlice";

import userReducer from "../components/Main/Components/User/reduxSlice";
import viewProfileReducer from "../components/Test/components/Profile/reduxSlice";

import registrationFormReducer from "../components/Test/components/MyGroup/RegistrationForm/reduxSlice";

import notificationReducer from "../components/Main/Components/User/Notification/reduxSlice";
import navBarReducer from "../components/Main/Components/Navbar/reduxSlice";
import eventsListReducer from "../components/Main/Components/Events/Card/reduxSlice";
import eventSpeakersReducer from "../components/Main/Components/Events/Page Component/Speakers/reduxSlice";
import eventsFilterListReducer from "../components/Main/Components/Events/List/reduxSlice";
import groupsListReducer from "../components/Main/Components/Groups/Card/reduxSlice";
import sponsorsReducer from "../components/Main/Components/Events/Page Component/Sponsors/reduxSlice";
import groupsFilterListReducer from "../components/Main/Components/Groups/List/reduxSlice";
import joinEventMailReducer from "../components/Main/Components/Events/Emails/JoinEvent/reduxSlice";

//test
import createPlanReducer from "../components/Test/components/AdminDashboard/Subscriptions/Plan/CreatePlan/reduxSlice";
import userProfileReducer from "../components/Test/components/UserProfile/reduxSlice";
import eventReducer from "../components/Main/Components/Events/Page Component/reduxSlice";
import mailReducer from "../components/Test/components/Mail/reduxSlice";
import createEventReducer from "../components/Test/components/MyGroup/Schedule_Event/reduxSlice";
import createGroupReducer from "../components/Main/Components/Groups/CreateGroup/reduxSlice";
import formBuilderReducer from "../components/Main/Components/Events/Page Component/Documents/components/MultipleInputFormBuilder/reduxSlice";
import formGeneratorReducer from "../components/Main/Components/Events/Page Component/Documents/components/FormGenerator/reduxSlice";
import groupReducer from "../components/Test/components/MyGroup/MyGroupOnBoard/reduxSlice.js";

import eventNetworkingMangReducer from "../components/Main/Components/Events/Networking/NetworkingManagement/reduxSlice";

import scheduleReducer from "../components/Main/Components/Events/Page Component/Schedule/reduxSlice";

import eventInvitationReducer from "../components/Main/Components/Events/Emails/EventInvitation/reduxSlice"
import shareReducer from "../components/Main/Components/ShareModal/reduxSlice";
import footerReducer from "../components/Main/Components/Footer/reduxSlice";
import whyAttendReducer from "../components/Main/Components/Events/Page Component/WhyAttend/reduxSlice";
import boothsReducer from "../components/Main/Components/Events/Page Component/Booths/reduxSlice";
import sessionFormReducer from "../components/Main/Components/Events/Page Component/Schedule/components/sessions/Form/reduxSlice";
import faqReducer from "../components/Main/Components/Events/Page Component/FAQ/reduxSlice"
import venueInformationReducer from "../components/Main/Components/Events/Page Component/Details/venueInfo/reduxSlice";
import checkInCheckOutReducer from "../components/Main/Components/Events/Page Component/CheckInCheckOut/reduxSlice";
//Calendar
import calendarReducer from "../components/Test/components/Calendar/reduxSlice";

//Interest
import interestReducer from "../components/Test/components/CommonComponents/Interest/reduxlice";

//AttendeeDetailsApi
import attendeeReducer from "../components/Main/Components/Events/Attendees/reduxSlice";

import announcementFormReducer from "../components/Main/Components/Events/Page Component/AnnouncementForm/reduxSlice";

//upload document for organizer
import uploadDocDataReducer from "../components/Test/components/UploadDocument/reduxSlice";

export const store = configureStore({
  reducer: {
    // Main
    // auth
    login: loginReducer,
    register: registerReducer,
    logout: logoutReducer,

    darkMode: darkModeReducer,
    error: errorReducer,
    rootPage: rootPageReducer,

    // navBar
    navBar: navBarReducer,

    // user
    user: userReducer,
    viewProfile: viewProfileReducer,

    // Notification
    notification: notificationReducer,
    // announcementForm
    announcementForm: announcementFormReducer,

    // Group
    groupsList: groupsListReducer,
    group: groupReducer,
    createGroup: createGroupReducer,

    // Event
    eventsList: eventsListReducer,
    eventSpeakers: eventSpeakersReducer,
    registrationForm: registrationFormReducer,
    formBuilder: formBuilderReducer,
    formGenerator: formGeneratorReducer,
    sponsors: sponsorsReducer,
    schedule: scheduleReducer,
    sessionForm: sessionFormReducer,
    whyAttend: whyAttendReducer,
    booths: boothsReducer,
    joinEventMail: joinEventMailReducer,
    faq: faqReducer,
    venueInformation: venueInformationReducer,
    // attendeeDetailsApi
    attendee: attendeeReducer,
    eventInvitation: eventInvitationReducer,
    eventNetworkingMang: eventNetworkingMangReducer,
    checkInCheckOut: checkInCheckOutReducer,


    // Filter
    eventsFilterList: eventsFilterListReducer,
    groupsFilterList: groupsFilterListReducer, //NEW

    // Test
    createPlan: createPlanReducer,
    createEvent: createEventReducer,

    userProfile: userProfileReducer,
    event: eventReducer,
    mail: mailReducer,

    // Share Link,
    share: shareReducer,

    //Calendar
    addCalendar: calendarReducer,

    //Interest
    interest: interestReducer,

    // Footer
    footer: footerReducer,

    // upload document for organizer
    uploadDocData: uploadDocDataReducer,
  },
});
