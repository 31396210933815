import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { darkModeState } from "../../../../../Main/Components/DarkMode/reduxSlice";

const ChatModals = ({ show, onHide }) => {
  const { dark } = useSelector(darkModeState);
  return (
    <div>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
        onHide={onHide}
        className='px-0'
      >
        <Modal.Header className="bg-dark text-light" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <iframe
            title="chat"
            className={`w-100 ${dark ? "bg-light" : "bg-dark"}`}
            style={{ height: "75vh" }}
            src={`https://bizchat.netlify.app/oneToOne/B9lpzR1W0KSB5VrOY3qNdaPnPUw1/ghanshyam${
              dark ? "?mode=dark" : "?mode=light"
            }`}
          />
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChatModals;
