import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getGroupIdAsync,
  groupOnlyEventAsync,
  groupState,
  groupUpcomingEventAsync,
} from "../../MyGroupOnBoard/reduxSlice";
import EventGroupCard from "../EventGroupCard/App";
import NoEvent from "../No Event/App";
import { BsFillCalendarEventFill } from "react-icons/bs";

const Upcoming = () => {
  const dispatch = useDispatch();
  const { groupId, companyName  , groupNameParams } = useParams();
  const { upcomingEventDataArr, generatedGroupId } = useSelector(groupState);

  useEffect(() => {
    dispatch(
      groupUpcomingEventAsync({ companyName, groupNameParams })
    );
  }, [dispatch, groupNameParams, companyName]);

  return (
    <div>
      <div className="">
        {upcomingEventDataArr.length > 0 ? (
          upcomingEventDataArr.map((item, index) => (
            <EventGroupCard index={index} eventsData={item} />
          ))
        ) : (
          <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-5 border mb-4">
            <BsFillCalendarEventFill />
            <p className="fs-6 mb-0 mt-2">No events yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upcoming;
