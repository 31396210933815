import React, { useEffect, useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";

// Css
import "./HomePage.css";

// Icons
import { IoIosArrowDroprightCircle } from "@react-icons/all-files/io/IoIosArrowDroprightCircle";
import { IoIosArrowDropleftCircle } from "react-icons/io";

// Router
import { Link } from "react-router-dom";

// Components
import EventCard from "../../Components/Events/Card/App";
import GroupCard from "../../Components/Groups/Card/App";
import NotFoundEvents from "../../Components/Events/NotFound/App";
import EventSkeleton from "../../Components/Events/PlaceHolder/EventSkeleton";
import BlogSection from "../../Components/Blog/App";

// Bootstrap
import { Col, Container, Row, Image, Placeholder } from "react-bootstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { rootPageState } from "../../Routes/reduxSlice";
import {
  eventsListAsync,
  eventsListState,
  groupsEventsAsync,
  joinedEventsAsync,
  organizedEventsAsync,
  clearAllFound,
  clearAllDataArr,
} from "../../Components/Events/Card/reduxSlice";
import {
  getSavedEventsOfUserAsync,
  userState,
} from "../../Components/User/reduxSlice";
import {
  clearPublicGroupDataArr,
  publicGroupsListAsync,
  clearOrganizedGroupsDataArr,
  clearJoinedGroupDataArr,
  joinedGroupsListAsync,
  groupsListState,
  groupsListAsync,
} from "../../Components/Groups/Card/reduxSlice";

//breakpoint css
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 600, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1000, itemsToShow: 4 },
];

//custom arrow
function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <div className="fs-1  custom-arrow custom-arrow-left">
        <IoIosArrowDropleftCircle />
      </div>
    ) : (
      <div className="fs-1 custom-arrow custom-arrow-right">
        <IoIosArrowDroprightCircle />
      </div>
    );
  return (
    <span className="" onClick={onClick}>
      {pointer}
    </span>
  );
}

const Onboard = () => {
  const dispatch = useDispatch();
  const { isAdmin, isSubscriber, isBizAdmin } = useSelector(rootPageState);

  const {
    organizedEventsLoading,
    createdEventsDataArr,
    foundOrganizedEvent,

    joinedEventsLoading,
    joinedEventsDataArr,
    foundJoinedEvents,

    groupsEventsLoading,
    groupsEventsDataArr,
    foundGroupsEvents,

    eventsDataArr,
    loading: fetchLoading,
    found,
  } = useSelector(eventsListState);

  const {
    organizedLoading,
    organizedFound,
    organizedGroupsDataArr,

    publicGroupLoading,
    publicGroupFound,
    publicGroupsDataArr,

    joinedGroupsLoading,
    joinedGroupsFound,
    joinedGroupsDataArr,
  } = useSelector(groupsListState);

  const { userAvl, docId } = useSelector(userState);

  useEffect(() => {
    dispatch(eventsListAsync({}));
    dispatch(publicGroupsListAsync({ all: true }));
    if (userAvl && docId) {
      dispatch(groupsListAsync({ organizer: true }));
      dispatch(joinedGroupsListAsync({}));
      dispatch(getSavedEventsOfUserAsync({}));
      dispatch(groupsEventsAsync({}));
      dispatch(joinedEventsAsync({}));
      dispatch(organizedEventsAsync({}));
    }

    return () => {
      dispatch(clearJoinedGroupDataArr({}));
      dispatch(clearAllFound({ value: true }));
      dispatch(clearAllDataArr({}));
      dispatch(clearPublicGroupDataArr({}));
      dispatch(clearOrganizedGroupsDataArr({}));
      dispatch(clearJoinedGroupDataArr({}));
      dispatch(clearOrganizedGroupsDataArr({}));
    };
  }, [dispatch, docId, userAvl]);

  const [imgLoading, setImgLoading] = useState(true);

  return (
    <>
      <div className={` container`}>
        <div className="">
          {!userAvl ? (
            <>
              <Row className="mx-0 justify-content-center align-items-center my-5 flex-column flex-md-row">
                <Col className="text-center text-md-start">
                  <h1>Dive in! There are so many things to do on Network</h1>
                  <p>
                    Join a group to meet people, make friends, find support,
                    grow a business, and explore your interests. Thousands of
                    events are happening every day, both online and in person!
                  </p>
                </Col>
                <Col className='py-0'>
                  <Image
                    fluid
                    draggable={false}
                    src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FNetworking_2%20(1).png?alt=media&token=231d1f1a-fb04-4435-9e5f-ba74b4812b57"
                    className={` ${imgLoading ? "d-none" : null}`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    alt="..."
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow w-100 h-100">
                      <Placeholder
                        className="rounded col-12"
                        style={{
                          aspectRatio: "16/ 10",
                        }}
                      />
                    </Placeholder>
                   ) : null}
                </Col>
              </Row>
              <Container fluid className="text-center my-5">
                <h3>How Network works</h3>
                <p>
                  Meet new people who share your interests through online and
                  in-person events. It's free to create an account.
                </p>
                <Row className="flex-column flex-md-row">
                  <Col>
                    <Link
                      to={"/find/group"}
                      className="text-decoration-none text-primary"
                    >
                      <Image
                        fluid
                        draggable={false}
                        src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fjoin-a-group.svg?alt=media&token=887c425e-6403-4c01-9ad2-e3683b614306"
                        alt="..."
                        className={`my-3 ${imgLoading ? "d-none" : null}`}
                        onLoad={() => {
                          setImgLoading(false);
                        }}
                        style={{ width: "110px" }}
                      />
                      {imgLoading ? (
                        <div className="my-3">
                        <Placeholder animation="glow w-100 h-100">
                          <Placeholder
                            className="rounded col-4"
                            style={{
                              aspectRatio: "12 / 10 ",
                            }}
                          />
                        </Placeholder>
                        </div>
                      ) : null}
                      <div className="body">
                        <h5>Join a group</h5>
                        <p className="text-light">
                          Do what you love, meet others who love it, find your
                          community. The rest is history!
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      to={"/find/event"}
                      className="text-decoration-none text-primary"
                    >
                      <Image
                        fluid
                        draggable={false}
                        src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Ffind-an-event.svg?alt=media&token=3636ffa7-e76d-4d6a-a621-70eb92a5b8dc"
                        alt="..."
                        className={`my-3 ${imgLoading ? "d-none" : null}`}
                        onLoad={() => {
                          setImgLoading(false);
                        }}
                        style={{ width: "110px" }}
                      />
                      {imgLoading ? (
                      <div className="my-3">
                        <Placeholder animation="glow w-100 h-100">
                          <Placeholder
                            className="rounded col-4"
                            style={{
                              aspectRatio: "12 / 10 ",
                            }}
                          />
                        </Placeholder>
                        </div>
                      ) : null}
                      <div className="body">
                        <h5>Find an event</h5>
                        <p className="text-light">
                          Events are happening on just about any topic you can
                          think of, from online gaming and photography to yoga
                          and hiking.
                        </p>
                      </div>
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      to={"/register"}
                      className="text-decoration-none text-primary"
                    >
                      <Image
                        fluid
                        draggable={false}
                        src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fstart-a-group.svg?alt=media&token=1d98131d-c5dc-4f1e-820c-2ecaf3bc4e95"
                        alt="..."
                        className={`my-3 ${imgLoading ? "d-none" : null}`}
                        onLoad={() => {
                          setImgLoading(false);
                        }}
                        style={{ width: "110px" }}
                      />
                      {imgLoading ? (
                      <div className="my-3">
                        <Placeholder animation="glow w-100 h-100">
                          <Placeholder
                            className="rounded col-4"
                            style={{
                              aspectRatio: "12 / 10 ",
                            }}
                          />
                        </Placeholder>
                        </div>
                      ) : null}
                      <div className="body">
                        <h5>Start a group</h5>
                        <p className="text-light">
                          You don't have to be an expert to gather people
                          together and explore shared interests.
                        </p>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </>
          ) : null}

          {isSubscriber || isAdmin || isBizAdmin ? (
            <section>
              <div className="d-flex justify-content-between align-items-center my-4 my-sm-3 my-md-4">
                <div>
                  <h4 className="h5 my-0">Event you organized</h4>
                </div>
                <div>
                  <Link to="/events" className="btn btn-outline-primary ">
                    <small>See all your events</small>
                  </Link>
                </div>
              </div>
              <div
                className={`row mx-0 ${organizedEventsLoading ? "carousel_custom" : ""
                  }  `}
              >
                {organizedEventsLoading ? (
                  <Carousel
                    renderArrow={myArrow}
                    className=""
                    breakPoints={breakPoints}
                  >
                    <EventSkeleton />
                    <EventSkeleton />
                    <EventSkeleton />
                    <EventSkeleton />
                  </Carousel>
                ) : (
                  <>
                    {foundOrganizedEvent ? (
                      <Carousel
                        renderArrow={myArrow}
                        className=""
                        breakPoints={breakPoints}
                      >
                        {createdEventsDataArr.map((item, index) => (
                          <div key={item.id}>
                            <EventCard index={index} eventData={item} />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <NotFoundEvents />
                    )}
                  </>
                )}
              </div>
              <GroupCard
                title={"Groups you organize"}
                groupsDataArr={organizedGroupsDataArr}
                found={organizedFound}
                loading={organizedLoading}
              />
            </section>
          ) : null}
          {/*ROLE ORGANIZER*/}
          {userAvl ? (
            <>
              <section>
                <div className="row my-2 my-sm-3 my-md-4 ">
                  <div className="col">
                    <h4 className="h5">You're attending</h4>
                  </div>
                </div>
                <div
                  className={`row mx-0 ${joinedEventsLoading ? "carousel_custom" : ""
                    }  `}
                >
                  {joinedEventsLoading ? (
                    <Carousel
                      renderArrow={myArrow}
                      className=""
                      breakPoints={breakPoints}
                    >
                      <EventSkeleton />
                      <EventSkeleton />
                      <EventSkeleton />
                      <EventSkeleton />
                    </Carousel>
                  ) : (
                    <>
                      {foundJoinedEvents ? (
                        <Carousel
                          renderArrow={myArrow}
                          className=""
                          breakPoints={breakPoints}
                        >
                          {joinedEventsDataArr.map((item, index) => (
                            <div key={item.id}>
                              <EventCard index={index} eventData={item} />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <NotFoundEvents />
                      )}
                    </>
                  )}
                </div>
              </section>

              <GroupCard
                title={"Groups you have joined"}
                groupsDataArr={joinedGroupsDataArr}
                found={joinedGroupsFound}
                loading={joinedGroupsLoading}
              />
              <section>
                <div className="row md-3 my-2 my-sm-3 my-md-4  ">
                  <div className="col">
                    <h4 className="h5">Events from groups you've joined</h4>
                  </div>
                </div>

                <div
                  className={`row mx-0 ${groupsEventsLoading ? "carousel_custom" : ""
                    }  `}
                >
                  {groupsEventsLoading ? (
                    <Carousel
                      renderArrow={myArrow}
                      className=""
                      breakPoints={breakPoints}
                    >
                      <EventSkeleton />
                      <EventSkeleton />
                      <EventSkeleton />
                      <EventSkeleton />
                    </Carousel>
                  ) : (
                    <>
                      {foundGroupsEvents ? (
                        <Carousel
                          renderArrow={myArrow}
                          className=""
                          breakPoints={breakPoints}
                        >
                          {groupsEventsDataArr.map((item, index) => (
                            <div key={item.id}>
                              <EventCard index={index} eventData={item} />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <NotFoundEvents />
                      )}
                    </>
                  )}
                </div>
              </section>
            </>
          ) : null}

          <section>
            <div className="row md-3 my-2 my-sm-3 my-md-4  ">
              <div className="col">
                <h4 className="h5">Upcoming online events</h4>
              </div>
            </div>

            <div
              className={`row mx-0 ${fetchLoading ? "carousel_custom" : ""}  `}
            >
              {fetchLoading ? (
                <Carousel
                  renderArrow={myArrow}
                  className=""
                  breakPoints={breakPoints}
                >
                  <EventSkeleton />
                  <EventSkeleton />
                  <EventSkeleton />
                  <EventSkeleton />
                </Carousel>
              ) : (
                <>
                  {found ? (
                    <Carousel
                      renderArrow={myArrow}
                      className=""
                      breakPoints={breakPoints}
                    >
                      {eventsDataArr.map((item, index) => (
                        <div key={item.id}>
                          <EventCard index={index} eventData={item} />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <NotFoundEvents />
                  )}
                </>
              )}
            </div>
          </section>
          <GroupCard
            title={"Public Groups"}
            groupsDataArr={publicGroupsDataArr}
            found={publicGroupFound}
            loading={publicGroupLoading}
          />
        </div>
        <BlogSection />
      </div>
    </>
  );
};

export default Onboard;
