import React from "react";
import {Link} from 'react-router-dom'
import './App.css'
import {AdminNavigation} from "../../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../../CommonComponents/Footer/App";



export function Plans() {
    return(
        <div className="min-vh-100">
<div>



        <div className="page position-relative ">
            <AdminNavigation/>
                <div className="page-wrapper container ">

                    <div className="page-header d-print-none">
                        <div className="row align-items-center  mt-3 mb-3">
                            <div className="col">
                                <div className="page-pretitle">
                                    <small>Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Plans
                                </h1>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <Link to = "createplan"
                                   className="btn btn-primary">
                                    Create new
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body ">
                    <div className="container">
                        <div className="row bg-dark text-light row-deck row-cards ">
                            <div className="col-12 mb-3">
                                <form className="card border bg-dark text-light" >
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">

                                            <div className="form-group">
                                                <input  type="text" className="bg-dark text-light form-control"
                                                       placeholder="Name"/>
                                            </div>

                                            <button type="submit" className="btn btn-link">
                                                <i className="fa fa-filter me-2"></i>
                                                Filter
                                            </button>

                                            <a className="btn btn-link">
                                                <i className="fa fa-redo me-2"></i>
                                                Clear
                                            </a>

                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12 ">
                                <div className="card bg-dark border text-light">
                                    <div className="card-header bg-dark text-light">
                                        <h3 className="card-title bg-dark text-light h6 fw-bolder">Plans</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table border bg-dark text-light card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr className="sm-fs-6">
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Yearly Price</th>
                                                <th>Trial Period</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>

                                            {/*mapping all table value here*/}

                                            <tbody className="list" id="plans">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">5</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">Growth Plan</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">$19.99</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">$199.99</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">7 Days</p>
                                                </td>
                                                <td>2022-07-10</td>
                                                <td className="text-end">
                                                    <Link to = "editplan">
                                                        Edit
                                                    </Link>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </div>

        </div>
            <Admin_Footer/>
        </div>
    )
}