import { collection, limit, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db_firestore } from "../../../../../../firebase.config";
import { getUserInfoDocIdAsync } from "../../../User/reduxSlice";
import { eventState } from "../reduxSlice";
import { darkModeState } from "../../../DarkMode/reduxSlice";
import { Button, Container, Nav, Navbar, Table } from "react-bootstrap";
import { rootPageState } from "../../../../../../components/Main/Routes/reduxSlice"
import { checkInCheckOutState, clearTableData, getAllEventsIdAsync, pushTableData, setShowScanQrModal, setShowThisUserDetails } from "./reduxSlice";
import { setHideFooter } from "../../../Footer/reduxSlice";
import moment from "moment";
import EachUserDetail from "./EachUserDetail";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Scanner from "./Scanner";

export async function getCheckInCheckOutSnapshot(eventId, dispatch) {
  const statusOfUserRef = collection(
    db_firestore,
    `events/${eventId}/checkIncheckOut`
  );

  const statusQueryOfUser = query(statusOfUserRef);

  return onSnapshot(statusQueryOfUser, async (querySnapshot) => {
    querySnapshot.forEach(async (doc) => {
      let action = await dispatch(
        getUserInfoDocIdAsync({ docId: doc.data().docId })
      );
      if (action.meta.requestStatus === "fulfilled") {
        dispatch(
          pushTableData({
            value: {
              location: doc.data().location,
              statusPath: doc.data().statusPath,
              docId: doc.data().docId,
              id: doc.id,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              profileURL: action.payload.profileUrl,
              email: action.payload.email,
              phoneNumber: action.payload.phoneNumber,
              twitter: action.payload.twitter,
              linkedin: action.payload.linkedin,
              instagram: action.payload.instagram,
              facebook: action.payload.facebook,
              website: action.payload.website,
              youtube: action.payload.youtube,
            },
          })
        );
      }
    });
  });
}

const CheckInCheckOut = () => {
  const dispatch = useDispatch();
  const {dark} = useSelector(darkModeState)
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [valueOfSelect, setValueOfSelect] = useState(null);

  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);




  useEffect(() => {
    let unSub;
    if (eventId !== "_") {
      unSub = getCheckInCheckOutSnapshot(eventId, dispatch);
    }
    dispatch(getAllEventsIdAsync({}));
    return () => {
      // unSub();
    };
  }, [eventId, dispatch]);

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));
    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, []);

  const selectEventHandler = ({ eventId: id }) => {
    if (id !== eventId) {
      dispatch(clearTableData({}));
      navigate(`/${id}/checkIn`, { replace: true });
    }
  };

  const { tableData, eventsIds } = useSelector(checkInCheckOutState);

  useEffect(() => {
    setValueOfSelect(
      eventsIds.find(({ eventId: id }) => {
        return id === eventId;
      })
    );
  }, [eventId, eventsIds]);

  return (
    <div>



      <Container className="py-4 ">
        <div className="row text-center align-items-center">
          <h4 className="bg-dark text-light mb-4 ">Check In & Check Out Status of Attendees</h4>
          <div className="col-6">
            <Select
              options={eventsIds}
              getOptionLabel={(option) => `${option.eventName}`}
              getOptionValue={(option) => `${option.eventId}`}
              // className="bg-dark text-primary mt-2 mb-3 "
              // classNamePrefix="bg-dark text-light "
              onChange={selectEventHandler}
              value={valueOfSelect}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor:dark?'#212529':'#f8f9fa',
                  color:dark?'#212529':'#f8f9fa',
                }),
              }}
            />
          </div>
          {eventId !== "_" && eventId ? (
            <div className=" col-6">
              <Button
              className="d-block ms-auto me-0"
                onClick={() => {
                  dispatch(setShowScanQrModal({ value: true }));
                }}
              >
                Scan QR
              </Button>
            </div>
          ) : null}

          {/* select event */}
          {/* title */}
          {/* filte */}
        </div>

        <Scanner />

        {Object.keys(tableData).length > 0 ? (<>    <h4 className="my-3">Attendees</h4>

          <Table
            className=" text-nowrap bg-dark text-light"
            hover
            size="sm"
            responsive="md"
            style={{
              fontSize: "12px",
            }}
          >
            <thead className="text-uppercase">
              <tr>
                <th className="p-2 px-3">Status</th>
                <th className="p-2 px-3">first name</th>
                <th className="p-2 px-3">last name</th>
                <th className=" p-2 px-3">location</th>
                <th className=" p-2 px-3">last check-in</th>
                <th className="  p-2 px-3">last check-out</th>
              </tr>
            </thead>
            {isAdmin || isSubscriber || isBizAdmin ? (<tbody>
              {Object.keys(tableData).map(
                (key, index) => {
                  let { firstName, lastName, location, statusPath, id } = tableData[key];

                  let len = statusPath.length;
                  // odd -> current CheckIn
                  // even -> current CheckOut || 0 -> current Nothing

                  let status;

                  if (len === 0) {
                    status = "No Status";
                  } else if (len % 2 === 0) {
                    status = "Checked-Out";
                  } else if (len % 2 !== 0) {
                    status = "Checked-In";
                  }
                  return (
                    <tr
                      className="border"
                      onClick={() => {
                        dispatch(setShowThisUserDetails({ id: id }));
                      }}
                    >
                      <td className="fw-semibold p-3 border text-capitalize">
                        {status}
                      </td>
                      <td className="p-3 ">{firstName}</td>
                      <td className="p-3 ">{lastName}</td>
                      <td className="p-3 ">{location || "Not Found"}</td>
                      <td className="p-3 ">
                        {moment(
                          statusPath[len - 1]?.statusTime.toDate()
                        ).format("llll")}
                      </td>
                      <td className="p-3 ">
                        {statusPath[len - 2]?.statusTime ? (
                          moment(
                            statusPath[len - 2]?.statusTime.toDate()
                          ).format("llll")
                        ) : (
                          <div className="">Not checked-out</div>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>) : null}

          </Table></>) : (
          <div className="text-center my-5 py-5">Please Select Event To See Attendees Status</div>
        )}
        {!(isAdmin || isSubscriber || isBizAdmin) ? <div className="text-center" > No Data </div> : null}
        <EachUserDetail valueOfSelect={valueOfSelect} />
      </Container>
    </div>
  );
};

export default CheckInCheckOut;
