import React from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { darkModeState, toggleMode } from "./reduxSlice";

// animation
import { useSpring, animated } from "@react-spring/web";

// icon
import { FaSun, FaMoon } from "react-icons/fa";

// Css
import "./App.css";

const DarkModeSwitch = () => {
  const dispatch = useDispatch();
  const { dark } = useSelector(darkModeState);

  const switchAnimate = useSpring({
    transform: dark ? "translateX(0px)" : "translateX(27.1px)",
  });

  return (
    <div className="position-fixed zindex-popover bottom-0 ms-auto me-1 start-auto end-0" id="darkMoodIndex">
      <input
        type="checkbox"
        onClick={() => {
          if (dark) {
            localStorage.setItem("theme", "light");
          } else {
            localStorage.setItem("theme", "dark");
          }
          dispatch(toggleMode({}));
        }}
        className="checkbox position-absolute opacity-0"
        id="darkMode"
      />
      <label
        htmlFor="darkMode"
        className="label m-1 px-1 bg-black rounded-pill border d-flex align-items-center justify-content-between position-relative  "
      >
        <FaMoon className="switch_moon text-white" />
        <FaSun className="switch_sun" />

        <animated.div
          style={switchAnimate}
          className="ball bg-white position-absolute rounded-pill "
        />
      </label>
    </div>
  );
};

export default DarkModeSwitch;
