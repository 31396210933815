import React from "react";

// Bootstrap
import { Button, Form, InputGroup } from "react-bootstrap";

const GroupName = ({ groupName, setGroupName, nextPage }) => {
  return (
    <div className="page-wrapper">
      <section className="">
        <form>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-8">
              <h1>What will your group's name be?</h1>
              <p>
                Choose a name that will give people a clear idea of what the
                group is about. Feel free to get creative! You can edit this
                later if you change your mind.
              </p>
              <div className="input-icon mb-3 place_autocomplete_container">
                <Form validated={groupName} className="">
                  <InputGroup>
                    <Form.Control
                      required
                      value={groupName}
                      onChange={setGroupName}
                      type="Group name"
                      placeholder="Group name"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      className="bg-dark text-light"
                    ></Form.Control>
                  </InputGroup>
                  <Button
                    className="btn btn-primary float-end mt-3"
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupName;
