import React from "react";

// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Placeholder } from "react-bootstrap";

const GroupSkeleton = () => {
  return (
    <>
      <div className="col-12 col-lg-3 ">
        <Link to="/" className="card card-link text-decoration-none mb-3">
          <div className="card-body bg-dark text-light p-3 ">
            <div className="row align-items-center">
              <div className="col-4">
                <Placeholder animation="glow">
                  <Placeholder
                    className="rounded w-100 h-100 "
                    style={{
                      aspectRatio: "1 / 1 ",
                    }}
                  />
                </Placeholder>
              </div>
              <div className="col-8   ">
                <div className="fw-bold mb-1 text-truncate text-truncate-two-line">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="text-muted fst-italic">
                  <Placeholder animation="glow">
                    <Placeholder xs={8} />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-lg-3 ">
        <Link to="/" className="card card-link text-decoration-none mb-3">
          <div className="card-body bg-dark text-light p-3 ">
            <div className="row align-items-center">
              <div className="col-4">
                <Placeholder animation="glow">
                  <Placeholder
                    className="rounded w-100 h-100 "
                    style={{
                      aspectRatio: "1 / 1 ",
                    }}
                  />
                </Placeholder>
              </div>
              <div className="col-8   ">
                <div className="fw-bold mb-1 text-truncate text-truncate-two-line">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="text-muted fst-italic">
                  <Placeholder animation="glow">
                    <Placeholder xs={8} />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-lg-3 ">
        <Link to="/" className="card card-link text-decoration-none mb-3">
          <div className="card-body bg-dark text-light p-3 ">
            <div className="row align-items-center">
              <div className="col-4">
                <Placeholder animation="glow">
                  <Placeholder
                    className="rounded w-100 h-100 "
                    style={{
                      aspectRatio: "1 / 1 ",
                    }}
                  />
                </Placeholder>
              </div>
              <div className="col-8   ">
                <div className="fw-bold mb-1 text-truncate text-truncate-two-line">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="text-muted fst-italic">
                  <Placeholder animation="glow">
                    <Placeholder xs={8} />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="col-12 col-lg-3 ">
        <Link to="/" className="card card-link text-decoration-none mb-3">
          <div className="card-body bg-dark text-light p-3 ">
            <div className="row align-items-center">
              <div className="col-4">
                <Placeholder animation="glow">
                  <Placeholder
                    className="rounded w-100 h-100 "
                    style={{
                      aspectRatio: "1 / 1 ",
                    }}
                  />
                </Placeholder>
              </div>
              <div className="col-8   ">
                <div className="fw-bold mb-1 text-truncate text-truncate-two-line">
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="text-muted fst-italic">
                  <Placeholder animation="glow">
                    <Placeholder xs={8} />
                  </Placeholder>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default GroupSkeleton;
