import Geocode from "react-geocode";

// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../../../../../../firebase.config";

import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../firebase.config";

export function getMyStatusAPI(eventId, docId) {
    const getMyStatus = httpsCallable(functions, "getMyStatus");
    return getMyStatus({
        eventId,
        docId,
    });
}
export function addCheckInCheckOutAPI(data) {
    const addCheckInCheckOut = httpsCallable(functions, "addCheckInCheckOut");
    return addCheckInCheckOut({
        ...data,
    });
}

export function getAllEventsIdAPI() {
    const getAllEventsId = httpsCallable(functions, "getAllEventsId");
    return getAllEventsId({});
}

Geocode.setApiKey("AIzaSyBsAuUYnemXEjX_fLZuyDHeOy4VRcZsXlY"); // Todo : add here working API
Geocode.setLanguage("en");

// Optional
// Geocode.setRegion("es");
// Geocode.setLocationType("ROOFTOP");
// Geocode.enableDebug();

export async function getCity(latitude, longitude) {
    try {
        const response = await Geocode.fromLatLng(latitude, longitude);
        const address = response.results[10].formatted_address;
        console.log("API address ", address);
        return address;
    } catch (error) {
        console.error(error);
    }
}
