import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContentState, EditorState } from "draft-js";
import { pushErrorNotification } from "../../Error/reduxSlice";
import { setOrganizer } from "../List/reduxSlice";
import {
  addCreatedGroupsId,
  addGroupGallery,
  createGroup,
  deleteGroupImages,
  getInterestApi,
  updateGroup,
  uploadFiles,
} from "./reduxAPI";
import htmlToDraft from "html-to-draftjs";
import { getGroupIdAsync } from "../../../../Test/components/MyGroup/MyGroupOnBoard/reduxSlice";

const initialState = {
  editGroup: false,
  groupName: "",
  groupGallery: [],
  categories: [],
  selectedCategories: [],
  previewCategories: [],
  searchCategories: "",
  description: EditorState.createEmpty(),
  groupType: "",
  location: "",
  selectedFiles: [],
  preViewFiles: [],
  loading: false,
};

// upload file
export const uploadAsync = createAsyncThunk(
  "createGroup/uploadFiles",
  async ({ groupId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles } = getState().createGroup;
      let eventGallery = [];
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, groupId);
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setGroupGallery({ value: downloadURL }));
            eventGallery = [...eventGallery, downloadURL];
          }
        })
      );

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      if (selectedFiles.length <= 0) {
        // https://source.unsplash.com/random/?events,meeting
        // https://source.unsplash.com/random/?sig=${getRandomInt(1, 100000)}
        eventGallery.push(
          `https://picsum.photos/id/${getRandomInt(1, 100)}/1920/760`
        );
      }

      await addGroupGallery(eventGallery, groupId);
      dispatch(setOrganizer({ value: true }));
      navigate("/groups/ga");
      dispatch(clearGroup({}));

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

//Async Thunk
export const createGroupAsync = createAsyncThunk(
  "createGroup/createGroupInDB",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      let { groupName, categories, description, groupType, location } =
        getState().createGroup;

      const { user } = getState().user;

      const response = await createGroup(
        groupName,
        categories,
        description,
        groupType,
        location
      );

      if (response.data.id && user.uid) {
        await addCreatedGroupsId(response.data.id, user.uid);
        dispatch(uploadAsync({ groupId: response.data.id, navigate }));
      }

      if (response.data.error) {
        throw response.data;
      }
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//UPDATE GROUP THUNK
export const updateGroupAsync = createAsyncThunk(
  "createGroup/updateGroupInDB",
  async ({ groupId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      let {
        groupName,
        categories,

        description,
        groupType,
        location,

        selectedFiles,
      } = getState().createGroup;

      const { user } = getState().user;

      const response = await updateGroup(
        groupName,
        categories,

        description,
        groupType,
        location,
        groupId,
        user.uid
      );

      // if (updateGroupImgAsync({ groupId, navigate })) {
      //   dispatch(updateGroupImgAsync({ groupId, navigate }));
      // }

      if (selectedFiles.length > 0) {
        dispatch(deleteGroupImagesAsync({ groupId: groupId }));
      }
      dispatch(updateGroupImgAsync({ groupId: groupId, navigate }));

      if (response.data.error) {
        throw response.data;
      }
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteGroupImagesAsync = createAsyncThunk(
  "createGroup/deleteGroupImagesAsync",
  async ({ groupId }, { dispatch, getState, rejectWithValue }) => {
    try {
      let response = await deleteGroupImages(groupId);

      console.log("delete response", response);
    } catch (err) {
      console.log(err);
    }
  }
);

//UPDATE IMAGE
export const updateGroupImgAsync = createAsyncThunk(
  "createGroup/updateGroupImgAsync",
  async ({ groupId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles } = getState().createGroup;
      let groupGallery = [];
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, groupId);
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setGroupGallery({ value: downloadURL }));
            groupGallery = [...groupGallery, downloadURL];
          }
        })
      );
      await addGroupGallery(groupGallery, groupId);
      navigate("/groups/ga");
      dispatch(setOrganizer({ value: true }));
      dispatch(clearGroup({}));

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

//interest async
export const groupPreviewInterestAsync = createAsyncThunk(
  "createGroup/fetchInterest",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getInterestApi();

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//ADD NEW INTEREST

export const createGroupSlice = createSlice({
  name: "createGroup",
  initialState,
  reducers: {
    setGroupName: (state, action) => {
      state.groupName = action.payload.value;
    },
    setLocation: (state, action) => {
      state.location = action.payload.value;
    },
    setGroupType: (state, action) => {
      state.groupType = action.payload.value;
    },
    setDescription: (state, action) => {
      state.description = action.payload.value;
    },
    setGroupGallery: (state, action) => {
      state.groupGallery = [...state.groupGallery, action.payload.value];
    },
    setSelectedFiles: (state, action) => {
      state.selectedFiles = [...state.selectedFiles, action.payload.newImage];
    },

    clearSelectedFiles: (state, action) => {
      state.selectedFiles = [];
    },
    setPreviewFiles: (state, action) => {
      state.preViewFiles = [...state.preViewFiles, action.payload.newPreview];
    },

    clearPreviewFiles: (state, action) => {
      state.preViewFiles = [];
    },
    //Categories
    setSelectedCategories: (state, action) => {
      state.selectedCategories = [
        ...state.selectedCategories,
        action.payload.newCateories,
      ];
    },
    removeCategories: (state, action) => {
      state.selectedCategories.splice(
        state.selectedCategories.findIndex(
          (arrow) => arrow.id === action.payload
        ),
        1
      );
      state.categories.splice(
        state.categories.findIndex((arrow) => arrow.id === action.payload),
        1
      );
      // state.previewCategories.splice(state.previewCategories.findIndex((arrow) => arrow.id === action.payload), 1);
    },
    setSearchCategories: (state, action) => {
      state.searchCategories = action.payload.value;
    },
    setCategories: (state, action) => {
      state.categories = [...state.categories, action.payload.newCateoriesId];
    },

    clearGroup: (state, action) => {
      state.groupName = "";
      state.groupGallery = [];
      state.categories = [];
      state.description = EditorState.createEmpty();
      state.groupType = "";
      state.location = "";
      state.selectedFiles = [];
      state.preViewFiles = [];
      state.loading = false;
    },

    //EDIT GROUP
    setEditGroup: (state, action) => {
      state.editGroup = action.payload.value;
    },
    setDataToEdit: (state, action) => {
      if (state.editGroup) {
        let {
          groupName,
          categories,

          description,
          groupType,
          location,
        } = action.payload.groupData;

        state.groupName = groupName;

        if (description) {
          const contentBlock = htmlToDraft(description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            state.description = editorState;
          }
        }

        state.groupType = groupType;
        state.location = location;
        state.categories = categories;
      }
    },

    setPreviewCategories: (state, action) => {
      // ONly for Test by dhruv
      state.previewCategories = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadAsync.fulfilled, (state, action) => {
        // state.loading = false;
      })
      .addCase(uploadAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createGroupAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createGroupAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateGroupAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateGroupAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(groupPreviewInterestAsync.fulfilled, (state, action) => {
        state.previewCategories = action.payload;
      });
  },
});

export const {
  setGroupName,
  setDescription,
  setCategories,
  setGroupGallery,
  setGroupType,
  setLocation,
  setSelectedFiles,
  clearSelectedFiles,
  setPreviewFiles,
  clearPreviewFiles,
  removeCategories,
  setDataToEdit,
  setEditGroup,
  clearGroup,
  setSelectedCategories,
  setSearchCategories,
  setPreviewCategories,
} = createGroupSlice.actions;

export const createGroupState = (state) => state.createGroup;

export default createGroupSlice.reducer;
