import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";

import Banner from "../Banner/App";
import About from "../About/App";
import Event from "../Event/App";
import Member from "../Member/App";
import Photo from "../Photo/App";
import Discussion from "../Discussion/App";
import { RiArrowDropDownLine } from "@react-icons/all-files/ri/RiArrowDropDownLine";
import GroupSkeleton from "./components/GroupSkeleton/App";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupIdAsync,
  groupAsync,
  groupState,
  joinGroupAsync,
  leaveGroupAsync,
  setGroupPage,
} from "./reduxSlice";

//css
import "./App.css";
import { rootPageState } from "../../../../Main/Routes/reduxSlice";
import { userState } from "../../../../Main/Components/User/reduxSlice";
import { Icon } from "@iconify/react";
import { setHideLogin_Register } from "../../../../Main/Components/Navbar/reduxSlice";

const MygroupOnBaord = () => {
  let { companyName, groupNameParams } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHideLogin_Register({ value: true }));

    return () => {
      dispatch(setHideLogin_Register({ value: false }));
    };
  }, [dispatch]);

  const navigate = useNavigate();
  const {
    generatedGroupId,

    loading,
    groupActionLoading,
    groupPage,
    groupData: {
      groupName,
      description,
      location,
      groupType,
      subscriberUid,
      memberUid,
      groupGallery,
    },
  } = useSelector(groupState);
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);
  const { userAvl, user } = useSelector(userState);

  let ismyGroup;
  let isMember;
  if (userAvl) {
    ismyGroup = user.uid === subscriberUid && userAvl;
    isMember = memberUid?.includes(user.uid);
  }

  useEffect(() => {
    dispatch(groupAsync({ companyName, groupNameParams }));
  }, [dispatch, companyName, groupNameParams]);

  //Navigation
  function NavPage(e) {
    switch (e.target.innerText) {
      case "About":
        dispatch(setGroupPage({ newPage: "1" }));
        break;
      case "Events":
        dispatch(setGroupPage({ newPage: "2" }));
        console.log(groupPage);
        break;
      case "Members":
        dispatch(setGroupPage({ newPage: "3" }));
        break;
      case "Photos":
        dispatch(setGroupPage({ newPage: "4" }));
        break;
      case "Discussions":
        dispatch(setGroupPage({ newPage: "5" }));
        break;
      default:
        break;
    }
  }

  const loginHandler = () => {
    navigate("/login");
  };

  const actionHandlerofGroup = () => {
    if (ismyGroup) {
      navigate("newEvent");
    } else if (isMember) {
      dispatch(leaveGroupAsync({ companyName, groupNameParams }));
    } else {
      dispatch(joinGroupAsync({ companyName, groupNameParams }));
    }
  };

  return (
    <div>
      {loading ? (
        <GroupSkeleton />
      ) : (
        <>
          <Banner />
          <div
            className="bg-dark sticky-top border-top border-bottom py-2"
            style={{ minHeight: "70px" }}
          >
            <div className="container ">
              <div className="d-flex flex-column flex-md-row mx-0 justify-content-between">
                <ul
                  className="nav overflow-auto mobile-horizontal p-1"
                  style={{ overflowX: "auto" }}
                >
                  <li className="nav-item">
                    <span
                      className={`${
                        groupPage === "1"
                          ? "nav-link fs-6 ps-0 fw-bold text-primary cursor-event"
                          : "nav-link fs-6 ps-0 fw-bold text-light cursor-event"
                      }`}
                      onClick={NavPage}
                    >
                      About
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      onClick={NavPage}
                      className={`${
                        groupPage === "2"
                          ? "nav-link fs-6 ps-0 fw-bold text-primary cursor-event"
                          : "nav-link fs-6 ps-0 fw-bold text-light cursor-event"
                      }`}
                    >
                      Events
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`${
                        groupPage === "3"
                          ? "nav-link fs-6 ps-0 fw-bold text-primary cursor-event"
                          : "nav-link fs-6 ps-0 fw-bold text-light cursor-event"
                      }`}
                      onClick={NavPage}
                    >
                      Members
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`${
                        groupPage === "4"
                          ? "nav-link fs-6 ps-0 fw-bold text-primary cursor-event"
                          : "nav-link fs-6 ps-0 fw-bold text-light cursor-event"
                      }`}
                      onClick={NavPage}
                    >
                      Photos
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`${
                        groupPage === "5"
                          ? "nav-link fs-6 ps-0 fw-bold text-primary cursor-event"
                          : "nav-link fs-6 ps-0 fw-bold text-light cursor-event"
                      }`}
                      onClick={NavPage}
                    >
                      Discussions
                    </span>
                  </li>
                </ul>
                <div className="d-flex align-self-end mt-3 mt-md-auto">
                  {(isSubscriber || isAdmin || isBizAdmin) && ismyGroup ? (
                    <Dropdown
                      id="dropdown-basic-button"
                      bg
                      className="bg-transparent "
                      title="You're a organizer"
                    >
                      <Dropdown.Toggle
                        className="bg-transparent  text-light "
                        id="dropdown-custom-components"
                      >
                        You're a organizer
                        <RiArrowDropDownLine />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="text-light  bg-dark border dropdown-menu-center ">
                        <Dropdown.Item className="">
                          <Link
                            className="text-decoration-none dropdown-menu-center  text-light"
                            to={`/groups/g/${generatedGroupId}/setting`}
                          >
                            Settings
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}

                  {userAvl ? (
                    <Button
                      onClick={actionHandlerofGroup}
                      className=" btn text-white btn-tertiary  ms-2 text-nowrap"
                    >
                      {ismyGroup ? (
                        "Schedule events"
                      ) : groupActionLoading ? (
                        <Icon icon="eos-icons:bubble-loading" />
                      ) : isMember ? (
                        "Leave Group"
                      ) : (
                        "Join Group"
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={loginHandler}
                      className=" btn text-white btn-tertiary w-100  ms-2 text-nowrap"
                    >
                      Join Group
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            {" "}
            {(groupPage === "1" && <About />) ||
              (groupPage === "2" && <Event />) ||
              (groupPage === "3" && <Member />) ||
              (groupPage === "4" && <Photo />) ||
              (groupPage === "5" && <Discussion />)}
          </div>
        </>
      )}
    </div>
  );
};

export default MygroupOnBaord;
