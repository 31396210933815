import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    announcementFormState,
    createAnnouncementAsync,
    setAnnouncementText,
    setShowForm,
} from "./reduxSlice";

const AnnouncementForm = () => {
    const dispatch = useDispatch();
    const { showForm, announcementText, loading } = useSelector(announcementFormState);

    const handleClose = () => {
        dispatch(setShowForm({ value: false }));
    };

    return (
        <Modal show={showForm} onHide={handleClose}>
            <Modal.Header className="bg-dark text-light" closeButton>
                <Modal.Title>What's the announcement</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-light">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Announcement</Form.Label>
                    <Form.Control
                        className="bg-dark text-light border"
                        as="textarea"
                        rows={3}
                        value={announcementText}
                        onChange={(e) => {
                            dispatch(setAnnouncementText({ value: e.target.value }));
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-dark text-light">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(createAnnouncementAsync({}));
                        handleClose();
                    }}
                >
                    {loading ? "Loading..." : "Announce now"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AnnouncementForm;
