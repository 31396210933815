import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "@firebase/database";
import { getStorage, connectStorageEmulator } from "@firebase/storage";
import { getAuth, connectAuthEmulator } from "@firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "@firebase/functions";

import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "firebase/app-check";

export const firebaseConfig = {
  apiKey: "AIzaSyA0eOJzsvirn4s9PMNk5pLHlM0QprCq5d8",
  authDomain: "networking-pre-event-app.firebaseapp.com",
  projectId: "networking-pre-event-app",
  storageBucket: "networking-pre-event-app.appspot.com",
  messagingSenderId: "121739830042",
  appId: "1:121739830042:web:593a060009043ae0a1c2a3",
  measurementId: "G-F3F066VJ5J",
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db_firestore = getFirestore(app);
export const db_database = getDatabase(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-south1");

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfQCUghAAAAAGOHAAuap9kt6UNQVK4BMQc_aMXH"),
});

const callApiWithAppCheckExample = async () => {
  try {
    return await getToken(appCheck, /* forceRefresh= */ false);
  } catch (err) {
    return;
  }
};
export const appCheckTokenResponse = callApiWithAppCheckExample();

export const baseURLProd =
  "https://us-central1-bizconnectchat.cloudfunctions.net/"; // Todo: change link

if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db_firestore, "localhost", 8080);
  connectDatabaseEmulator(db_database, "localhost", 9000);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
