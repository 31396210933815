import update from "immutability-helper";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "./Card";
import { createEventState, setDndTabsManagementShow } from "../../reduxSlice";

const Container = () => {
  const dispatch = useDispatch();
  const { tabsManagement } = useSelector(createEventState);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(
        setDndTabsManagementShow({
          newTabsManagement: update(tabsManagement, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, tabsManagement[dragIndex]],
            ],
          }),
        })
      );
    },
    [dispatch, tabsManagement]
  );
  const renderCard = useCallback(
    (input, index) => {
      return (
        <Card
          key={index}
          index={index}
          show={input.show}
          name={input.name}
          moveCard={moveCard}
        />
      );
    },
    [moveCard]
  );
  return (
    <>
      <div className="bg-dark text-light row">
        {tabsManagement.map((input, i) => renderCard(input, i))}
      </div>
    </>
  );
};

export default Container;
