import React, { useEffect, useState } from "react";
import { BsFillCalendarEventFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";

import {
  clearAllDataArr,
  clearAllFound,
  eventsListAsync,
  eventsListState,
  groupsEventsAsync,
  joinedEventsAsync,
  organizedEventsAsync,
} from "../../../../../Main/Components/Events/Card/reduxSlice";
import {
  getSavedEventsOfUserAsync,
  userState,
} from "../../../../../Main/Components/User/reduxSlice";
import {
  clearJoinedGroupDataArr,
  clearOrganizedGroupsDataArr,
  clearPublicGroupDataArr,
  joinedGroupsListAsync,
  publicGroupsListAsync,
} from "../../../../../Main/Components/Groups/Card/reduxSlice";

import EventGroupCard from "../EventGroupCard/App";

const Draft = () => {
  const dispatch = useDispatch();

  const {
    organizedEventsLoading,
    createdEventsDataArr,
    foundOrganizedEvent,
    loading: fetchLoading,
    found,
  } = useSelector(eventsListState);
  const { userAvl, docId } = useSelector(userState);

  const [foundDraft, setFoundDraft] = useState(undefined);

  useEffect(() => {
    for (let i = 0; i < createdEventsDataArr.length; i++) {
      const event = createdEventsDataArr[i];

      if (event.draft === true) {
        setFoundDraft(true);
        break;
      }
      setFoundDraft(false);
    }
    return () => {};
  }, [createdEventsDataArr]);

  useEffect(() => {
    dispatch(eventsListAsync({}));
    dispatch(publicGroupsListAsync({ all: true }));
    if (userAvl && docId) {
      dispatch(joinedGroupsListAsync({}));
      dispatch(getSavedEventsOfUserAsync({}));
      dispatch(groupsEventsAsync({}));
      dispatch(joinedEventsAsync({}));
      dispatch(organizedEventsAsync({}));
    }

    return () => {
      dispatch(clearJoinedGroupDataArr({}));
      dispatch(clearAllFound({ value: true }));
      dispatch(clearAllDataArr({}));
      dispatch(clearPublicGroupDataArr({}));
      dispatch(clearOrganizedGroupsDataArr({}));
      dispatch(clearJoinedGroupDataArr({}));
    };
  }, [dispatch, docId, userAvl]);
  return (
    <div>
      {createdEventsDataArr.map((item) =>
        item.draft === true ? (
          <EventGroupCard index={item.id} eventsData={item} />
        ) : null
      )}

      {foundDraft === false ? (
        <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-5 border mb-4">
          <BsFillCalendarEventFill />
          <p className="fs-6 mb-0 mt-2">No events yet</p>
        </div>
      ) : null}
    </div>
  );
};

export default Draft;
