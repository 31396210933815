import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDoc } from "firebase/firestore";
import { pushErrorNotification } from "../../../../../Error/reduxSlice";
import {
  deleteDocumentsAPI,
  getDocumentFormInputDataAPI,
  saveDocumentsUrlInDbAPI,
  updateDocumentFormAPI,
  uploadDocuments,
} from "./reduxAPI";

const initialState = {
  submittingForm: false,

  foundForm: true,

  heading: "",
  formData: [],

  userData: [],

  showUploadDocumentForm: false,
};

export const uploadAsync = createAsyncThunk(
  "formGenerator/uploadFiles",
  async ({ eventId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles } = getState().createEvent;
    } catch (err) {
      console.log(err);
    }
  }
);

export const submitFormDataAsync = createAsyncThunk(
  "formGenerator/submitFormDataAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { userData, formData } = getState().formGenerator;
      const { docId } = getState().user;
      const {
        eventData: { id: eventId },
      } = getState().event;

      await deleteDocumentsAPI(eventId, docId);

      // eslint-disable-next-line array-callback-return
      userData.map(
        async ({ uploadDocumentId, document, selectedDocuments }, index) => {
          let response = await Promise.all(
            // eslint-disable-next-line array-callback-return
            selectedDocuments.map(async (file, index) => {
              if (file) {
                const downloadURL = await uploadDocuments(
                  file,
                  eventId,
                  docId,
                  uploadDocumentId
                );
                console.log("**downloadUrl ", index, " ", downloadURL);

                // eventId, docId, uploadDocumentId, url;
                await saveDocumentsUrlInDbAPI(
                  eventId,
                  docId,
                  uploadDocumentId,
                  downloadURL
                );
              }
            })
          );
          dispatch(setShowUploadDocumentForm({ value: false }));
          dispatch(clearUserData({}));
        }
      );
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const updateDocumentFormAsymc = createAsyncThunk(
  "formGenerator/updateDocumentFormAsymc",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { inputFields, heading } = getState().formBuilder;

      const {
        eventData: { id: eventId },
      } = getState().event;

      // eslint-disable-next-line array-callback-return
      await updateDocumentFormAPI(eventId, inputFields, heading);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const getFormDataAsync = createAsyncThunk(
  "formGenerator/getFormDataAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getDocumentFormInputDataAPI(eventId);
      return response.data;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const formGeneratorSlice = createSlice({
  name: "formGenerator",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFormData: (state, action) => {
      state.formData = action.payload.value;
    },
    setUserData: (state, action) => {
      let { index, newDocs } = action.payload;
      let data = [...state.userData];

      let newData = {
        document: state.formData[index].label,
        uploadDocumentId: state.formData[index]._id,
        selectedDocuments: newDocs,
      };
      data[index] = newData;

      state.userData = [...data];
    },
    clearUserData: (state, action) => {
      state.userData = [];
    },

    setShowUploadDocumentForm: (state, action) => {
      state.showUploadDocumentForm = action.payload.value;
    },

    clearFormGeneratorData: (state, action) => {
      state.submittingForm = false;

      state.foundForm = true;

      state.formData = [];

      state.userData = [];

      state.showUploadDocumentForm = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFormDataAsync.pending, (state, action) => {})
      .addCase(getFormDataAsync.fulfilled, (state, action) => {
        if (action.payload.formInputData === null) {
          state.foundForm = false;
        } else {
          state.heading = action.payload.formInputData.heading;
          state.formData = action.payload.formInputData.formData;
        }
      })
      .addCase(getFormDataAsync.rejected, (state, action) => {})
      .addCase(submitFormDataAsync.pending, (state, action) => {
        state.submittingForm = true;
      })
      .addCase(submitFormDataAsync.fulfilled, (state, action) => {
        state.submittingForm = false;
      })
      .addCase(submitFormDataAsync.rejected, (state, action) => {
        state.submittingForm = false;
      });
  },

  getFormDataAsync,
});

export const {
  setFormData,
  setUserData,
  clearUserData,
  setShowUploadDocumentForm,
  clearFormGeneratorData,
} = formGeneratorSlice.actions;

export const formGeneratorState = (state) => state.formGenerator;

export default formGeneratorSlice.reducer;
