import React from 'react'
import FooterComponent from '../FooterComponent/App'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { ImCheckmark } from "react-icons/im"
import './App.css'
export default function Starting() {
    return (
        <>
            <div>
                <Container className='my-5'>
                    <p><small>BECOME AN ORGANIZER</small></p>
                    <h3>We'll walk you through a few steps to build your local <br className='d-none d-lg-block' />community</h3>
                    <Button variant='tertiary' className='text-white'>
                        Get started
                    </Button>
                </Container>
                <hr />
                <Container className='text-center my-5'>
                    <h5>Organize your way, on your schedule</h5>
                    <Row className='flex-column flex-lg-row my-5'>
                        <Col className='starting-page-schedule'>
                            <img src='https://network.varuscreative.com/assets/images/start/find-members.svg' className='img-fluid' alt='...' />
                            <h6>Find your members</h6>
                            <p>We'll help you find interested members and you can approve them to be sure they'll be a good fit for your community.</p>
                        </Col>
                        <Col className='starting-page-schedule'>
                            <img src='https://network.varuscreative.com/assets/images/start/schedule-event.svg' className='img-fluid' alt='...' />
                            <h6>Schedule events in minutes</h6>
                            <p>Organizer tools make it easy to schedule and manage your events. You decide when, where, and how often your group meets.</p>
                        </Col>
                        <Col className='starting-page-schedule'>
                            <img src='https://network.varuscreative.com/assets/images/start/get-help-from-others.svg' className='img-fluid' alt='...' />
                            <h6>Have others help you host</h6>
                            <p>You don't have to do it alone. Recruit a leadership team to help you host events and manage your group.</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='bg-primary py-2'>
                    <Row className='p-5 align-items-center mx-auto gap-2 flex-column flex-md-row'>
                        <Col className='text-center my-2 px-md-5 px-lg-0'>
                            <img src='https://network.varuscreative.com/assets/images/start/testimonial.png' alt='...' className='img-fluid rounded-circle w-100' />
                        </Col>
                        <Col className='border-start border-3 text-white mt-5 my-md-auto'>
                            <h5 className='fw-bold'>Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.</h5>
                            <p className='fw-bold mt-4'><small>— MEREDITH GOODWIN, FOUNDER</small></p>
                        </Col>
                    </Row>
                </Container>
                <Container className='my-5 text-center'>
                    <h4>How to get started</h4>
                    <p><small>Here's an overview of how you'll create your Network group, from start to finish.</small></p>
                    <Row className='my-5 flex-column flex-lg-row'>
                        <Col>
                            <div className='step-Number '>
                                <h3>1</h3>
                            </div>
                            <h5>Create a group</h5>
                            <p>Decide what the group is about, who should join, and what you'll do at your events.</p>
                        </Col>
                        <Col>
                            <div className='step-Number'>
                                <h3>2</h3>
                            </div>
                            <h5>Review and submit</h5>
                            <p>Your group will be reviewed and shared with members who have similar interests.</p>
                        </Col>
                        <Col>
                            <div className='step-Number'>
                                <h3>3</h3>
                            </div>
                            <h5>Plan your first event</h5>
                            <p>Use Network's organizer tools to schedule your events and manage your attendee lists.</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='my-5 py-4 bg-primary text-white'>
                    <Container className='py-5'>
                        <h4>Subscribe to find people who share your interests</h4>
                        <Row className='mt-4 flex-column flex-lg-row'>
                            <Col>
                                <p>With your subscription, you can:</p>
                                <ul className='list-unstyled'>
                                    <li className='ps-2 py-1'><ImCheckmark className='me-2' /> Assign co-organizers to help you host events</li>
                                    <li className='ps-2 py-1'><ImCheckmark className='me-2' />Get support from our community experts 7 days a week</li>
                                    <li className='ps-2 py-1'><ImCheckmark className='me-2' />Schedule events and manage your attendee lists</li>
                                </ul>
                            </Col>
                            <Col>
                                <h4>Starting at:</h4>
                                <h5>$4.99 / Month</h5>
                                <Button className='border mt-3'>Get started</Button>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container className='my-5'>
                    <h4>Frequently asked questions</h4>
                    <Row className='my-5 flex-column flex-lg-row'>
                        <Col>
                            <div>
                                <h6>How much of a time commitment is expected?</h6>
                                <p>As a Network organizer, you decide how often your group meets. You can even recruit others to help you host events and share responsibilities as the organizer.</p>
                            </div>
                            <div>
                                <h6>How can I grow my Network group?</h6>
                                <p>Network will announce your new group to members in your area who share your interests. Every event you schedule will be announced to your group's members, and easily findable to non-members who are looking for events like yours. The more events your group hosts, the faster it's likely to grow. Sharing your upcoming events on your other social networks will also help attract people who aren't already Network members.</p>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <h6>How can I monetize my Network group?</h6>
                                <p>There are several ways that you can share costs, or even make a profit as an organizer. You can choose to charge your members dues, or you can ticket each event.</p>
                            </div>
                            <div>
                                <h6>How will Network help me be successful?</h6>
                                <p>Network provides the tools and resources you need to build a great community. We'll announce your group to people in your area who share your interests, and continuously help new Network members find your group and your events. Once you schedule events, Network manages your attendee lists and reminders. You'll have access to support 7 days a week, and access to articles and tips on how to grow a successful community.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterComponent />
        </>
    )
}
