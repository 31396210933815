import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { AdminNavigation } from "../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../CommonComponents/Footer/App";

//icons

import {FaTicketAlt} from "react-icons/fa"
import {MdOutlineGroup} from "react-icons/md"

//test data
const content = [
    {id: 0, val: 100},
    {id: 1, val: 10},
    {id: 2, val: 60},
    {id: 3, val: 10},
    {id: 4, val: 20},
    {id: 5, val: 10},
    {id: 6, val: 10},
    {id: 7, val: 45},
    {id: 8, val: 10},
    {id: 9, val: 80},
    {id: 10, val: 0}
]
const s = [
    {id: 0, val:1030, text:"Events this month"},
    {id: 1, val:1030, text:"Event Total"},
    {id: 2, val:103, text:"Group this month"},
    {id: 3, val:103, text:"Group Total"}
]


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    style:{  width: "1238px"
        ,height: "450px"}


};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July' , 'August' , 'September' , 'October' , 'November', 'December'];

export const dataset = {
    labels,
    datasets: [
        {    label: "Earning",
            data: content.map((item) => (item.val)),
            borderColor: '#00a6ff',
        },
    ],
};


export function Chart() {
    return <div className="apexcharts-canvas apexcharts-theme-light bg-dark text-light">
        <Line options={options} data={dataset} />
    </div>
}



const AdminDashboard = () => {
    return (
        <div className="page">
            <AdminNavigation />
            <div className="page-wrapper bg-dark text-light">
                <div className="container  mt-3 mb-3">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    <small>Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Dashboard
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards bg-dark text-light">
                            <div className="col-12">
                                <div className="row row-cards mb-3">
                                    {s.map((item) => (
                                        <div className="col-sm-6 col-lg-3" key={item.id}>
                                            <div className=" bg-dark text-light border card-sm">
                                                <div className="card-body">
                                                    <div className="row align-items-center">
                                                        <div className="col">

                                                            <div className="font-weight-medium ">
                                                                {item.val}
                                                            </div>
                                                            <div className="text-muted fs-6">
                                                                {item.text}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </div>



                            </div>

                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header bg-dark text-light border">
                                        <p className="card-title h6 fw-bolder">Earnings in USD</p>
                                    </div>
                                    <div className="card-body bg-dark text-light border">
                                        <div className="chart-lg" style={{minHeight: '465px'}}>


                                            <Chart/>

                                        </div>
                                        {/* chart */}
                                    </div>

                                    <div className="bg-dark text-light border card-footer">
                                        <div className="row">
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <strong>Users this month</strong>
                                                <p className="mb-0">204</p>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <strong>Users total</strong>
                                                <p className="mb-0">204</p>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <strong>Subscriptions this month</strong>
                                                <p className="mb-0">1</p>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0">
                                                <strong>Subscriptions total</strong>
                                                <p className="mb-0">1</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <Admin_Footer/>

        </div>
    );
};

export default AdminDashboard;