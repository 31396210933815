// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

// storage
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";
import {
  db_firestore,
  functions,
  storage,
} from "../../../../../firebase.config";
import { httpsCallable } from "firebase/functions";
import { convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";

import {
  increment,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getUidofUser } from "../../../../Main/Components/User/reduxAPI";
// import axios from "../../../../../axios/axiosInstance";

export function createEvent(
  eventName,
  eventStartsAt,
  eventEndsAt,

  description,
  eventType,
  draft,
  eventMode,
  location,
  link,
  groupId,
  uid,
  mapLink,
  tabsManagement,
  pollLink,
  generalChatLink,
  QnaLink,
  feedBackLink,
  eventLogoRedirectLink
) {
  const descriptionHTML = draftToHtml(
    convertToRaw(description.getCurrentContent())
  );

  const createEventfn = httpsCallable(functions, "createEvent");
  return createEventfn({
    eventName,
    eventStartsAt,
    eventEndsAt,

    description: descriptionHTML,
    eventType,
    draft,
    eventMode,
    location,
    link,
    groupId,
    uid,
    mapLink,
    tabsManagement,
    pollLink,
    generalChatLink,
    QnaLink,
    feedBackLink,
    eventLogoRedirectLink,
  });
}

export function addEventGallery(eventGallery, eventId, eventLogo) {
  const addEventGalleryfn = httpsCallable(functions, "addEventGallery");
  return addEventGalleryfn({
    eventGallery,
    eventId,
    eventLogo,
  });
}

export function updateEvent(
  eventName,
  eventStartsAt,
  eventEndsAt,

  description,
  eventType,
  draft,
  eventMode,
  location,
  link,

  uid,
  mapLink,
  eventId,
  tabsManagement,
  pollLink,
  generalChatLink,
  QnaLink,
  feedBackLink,
  eventLogoRedirectLink
) {
  const descriptionHTML = draftToHtml(
    convertToRaw(description.getCurrentContent())
  );

  const updateEventfn = httpsCallable(functions, "updateEvent");
  return updateEventfn({
    eventName,
    eventStartsAt,
    eventEndsAt,

    description: descriptionHTML,
    eventType,
    draft,
    eventMode,
    location,
    link,

    uid,
    mapLink,
    eventId,
    tabsManagement,
    pollLink,
    generalChatLink,
    QnaLink,
    feedBackLink,
    eventLogoRedirectLink,
  });
}

export function uploadFiles(file, eventId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(storage, `events/${eventId}/` + file.name);
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteEventImages(eventId) {
  // Create a reference to the file to delete
  const eventImgRef = ref(storage, `events/${eventId}/`);

  let eventImgList = [];
  // Find all the prefixes and items.
  listAll(eventImgRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        eventImgList = [...eventImgList, itemRef];
        // All the items under listRef.
      });
      if (eventImgList.length > 0) {
        eventImgList.map((value) =>
          deleteObject(ref(storage, `events/${eventId}/${value.name}`))
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export async function joinEventAPI(groupId, eventId) {
  const groupRef = doc(db_firestore, "groups", groupId);

  await updateDoc(groupRef, {
    eventUid: arrayUnion(eventId),
    totalEvents: increment(1),
  });
}

export async function addCreatedEventsId(eventId, uid) {
  // update user
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    createdEventsId: arrayUnion(eventId),
  });
}

export async function eventNameExistsAPI(companyName, eventName) {
  let eventNameExists = false;

  let uid = await getUidofUser(companyName);

  const eventDocq = query(
    collection(db_firestore, "events"),
    where("subscriberUid", "==", uid),
    where("eventName", "==", eventName)
  );
  const querySnapshot = await getDocs(eventDocq);

  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      eventNameExists = true;
    }
  });
  return eventNameExists;
}

export function uploadEventLogo(file, eventId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/eventLogo/` + file.name
    );
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteEventLogo(eventId) {
  // Create a reference to the file to delete
  const eventImgRef = ref(storage, `events/${eventId}/eventLogo`);

  let eventImgList = [];
  // Find all the prefixes and items.
  listAll(eventImgRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        eventImgList = [...eventImgList, itemRef];
        // All the items under listRef.
      });
      if (eventImgList.length > 0) {
        eventImgList.map((value) =>
          deleteObject(
            ref(storage, `events/${eventId}/eventLogo/${value.name}`)
          )
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}
