import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db_firestore } from "../../../../../../firebase.config";
import { getuserDetailsAPI } from "../../Attendees/reduxAPI";
import { sendEventUpdateNotificationAPI, sendMailAPI } from "./reduxAPI";
import { template1 } from "./template1";
import { template2 } from "./template2";

const initialState = {};

export const sendJoinEventMailAsync = createAsyncThunk(
  "joinEventMail/sendJoinEventMail",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { user, userAvl, docId } = getState().user;
      const { eventData } = getState().event;

      const { firstName, lastName } = getuserDetailsAPI(docId);
      let name = firstName + " " + lastName;

      const html = template1(eventData, name);

      if (userAvl) {
        const response = await sendMailAPI(
          user.email,
          eventData.eventName,
          eventData.eventName + ", You are registered!",
          html
        );
        return response;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const sendUpdateEventMailAsync = createAsyncThunk(
  "updateEventMail/sendUpdateEventMail",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { eventData } = getState().event;
      let { attendeesId, eventName } = eventData;

      for (let attendee = 0; attendee < attendeesId.length; attendee++) {
        const UserUid = attendeesId[attendee];

        const userQuery = query(
          collection(db_firestore, "users"),
          where("uid", "==", UserUid)
        );

        const querySnapshot = await getDocs(userQuery);

        let userData;
        querySnapshot.forEach((doc) => {
          userData = doc.data();
        });

        let { lastName, firstName, email } = userData;

        let name = firstName + " " + lastName;

        const html = template2(eventData, name);

        await sendMailAPI(
          email,
          eventName,
          eventName + ", Find new updates",
          html
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const sendUpdateEventNotificationAsync = createAsyncThunk(
  "updateEventNotification/sendUpdateEventNotification",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { eventData } = getState().event;

      let { id } = eventData;
      await sendEventUpdateNotificationAPI(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const joinEventMailSlice = createSlice({
  name: "joinEventMail",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(sendJoinEventMailAsync.pending, (state, action) => {})
      .addCase(sendJoinEventMailAsync.fulfilled, (state, action) => {})
      .addCase(sendJoinEventMailAsync.rejected, (state, action) => {});
  },
});

export const { _ } = joinEventMailSlice.actions;

export const joinEventMailState = (state) => state.joinEventMail;

export default joinEventMailSlice.reducer;
