import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserGroupsAPI, viewProfileAPI } from "./reduxAPI";

const initialState = {
  loading: false,
  found: true,

  firstName: "",
  lastName: "",
  profileUrl: "",
  loadingGroup: false,
  foundGroup: true,
  createdGroupsId: [],

  groupsDataArr: [],
};

export const viewProfileSlice = createSlice({
  name: "viewProfile",
  initialState,

  reducers: {
    clearViewProfile: (state, action) => {
      state.loading = false;
      state.found = true;

      state.loadingGroup = false;
      state.foundGroup = true;

      state.firstName = "";
      state.lastName = "";
      state.profileUrl = "";

      state.createdGroupsId = [];

      state.groupsDataArr = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewProfilebydocId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(viewProfilebydocId.fulfilled, (state, action) => {
        const user = action.payload;

        state.loading = false;

        if (action.payload.notFound) {
          state.found = false;
        } else {
          state.firstName = user.firstName;
          state.lastName = user.lastName;
          state.profileUrl = user.profileUrl;
          state.createdGroupsId = user.createdGroupsId;
        }
      })
      .addCase(viewProfilebydocId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(viewUserGroupsAsync.pending, (state, action) => {
        state.loadingGroup = true;
      })
      .addCase(viewUserGroupsAsync.fulfilled, (state, action) => {
        state.loadingGroup = false;

        if (action.payload.length <= 0) {
          state.foundGroup = false;
        }

        state.groupsDataArr = action.payload;
      })
      .addCase(viewUserGroupsAsync.rejected, (state, action) => {
        state.loadingGroup = false;
      });
  },
});

export const viewProfilebydocId = createAsyncThunk(
  "viewProfile/viewProfilebydocId",
  async ({ docId }, { dispatch, getState, rejectWithValue }) => {
    try {
      // const response =
      const response = await viewProfileAPI(docId);
      return response;

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewUserGroupsAsync = createAsyncThunk(
  "viewProfile/viewUserGroupsAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { createdGroupsId, firstName, lastName } = getState().viewProfile;
      const response = await getUserGroupsAPI(
        createdGroupsId,
        firstName,
        lastName
      );
      return response;

      // return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const { clearViewProfile } = viewProfileSlice.actions;

export const viewProfileState = (state) => state.viewProfile;

export default viewProfileSlice.reducer;
