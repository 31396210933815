import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  link: "http://localhost:3000/g/rxFgKgBdasYKfh9quwAs/e/yyj9Ihni3HR5Vnil7QxH",
};

export const shareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    setShareLink: (state, action) => {
      state.link = action.payload.value;
    },
  },
});

export const { setShareLink } = shareSlice.actions;

export const shareState = (state) => state.share;

export default shareSlice.reducer;
