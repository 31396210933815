import React from "react";

import algoliasearch from "algoliasearch";
import EventCardList from "../../../Main/Components/Events/List/App";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import { Button, Card, Container, Image, Placeholder } from "react-bootstrap";
import { AiFillVideoCamera } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import ShareModel from "../../../Main/Components/ShareModal/App";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShareLink } from "../../../Main/Components/ShareModal/reduxSlice";
import {
  getSavedEventsOfUserAsync,
  userState,
} from "../../../Main/Components/User/reduxSlice";
import {
  saveEventAsync,
  unSaveEventAsync,
} from "../../../Main/Components/Events/Page Component/reduxSlice";

import "./Algoliaor.css";

const searchClient = algoliasearch(
  "WATHJNNCPH",
  "93047dbcdc7325544538039050bc3893"
);
// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
// );

const SearchEvent = () => {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="events">
        <SearchBox className="d-block mx-auto my-2 text-center  " />
        <Hits hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
};

export default SearchEvent;

// {eventsFilterListArr.map(({ id }, index) => {
//   return <EventCardList key={id} index={index} />;
// })}

const Hit = ({ hit }) => {
  const dispatch = useDispatch();
  //loading
  const [imgLoading, setImgLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { savedEvents } = useSelector(userState);

  let {
    groupId,
    objectID,
    eventGallery,
    eventStartsAt,
    eventName,
    location,
    attendeesId,
  } = hit;
  let isSaved = savedEvents.includes(objectID);
  return (
    <>
      <Container className="px-0 ">
        <Card className="flex-column flex-md-row border-0 bg-dark text-light w-100 h-100">
          <Button
            variant="dark"
            className="position-absolute py-1 px-2"
            id="onlineBtn"
            style={{
              top: "4px",
              left: "4px",
              fontSize: "0.75rem",
              display: "none",
            }}
          >
            <AiFillVideoCamera /> ONLINE EVENT
          </Button>
          <div className="col-md-3">
            <Link to={`/event/${objectID}`} className="text-decoration-none">
              <div className="img-wrap ">
                <div className="img-content">
                  <Image
                    fluid
                    draggable={false}
                    src={eventGallery}
                    style={{
                      aspectRatio: "16 / 10",
                    }}
                    className={`rounded ${imgLoading ? "d-none" : null}`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="rounded w-100 h-100 "
                        style={{
                          aspectRatio: "16 / 10 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
          <Card.Body className="py-0 px-0 px-md-2 d-flex flex-column justify-content-between">
            <Container fluid className="p-0 multiLineLabel">
              <Card.Text className="mt-0 mb-2">
                <small>{moment(eventStartsAt._seconds).format("llll")}</small>
              </Card.Text>
              <Link
                to={`/events/${objectID}`}
                className="text-decoration-none text-light"
              >
                <Card.Title className="fs-6 textMaxLine">
                  {eventName}
                </Card.Title>
              </Link>
              <Card.Text className="d-flex">{location}</Card.Text>
            </Container>
            <Container
              fluid
              className="row p-0 w-100 m-0 "
              style={{ fontSize: "0.9rem" }}
            >
              <Container fluid className="col d-flex px-0">
                <Card.Text className="my-auto">
                  {attendeesId?.length}{" "}
                  {attendeesId?.length < 2 ? "attendee" : "attendees"}
                </Card.Text>
                {/* <Button
              variant="link"
              className="text-decoration-none text-success border py-0 px-1 shadow-none ms-2"
              style={{ fontSize: "0.8rem" }}
            >
              <TiTick className="p-0 rounded-circle border bg-success text-white" />
              Attending
            </Button> */}
              </Container>
              <Container
                fluid
                className="col d-flex justify-content-end align-items-center text-light"
              >
                {/* <Button
                  variant="link"
                  className="text-decoration-none shadow-none p-0 px-1 text-light"
                >
                  <span className="fs-5">
                    {isSaved ? (
                      <BsFillStarFill
                        onClick={() => {
                          dispatch(unSaveEventAsync({ eventId: objectID }));
                          dispatch(getSavedEventsOfUserAsync({}));
                        }}
                        className="starFill"
                      />
                    ) : (
                      <BsStar
                        onClick={() => {
                          dispatch(saveEventAsync({ eventId: objectID }));
                          dispatch(getSavedEventsOfUserAsync({}));
                        }}
                      />
                    )}
                  </span>
                </Button> */}
                <Button
                  variant="link"
                  onClick={() => {
                    handleShow();
                    dispatch(
                      setShareLink({
                        value: encodeURI(
                          `${window.location.protocol}//${window.location.host}/event/${objectID}`
                        ),
                      })
                    );
                  }}
                  className="text-decoration-none shadow-none p-0 px-1 text-light fs-5"
                >
                  <FaShareAlt />
                </Button>
              </Container>
            </Container>
          </Card.Body>
        </Card>
        <ShareModel show={show} handleClose={handleClose} />
        <hr className="mt-4" />
      </Container>
    </>
  );
};
