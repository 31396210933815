import React from 'react'
import { Link } from 'react-router-dom';

// Bootstrap
import { Container, Button } from 'react-bootstrap';

//Icon 

// Css
import styles from "../App.module.css";
import '../App.css'

export default function OrganizerPanel() {
    return (
        <>
            <Container className='mt-3 px-lg-5 px-2 vh-100'>
                <h4>Organizer Subscription</h4>
                <hr />
                <h6>You currently don't have a subscription</h6>
                <p>Want to start your own group on Network?</p>
                <Link to={'/creategroup'} className='btn btn-primary mt-4'>
                    Start a new group
                </Link>
            </Container>
        </>
    )
}