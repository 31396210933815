import React from "react";
import {Link} from 'react-router-dom'
import './App.css'
import {Container, Dropdown, Nav, Navbar} from "react-bootstrap";
import {RiArrowDropDownLine} from "@react-icons/all-files/ri/RiArrowDropDownLine";
import {GiHamburgerMenu} from "@react-icons/all-files/gi/GiHamburgerMenu";

// Dashboard
// Subscriptions
// Manage
// createPages
// Group_Settings

export function AdminNavigation() {
    return (
<div className="border-top border-bottom ">

    {/*menu bar*/}
    <Navbar  expand="lg">
        <Container>
            <Navbar.Brand href="#home"><Link to = "/admin/dashboard" className = "bg-dark text-light text-decoration-none">Dashboard</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-dark text-light">
                <GiHamburgerMenu/>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto align-items-center">
                    <Dropdown>
                        <Dropdown.Toggle
                            className="bg-transparent border h-100 border-0 text-center shadow-none d-flex justify-content-center align-items-center "
                            variant="bg-dark"
                            id="dropdown-basic"
                        >
                            <span className="text-light">Subscriptions<RiArrowDropDownLine/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-dark text-light shadow position-absolute border text-center d dropdown-menu-center">
                            <Dropdown.Item   className="bg-dark">
                                <Link className="text-decoration-none text-light" to= "/admin/plan">
                                    Plans
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item  className="bg-dark">
                                <Link className="text-decoration-none text-light" to = "/admin/orders" > Orders </Link>
                            </Dropdown.Item>

                            <Dropdown.Item className="bg-dark">
                                <Link  className="text-decoration-none text-light" to = "/admin/subscription" >  Subscriptions</Link>
                            </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>



                    {/*Subscription tab*/}

                    <Dropdown>
                        <Dropdown.Toggle
                            className="bg-transparent border h-100 border-0 shadow-none d-flex justify-content-center align-items-center "
                            variant="bg-dark"
                            id="dropdown-basic"
                        >
                            <span className="text-light">Manage<RiArrowDropDownLine/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-dark text-light shadow position-absolute border dropdown-menu-center">
                            <Dropdown.Item className="bg-dark text-light "><Link  className="text-decoration-none text-light"  to = "/admin/group" > Group</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/event" > Events</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/topiccategories" > Topic Categories</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/topics" > Topics</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/users" > Users</Link> </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>



                    <Dropdown>
                        <Dropdown.Toggle
                            className="bg-transparent border h-100 border-0 shadow-none d-flex justify-content-center align-items-center "
                            variant="bg-dark"
                            id="dropdown-basic"
                        >
                            <span className="text-light">Pages<RiArrowDropDownLine/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-dark text-light shadow position-absolute border dropdown-menu-center">
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/blogcategories" >Blog Categories </Link></Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/blog" > Blog  </Link></Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/pages" > Pages</Link>  </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>


                    <Dropdown>
                        <Dropdown.Toggle
                            className="bg-transparent border h-100 border-0 shadow-none d-flex justify-content-center align-items-center "
                            variant="bg-dark"
                            id="dropdown-basic"
                        >
                            <span className="text-light">Settings<RiArrowDropDownLine/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-dark text-light shadow position-absolute border dropdown-menu-center">
                            <Dropdown.Item className="bg-dark"><Link  className=" text-decoration-none text-light" to = "/admin/setting/application" > Application</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/setting/location" > Location</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className=" text-decoration-none text-light" to = "/admin/setting/application" > Payment</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/setting/mail" > Mail</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className=" text-decoration-none text-light" to = "/admin/setting/socallogin" > Social Login</Link>  </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className=" text-decoration-none text-light" to = "/admin/setting/company" > Company</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link className=" text-decoration-none text-light"  to = "/admin/setting/currencies" > Currencies</Link> </Dropdown.Item>
                            <Dropdown.Item className="bg-dark"><Link  className="text-decoration-none text-light" to = "/admin/setting/application" > Languages</Link> </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>

                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>


    {/*menu bar*/}

</div>

    )
}