import React, {useState} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './App.css'

import Form from './Form'
import moment from "moment/moment";
import {IoArrowBackCircleSharp} from "@react-icons/all-files/io5/IoArrowBackCircleSharp";
import CalendarForm from "./Form";
import {Badge} from "react-bootstrap";

const mark = [
    '12-09-2022',

]

const Cards = () => {
    const [value, onChange] = useState(new Date());
    const [confirmed, setConfirmed] = useState(false);
    const [innerText, setInnerText] = useState("");

    let hour = Number(new Date().getHours());

    const handleClick = (e) => {
        setInnerText(e.target.innerText);
        setConfirmed(true);
    };

    const handleClickBack = () => {
        setConfirmed(false);
    };

    const weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    // let minute = Number(value.getMinutes());

    // if (minute > 30) {
    //   hour = hour + 1;
    //   minute = "00";
    //   console.log(minute === "00");
    // } else {
    //   minute = 30;
    // }

    // if (minute === "00") {
    //   console.log("its string");
    // }

    // const test = new Date(
    //   Number(value.getFullYear()),
    //   Number(value.getMonth()),
    //   Number(value.getDay()),
    //   Number(value.getHours()),
    //   Number(value.getMinutes())
    // );
    // console.log(test);

    return (
        <div className='container card p-3 text-light bg-dark border'>
            <div className="row">
                <div className="col-sm-6">
                    {confirmed ? (
                        <h2 onClick={handleClickBack} className="">
                            <IoArrowBackCircleSharp/>
                        </h2>
                    ) : null}

                    <h4 className="">UserName</h4>
                    <h3>Chat Our</h3>
                    <div className="">
                        video
                    </div>
                    <div className="">
                        clock
                        <h4>Web conferencing details provided upon confirmation.</h4>
                    </div>
                    {confirmed ? (
                        <>
                            <div style={{color: "rgb(6, 194, 156)"}} className="desc">
                                <h4>{`${innerText}, ${weekDays[value.getDay()]}, ${
                                months[value.getMonth()]
                            } ${value.getDate()}, ${value.getFullYear()} `}</h4>
                        </div>
                        <div className="desc">
                            <h4>India Standard Time</h4>
                        </div>
                    </>
                ) : null}
            </div>

            {confirmed ? (
                <div className='col-sm-6'>
                    <CalendarForm value={value} confirmed={confirmed} innerText={innerText}/>
                </div>

            ) : (
                <div className="col-sm-6">
                    <div className="fs-5 bold mb-3">Select a Date & Time</div>


                    <div className="d-flex flex-lg-row flex-column">
                        <div className="">
                            <Calendar className='bg-dark text-light' onChange={onChange} value={value}
                                      tileClassName={({date, view}) => {
                                          if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                              return 'highlight'
                                          }
                                      }}/>
                        </div>
                        <div className="mx-lg-3 mx-md-0">
                            <div className="">
                                {`${weekDays[value.getDay()]}, ${
                                    months[value.getMonth()]
                                } ${value.getDate()}`}
                            </div>

                            <div className="">
                                <div onClick={handleClick}>
                                    <h4><Badge
                                        bg="primary"> {hour + 1 > 12 ? `${hour + 1 - 12}PM` : `${hour + 1}AM`}</Badge>
                                    </h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
};

export default Cards;
