import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Routes
import { useParams, Navigate, useLocation } from "react-router-dom";
import { setHideFooter } from "../../Footer/reduxSlice";

// Redux
import { getCompanyNameFromEventId } from "./reduxAPI";

const EventIdPage = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const { state } = useLocation();

  const [companyName, setCompanyName] = useState();
  const [eventName, setEventName] = useState(state?.eventName);

  useEffect(() => {
    (async () => {
      let eventNameAvl;

      if (!state?.eventName) {
        eventNameAvl = false;
      } else {
        eventNameAvl = true;
      }

      const result = await getCompanyNameFromEventId(eventId, eventNameAvl);

      setCompanyName(result.companyName);

      if (!state?.eventName) {
        setEventName(result.eventName);
      }
    })();

    return () => {};
  }, [eventId, state?.eventName]);

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));

    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, [dispatch]);

  return (
    <>
      {companyName && (state?.eventName || eventName) ? (
        <Navigate
          to={`/${companyName}/${state?.eventName || eventName}`}
          replace={false}
        />
      ) : null}
    </>
  );
};

export default EventIdPage;
