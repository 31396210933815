import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import { getCompanyNameofUser } from "../../User/reduxAPI";

export async function getFilteredEventsAPI(
  filterEvent,
  upcomming,
  docId,
  allEvent = false
) {
  let events = [];
  if (allEvent) {
    const eventRef = collection(db_firestore, "events");

    const q = query(eventRef);

    const eventSnap = await getDocs(q);

    eventSnap.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const id = doc.id;
      events.push({ id, ...doc.data() });
    });

    return events;
  } else {
    const userRef = doc(db_firestore, "users", docId);

    const userSnap = await getDoc(userRef);

    let groupsId = [];
    let eventsId = [];

    if (userSnap.exists()) {
      switch (filterEvent) {
        case "attennding":
          eventsId = userSnap.data().joinedEventsId;
          break;
        case "saved":
          eventsId = userSnap.data().savedEvents;
          break;
        case "from_groups_you_organize":
          groupsId = userSnap.data().createdGroupsId;

          for (let index = 0; index < groupsId.length; index++) {
            const groupRef = doc(db_firestore, "groups", groupsId[index]);
            const groupSnap = await getDoc(groupRef);

            if (groupSnap.exists()) {
              eventsId = [...eventsId, ...groupSnap.data().eventUid];
            } else {
              console.log("this Group id is not exist in db");
            }
          }

          break;
        case "from_groups_you_joined":
          groupsId = userSnap.data().joinedGroupsId;

          for (let index = 0; index < groupsId.length; index++) {
            const groupRef = doc(db_firestore, "groups", groupsId[index]);
            const groupSnap = await getDoc(groupRef);

            if (groupSnap.exists()) {
              eventsId = [...eventsId, ...groupSnap.data().eventUid];
            } else {
              console.log("this Group id is not exist in db");
            }
          }

          break;
        default:
          break;
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    for (let index = 0; index < eventsId.length; index++) {
      const eventRef = doc(db_firestore, "events", eventsId[index]);

      const eventSnap = await getDoc(eventRef);

      if (eventSnap.exists()) {
        let todayTime = new Date().getTime();
        let eventDateTime = eventSnap.data().eventStartsAt.toDate().getTime();
        if (upcomming && eventDateTime > todayTime) {
          events.push({
            id: eventsId[index],
            ...eventSnap.data(),
          });
        } else if (!upcomming && eventDateTime < todayTime) {
          events.push({
            id: eventsId[index],
            ...eventSnap.data(),
          });
        }

        for (let index = 0; index < events.length; index++) {
          const companyName = await getCompanyNameofUser(
            events[index].subscriberUid
          );
          events[index].companyName = companyName;
        }
      } else {
        console.log("This Event id  is not exist in db");
      }
    }

    return events;
  }
}
