import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAnnouncementAPI } from "./reduxAPI";

const initialState = {
    showForm: false,

    announcementText: "",

    loading: false,
};


export const createAnnouncementAsync = createAsyncThunk(
    "announcementForm/createAnnouncement",
    async ({ _ }, { dispatch, getState, rejectWithValue }) => {
        try {
            const {
                eventData: { id: eventId },
            } = getState().event;

            const { announcementText } = getState().announcementForm;

            const response = await createAnnouncementAPI(eventId, announcementText);

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const announcementFormSlice = createSlice({
    name: "announcementForm",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setShowForm: (state, action) => {
            state.showForm = action.payload.value;
        },
        setAnnouncementText: (state, action) => {
            state.announcementText = action.payload.value;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(createAnnouncementAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createAnnouncementAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.announcementText = "";
            })
            .addCase(createAnnouncementAsync.rejected, (state, action) => {
                state.loading = false;
                state.announcementText = "";
            });
    },
});

export const {
    setShowForm,
    setAnnouncementText
} = announcementFormSlice.actions;

export const announcementFormState = (state) => state.announcementForm;

export default announcementFormSlice.reducer;
