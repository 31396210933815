import React, { useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import moment from "moment";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { eventState } from "../reduxSlice";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="fs-4 fw-bold">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

export default function App() {
  const {
    eventData: { eventStartsAt },
  } = useSelector(eventState);

  const [hideCount, setHideCount] = React.useState(true);
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = eventStartsAt?.seconds; // use UNIX timestamp in seconds
  const remainingTime = endTime - stratTime;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!(eventStartsAt?.seconds < Date.now() / 1000)) {
        setHideCount(false);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [eventStartsAt]);

  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <div className={`d-flex justify-content-around text-center gap-3`}>
      {!hideCount ? (
        <>
          <CountdownCircleTimer
            {...timerProps}
            colors={["#BC986A"]}
            size="110"
            duration={daysDuration}
            initialRemainingTime={remainingTime}
          >
            {({ elapsedTime, color }) => (
              <span style={{ color }}>
                {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={["#8D8741", "#659DBD", "#DAAD86"]}
            colorsTime={[24, 16, 8]}
            size="110"
            duration={daySeconds}
            initialRemainingTime={remainingTime % daySeconds}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
            })}
          >
            {({ elapsedTime, color }) => (
              <span style={{ color }}>
                {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={["#8D8741", "#659DBD", "#DAAD86"]}
            colorsTime={[60, 40, 20]}
            size="110"
            duration={hourSeconds}
            initialRemainingTime={remainingTime % hourSeconds}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
            })}
          >
            {({ elapsedTime, color }) => (
              <span style={{ color }}>
                {renderTime(
                  "minutes",
                  getTimeMinutes(hourSeconds - elapsedTime)
                )}
              </span>
            )}
          </CountdownCircleTimer>
          <CountdownCircleTimer
            {...timerProps}
            colors={["#8D8741", "#659DBD", "#DAAD86"]}
            colorsTime={[60, 40, 20]}
            size="110"
            duration={minuteSeconds}
            initialRemainingTime={remainingTime % minuteSeconds}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > 0,
            })}
          >
            {({ elapsedTime, color }) => (
              <span style={{ color }}>
                {renderTime("seconds", getTimeSeconds(elapsedTime))}
              </span>
            )}
          </CountdownCircleTimer>
        </>
      ) : null}
    </div>
  );
}
