import { collection, getDocs, query, where } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import { getCompanyNameofUser, getUserInfo } from "../../User/reduxAPI";

export async function getGroupsAPI(
  organizer = false,
  uid,
  all = false,
  publicOnly = false
) {
  const groupRef = collection(db_firestore, "groups");

  let customQuery;

  if (all && !publicOnly) {
    customQuery = query(groupRef);
  } else if (all && publicOnly) {
    customQuery = query(groupRef, where("groupType", "==", "public"));
  } else {
    customQuery = query(
      groupRef,
      where(
        organizer ? "subscriberUid" : "memberUid",
        organizer ? "==" : "array-contains",
        uid
      )
    );
  }

  const querySnapshot = await getDocs(customQuery);

  let groups = [];

  querySnapshot.forEach(async (doc) => {
    // doc.data() is never undefined for query doc snapshots
    const id = doc.id;
    groups.push({
      id,
      ...doc.data(),
    });
  });

  for (let index = 0; index < groups.length; index++) {
    const user = await getUserInfo(groups[index].subscriberUid);
    const companyName = await getCompanyNameofUser(groups[index].subscriberUid);
    groups[index].subscriberName = user.firstName + " " + user.lastName;
    groups[index].companyName = companyName;
  }

  return groups;
}
