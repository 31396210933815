import React from "react";
import FooterComponent from "../FooterComponent/App";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { EventCard } from "../../../Main/Components/Events/Card/App";
import Carousel, { consts } from "react-elastic-carousel";
import { IoIosArrowDroprightCircle } from "@react-icons/all-files/io/IoIosArrowDroprightCircle";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import "./App.css";
import { Link } from "react-router-dom";

//Test Data
const content = [
  {
    id: 0,
    date: "TUE, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "He says it kills all the things being alive; for instance, there's the arch",
    name: "Jones-Cole",
    attendees: "7",
  },
  {
    id: 1,
    date: "TUE, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "7",
  },
  {
    id: 2,
    date: "TUE, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "21",
  },
  {
    id: 3,
    date: "WEN, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "12",
  },
  {
    id: 4,
    date: "WEN, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "5",
  },
  {
    id: 5,
    date: "SAT, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "9",
  },
  {
    id: 6,
    date: "MON, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "23",
  },
  {
    id: 7,
    date: "MON, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "8",
  },
  {
    id: 8,
    date: "TUE, JUL 5 @ 7:00 PM GMT+03",
    image: "https://picsum.photos/300/200",
    eventDesc:
      "CHORUS. 'Wow! wow! wow!' While the Panther were sharing a pie--'",
    name: "Jones-Cole",
    attendees: "7",
  },
];

//breakpoint css
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 600, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1020, itemsToShow: 4 },
];

//custom arrow
function myArrow({ type, onClick, isEdge }) {
  const pointer =
    type === consts.PREV ? (
      <div className="fs-1  custom-arrow custom-arrow-left">
        <IoIosArrowDropleftCircle />
      </div>
    ) : (
      <div className="fs-1 custom-arrow custom-arrow-right">
        <IoIosArrowDroprightCircle />
      </div>
    );
  return (
    <span className="" onClick={onClick}>
      {pointer}
    </span>
  );
}

export default function LandingPage() {
  return (
    <>
      <Container>
        <Row className="mx-0 justify-content-center align-items-center my-5 flex-column flex-md-row">
          <Col className="text-center text-md-start">
            <h1>Dive in! There are so many things to do on Network</h1>
            <p>
              Join a group to meet people, make friends, find support, grow a
              business, and explore your interests. Thousands of events are
              happening every day, both online and in person!
            </p>
          </Col>
          <Col>
            <img
              src="https://network.varuscreative.com/assets/images/homepage/banner.svg"
              alt="..."
              className="img-fluid"
            />
          </Col>
        </Row>

      
      </Container>
      <FooterComponent />
    </>
  );
}
