import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {pushErrorNotification} from "../../../../Main/Components/Error/reduxSlice";
import {Icon} from "@iconify/react";
import {addInterest} from "./reduxApi";

const initialState = {
    // interests: [],
    interest: ""
};

export const addInterestAsync = createAsyncThunk(
    "interest/addInterest",
    async ({interest}, {dispatch, getState, rejectWithValue}) => {
        try {


            const { docId } = getState().user;

            const response = await addInterest(
                interest
                ,docId
            );

            if (response.data.error) {
                throw response.data;
            }
        } catch (error) {
            if (error.name === "bizError") {
                dispatch(
                    pushErrorNotification({
                        notify: {
                            iconifyIconLink: (
                                <Icon icon="bx:notification" className="rounded"/>
                            ),
                            errorTitle: error.errorTitle,
                            errorMessage: error.errorMessage,
                            theme: error.theme,
                            time: error.time,
                            autoHideTime: error.autoHideTime,
                            statusCode: error.statusCode,
                            show: error.show,
                        },
                    })
                );
            }
            return rejectWithValue(error);
        }
    }
);
const interestSlice = createSlice({
    name: 'interests',
    initialState,
    reducers: {
        // setInterests: (state, action) => {
        //     state.interests = [...state.interests, action.payload.newInterest]
        // },
        setInterest: (state, action) => {
            state.interest = action.payload.value;
        },
        // removeInterest: (state, action) => {
        //     console.log(`action.payload = ${action.payload}`);
        //     state.interests.splice(state.interests.findIndex((arrow) => arrow === action.payload), 1);
        // }
    }
})

// Action creators are generated for each case reducer function
export const {setInterest} =
    interestSlice.actions;

// create state here and export
export const interestState = state => state.interest


export default interestSlice.reducer;
