import React from 'react'
import Admin_Footer from "../../CommonComponents/Footer/App";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";

const MailSetting = () => {
    return (
        <div className="min-vh-100">

            <AdminNavigation/>
            <div className="d-flex flex-column position-relative">
                <div className="d-flex flex-column flex-grow-1">
                    <div className="container">
                        <div className="page-header d-print-none">
                            <div className="row align-items-center">
                                <div className="col mt-3 mb-3">
                    <div className="page-pretitle">
                       <small> Settings</small>
                    </div>
                    <h1 className="page-title h5 fw-bolder">
                        Mail Settings
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div className="page-body">
        <div className="container">
            <div className="row">
                <div className="col-12 bg-dark text-light card border">
                    <form className="card-body">
                        <h3>SMTP Settings</h3>
                         
    
                        <div className="form-group mb-3">
                            <label>Mail Host</label>
                            <input  type="text" className="form-control bg-dark text-light" placeholder="Mail Host" value="smtp.mailtrap.io"/>
                        </div>
    
                        <div className="form-group mb-3">
                            <label>Mail Port</label>
                            <input   type="number" className="form-control bg-dark text-light" placeholder="Mail Port" value="2525"/>
                        </div>
    
                        <div className="form-group mb-3">
                            <label>Mail Encryption</label>
                            <select  className="form-control bg-dark text-light">
                                <option value="" selected="&quot;&quot;">None</option>
                                <option value="tls">TLS</option>
                                <option value="ssl">SSL</option>                            
                            </select>
                        </div>
    
                        <div className="form-group mb-3">
                            <label>Username</label>
                            <input  type="text" className="form-control bg-dark text-light" placeholder="Username" value=""/>
                        </div>
    
                        <div className="form-group mb-3">
                            <label>Password</label>
                            <input  type="text" className="form-control bg-dark text-light" placeholder="Password" value=""/>
                        </div>
    
                        <div className="form-group mb-3">
                            <label >From Email Address</label>
                            <input  type="text" className="form-control bg-dark text-light" placeholder="From Email Address" value="contact@example.com"/>
                        </div>
    
                        <div className="form-group mb-3">
                            <label>From Name</label>
                            <input   type="text" className="form-control bg-dark text-light" placeholder="From Name" value="Acme Company"/>
                        </div>
    
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">Update Settings</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


        </div>
      </div>
          <Admin_Footer/>
      </div>
  )
}

export default MailSetting