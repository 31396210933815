import React from 'react';
import {Button} from "react-bootstrap";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

const SettingApplication = () => {
    return (
        <div className="page min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-prettier ">
                                    <small>Settings</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Application Settings
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 bg-dark text-light border ">

                                <form className="card-body">

                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Logo</label>
                                                    <p>
                                                        <img className="img-thumbnail bg-dark text-light h-110px"
                                                             src="" alt = "logo"/>
                                                    </p>
                                                    <input className="form-control bg-dark text-light"   type="file"
                                                           accept="image/png, image/jpeg, image/svg+xml"/>
                                                        <small>Recommended size: 654x191px</small>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Favicon</label>
                                                    <p>
                                                        <img className="img-thumbnail h-110px bg-dark text-light"
                                                             src=" "  alt="favicon"/>
                                                    </p>
                                                    <input className="form-control bg-dark text-light"  type="file"
                                                           accept="image/png, image/jpeg"/>
                                                        <small>Recommended size: 192x192px</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group mb-3 required">
                                            <label> Application Name</label>
                                            <input  type="text" className="form-control bg-dark text-light"
                                                   placeholder="Application Name"  />
                                        </div>

                                        <div className="form-group mb-3 required">
                                            <label >Meta Description</label>
                                            <input   type="text" className="form-control bg-dark text-light"
                                                   placeholder="Meta Description"
                                            />
                                        </div>

                                        <div className="form-group mb-3 required">
                                            <label>Meta Keywords</label>
                                            <input  type="text" className="form-control bg-dark text-light"
                                                   placeholder="Meta Keywords"/>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label>Google ReCaptcha Key</label>
                                            <input   type="text" className="form-control bg-dark text-light"
                                                   placeholder="Enter the key"  />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label>Google ReCaptcha Secret fKey</label>
                                            <input  type="text"
                                                   className="form-control bg-dark text-light" placeholder="Enter the secret key"/>
                                        </div>

                                        <div className="text-end">
                                            <Button type="submit" className="btn btn-primary">Update Settings</Button>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

               

                </div>
            <Admin_Footer/>
            </div>

    );
};

export default SettingApplication;