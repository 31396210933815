import { httpsCallable } from "firebase/functions";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getStorage,
  deleteObject,
} from "firebase/storage";
import { functions, storage } from "../../../../../../../../../firebase.config";

export function createAgendaSessionAPI(sessionData) {
  const createAgendaSession = httpsCallable(functions, "createAgendaSession");
  return createAgendaSession({
    ...sessionData,
  });
}
export function updateAgendaSessionAPI(sessionData) {
  const updateAgendaSession = httpsCallable(functions, "updateAgendaSession");
  return updateAgendaSession({
    ...sessionData,
  });
}
export function getAgendaAPI(eventId, docId) {
  const getAgenda = httpsCallable(functions, "getAgenda");
  return getAgenda({
    eventId,
    docId,
  });
}
export function createPrensentationsAPI(sessionData) {
  const createPrensentations = httpsCallable(functions, "createPrensentations");
  return createPrensentations({
    ...sessionData,
  });
}

export function deleteAgendaSessionAPI(dataId) {
  const deleteAgendaSession = httpsCallable(functions, "deleteAgendaSession");
  return deleteAgendaSession({
    ...dataId,
  });
}
export function deletePrensentationsAPI(presentationData) {
  const deletePrensentations = httpsCallable(functions, "deletePrensentations");
  return deletePrensentations({
    ...presentationData,
  });
}

export function addSessionRatingAPI(ratingData) {
  const addSessionRating = httpsCallable(functions, "addSessionRating");
  return addSessionRating({
    ...ratingData,
  });
}


export function uploadAgendaPresentationFile(
  file,
  eventId,
  agendaId,
  sessionId
) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return

    let uniqueName = Math.random().toString() + "_" + file.name;

    const mountainsRef = ref(
      storage,
      `events/${eventId}/agenda/${agendaId}/session/${sessionId}/` + uniqueName
    );
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteAgendaPresentationFile(prensentationsUrl) {
  const presentationRef = ref(storage, prensentationsUrl);
  let res = await deleteObject(presentationRef);
  return res;
}
