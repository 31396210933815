import React, {useEffect, useState} from "react";

// Routes
import { useNavigate } from "react-router-dom";

// Components
import GroupLocation from "./Location/App";
import GroupCatagories from "./Cateogries/App";
import GroupName from "./GroupName/App";
import GroupDescription from "./Description/App";
import GroupType from "./GroupType/App";
import GroupGallery from "./GroupGallery/App";

// Icons
import { Icon } from "@iconify/react";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
    clearPreviewFiles,
    clearSelectedFiles,
    createGroupAsync,
    createGroupState, groupPreviewInterestAsync,
    setDescription,
    setGroupName,
    setGroupType,
    setLocation,
    setPreviewFiles,
    setSelectedFiles,
} from "./reduxSlice";
import { pushErrorNotification } from "../../Error/reduxSlice";

const CreateGroup = () => {
  const navigate = useNavigate();

  //Redux
  const dispatch = useDispatch();
  const {
    loading,
    groupName,
    categories,
    groupGallery,
    description,
    groupType,
    location,
    // previewCategories,
    // preViewFiles,
    // testInterest,
  } = useSelector(createGroupState);

  const handelSubmit = (e) => {
    e.preventDefault();
    if (page === 6) {
      if (!groupType) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Select Group Type",
              errorMessage: "Only public groups are available for attendees.",
              theme: "info",
              time: "now",
              autoHideTime: 3000,
              statusCode: 400,
              show: true,
            },
          })
        );
        return;
      }

      dispatch(createGroupAsync({ navigate }));

      console.log(
        groupName,
        categories,
        groupGallery,
        description,
        groupType,
        location
      );
    } else {
      setPage((currPage) => currPage + 1);
    }
  };

  //Gallery
  const previewFiles = (evt) => {
    dispatch(clearSelectedFiles({}));
    dispatch(clearPreviewFiles({}));

    var files = evt.target.files;

    for (let i = 0; i < evt.target.files.length; i++) {
      const newImage = evt.target.files[i];

      newImage["id"] = Math.random();
      dispatch(setSelectedFiles({ newImage }));
    }

    for (var i = 0, len = files.length; i < len; i++) {
      var file = files[i];

      var reader = new FileReader();

        reader.onload = (function () {
            return function (e) {
                // Here you can use `e.target.result` will give you <img src={e.target.result} link
                // and `f.name` will give you file name

                dispatch(setPreviewFiles({newPreview: e.target.result}));
            };
        })(file);

      reader.readAsDataURL(file);
    }
  };

  //state for steps
    const [page, setPage] = useState(1);

    const FormTitles = [
        "Location",
        "Categories",
        "Group name",
        "Description",
        "Group Type",
        "Group Gallery",
    ];

    //Interest
    useEffect(() => {
        dispatch(groupPreviewInterestAsync({}));
    }, []);

    const next = (e) => {
        e.preventDefault();
        setPage(page + 1);
    };
    const prev = () => setPage(page - 1);

    const renderPage = (step) => {
        switch (step) {
            case 1:
        return (
          <GroupName
            groupName={groupName}
            setGroupName={(e) => {
              dispatch(setGroupName({ value: e.target.value }));
            }}
            nextPage={next}
          />
        );
      case 2:
        return <GroupCatagories nextPage={next} backPage={prev} />;
      case 3:
        return (
          <GroupLocation
            location={location}
            setLocation={(e) => {
              dispatch(setLocation({ value: e.target.value }));
            }}
            nextPage={next}
            backPage={prev}
          />
        );
      case 4:
        return (
          <GroupDescription
            description={description}
            setDescription={(newState) => {
              dispatch(setDescription({ value: newState }));
            }}
            nextPage={next}
            backPage={prev}
          />
        );

      case 5:
        return (
          <GroupGallery
            groupGallery={previewFiles}
            nextPage={next}
            backPage={prev}
          />
        );

      case 6:
        return (
            <GroupType
                groupType={groupType}
                setGroupType={(e) => {
                    dispatch(setGroupType({value: e.target.value}));
                }}
                backPage={prev}
                Submit={handelSubmit}
                loading={loading}
            />
        );

        default:
            return;
    }
  };


    return (
        <div className="page-wrapper">
            <section className="container py-5">
                <div className="row d-flex justify-content-center ">
                    <div className="row d-flex justify-content-center pb-3">
                        <div className="col-md-6">
                            <p className="text-center">
                                Step {page}/{FormTitles.length}
                            </p>
                        </div>
                    </div>
                    {renderPage(page)}
                </div>
            </section>
        </div>
    );
};

export default CreateGroup;
