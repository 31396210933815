import React from 'react'
import '../App.css'
import {Button} from "react-bootstrap";
import {MdEvent} from 'react-icons/md'

export function RegisterBox() {

    return (
             <div className=" text-black card rounded-3  bg-dark text-light border ">
                 <div className="p-3">

                     <div>
                     <div className="flex-row d-inline-flex align-items-center">
                         <p className="bg-danger text-white fs-6 rounded-circle p-1">LIVE</p>
                         <p className="fw-bolder fs-4"> Programmatic Advertising Conference 2022</p>
                     </div>
                     <p>The event is live! 130 People are registered</p>
                    </div>

                     <div className="d-flex flex-row justify-content-between">
                         <div>
                             <p className=" fw-bolder">START TIME</p>
                            <p>01 Jul, 11:30 AM</p>
                         </div>

                         <div>
                             <p className="fw-bolder">END TIME</p>
                             <p>01 Jul, 11:30 AM</p>
                         </div>

                         <div>
                             <MdEvent/>
                         </div>
                     </div>

                     <div className="d-flex flex-column">
                         <Button className="bg-primary my-2" variant="primary">Register</Button>
                         <Button className="btn btn-outline-primary" variant="outline-primary">Join Event</Button>
                     </div>
                 </div>

             </div>



    )
}
