import React, { useState, useCallback } from "react";

// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Card, Button, Container, Image, Placeholder } from "react-bootstrap";

// Animation
import { useSpring, animated } from "@react-spring/web";

// Icons
import { FaShareAlt } from "react-icons/fa";

// Redux
import { useDispatch } from "react-redux";

// Componentss
import ShareModel from "../../ShareModal/App";

// Other laibrary
import parse from "html-react-parser";
import { setShareLink } from "../../ShareModal/reduxSlice";

const GroupList = ({ groupData }) => {
  const dispatch = useDispatch();

  //loading
  const [imgLoading, setImgLoading] = useState(true);
  // const [isBooped, setIsBooped] = React.useState(false);
  const [isBooped, setBooped] = React.useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const transformFrom = "scale(1.1)";
  const transformTo = "scale(1)";
  const [cardHover, hover] = useSpring(() => ({
    from: { transform: transformTo },
  }));

  const onMouseEnter = useCallback(() => setBooped(true), []);
  const onMouseLeave = useCallback(() => setBooped(false), []);

  React.useEffect(() => {
    if (isBooped) {
      hover.start({
        to: transformFrom,
      });
    } else {
      hover.stop();
      hover.start({
        to: transformTo,
      });
    }
  }, [isBooped]);

  if (!groupData) {
    return null;
  }

  const {
    description,
    groupGallery,
    groupName,
    groupType,
    id,
    totalMember,
    subscriberName,
    companyName,
    // memberUid,
    // subscriberUid,
    // location,
    // eventUid,
  } = groupData;

  const handleShow = () => {
    setShow(true);

    if (companyName) {

      dispatch(
        setShareLink({
          value: encodeURI(
            `${window.location.protocol}//${window.location.host}/${companyName}/group/${groupName}`
          ),
        })
      );
    } else {
      dispatch(
        setShareLink({
          value: encodeURI(
            `${window.location.protocol}//${window.location.host}/group/${id}`
          ),
        })
      );
    }

  };

  return (
    <>
      <Card className="flex-column flex-md-row border-0 bg-dark text-light w-100 h-100 my-3">
        <div className="col-md-3 ">
          <Link
            to={`/group/${id}`}
            state={{ groupName }}
            className="text-decoration-none"
          >
            <div className="img-wrap ">
              <div className="img-content overflow-hidden rounded">
                <animated.div
                  style={cardHover}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <Image
                    fluid
                    draggable={false}
                    src={groupGallery}
                    style={{
                      aspectRatio: "16 / 10",
                    }}
                    className={`rounded w-100 ${imgLoading ? "d-none" : null}`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    alt="Runolfsdottir Group"
                  />
                </animated.div>
                {imgLoading ? (
                  <Placeholder animation="glow">
                    <Placeholder
                      className="rounded w-100 h-100 "
                      style={{
                        aspectRatio: "12/8 ",
                      }}
                    />
                  </Placeholder>
                ) : null}
              </div>
            </div>
          </Link>
        </div>
        <Card.Body className="py-0 px-0 px-md-3 d-flex flex-column justify-content-between multiLineLabel">
          <Container fluid className="p-0 py-2 pt-0">
            <Link to={`/groups/ga`} className="text-decoration-none text-light">
              <Card.Title className="fs-6 fw-bold mt-0">{groupName}</Card.Title>
            </Link>
            <Card.Text className="d-flex fs-6 mb-1">{subscriberName}</Card.Text>
            <Card.Text className="textMaxLine description_filter">
              <small>{parse(description)}</small>
            </Card.Text>
          </Container>
          <Container
            fluid
            className="d-flex flex-row align-items-center justify-content-between px-0"
            style={{ fontSize: "0.9rem" }}
          >
            <div className="px-0 ">
              <p className="my-0">
                <i>
                  {totalMember} Members - {groupType} Group
                </i>
              </p>
            </div>
            <div className="text-light">
              <Button
                variant="link"
                onClick={handleShow}
                className="text-decoration-none shadow-none p-0 px-1 text-light"
              >
                <FaShareAlt />
              </Button>
            </div>
          </Container>
        </Card.Body>
        <ShareModel show={show} handleClose={handleClose} />
      </Card>
      <hr className="mt-4" />
    </>
  );
};
export default GroupList;
