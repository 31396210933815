import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addInterestAsync, interestState, setInterest } from "./reduxlice";

//ICONS
import { MdClose } from "react-icons/md";
import { Button } from "react-bootstrap";

const Interest = () => {
  const dispatch = useDispatch();
  let { interest } = useSelector(interestState);

  const addInterest = (e) => {
    e.preventDefault();
    dispatch(addInterestAsync({ interest }));
  };
  console.log(interest);
  return (
    <div>
      <div className="col-md-8 container">
        <div className="mb-3">
          <input
            value={interest}
            onChange={(e) => {
              dispatch(setInterest({ value: e.target.value }));
            }}
          />
        </div>
        <Button onClick={addInterest}>Add</Button>
      </div>
    </div>
  );
};

export default Interest;
