import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GroupCard from "../../../../Main/Components/Groups/List/App";
import { EventListFilterSkeleton } from "../../MyGroup/MyGroup/Organizer/Organizer";
import NotFoundGroups from "../../MyGroup/MyGroup/Organizer/NotFoundGroups";
import { useDispatch, useSelector } from "react-redux";

import {
  algoliaAsync,
  navBarState,
  setAlgoliaIndex,
  setNavigatePath,
  setResult,
  setSearchFound,
  setSearchQuery,
} from "../../../../Main/Components/Navbar/reduxSlice";

const FindGroup = () => {
  const { searchLoading, searchFound, result } = useSelector(navBarState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAlgoliaIndex({ value: "groups" })); // algoliaIndex
    dispatch(setNavigatePath({ navigate: "/find/group" })); // navigatePath
    dispatch(algoliaAsync({}));

    return () => {
      dispatch(setSearchFound({ value: true }));
      dispatch(setResult({ result: [] }));
    };
  }, [dispatch]);
  return (
    <div className="min-vh-100">
      <section className="container py-5">
        <div>
          <nav className="nav">
            <Link
              to="/find/event"
              onClick={() => {
                dispatch(setSearchQuery({ value: "" }));
                dispatch(setResult({ result: [] }));
              }}
              className="text-decoration-none shadow-none fw-bold btn btn-outline-primary   rounded-start rounded-0 "
            >
              Events
            </Link>
            <Link
              to="/find/group"
              className="text-decoration-none shadow-none fw-bold btn btn-primary rounded-end rounded-0  "
            >
              Groups
            </Link>
          </nav>

          {/* <div className="sticky-top border-bottom py-3">
            <p className="text-muted">Suggested events </p>
            <div className="d-inline-flex mx-2 align-items-center">
              <div className=" mx-2 ">
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-light bg-dark border"
                    id="dropdown-basic"
                  >
                    Any Date
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className=" mx-2 ">
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-light bg-dark border"
                    id="dropdown-basic"
                  >
                    Any type
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className=" mx-2 ">
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-light bg-dark border"
                    id="dropdown-basic"
                  >
                    Any Categories
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className=" mx-2 ">
                <span className="text-primary">Clear all</span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="  mt-5">
          {searchLoading ? (
            <EventListFilterSkeleton list={3} />
          ) : (
            <>
              {searchFound ? (
                <>
                  {result.map((group, index) => (
                    <GroupCard key={index} groupData={group} />
                  ))}
                </>
              ) : (
                <NotFoundGroups />
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default FindGroup;
