import React from "react";

// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Image, Placeholder } from "react-bootstrap";
import { useState } from "react";

const NotFoundGroups = () => {
  const [imgLoading, setImgLoading] = useState(true);
  return (
    <div className="col-12 col-lg-3 ms-0 px-0">
      <Link to="/" className="card card-link text-decoration-none mb-3">
        <div className="card-body bg-dark text-light p-3 ">
          <div className="row align-items-center">
            <div className="col-4">
              <Image
                fluid
                draggable={false}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fno-events.svg?alt=media&token=4c59ee87-eaeb-4425-b9fb-73f5b137941d"
                }
                style={{
                  aspectRatio: "1 / 1",
                }}
                className={`rounded ${imgLoading ? "d-none" : null}`}
                onLoad={() => {
                  setImgLoading(false);
                }}
                alt="Runolfsdottir Group"
              />
              {imgLoading ? (
                <Placeholder animation="glow">
                  <Placeholder
                    className="rounded w-100 h-100 "
                    style={{
                      aspectRatio: "1 / 1 ",
                    }}
                  />
                </Placeholder>
              ) : null}
            </div>
            <div className="col-8   ">
              <div className="fw-bold mb-1 text-truncate text-truncate-two-line">
                Not Found
              </div>
              <div className="text-muted fst-italic">Not Found</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NotFoundGroups;
