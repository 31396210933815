import React from 'react';
import Admin_Footer from "../../CommonComponents/Footer/App";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import {Pagination} from "react-bootstrap";
import {BsThreeDotsVertical} from "react-icons/bs";

const TopicCateogries = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                 <small>   Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Topic Categories
                                </h1>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <a  
                                   className="btn btn-primary">
                                    Create new
                                </a>
                                <a 
                                   className="text-danger delete-confirm mx-2"  >
                                    Delete All Defaults
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12 mb-3">
                                <form className="card bg-dark text-light border"  >
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">
                                            <div className="form-group">
                                                <input   type="text" className="form-control bg-dark text-light " value=""
                                                       placeholder="Name"/>
                                            </div>
                                            <button type="submit" className="btn btn-link">
                                                <i className="fa fa-filter me-2"></i>
                                                Filter
                                            </button>
                                            <a className="btn btn-link"
                                               >
                                                <i className="fa fa-redo me-2"></i>
                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bold">Categories</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table  bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="topic_categories">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">33</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">Writing</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">writing</p>
                                                </td>
                                                <td>
                                                    2022-07-13
                                                </td>
                                                <td className="text-end">
                                                  <BsThreeDotsVertical/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="card-footer d-flex align-items-center justify-content-center">
                                            <nav>
                                                <ul className="pagination">

                                                    <li className="page-item disabled" aria-disabled="true"
                                                        aria-label="« Previous">
                                                        <span className  = "page-link bg-dark text-light bg-primary" aria-hidden="true">‹</span>
                                                    </li>


                                                    <li className="page-item active" aria-current="page"><span
                                                        className="page-link page-link bg-dark text-light bg-primary">1</span></li>
                                                    <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                    >2</a>
                                                    </li>
                                                    <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                    >3</a>
                                                    </li>
                                                    <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                    >4</a>
                                                    </li>
                                                    <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                    >5</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className  = "page-link bg-dark text-light bg-primary"

                                                           rel="next" aria-label="Next »">›</a>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Admin_Footer/>
            
        </div>
    );
};

export default TopicCateogries;