import React, { useEffect } from "react";

// Css
// import "./MemberCard.css";

// Routes
import { Link, useParams } from "react-router-dom";

// Bootstrap
import {
  Button,
  Container,
  InputGroup,
  Form,
  Row,
  Col,
  Image,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Popover,
} from "react-bootstrap";
//Icons
import { Icon } from "@iconify/react";

import { FiChevronLeft, FiSearch } from "react-icons/fi";
import { RiAccountCircleFill } from "@react-icons/all-files/ri/RiAccountCircleFill";
import {
  BsFillPersonFill,
  BsThreeDotsVertical,
  BsChatLeftDotsFill,
  BsFillCalendarEventFill,
} from "react-icons/bs";
import { IoPersonAddSharp } from "react-icons/io5";
import { IoIosRemoveCircle } from "react-icons/io";
import { CgProfile } from "react-icons/cg";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  clearAttendeesDetails,
  eventState,
  getAttendeesDetails,
  sendFriendRequestToAttendeeAsync,
  setSetSendRequestUserIndex,
} from "../Page Component/reduxSlice";

// Components
import CalendarModals from "../../../../Test/components/MyGroup/GroupModals/CalendarModals/App";
import ChatModals from "../../../../Test/components/MyGroup/GroupModals/ChatModals/App";
import ProfileModal from "../../../../Test/components/MyGroup/GroupModals/ProfileModals/App";

// Other laibrary
import moment from "moment";
import {
  clearFriendIds,
  getFriendsIdAsync,
  setFriendId,
  userState,
} from "../../User/reduxSlice";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import { removeFriendHandlerAPI } from "../../User/Notification/reduxAPI";
import {
  notificationState,
  removeFriendHandlerAsync,
} from "../../User/Notification/reduxSlice";
import {
  resetEventLogonLink,
  setHideLogin_Register,
  setRedirectLink,
} from "../../Navbar/reduxSlice";
import {
  getProfileDataAsync,
  attendeeState,
  setLoadingState,
  eventAttendeeMatchMakingAsync,
  SetMatchAttendee,
} from "./reduxSlice";
import { setLogoLink } from "../../Navbar/reduxSlice";
import { MdPendingActions } from "react-icons/md";

export default function EventAttendeesList() {
  const dispatch = useDispatch();
  const { companyName, eventNameParams } = useParams();
  const { findingMatch, matchAttendee } = useSelector(attendeeState);
  const events = useSelector(eventState);

  const {
    sendingRequest,
    sentRequest,

    eventPageNav,
    eventActionLoading,
    loading,
    eventData: {
      description,
      draft,
      eventEndsAt,
      eventGallery,
      eventMode,
      eventName,
      eventStartsAt,
      eventType,
      id,
      groupId: groupIdDb,
      link,
      location,
      subscriberUid,
      eventLogo,
      eventLogoRedirectLink,

      attendeesId,
    },
    groupData: { groupGallery, groupName, description: groupDescription },
    showRegistrationForm,
    formData,
  } = useSelector(eventState);

  useEffect(() => {
    if (eventLogo && eventLogoRedirectLink) {
      dispatch(setLogoLink({ value: eventLogo }));
      dispatch(setRedirectLink({ value: eventLogoRedirectLink }));
    }

    return () => {
      dispatch(resetEventLogonLink({}));
    };
  }, [dispatch, eventLogo, eventLogoRedirectLink]);

  const popover = (
    <Popover id="popover-basic" className="bg-dark text-light border">
      <Popover.Body className="text-light">
        For better results, update your interests by editing profile.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Container className="py-5">
        <p className="my-0">{moment(eventStartsAt?._seconds).format("llll")}</p>
        <h4 className="mt-0">{eventName}</h4>
        <Link
          className="text-decoration-none p-0 text-light"
          to={`/${companyName}/${eventNameParams}`}
        >
          <FiChevronLeft />
          Back to event
        </Link>
        <Container
          className="border rounded mt-5 pt-4 px-0"
          style={{ width: "90%" }}
        >
          <div className="d-flex justify-content-between align-items-center px-4">
            <h5>Attendees</h5>
            {matchAttendee ? (
              <Button
                variant="quaternary"
                size="sm"
                onClick={() => {
                  dispatch(SetMatchAttendee({ value: false }));
                  dispatch(
                    getAttendeesDetails({ companyName, eventNameParams })
                  );
                }}
                className="text-decoration-none  d-flex justify-content-between align-items-center "
              >
                All Attendees
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={popover}
                >
                  <Icon
                    className="float-end ms-2 "
                    icon="entypo:info-with-circle"
                  />
                </OverlayTrigger>
              </Button>
            ) : (
              <Button
                variant="quaternary"
                size="sm"
                onClick={() => {
                  dispatch(eventAttendeeMatchMakingAsync({}));
                }}
                className="text-decoration-none  d-flex justify-content-between align-items-center "
              >
                {findingMatch ? "Matching..." : "My Match"}

                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={popover}
                >
                  <Icon
                    className="float-end ms-2 "
                    icon="entypo:info-with-circle"
                  />
                </OverlayTrigger>
              </Button>
            )}
          </div>
          <InputGroup className="my-2 px-4">
            <InputGroup.Text
              id="basic-addon1"
              className="bg-dark border-end-0 text-light "
            >
              <FiSearch />
            </InputGroup.Text>
            <Form.Control
              className="border-start-0 ps-0 me-0 bg-dark  text-light shadow-none"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
          </InputGroup>
          <hr />
          <MembersCard />
        </Container>
      </Container>
    </>
  );
}

export async function getFriendsIdSnapshot(docId, dispatch) {
  const notificationsRef = collection(db_firestore, `users/${docId}/friends`);

  const notificationQuery = query(notificationsRef);

  return onSnapshot(notificationQuery, async (querySnapshot) => {
    dispatch(clearFriendIds({}));

    querySnapshot.forEach(async (doc) => {
      dispatch(
        setFriendId({
          value: doc.data().friendDocId,
        })
      );
    });
  });
}

const MembersCard = () => {
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [showChat, setShowChat] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);

  const { companyName, eventNameParams } = useParams();

  const dispatch = useDispatch();

  const {
    sendRequestUserIndex,
    sendingRequest,
    sentRequest,
    loadingAttendees,
    joinedAttendeesFound,
    joinedAttendees,
  } = useSelector(eventState);

  const { friendsId, docId, userAvl } = useSelector(userState);
  const { profileDetails } = useSelector(attendeeState);

  const { showNotification, notifications } = useSelector(notificationState);

  useEffect(() => {
    dispatch(setHideLogin_Register({ value: true }));

    dispatch(getAttendeesDetails({ companyName, eventNameParams }));
    getFriendsIdSnapshot(docId, dispatch);
    return () => {
      dispatch(clearAttendeesDetails({}));
    };
  }, [dispatch, companyName, eventNameParams, docId]);

  const handleShowCalendar = () => {
    setShowCalendar(true);
  };
  const handleShowChat = () => {
    setShowChat(true);
  };
  function handlerShowProfile(id) {
    setShowProfile(true);
    dispatch(getProfileDataAsync({ docId: id }));
  }
  const handleCloseChat = () => setShowChat(false);
  const handleCloseCalendar = () => setShowCalendar(false);
  const handleCloseProfile = () => setShowProfile(false);

  return (
    <div>
      <div className="text-center my-3 px-3">
        {loadingAttendees ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <>
            {joinedAttendeesFound ? (
              <>
                {joinedAttendees.map(
                  (
                    { firstName, lastName, id, profileUrl, isRequestSent },
                    index
                  ) => {
                    if (id === docId) {
                      return null;
                    }

                    let isMYFriend = friendsId.includes(id);

                    const requestHandler = () => {
                      dispatch(setSetSendRequestUserIndex({ index }));

                      dispatch(
                        sendFriendRequestToAttendeeAsync({ friendDocId: id })
                      );
                    };

                    const removeFriendHandler = () => {
                      dispatch(removeFriendHandlerAsync({ friendDocId: id }));
                    };

                    return (
                      <div className="d-flex align-items-center border-bottom py-3 my-2">
                        <div className="px-3">
                          <Image
                            src={profileUrl}
                            style={{
                              aspectRatio: "1/1",
                              maxHeight: "30px",
                            }}
                            className="rounded-circle shadow-sm img-fluid"
                          />
                        </div>
                        <div className="text-start  px-0">
                          <h6 className="my-0 ">
                            {firstName} {lastName}
                          </h6>
                        </div>
                        <div className="ms-auto">
                          {isMYFriend ? (
                            <>
                              <div className="d-md-none d-lg-none">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="link"
                                    className="shadow-none text-light"
                                    id="dropdown-basic"
                                  >
                                    <BsThreeDotsVertical />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="bg-dark text-light">
                                    <Dropdown.Item
                                      className="text-light"
                                      onClick={handlerShowProfile}
                                    >
                                      Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-light"
                                      onClick={handleShowChat}
                                    >
                                      {" "}
                                      Start Chat
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-light"
                                      onClick={handleShowCalendar}
                                    >
                                      Add to Calendar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-light"
                                      onClick={removeFriendHandler}
                                    >
                                      Disconnect
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="d-none d-md-flex">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Profile
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    value={id}
                                    onClick={() => handlerShowProfile(id)}
                                    className="d-flex btn-primary justify-content-center align-items-center text-white mx-1"
                                  >
                                    <CgProfile />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Start Chat
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    onClick={handleShowChat}
                                    className="d-flex btn-primary justify-content-center align-items-center text-white mx-1 "
                                  >
                                    <BsChatLeftDotsFill />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Add to Calendar
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    onClick={handleShowCalendar}
                                    className="d-flex btn-primary justify-content-center align-items-center text-white mx-1"
                                  >
                                    <BsFillCalendarEventFill />
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Disconnect
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    onClick={removeFriendHandler}
                                    className="d-flex btn-primary justify-content-center align-items-center text-white mx-1"
                                  >
                                    <IoIosRemoveCircle />
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            </>
                          ) : (
                            <>
                              {userAvl ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {isRequestSent
                                        ? "Request Sent"
                                        : "Send Request"}
                                    </Tooltip>
                                  }
                                >
                                  {isRequestSent ? (
                                    <Button className="d-flex btn-primary justify-content-center align-items-center text-white mx-1">
                                      <MdPendingActions />
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={requestHandler}
                                      className="d-flex btn-primary justify-content-center align-items-center text-white mx-1"
                                    >
                                      {sendingRequest &&
                                      sendRequestUserIndex === index ? (
                                        <Icon icon="line-md:loading-twotone-loop" />
                                      ) : (
                                        <>
                                          <IoPersonAddSharp />
                                        </>
                                      )}
                                    </Button>
                                  )}
                                </OverlayTrigger>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              "Not Found"
            )}
          </>
        )}
      </div>

      <CalendarModals
        showCalendar={showCalendar}
        onHide={handleCloseCalendar}
      />
      <ChatModals show={showChat} onHide={handleCloseChat} />
      <ProfileModal show={showProfile} onHide={handleCloseProfile} />
    </div>
  );
};
