import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Group_Card from "../../../Main/Components/Groups/List/App";
import EventListFilterSkeleton from "../MyEvents/components/EventListFilterSkeleton";
import NotFoundGroups from "../MyGroup/MyGroup/Organizer/NotFoundGroups";
import { QRCodeSVG } from 'qrcode.react';
import {
  clearViewProfile,
  viewProfilebydocId,
  viewProfileState,
  viewUserGroupsAsync,
} from "./reduxSlice";
import { Image, Placeholder } from "react-bootstrap";

//Todo: Implement infinite loading and image optimization(lazy loading)

const Profile = () => {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const {
    profileUrl,
    firstName,
    lastName,
    loading,
    found,
    groupsDataArr,

    loadingGroup,
    foundGroup,
  } = useSelector(viewProfileState);

  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    const getProfile = async () => {
      await dispatch(viewProfilebydocId({ docId }));
      await dispatch(viewUserGroupsAsync({}));
    };
    getProfile();

    return () => {
      dispatch(clearViewProfile({}));
    };
  }, [dispatch, docId]);

  return (
    <>
      {loading ? (
        <div style={{ height: 1000 }}></div>
      ) : (
        <>
          {found ? (
            <>
              <div className="min-vh-100">
                <div className="page-wrapper">
                  <section className="container py-5">
                    <div className="row">
                      <div className="col-lg-3 me-lg-auto">
                        <div className="card bg-dark text-light border">
                          <div className="card-body p-3 text-center ">
                            <div className="row justify-content-center ">
                              <div className="col-6 ">
                                <div className="img-wrap ">
                                  <div className="img-content">
                                    <Image
                                      fluid
                                      draggable={false}
                                      src={profileUrl}
                                      style={{
                                        aspectRatio: "1 / 1",
                                        objectFit: "cover"
                                      }}
                                      className={` mb-3 rounded-3 border w-100 h-100  ${imgLoading ? "d-none" : null
                                        }`}
                                      onLoad={() => {
                                        setImgLoading(false);
                                      }}
                                      alt="Runolfsdottir Group"
                                    />
                                    {imgLoading ? (
                                      <Placeholder animation="glow">
                                        <Placeholder
                                          className="rounded w-100 h-100 "
                                          style={{
                                            aspectRatio: "1 /1",
                                          }}
                                        />
                                      </Placeholder>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h6 className="m-0 mb-1 mt-2 ">
                              <Link
                                className="text-decoration-none text-light"
                                to="#"
                              >
                                {firstName} {lastName}
                              </Link>
                            </h6>
                          </div>
                          <div className="d-flex flex-column align-items-center mb-3"></div>
                        </div>
                        <div className="px-2 py-5 rounded text-center border my-3">

                          <QRCodeSVG value={docId} />
                        </div>
                      </div>

                      <div className="col-lg-9 ps-lg-5">
                        <h4 className="hero-heading mb-0 fw-bold">
                          Hello, I'm {firstName} {lastName}
                        </h4>

                        <h5 className="my-3 mt-5 fw-bold">Groups</h5>
                        <div className="mb-4">
                          <div className="row">
                            <>
                              {loadingGroup ? (
                                <EventListFilterSkeleton list={3} />
                              ) : (
                                <>
                                  {foundGroup ? (
                                    <>
                                      {groupsDataArr.map((item, index) => (
                                        <div key={item.id}>
                                          <Group_Card
                                            index={index}
                                            groupData={item}
                                          />
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <NotFoundGroups />
                                  )}
                                </>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          ) : (
            // <NotFoundGroups />
            "Not found"
          )}
        </>
      )}
    </>
  );
};

export default Profile;
