import React, { useEffect, useState } from "react";
import { Image, Placeholder } from "react-bootstrap";
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";
import { IoPeople } from "@react-icons/all-files/io5/IoPeople";
import { BsFillPersonFill } from "@react-icons/all-files/bs/BsFillPersonFill";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupIdAsync,
  groupOrganizerDetailsAsync,
  groupState,
} from "../MyGroupOnBoard/reduxSlice";
import {
  clearViewProfile,
  viewProfilebydocId,
  viewProfileState,
} from "../../Profile/reduxSlice";
import {
  clearGroupsDataArr,
  clearOrganizedGroupsDataArr,
  groupsListState,
  setFound,
} from "../../../../Main/Components/Groups/Card/reduxSlice";
import { useParams } from "react-router-dom";

const Banner = () => {
  //loading
  const [imgLoading, setImgLoading] = useState(true);

  const { loading, groupData, found } = useSelector(groupState);
  const { companyName, groupNameParams } = useParams();

  const {
    groupName,
    groupType,
    subscriberUid,
    location,
    groupGallery,
    totalMember,
  } = groupData;
  const {
    groupsDataArr,
    organizer,
    loading: fetchLoading,
  } = useSelector(groupsListState);
  const {
    generatedGroupId,
    groupOrganizerDetailsLoading,
    groupOrganizerDetails,
    foundGroupOrganizerDetails,
  } = useSelector(groupState);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      groupOrganizerDetailsAsync({ companyName, groupNameParams })
    );
    return () => {
      dispatch(clearOrganizedGroupsDataArr({}));
    };
  }, [dispatch, organizer, companyName, groupNameParams]);
  //User Data
  return (
    <div>
      <div>
        <div className="container pt-5 pb-3">
          <div className="row">
            <div className="col-md-6">
              <div className="img-wrap ">
                <div className="img-content">
                  <Image
                    fluid
                    draggable={false}
                    src={groupGallery}
                    style={{
                      aspectRatio: "16 / 10",
                    }}
                    className={`rounded w-100 ${imgLoading ? "d-none" : null}`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="rounded w-100 h-100 "
                        style={{
                          aspectRatio: "16 / 10 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h1 className="fs-2 mb-2 mt-2 mt-lg-0 mb-3 fw-bolder">
                {groupName}
              </h1>
              <div className="row align-items-center mb-2">
                <div className="col-auto">
                  <span className=" fs-3">
                    <IoLocationSharp />
                  </span>
                </div>
                <div className="col fs-6">{location}</div>
              </div>

              <div className="row align-items-center mb-2">
                <div className="col-auto">
                  <span className=" fs-3">
                    <IoPeople />
                  </span>
                </div>
                <div className="col fs-6">
                  {totalMember} Members -
                  <span className="fst-italic">{groupType} group</span>
                </div>
              </div>

              <div className="row align-items-center mb-2">
                <div className="col-auto">
                  <span className="fs-3">
                    <BsFillPersonFill />
                  </span>
                </div>
                <div className="col fs-6">
                  Organized by{" "}
                  <strong>
                    {groupOrganizerDetails.firstName}{" "}
                    {groupOrganizerDetails.lastName}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
