import React, { useState } from "react";

// Bootstrap
import { Form, Image, InputGroup, Placeholder, Button } from "react-bootstrap";

// Redux
import { useSelector } from "react-redux";
import { createGroupState } from "../reduxSlice";

const GroupGallery = ({ groupGallery, nextPage, backPage }) => {
  const { preViewFiles } = useSelector(createGroupState);

  //loading
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <div className="page-wrapper">
      <section className="">
        <h1 className="ps-md-5">Group Gallery</h1>
        <form>
          <div className="row justify-content-center align-items-start gap-1 mx-0 px-0">
            <div className="col-md-8 order-2 order-md-0">
              <div className="input-icon mb-3 place_autocomplete_container">
                <Form validated className="">
                  <InputGroup>
                    <Form.Control
                      id="images_group"
                      onChange={groupGallery}
                      type="file"
                      multiple={true}
                      placeholder="Upload Images, videos, gif"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      className="border-start-0 bg-dark text-light d-none "
                    ></Form.Control>
                    <label for="images_group" className="w-100">
                      {preViewFiles?.length <= 0 ? (
                        <div className="img-content  ">
                          <Image
                            draggable={false}
                            style={{
                              aspectRatio: "16 / 10",
                            }}
                            className={`rounded shadow-sm w-100 ${imgLoading ? "d-none" : null
                              }`}
                            onLoad={() => {
                              setImgLoading(false);
                            }}
                            src={"https://picsum.photos/300/200"}
                            alt="Runolfsdottir Group"
                          />
                          {imgLoading ? (
                            <Placeholder animation="glow">
                              <Placeholder
                                className="rounded w-100 h-100 "
                                style={{
                                  aspectRatio: "16 / 10 ",
                                }}
                              />
                            </Placeholder>
                          ) : null}
                        </div>
                      ) : null}

                      <div className="img-wrap ratio-16-9 w-100">
                        {preViewFiles.map((tempImg) => (
                          <div className="img-content ">
                            <Image fluid src={tempImg} />
                          </div>
                        ))}
                      </div>
                    </label>
                  </InputGroup>
                </Form>
                <div className="mt-3">
                  <Button onClick={backPage}>Back</Button>
                  <Button
                    className="btn btn-primary float-end"
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-3 border shadow-sm p-3 rounded">
              <h6>Add an image</h6>
              <p>Upload a photos or images (Ratio 16:9) to give members a better feel for the event.
                Optional for home page - add image size 1920x760px for full stretched image.</p>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupGallery;
