import React, { useEffect, useState } from "react";
import Member_Card from "../../MyGroup_Props/Members_Card/App";
import { useDispatch, useSelector } from "react-redux";
import { groupMemberAsync, groupState } from "../../MyGroupOnBoard/reduxSlice";
import { rootPageState } from "../../../../../Main/Routes/reduxSlice";
import { userState } from "../../../../../Main/Components/User/reduxSlice";
import { useParams } from "react-router-dom";
import { MdCheck, MdContentCopy } from "react-icons/md";

//test data
const data = [
  { id: 0, name: "Aliya Sporer", role: "Organizer" },
  { id: 1, name: "John Doe", role: "Member" },
  { id: 2, name: "Aliya Sporer", role: "Member" },
  { id: 3, name: "Aliya Sporer", role: "Organizer" },
];

const All_Member = () => {
  const [copied, setCopied] = useState(false);
  //Redux
  const dispatch = useDispatch();
  const {
    groupMemberLoading,
    groupMemberDataArr,
    foundGroupMember,
    generatedGroupId,
  } = useSelector(groupState);
  const { userAvl } = useSelector(userState);
  const { companyName, groupNameParams } = useParams();
  useEffect(() => {
    if (userAvl) {
      dispatch(groupMemberAsync({ companyName,groupNameParams }));
    }
  }, [dispatch, userAvl, generatedGroupId, companyName, groupNameParams]);

  //COPY GROUP LINK
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <div>
      {
        <div>
          {groupMemberDataArr?.length > 0 ? (
            groupMemberDataArr.map((member) => (
              <Member_Card
                key={member.id}
                name={member.firstName + " " + member.lastName}
                role="member"
              />
            ))
          ) : (
            <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-2 mb-4">
              <div className="d-flex align-items-center">
                <div onClick={copy} className=" fs-6 mb-2 mt-2">
                  <small>
                    No Member Found{" "}
                    <span className="text-tertiary fw-bold">Share</span>
                  </small>
                </div>
                <div onClick={copy} className="">
                  {copied ? (
                    <div className="border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2">
                      <MdCheck />
                    </div>
                  ) : (
                    <div className="border px-1 rounded-3 fs-6 mt-2 mb-2 mx-2">
                      <MdContentCopy />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default All_Member;
