import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addOwnCalender, createCalender} from "./reduxApi";
import {pushErrorNotification} from "../../../Main/Components/Error/reduxSlice";
import {Icon} from "@iconify/react";

const initialState = {
    calendarLink: "",
    //CREATE OWN CALENDAR
    meetingName: "",
    meetingLocation: "",
    meetingDescription: "",
    meetingLink: "",
    duration: "",
    customDuration: "",

}
//Add own Calendar
export const addCalendarAsync = createAsyncThunk(
    "calendar/addCalendar",
    async ({
               calendarLink
           },
           {dispatch, getState, rejectWithValue}
    ) => {
        try {
            await addOwnCalender(
                calendarLink
            )
        } catch (error) {

            console.log(error)

            return rejectWithValue(error);
        }
    }
)

//Create own Calendar
export const createCalendarAsync = createAsyncThunk(
    "calendar/createCalendar",
    async ({
               meetingName,
               meetingLocation,
               meetingDescription,
               meetingLink,
               duration,
               customDuration
           },
           {dispatch, getState, rejectWithValue}
    ) => {
        try {
            await createCalender(
                meetingName,
                meetingLocation,
                meetingDescription,
                meetingLink,
                duration,
                customDuration
            )
        } catch (error) {

            console.log(error)

            return rejectWithValue(error);
        }
    }
)


export const addCalenderSlice = createSlice({
    name: 'calender',
    initialState,
    reducers: {
        setCalendarLink: (state, action) => {
            state.calendarLink = action.payload.value;
        },
        setMeetingName: (state, action) => {
            state.meetingName = action.payload.value
        },
        setMeetingLocation: (state, action) => {
            state.meetingLocation = action.payload.value;
        },
        setMeetingDescription: (state, action) => {
            state.meetingDescription = action.payload.value;
        },
        setMeetingLink: (state, action) => {
            state.meetingLink = action.payload.value
        },
        setDuration: (state, action) => {
            state.duration = action.payload.value
        },
        setCustomDuration: (state, action) => {
            state.customDuration = action.payload.value
        },

        //SET HOURS
    }
})

export const {
    setCalendarLink,
    setDuration,
    setMeetingLink,
    setMeetingDescription,
    setMeetingName,
    setMeetingLocation,
    setCustomDuration
} = addCalenderSlice.actions;

export const calendarState = (state) => state.addCalendar

export default addCalenderSlice.reducer