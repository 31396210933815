import React, {useState} from 'react';

// other laibrary
import { useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom'

// State management (Redux)
import { useSelector, useDispatch } from "react-redux";
import {setName,
    setDescription,
    setMonthlyPrice,
    setYearlyPrice,
    setTrialPeriod,
    setOrder,
    setPaypalPlanID_M,
    setPaypalPlanID_Y,
    setGroupsSubscribeCreate,
    setMaxSponsors} from './reduxSlice'
import {setFirstName} from "../../../../../../Main/Components/auth/Register/reduxSlice";


const CreatePlan = () => {



    // assigning
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e) => {


    };

    return (
        <div className="min-vh-100">
            <div className="page-wrapper">
                <div className="container ">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <Link className="page-pretitle" to = "/admin/plan">
                                    Plans
                                </Link>

                                <h1 className="page-title">
                                    Create Plan
                                </h1>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <form>
                                    <input type="hidden"/>
                                    <div className="bg-dark text-light card p-3 mb-4">
                                        <div className="row">
                                            <div className="col-12 bg-dark text-light card-body">

                                                <div className="form-group mb-4 required">
                                                    <label className="mb-1">Name</label>
                                                    <input
                                                        type="text" className="form-control bg-dark text-light" required=""/>
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label className="mb-1">Description</label>
                                                    <input type="text" className="form-control bg-dark text-light"  />
                                                </div>

                                                <div className="row mb-4">
                                                    <div className="col-6">

                                                        <div className="form-group required">
                                                            <label className="mb-1">Monthly Price</label>
                                                            <input  type="number" step="0.01" className="form-control bg-dark text-light" />
                                                            <small className="form-text text-muted">Set this 0 if you want this plan to be free</small>
                                                        </div>

                                                    </div>

                                                    <div className="col-6">

                                                        <div className="form-group required">
                                                            <label className="mb-1">Yearly Price</label>
                                                            <input  autoComplete="false" type="number" step="0.01" className="form-control bg-dark text-light"/>
                                                            <small className="form-text text-muted">Set this 0 if you want this plan to be free</small>

                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="form-group mb-4 required">
                                                    <label className="mb-1">Trial Period</label>
                                                    <input  type="number" min="0" step="1" className="form-control bg-dark text-light"/>
                                                    <small className="form-text text-muted">Set this as 0 if you want to disable trial period</small>
                                                </div>

                                                <div className="form-group mb-4 required">
                                                    <label className="mb-1">Order</label>
                                                    <input  type="number" min="0" step="1" className="form-control bg-dark text-light" />
                                                    <small className="form-text text-muted">This is required for ordering plans (from left to right) at checkout page</small>
                                                </div>

                                                <hr></hr>

                                                    <h5 className="mb-3">Paypal</h5>
                                                    <div className="row">
                                                        <div className="col-12 col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-1" >Paypal Plan ID (Monthly)</label>
                                                                <input  type="text"
                                                                       className="form-control bg-dark text-light"
                                                                       placeholder="Paypal Plan ID"  />
                                                                <small className="form-text text-muted">Please
                                                                    check the documentation to find how you can
                                                                    get Paypal Plan ID</small>
                                                                <Link to="#" target="_blank">Click here</Link>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-lg-6">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-1" >Paypal Plan ID (Yearly)</label>
                                                                <input  type="text"
                                                                       className="form-control bg-dark text-light"
                                                                       placeholder="Paypal Plan ID"/>
                                                                <small className="form-text text-muted">Please
                                                                    check the documentation to find how you can
                                                                    get Paypal Plan ID</small>
                                                                <Link to = "#" target="_blank">Click here</Link>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="form-group mb-4">
                                                                <label className="mb-1"
                                                                        >Paypal Webhook URL</label>
                                                                <input type="text" className="form-control bg-dark text-light"
                                                                       readOnly=""/>
                                                                <small className="form-text text-muted">Please
                                                                    use this url for Webhook Notification</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <hr></hr>

                                                        <h5 className="mb-3">Features</h5>

                                                        <div className="form-group mb-4 required">
                                                            <label className="mb-1">How many groups can subscriber create</label>

                                                            <input type="number" min="-1"
                                                                   step="1" className="form-control bg-dark text-light"
                                                                   placeholder="Ex. 3"/>

                                                            <small className="form-text text-muted">Set -1 to
                                                                make it unlimited</small>
                                                        </div>

                                                        <div className="form-group mb-4">
                                                            <label className="mb-1">Can use communication tools</label>
                                                            <div className="form-check form-switch">
                                                                <input type="hidden" value="0"/>
                                                                <input className="form-check-input"
                                                                       id="communicationTools" type="checkbox"
                                                                       value="1"/>
                                                                <label
                                                                    className="form-check-label text-white">Yes</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-4">
                                                            <label className="mb-1">Can
                                                                access the email addresses of attendees</label>
                                                            <div className="form-check form-switch">
                                                                <input type="hidden"
                                                                       value="0"/>
                                                                <input className="form-check-input"
                                                                       type="checkbox" value="1"/>
                                                                <label
                                                                    className="form-check-label">Yes</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-4">
                                                            <label className="mb-1">Can
                                                                access the custom reports</label>
                                                            <div className="form-check form-switch">
                                                                <input type="hidden"
                                                                       name="features[can_access_custom_reports]"
                                                                       value="0"/>
                                                                <input className="form-check-input"
                                                                       name="features[can_access_custom_reports]"
                                                                       id="canAccessCustomReports"
                                                                       type="checkbox" value="1"/>
                                                                <label
                                                                    className="form-check-label text-white"
                                                                     >Yes</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-4">
                                                            <label className="mb-1">Can
                                                                display sponsors on event pages</label>
                                                            <div className="form-check form-switch">
                                                                <input type="hidden" value="0"/>
                                                                <input className="form-check-input" id="canDisplaySponsors" type="checkbox" value="1"/>
                                                                <label
                                                                    className="form-check-label text-white">Yes</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-4">
                                                            <label className="mb-1">Maximum sponsors count</label>

                                                            <div className="form-group">
                                                                <input type="number" min="-1" step="1"
                                                                       className="form-control bg-dark text-light" placeholder="Ex. 6"
                                                                       required=""/>
                                                                <small className="form-text text-muted">Set -1
                                                                    to make it unlimited</small>
                                                            </div>
                                                        </div>

                                                        <div className="text-end">
                                                            <button  onClick={handleSubmit} type="submit" className="btn btn-primary">
                                                                Save plan
                                                            </button>
                                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default CreatePlan;