import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEventState,
  setSendUpdateMail,
  setSendUpdateNotification,
} from "../../reduxSlice";

const UpdateEmail = () => {
  const dispatch = useDispatch();

  const { sendUpdateMail, sendUpdateNotification } =
    useSelector(createEventState);

  return (
    <div className="py-3">
      <div className="d-flex justify-content-evenly">
        <div className="d-flex align-items-center border-bottom pb-1 ">
          <p className="mb-0">Send Update Mail</p>

          <div className="form-check form-switch ms-2 ">
            <input
              className="form-check-input "
              checked={sendUpdateMail}
              onChange={(e) => {
                dispatch(setSendUpdateMail({ value: e.target.checked }));
              }}
              type="checkbox"
            />
          </div>
        </div>
        <div className="d-flex align-items-center border-bottom pb-1">
          <p className="mb-0">Send Update Notification</p>

          <div className="form-check form-switch ms-2 ">
            <input
              className="form-check-input"
              checked={sendUpdateNotification}
              onChange={(e) => {
                dispatch(
                  setSendUpdateNotification({ value: e.target.checked })
                );
              }}
              type="checkbox"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmail;
