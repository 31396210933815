import React, {useState} from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import {Link} from "react-router-dom";
import AlertModal from "../../CommonComponents/AlertModal/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

const BlogCateogries = () => {
    const [showModel , setShowModel] = useState(false)

    const ShowModel = () => {
        setShowModel(true)
    }
    return (
        <div className='min-vh-100'>
            <AdminNavigation/>
            
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                  <small>Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Blog Categories
                                </h1>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <Link to = "createblogcateogries"
                                   className="btn btn-primary">
                                    Create new
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards ">
                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bolder">Categories</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="blog_categories">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">10</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">sit</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">sit</p>
                                                </td>
                                                <td>2022-07-10</td>
                                                <td className="text-end">
                                                    <Link to ="#" className = "text-decoration-none">
                                                        Edit
                                                    </Link>
                                                    <span
                                                       onClick={ShowModel}
                                                       className="ms-2 text-danger delete-confirm text-decoration-none"
                                                    >
                                                        Delete
                                                    </span>
                                                </td>

                                                <AlertModal
                                                    show={showModel}
                                                    onHide={() => setShowModel(false)}
                                                />
                                            </tr>
    
                                            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Admin_Footer/>
        </div>
    );
};

export default BlogCateogries;