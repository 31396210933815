import React from "react";
// Routes
import { Link } from "react-router-dom";

import { Button, Modal, Row, Col, Image, CloseButton, Container, Spinner } from "react-bootstrap";
import {
  BsLinkedin,
  BsFillPersonPlusFill,
  BsInstagram,
  BsTwitter,
  BsFacebook,
  BsYoutube,
} from "react-icons/bs";

import { TbWorld } from "react-icons/tb";

//redux
import { useSelector } from "react-redux";
import { attendeeState } from "../../../../../Main/Components/Events/Attendees/reduxSlice"

const ProfileModal = ({ show, onHide }) => {
  const { profileDetails } = useSelector(attendeeState)

  return (
    <>
      {profileDetails ?(
      <div>
        <Modal
          show={show}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          onHide={onHide}
          className='px-0'
        >
          <Modal.Body className="bg-dark text-light p-0 rounded overflow-hidden rounded border-0" >
            <Row className="mx-0 gap-0 row-cols-1 row-cols-lg-2">
              <Col className="col-12 col-md-5 col-lg-3 px-3 d-flex justify-content-between flex-column bg-primary px-2 py-4  shadow-sm rounded-start">
                <CloseButton className="ms-auto me-0 d-block d-md-none " variant="white" onClick={onHide}></CloseButton>
                <Image
                  fluid
                  draggable={false}
                  style={{
                    aspectRatio: "4 / 4",
                    minWidth: '80%',
                    maxWidth: '80%'
                  }}
                  className={`rounded-circle mx-auto shadow-sm`}
                  src={profileDetails.data.profileUrl}
                  alt={profileDetails.data.firstName}
                />
                <Container className="d-flex justify-content-center mt-3 mt-md-auto px-0 fs-5">
                  {profileDetails.data.twitter ? (<Link
                    to={profileDetails.data.twitter}
                    className="bg-transparent text-white shadow-none me-2"
                  >
                    <BsTwitter />
                  </Link>) : null}
                  {profileDetails.data.linkedin ? (<Link
                    to={profileDetails.data.linkedin}
                    className="bg-transparent text-white shadow-none me-2"
                  >
                    <BsLinkedin />
                  </Link>) : null}
                  {profileDetails.data.instagram ? (<Link
                    to={profileDetails.data.instagram}
                    className="bg-transparent text-white shadow-none me-2"
                  >
                    <BsInstagram />
                  </Link>) : null}
                  {profileDetails.data.facebook ? (<Link
                    to={profileDetails.data.facebook}
                    className="bg-transparent text-white shadow-none me-2"
                  >
                    <BsFacebook />
                  </Link>) : null}
                </Container>
              </Col>
              <Col className="col-12 col-md-7 col-lg-9 px-4 py-3">
                <CloseButton className="shadow-none ms-auto me-0 d-none d-md-block" onClick={onHide}></CloseButton>
                <p className="mb-3"><span className="fw-bold">Name:</span> {profileDetails.data.firstName ? profileDetails.data.firstName + " " + profileDetails.data.lastName : "Not given"}</p>
                <p className="mb-3"><span className="fw-bold">Email:</span> {profileDetails.data.email ? profileDetails.data.email : "Not given"}</p>
                <p className="mb-3"><span className="fw-bold">Contact No:</span> {profileDetails.data.phoneNumber ? profileDetails.data.phoneNumber : "Not given"}</p>
                <p className="mb-3"><span className="fw-bold">Company Name:</span> {profileDetails.data.companyName ? profileDetails.data.companyName : "Not given"}</p>
                <p className="mb-3"><span className="fw-bold">Designation:</span> {profileDetails.data.headLine ? profileDetails.data.headLine : "Not given"}</p>
                <p className="mb-3"><span className="fw-bold">Description:</span> {profileDetails.data.bio ? profileDetails.data.bio : "Not given"}</p>
              </Col>
            </Row>
          </Modal.Body>
          {/* <Modal.Footer className="bg-dark text-light">
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer> */}
        </Modal>
      </div>
    ):null}
    </>

  );
};

export default ProfileModal;
