import React, {useState} from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";
import All_Member from "./All_Member/App";
import Organizer_Member from "./Organizers/App";
import {useSelector} from "react-redux";
import {groupState} from "../MyGroupOnBoard/reduxSlice";


const Member = () => {
    const [page, setPage] = useState(0);
    //Redux
    const {
        groupData: {
            memberUid,
        },
    } = useSelector(groupState);

    //Navigation
    const PageDisplay = () => {
        if (page === 0) {
            return <All_Member/>;
        } else {
            return <Organizer_Member/>
        }
    };

    return (
        <div>
            <div id="members" className="container py-5">
                <div className="row align-items-start">
                    <div className="col-lg-3 mb-4">
                        <div className="card border bg-dark text-light card-fluid">
                            <nav className="nav side-nav flex-row flex-nowrap flex-lg-column flex-lg-wrap">
                                <span onClick={() => setPage(0)}
                                      className={page === 0 ? "nav-link fs-6 fw-bold text-primary cursor-event" : "nav-link fs-6  fw-bold text-light cursor-event"}
                                >
                                    All Members ({memberUid?.length})
                                </span>
                                <span onClick={() => setPage(2)}
                                      className={page === 2 ? "nav-link fs-6 fw-bold text-primary cursor-event" : "nav-link fs-6  fw-bold text-light cursor-event"}>
                                    Organizers (1)
                                </span>
                            </nav>
                        </div>
                    </div>

                    <div className="col-md-9 col-xs-12">
                        <div className="list-group card-list-group border">
                            <div className="list-header p-3">
                                <h5 className='fw-bold '>All Members</h5>
                                <InputGroup>
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        className="border-end-0 bg-dark text-light"
                                    >
                                        <BsSearch/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="basic-addon1"
                                        className="border-start-0 bg-dark text-light"
                                    ></Form.Control>

                                </InputGroup>
                            </div>

                            {PageDisplay()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Member;