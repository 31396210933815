import {
  arrayRemove,
  increment,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db_firestore } from "../../../../firebase.config";

export async function viewProfileAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return { notFound: true };
  }
}

export async function getUserGroupsAPI(groupsId, firstName, lastName) {
  let groups = [];
  for (let index = 0; index < groupsId.length; index++) {
    const groupRef = doc(db_firestore, "groups", groupsId[index]);
    const groupSnap = await getDoc(groupRef);

    if (groupSnap.exists()) {
      groups = [
        ...groups,
        {
          ...groupSnap.data(),
          id: groupsId[index],
          subscriberName: firstName + " " + lastName,
        },
      ];
    } else {
      console.log("this Group id is not exist in db");
    }
  }

  return groups;
}
