import React from "react";

// Css
import "./EventUpdateNotify.css";

// Bootstrap
import { Card, Col, Image, Button, Row } from "react-bootstrap";

// Icons
import { Icon } from "@iconify/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { notificationState, removeNotificationAsync } from "../reduxSlice";

// Other Laibrary
import parse from "html-react-parser";

export default function EventUpdateNotify({ index = undefined }) {
  // var
  const dispatch = useDispatch();

  const { notifications } = useSelector(notificationState);

  if (index === undefined) {
    return null;
  }

  let { id, eventId, eventName, markAsRead } = notifications[index];

  return (
    <>
      <Card className="mx-1 bg-dark text-light my-1 border ">
        <Card.Body className="  align-items-center">
          <Row>
            <Col className="col-11 px-0 ">
              <Card.Title className="fs-6 mb-0 ">
                {eventName} <u>(Updated)</u>
              </Card.Title>
              <Card.Text className="my-0 small ">
                There are few important updates, check the all the sections and
                continue to network and block your schedules.
              </Card.Text>
            </Col>
            <Col className="col-1 d-flex  px-0">
              <Button
                variant="link"
                className=" no_cursor  fs-4 text-tertiary px-1 py-1 d-flex  me-1 shadow-none "
              >
                <Icon
                  className="markAsRead_eventUpdate"
                  icon="charm:tick-double"
                  onClick={() => {
                    dispatch(
                      removeNotificationAsync({
                        notificationId: id,
                      })
                    );
                  }}
                />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
