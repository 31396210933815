import React from 'react';
import Group_Setting_Navigation from "../Group_Setting_Navigation/App";
import {Button} from "react-bootstrap";

const OptionalSeting = () => {
    return (
        <div className="container py-5 min-vh-100 ">
            <div className="row gx-5">


                <div className='col-lg-3'>
                    <Group_Setting_Navigation/>
                </div>

                <form className="col-12 col-lg-8 mb-5 mb-lg-0 mt-lg-5">
                    <h5 className="mb-4 mt-4  fw-bold">Optional
                        Settings</h5>

                    <div className="form-group mt-2 mb-4">
                        <label className="form-label">Facebook</label>
                        <input className="form-control bg-dark text-light" type="text" placeholder="Link"/>
                    </div>

                    <div className="form-group mb-4">
                        <label className="form-label">Instagram</label>
                        <input className="form-control bg-dark text-light" type="text" placeholder="Link"/>
                    </div>

                    <div className="form-group mb-4">
                        <label className="form-label">Twitter</label>
                        <input className="form-control bg-dark text-light" type="text" name="twitter" value=""
                               placeholder="Link"/>
                    </div>

                    <div className="form-group mb-4">
                        <label className="form-label">Linkedin</label>
                        <input className="form-control bg-dark text-light" type="text" name="linkedin" value=""
                               placeholder="Link"/>
                    </div>

                    <div className="form-group mb-4">
                        <label className="form-label">Website</label>
                        <input className="form-control bg-dark text-light" type="text" name="website" value=""
                               placeholder="Link"/>
                    </div>

                    <div className="float-end">
                        <Button type="submit" className="btn btn-primary">Update settings</Button>
                    </div>
                </form>

            </div>
        </div>
    );
};

export default OptionalSeting;

