import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db_firestore, functions } from "../../../../../../firebase.config";

export function getUpdatedEventRoleAPI(eventId) {
  const getUpdatedEventRole = httpsCallable(functions, "getUpdatedEventRole");
  return getUpdatedEventRole({ eventId });
}



export async function addEventRoleAPI(uid, eventId, role) {
  const eventRef = doc(db_firestore, "events", eventId);

  let newUser;
  switch (role) {
    case "sponsor":
      newUser = {
        sponsorsId: arrayUnion(uid),
      };
      break;
    case "boothOwner":
      newUser = {
        boothOwnersId: arrayUnion(uid),
      };
      break;
    case "speaker":
      newUser = {
        speakersId: arrayUnion(uid),
      };
      break;

    default:
      break;
  }

  await updateDoc(eventRef, newUser);
}
export async function removeEventRoleAPI(uid, eventId, role) {
  const eventRef = doc(db_firestore, "events", eventId);

  let newUser;
  switch (role) {
    case "sponsor":
      newUser = {
        sponsorsId: arrayRemove(uid),
      };
      break;
    case "boothOwner":
      newUser = {
        boothOwnersId: arrayRemove(uid),
      };
      break;
    case "speaker":
      newUser = {
        speakersId: arrayRemove(uid),
      };
      break;

    default:
      break;
  }

  await updateDoc(eventRef, newUser);
}


