import React from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

const Orders = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>


        <div className="page-wrapper container">
            <div className="">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col  mt-3 mb-3">
                            <div className="page-pretitle">
                                <small>Overview</small>
                            </div>
                            <h1 className=" h5 fw-bolder page-title">
                                Orders
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-body">
                <div className="">
                    <div className="row row-deck row-cards">
                        <div className="col-12">
                            <form className="card border mb-3 bg-dark text-light" >
                                <div className="card-body">
                                    <strong className="card-title">Filter</strong>
                                    <div className="d-flex">
                                        <div className="form-group">
                                            <input  type="text" className="form-control bg-dark text-light" value=""
                                                   placeholder="Order ID"/>
                                        </div>
                                        <div className="form-group ms-2">
                                            <input   type="text" className="form-control bg-dark text-light"
                                                     placeholder="Transaction ID"/>
                                        </div>
                                        <div className="form-group ms-2">
                                            <input   type="text" className="form-control bg-dark text-light" value=""
                                                   placeholder="Search user"/>
                                        </div>
                                        <div className="form-group ms-2">
                                            <select   data-toggle="select"
                                                    className="form-control bg-dark text-light">
                                                <option selected="" value="">Select Plan</option>
                                                <option value="1">Basic Plan</option>
                                                <option value="3">Startup Plan</option>
                                                <option value="5">Growth Plan</option>
                                            </select>
                                        </div>
                                        <button type="submit" className="btn btn-link">
                                            <i className="fa fa-filter me-2"></i>
                                            Filter
                                        </button>
                                        <a className="btn btn-link">
                                            Clear
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col-12">
                            <div className="card border bg-dark text-light">
                                <div className="card-header text-light">
                                    <h3 className="card-title h6 align-items-center fw-bolder">Orders</h3>
                                </div>
                                <div className="table-responsive border">
                                    <table className="table card-table table-vcenter bg-dark text-light text-nowrap datatable">
                                        <thead>
                                        <tr className="md-fs-6">
                                            <th>ID</th>
                                            <th>Transaction ID</th>
                                            <th>User</th>
                                            <th>Plan</th>
                                            <th>Price</th>
                                            <th>Payment Type</th>
                                            <th>Ordered at</th>
                                        </tr>
                                        </thead>
                                        <tbody className="list" id="orders">
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
            <Admin_Footer/>
        </div>

    );
};

export default Orders;