import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";

const CalendarForm = ({value, confirmed, innerText}) => {
    const [data, setData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        team: "",
        quantity: "",
        textArea: "",
        website: "",
    });

    const [resData, setResData] = useState("");

    const r = /\d+/;
    const hour = Number(innerText.match(r)[0]);

    const start = [value.getFullYear(), value.getMonth(), value.getDate(), hour];

    console.log(start);

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };


    const submitHandler = (e) => {
        e.preventDefault();
    };

    return (
        <Form className="">
            <h1 className="fs-5 bold">Enter Details</h1>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" placeholder="Enter name" className='bg-dark text-light border'/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" className='bg-dark text-light border'/>
                <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Please share anything that will help prepare for our meeting.</Form.Label>
                <Form.Control as="textarea" rows={3} className='bg-dark text-light border'/>
            </Form.Group>

            <Button onClick={submitHandler} className="btn">
                Schedule Event
            </Button>
            {resData.status === "success" ? (
                <div style={{color: "green"}}>Schedule sent to</div>
            ) : null}
        </Form>
    );
};

export default CalendarForm;
