import React from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import {Link} from "react-router-dom";
import Admin_Footer from "../../CommonComponents/Footer/App";

const Pages = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>

            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                   <small>Overview</small>
                                </div>
                                <h1 className="page-title fw-bolder h5">
                                    Pages
                                </h1>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <Link  to = "createpage"
                                   className="btn btn-primary">
                                    Create new
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bold">Pages</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Slug</th>
                                                <th>Status</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="pages">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">1</p>
                                                </td>
                                                <td>
                                                    <Link to = "#"className="mb-0 text-truncate text-decoration-none" target="_blank"
                                                      >
                                                        Terms &amp; Conditions
                                                    </Link>
                                                </td>
                                                <td>
                                                    <p className="mb-0">terms</p>
                                                </td>
                                                <td>
                                                    <div className="badge bg-danger fs-7">
                                                        Disabled
                                                    </div>
                                                </td>
                                                <td>2022-07-13</td>
                                                <td className="text-end">
                                                    <Link  to = "#" className = "text-decoration-none">
                                                        Edit
                                                    </Link>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Admin_Footer/>

        </div>
    );
};

export default Pages;