import React from 'react';
import Group_Setting_Navigation from "../Group_Setting_Navigation/App";
import {Form, InputGroup} from "react-bootstrap";
import {BsSearch} from "react-icons/bs";

//test data
const badge = [
    {id: 0, name: "Italiano"},
    {id: 1, name: "London"},
    {id: 2, name: "Indian Music"},
    {id: 3, name: "Melbourne"},
    {id: 4, name: "Moms of Autistic Kids"}


]

const TopicsSetting = () => {
    return (
        <div className="container py-5 min-vh-100 ">
            <div className="row gx-5 align-items-center">


                <div className='col-lg-3'>
                    <Group_Setting_Navigation/>
                </div>


                <div className="col-12 col-lg-8 mb-5 mb-lg-0">
                    <div>
                        <div className="text-block mb-3">
                            <h3>Selected Interests</h3>
                            <ul className="list-inline">


                                {badge.map((item) => (
                                    <li key={item.id} className="list-inline-item mb-2 fs-5">
                                        <span className="badge bg-primary p-2">{item.name}</span>
                                    </li>
                                ))}


                            </ul>
                        </div>

                        <div className="text-block mb-3">
                            <div className="row d-flex align-items-center mb-3">
                                <div className="col-sm-8">
                                    <h3 className="mb-0">Recommended</h3>
                                </div>
                                <div className="col-sm-4 text-end">
                                    <InputGroup>
                                        <InputGroup.Text
                                            id="basic-addon1"
                                            className="border-end-0 bg-dark text-light"
                                        >
                                            <BsSearch/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon1"
                                            className="border-start-0 bg-dark text-light"
                                        ></Form.Control>

                                    </InputGroup>
                                </div>
                            </div>
                            <ul className="list-inline">

                                {badge.map((item) => (
                                    <li key={item.id} className="list-inline-item mb-2 fs-5">
                                        <span className="badge bg-primary p-2">{item.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopicsSetting;