import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../../../../firebase.config";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";

export function getBoothsAPI(eventId) {
  const getBooths = httpsCallable(functions, "getBooths");
  return getBooths({
    eventId,
  });
}

export function createNewBoothAPI(
  eventId,

  companyName,
  subHeading,
  redirectLink,
  columnType
) {
  const createNewBooth = httpsCallable(functions, "createNewBooth");
  return createNewBooth({
    eventId,

    companyName,
    subHeading,
    redirectLink,
    columnType,
  });
}

export function createBoothImageUrlAPI(
  eventId,

  boothId,
  boothUrl
) {
  const createBoothImageUrl = httpsCallable(functions, "createBoothImageUrl");
  return createBoothImageUrl({
    eventId,

    boothId,
    boothUrl,
  });
}
export function updateBoothAPI(
  eventId,
  boothId,
  companyName,
  subHeading,
  redirectLink,
  columnType
) {
  const updateBooth = httpsCallable(functions, "updateBooth");
  return updateBooth({
    eventId,
    boothId,
    companyName,
    subHeading,
    redirectLink,
    columnType,
  });
}
export function deleteBoothAPI(eventId, boothId) {
  const deleteBooth = httpsCallable(functions, "deleteBooth");
  return deleteBooth({
    eventId,
    boothId,
  });
}

export function createBrouchersinBoothAPI(data) {
  const createBrouchersinBooth = httpsCallable(
    functions,
    "createBrouchersinBooth"
  );
  return createBrouchersinBooth({
    ...data,
  });
}

export function deleteBroucherFromBoothAPI(presentationData) {
  const deleteBroucherFromBooth = httpsCallable(
    functions,
    "deleteBroucherFromBooth"
  );
  return deleteBroucherFromBooth({
    ...presentationData,
  });
}

export function uploadBoothsFiles(file, eventId, boothId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/booths/${boothId}/` + file.name
    );
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export function uploadBoothBrouchersFile(file, eventId, boothId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return

    let uniqueName = Math.random().toString() + "_" + file.name;

    const mountainsRef = ref(
      storage,
      `events/${eventId}/booths/${boothId}/brouchers/` + uniqueName
    );
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteBoothFile(eventId, boothId) {
  // Create a reference to the file to delete
  const sponsorRef = ref(storage, `events/${eventId}/booths/${boothId}`);

  let speakerImgList = [];
  // Find all the prefixes and items.
  listAll(sponsorRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        speakerImgList = [...speakerImgList, itemRef];
        // All the items under listRef.
      });
      if (speakerImgList.length > 0) {
        speakerImgList.map((value) =>
          deleteObject(
            ref(storage, `events/${eventId}/booths/${boothId}/${value.name}`)
          )
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export async function deleteBrouchersUrlFile(brouchersUrl) {
  const brouchersref = ref(storage, brouchersUrl);
  let res = await deleteObject(brouchersref);
  return res;
}
