import React from "react";

// Bootstrap
import { Button, Col, Container,  Row } from "react-bootstrap";

const BlogSection = () => {
  return (
    <Container fluid className="text-center">
      <h3>Stories from Network</h3>
      <p>
        People on Network have fostered community, learned new skills, started
        businesses, and made life-long friends. Learn how.
      </p>
      <Row className="gap-5 justify-content-evenly flex-column flex-md-row">
        <Col className="position-relative px-0 col text-start multiLineLabel">
          <Button
            variant="dark"
            className="position-absolute py-1 px-2 shadow"
            id="onlineBtn"
            style={{ top: "10px", left: "10px",fontSize:"0.75rem"}}
          >
            
            VIRTUAL EVENT
          </Button>
          <img
            src="https://picsum.photos/id/21/400/300"
            className="img-fluid mb-2 rounded-3 w-100"
            alt="..."
          />
          <h6 className="w-100 textMaxLine">
            Alice looked round, eager to see it again, but it puzzled her a good
            deal frightened by this.
          </h6>
          <p className="textMaxLine">
            VERY long claws and a large caterpillar, that was linked into hers
            began to repeat it, but her voice sounded hoarse and strange, and
            the other ladder?--Why, I hadn't cried so much!' said Alice, (she
            had grown to her that she was talking. Alice could hear the words:--
            'I speak severely to my jaw, Has lasted the rest of the other paw,
            'lives a March Hare. 'It was a little hot tea upon its nose. The
            Dormouse slowly opened his eyes very wide on hearing this; but all
            he SAID was, 'Why is a long time together.'
          </p>
        </Col>
        <Col className="position-relative px-0 col text-start multiLineLabel">
        <Button
            variant="dark"
            className="position-absolute  py-1 px-2"
            id="onlineBtn"
            style={{ top: "10px", left: "10px", fontSize: "0.75rem" }}
          >
            
            HYBRID EVENT
          </Button>
          <img
            src="https://picsum.photos/id/22/400/300"
            className="img-fluid mb-2 rounded-3 w-100"
            alt="..."
          />
          <h6 className="w-100 textMaxLine">
            Alice looked round, eager to see it again, but it puzzled her a good
            deal frightened by this.
          </h6>
          <p className="textMaxLine">
            VERY long claws and a large caterpillar, that was linked into hers
            began to repeat it, but her voice sounded hoarse and strange, and
            the other ladder?--Why, I hadn't cried so much!' said Alice, (she
            had grown to her that she was talking. Alice could hear the words:--
            'I speak severely to my jaw, Has lasted the rest of the other paw,
            'lives a March Hare. 'It was a little hot tea upon its nose. The
            Dormouse slowly opened his eyes very wide on hearing this; but all
            he SAID was, 'Why is a long time together.'
          </p>
        </Col>
        <Col className="position-relative px-0 col text-start multiLineLabel">
          <Button
            variant="dark"
            className="position-absolute  py-1 px-2"
            id="onlineBtn"
            style={{ top: "10px", left: "10px", fontSize: "0.75rem" }}
          >
            
            ONLINE EVENT
          </Button>
          <img
            src="https://picsum.photos/id/23/400/300"
            className="img-fluid mb-2 rounded-3 w-100"
            alt="..."
          />
          <h6 className="w-100 textMaxLine">
            Alice looked round, eager to see it again, but it puzzled her a good
            deal frightened by this.
          </h6>
          <p className="textMaxLine">
            VERY long claws and a large caterpillar, that was linked into hers
            began to repeat it, but her voice sounded hoarse and strange, and
            the other ladder?--Why, I hadn't cried so much!' said Alice, (she
            had grown to her that she was talking. Alice could hear the words:--
            'I speak severely to my jaw, Has lasted the rest of the other paw,
            'lives a March Hare. 'It was a little hot tea upon its nose. The
            Dormouse slowly opened his eyes very wide on hearing this; but all
            he SAID was, 'Why is a long time together.'
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default BlogSection;
