import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dark: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const darkModeSlice = createSlice({
  name: "darkMode",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleMode: (state, action) => {
      state.dark = !state.dark;
      let themeStyle = document.documentElement;
      if (state.dark) {
        var body = document.getElementsByTagName("body")[0];
        body.classList.add("TurnTodark");
        themeStyle.style.setProperty("color-scheme", "dark");
      } else {
        var bodyLight = document.getElementsByTagName("body")[0];
        bodyLight.classList.remove("TurnTodark");
        themeStyle.style.setProperty("color-scheme", "light");
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { toggleMode } = darkModeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const darkModeState = (state) => state.darkMode;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = errorState(getState());
//   if (currentValue % 2 === 1) {
//     // dispatch(incrementByAmount(amount));
//   }
// };

export default darkModeSlice.reducer;
