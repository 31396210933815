import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  algoliaAsync,
  navBarState,
  setResult,
  setSearchFound,
} from "../../../../../Main/Components/Navbar/reduxSlice";
import EventCardList from "../../../../../Main/Components/Events/List/App";
import EventListFilterSkeleton from "../../../MyEvents/components/EventListFilterSkeleton";
import NotFoundFilterEvent from "../../../MyEvents/components/NotFoundFilterEvent";

const EventCard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(algoliaAsync({}));
    return () => {
      dispatch(setSearchFound({ value: true }));
      dispatch(setResult({ result: [] }));
    };
  }, [dispatch]);

  const { searchLoading, searchFound, result } = useSelector(navBarState);

  return (
    <div>
      {searchLoading ? (
        <EventListFilterSkeleton list={3} />
      ) : (
        <>
          {searchFound ? (
            <>
              {result.map((event, index) => {
                return (
                  <EventCardList
                    key={event.id}
                    index={index}
                    eventData={event}
                  />
                );
              })}
            </>
          ) : (
            <NotFoundFilterEvent />
          )}
        </>
      )}
    </div>
  );
};

export default EventCard;
