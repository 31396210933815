import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errors: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const errorSlice = createSlice({
  name: "error",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    pushErrorNotification: (state, action) => {
      // action.payload.obj
      state.errors = [...state.errors, action.payload.notify];
    },
    hideNotification: (state, action) => {
      state.errors[action.payload.index].show = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { pushErrorNotification, hideNotification } = errorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const errorState = (state) => state.error;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = errorState(getState());
  if (currentValue % 2 === 1) {
    // dispatch(incrementByAmount(amount));
  }
};

export default errorSlice.reducer;
