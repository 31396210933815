import React, { useState } from "react";
import Counter from "./Counter";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";

import "./swiper.css";
// Bootstrap
import { Image, Placeholder, Button } from "react-bootstrap";

import { BsFillStarFill, BsStar, BsFillTriangleFill } from "react-icons/bs";
import { FaHourglassEnd, FaShareAlt } from "react-icons/fa";
// Routes
import { useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  eventRegistrationFormCheck,
  eventState,
  leaveEventAsync,
  saveEventAsync,
  setEventpageNavPannel,
  unSaveEventAsync,
} from "../reduxSlice";
import { getSavedEventsOfUserAsync, userState } from "../../../User/reduxSlice";
import { setShareLink } from "../../../ShareModal/reduxSlice";

const Banner = ({ handleShareShow }) => {
  const dispatch = useDispatch();
  // Image Loading
  const [imgLoading, setImgLoading] = useState(true);
  const { userAvl, user, savedEvents } = useSelector(userState);

  const {
    eventPageNav,
    eventActionLoading,
    eventData: { id, subscriberUid, attendeesId },
  } = useSelector(eventState);

  let isSaved = savedEvents.includes(id);
  let ismyEvent;
  let going;
  if (userAvl) {
    ismyEvent = user?.uid === subscriberUid;
    going = attendeesId?.includes(user?.uid);
  }
  // State
  const { companyName, eventNameParams } = useParams();
  // State
  const {
    eventData: { eventGallery },
  } = useSelector(eventState);

  return (
    <div className="position-relative" style={{ userSelect: "none" }}>
      <div
        className="flex-column position-absolute d-none d-lg-flex justify-content-center align-items-center start-0 pt-3 px-3 pb-5"
        id="bannerShare"
        style={{ zIndex: 100 }}
      >
        {isSaved ? (
          <BsFillStarFill
            onClick={async () => {
              await dispatch(
                unSaveEventAsync({ companyName, eventNameParams })
              );
              dispatch(getSavedEventsOfUserAsync({}));
            }}
            className="starFill cursor_pointer fs-5 text-white"
          />
        ) : (
          <BsStar
            onClick={async () => {
              await dispatch(saveEventAsync({ companyName, eventNameParams }));
              dispatch(getSavedEventsOfUserAsync({}));
            }}
            className="cursor_pointer fs-5 text-white"
          />
        )}

        <FaShareAlt
          className="cursor_pointer mt-3 fs-5 text-white"
          onClick={() => {
            handleShareShow();
            dispatch(
              setShareLink({
                value: encodeURI(
                  `${window.location.protocol}//${window.location.host}/${companyName}/${eventNameParams}`
                ),
              })
            );
          }}
        />
      </div>

      <div
        className={`${id == "wg9ZthfosdfQ3ywVd0ii" ?"d-none d-md-block":"d-block"}`}
        style={{
          aspectRatio: "16/6",
          objectFit: "contain",
        }}
      >
        <Swiper
          className="mySwiper"
          autoplay={{
            delay: 4500,
          }}
          modules={[Autoplay]}
        >
          {eventGallery?.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <Image
                  draggable={false}
                  style={{
                    aspectRatio: "16/6",
                    objectFit: "contain",
                  }}
                  className={`img-fluid w-100 ${imgLoading ? "d-none" : null}`}
                  onLoad={() => {
                    setImgLoading(false);
                  }}
                  src={img}
                  alt="Banner"
                />
                {imgLoading ? (
                  <Placeholder animation="glow" className="row h-100 w-100">
                    <Placeholder
                      className="w-100 h-100 col-12"
                      style={{
                        aspectRatio: "16/6 ",
                      }}
                    />
                  </Placeholder>
                ) : null}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div
          className={`${id == "wg9ZthfosdfQ3ywVd0ii" ?"d-block d-md-none":"d-none"}`}
        style={{
          aspectRatio: "4/5",
          objectFit: "contain",
        }}
      >
        <Image
          draggable={false}
          style={{
            aspectRatio: "4/5",
            objectFit: "contain",
          }}
          className={`img-fluid w-100 ${imgLoading ? "d-none" : null}`}
          onLoad={() => {
            setImgLoading(false);
          }}
          src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FSlide%204_3%20-%201.png?alt=media&token=e9a3fe74-1e56-4bb7-8f86-ba4d4ab9d3a9"
          alt="Banner"
        />
        {imgLoading ? (
          <Placeholder animation="glow" className="row h-100 w-100">
            <Placeholder
              className="w-100 h-100 col-12"
              style={{
                aspectRatio: "16/6 ",
              }}
            />
          </Placeholder>
        ) : null}
      </div>

      <div
        className="position-absolute rounded d-none d-md-block"
        style={{
          zIndex: 100,
          top: "auto",
          bottom: "18%",
          left: "30%",
          transform: "translateX(-50%)",
        }}
      >
        <Counter />
      </div>
    </div>
  );
};

export default Banner;
