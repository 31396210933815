import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../firebase.config";

import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

export function createNewQnAAPI(eventId, answer, question) {
  const answerHtml = draftToHtml(convertToRaw(answer.getCurrentContent()));

  const createNewQnA = httpsCallable(functions, "createNewQnA");
  return createNewQnA({
    eventId,
    answer: answerHtml,
    question,
  });
}
export function updateQnAAPI(eventId, answer, question, whyAttendId) {
  const answerHtml = draftToHtml(convertToRaw(answer.getCurrentContent()));

  const updateQnA = httpsCallable(functions, "updateQnA");
  return updateQnA({
    eventId,
    answer: answerHtml,
    question,
    whyAttendId,
  });
}
export function getQnAAPI(eventId) {
  const getQnA = httpsCallable(functions, "getQnA");
  return getQnA({
    eventId,
  });
}
export function deleteQnaAPI(eventId, whyAttendId) {
  const deleteQna = httpsCallable(functions, "deleteQna");
  return deleteQna({
    eventId,
    whyAttendId,
  });
}
