import React from 'react'
import '../App.css'

//icons
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

export function HostCard() {
    return (
        <div className="w-100">

            <div className="card text-black bg-dark text-light border  w-auto">

                <div className="d-flex  p-1 gap-3">
                    <p>logo</p>
                    <div>
                        <span>Hosted By</span>
                        <p>Company Name</p>
                    </div>
                </div>
                <div>
                    <p className="p-1">A leading European B2B trade publishing, conferences and awards organizing company, committed to quality and innovation.</p>

                    <ul className="list-unstyled d-flex">
                        <li className="px-2  text-primary fs-3">
                            <FacebookShareButton
                                url={"https://preevent.netlify.app/test/event"}
                                quote={"Event"}
                                hashtag="#OnlineBizconnectEvent"
                            >
                                <FacebookIcon size={36}/>
                            </FacebookShareButton>

                        </li>
                        <li className="px-2 fs-3">
                            <TwitterShareButton
                                url={"https://preevent.netlify.app/test/event"}
                                quote={"Event"}
                                hashtag="#OnlineBizconnectEvent"
                            >
                                <TwitterIcon size={36}/>
                            </TwitterShareButton>
                        </li>
                        <li className="px-2 fs-3">
                            <LinkedinShareButton
                                url={"https://preevent.netlify.app/test/event"}
                                quote={"Event"}
                                hashtag="#OnlineBizconnectEvent"
                            >
                                <LinkedinIcon size={38}/>
                            </LinkedinShareButton>
                        </li>
                    </ul>
                </div>
            </div>

            <span>Share on</span>
            <ul className="list-unstyled d-flex">

                <li className="px-2  text-primary fs-3">
                    <FacebookShareButton
                        url={"https://preevent.netlify.app/test/event"}
                        quote={"Event"}
                        hashtag="#OnlineBizconnectEvent"
                    >
                        <FacebookIcon size={36}/>
                    </FacebookShareButton>

                </li>
                <li className="px-2 fs-3">
                    <TwitterShareButton
                        url={"https://preevent.netlify.app/test/event"}
                        quote={"Event"}
                        hashtag="#OnlineBizconnectEvent"
                    >
                        <TwitterIcon size={36}/>
                    </TwitterShareButton>

                </li>
                <li className="px-2 fs-3">
                    <LinkedinShareButton
                        url={"https://preevent.netlify.app/test/event"}
                        quote={"Event"}
                        hashtag="#OnlineBizconnectEvent"
                    >
                        <LinkedinIcon size={38}/>
                    </LinkedinShareButton>
                </li>
                <li className="px-2 fs-3">
                    <WhatsappShareButton
                        url={"https://preevent.netlify.app/test/event"}
                        quote={"Event"}
                        hashtag="#OnlineBizconnectEvent"
                    >
                        <WhatsappIcon size={36}/>
                    </WhatsappShareButton>
                </li>
                <li className="px-2 fs-3">
                    <EmailShareButton
                        url={"https://preevent.netlify.app/test/event"}
                        quote={"Event"}
                        hashtag="#OnlineBizconnectEvent"
                    >
                        <EmailIcon size={36}/>
                    </EmailShareButton>
                </li>
            </ul>

        </div>
    )
}