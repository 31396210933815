import React, {useEffect} from 'react'
import GeneralPanel from '../GeneralPanel'


// Bootstrap
import {Button, Container, Form, InputGroup} from 'react-bootstrap';

//Icon
import {MdChevronLeft} from "react-icons/md";
import { Icon } from "@iconify/react";


// Css
import '../../App.css'

//redux
import {useDispatch, useSelector} from "react-redux";
import {
    getUserProfileAsync, 
    setDataToEdit, 
    setEditProfile,
    setFacebook,
    setInstagram,
    setLinkedin,
    setTwitter,
    setUserProfilePannel,
    socialUpdateUserAsync,
    userProfileState
} from "../../reduxSlice"
import {useMatch, useParams} from "react-router-dom";


export default function SocialForm() {
    const dispatch = useDispatch();
    const {docId} = useParams();
    const editMode = useMatch("/u/setting/:docId");

    const {
        editProfile,
        userData,
        facebook,
        instagram,
        twitter,
        linkedin,
        updateSocialLoading
    } = useSelector(userProfileState)

    useEffect(() => {
        if (editMode) {
            if (Object.keys(userData).length === 0) {
                dispatch(getUserProfileAsync({docId}));
            }
            dispatch(setEditProfile({value: true}));
            dispatch(setDataToEdit({userData}));
        }

    }, [dispatch, editMode, docId]);

    const handelSubmit = (e) => {
        e.preventDefault();
        dispatch(socialUpdateUserAsync({
                facebook,
                instagram,
                twitter,
                linkedin
            }
        ))
    }

    const backButton = () => {
        dispatch(setUserProfilePannel({newPannel: "General"}));
    }

    return (
        <>
            <Container className='mt-3 mt-lg-0 px-lg-5 px-2 bg-dark text-light'>
                <Button variant="link" className='ps-0 text-decoration-none shadow-none'
                        onClick={backButton}><MdChevronLeft/> Back</Button>
                <h4>Social Settings</h4>
                <hr/>
                <Form validated onSubmit={handelSubmit}>
                    <Container className='d-flex justify-content-between flex-column flex-md-row'>
                        <Form.Group className="pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Facebook</Form.Label>
                            <InputGroup className="mb-3 w-100">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    value={facebook}
                                    onChange={(e) => {
                                        dispatch(setFacebook({value: e.target.value}))
                                    }}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    required

                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Instagram</Form.Label>
                            <InputGroup className="mb-3 w-100">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    value={instagram}
                                    onChange={(e) => {
                                        dispatch(setInstagram({value: e.target.value}))
                                    }}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Twitter</Form.Label>
                            <InputGroup className="mb-3 w-100">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    value={twitter}
                                    onChange={(e) => {
                                        dispatch(setTwitter({value: e.target.value}))
                                    }}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Linkedin</Form.Label>
                            <InputGroup className="w-100">
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    value={linkedin}
                                    onChange={(e) => {
                                        dispatch(setLinkedin({value: e.target.value}))
                                    }}
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                    </Container>
                    <Container>
                        <Button type="submit" className=' d-block ms-auto me-0'>
                        {updateSocialLoading ? (<Icon icon="eos-icons:bubble-loading" />
                            ) : 'Update'}
                        </Button>
                    </Container>
                </Form>
            </Container>
        </>
    )
}
