import React from "react";
import { RiAccountCircleFill } from "@react-icons/all-files/ri/RiAccountCircleFill";
import moment from "moment";
import { Link } from "react-router-dom";

const Disscusion_Card = ({
  DiscussionTitle,
  DiscussionDescription,
  createdAt,
  startedBy,
}) => {
  return (
    <div>
      <div className="col-12 mb-4">
        <a className="card  bg-dark border card-link text-decoration-none">
          <div className="card-body text-decoration-none">
            <h6 className="card-title text-light fw-bold text-decoration-none">
              {DiscussionTitle}
            </h6>
            <div className="markdown text-truncate text-truncate-five-line text-light fw-light">
              <small>{DiscussionDescription}</small>
            </div>
          </div>

          <div className="card-footer bg-dark border">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className=" d-flex justify-content-center align-items-center   fs-1">
                  <RiAccountCircleFill />
                </span>
              </div>
              <div className="col">
                <p className="mb-0 fs-6 text-light">
                  Started by <strong>{startedBy}</strong>
                </p>
              </div>
              <div className="col-2 text-center text-light">
                {moment(createdAt?.toDate()).fromNow()}
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Disscusion_Card;
