import React, { useState, useCallback } from "react";

// Bootstrap
import { Card, Button, Container, Image, Placeholder } from "react-bootstrap";

//Icons
import { FaShareAlt } from "react-icons/fa";
import { AiFillVideoCamera } from "react-icons/ai";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { Link } from "react-router-dom";
import ShareModel from "../../ShareModal/App";
import moment from "moment";
import { useSpring, animated } from "@react-spring/web";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getSavedEventsOfUserAsync, userState } from "../../User/reduxSlice";
import { saveEventAsync, unSaveEventAsync } from "../Page Component/reduxSlice";
import { setShareLink } from "../../ShareModal/reduxSlice";

// Other laibrary
// import parse from "html-react-parser";

const EventList = ({ eventData }) => {
  const dispatch = useDispatch();

  //loading
  const [imgLoading, setImgLoading] = useState(true);
  const { savedEvents } = useSelector(userState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Animation Style
  const [isBooped, setBooped] = React.useState(false);
  const transformFrom = "scale(1.1)";
  const transformTo = "scale(1)";
  const [cardHover, hover] = useSpring(() => ({
    from: { transform: transformTo },
  }));

  const onMouseEnter = useCallback(() => setBooped(true), []);
  const onMouseLeave = useCallback(() => setBooped(false), []);

  React.useEffect(() => {
    if (isBooped) {
      hover.start({
        to: transformFrom,
      });
    } else {
      hover.stop();
      hover.start({
        to: transformTo,
      });
    }
  }, [isBooped]);

  if (!eventData) {
    return null;
  }

  const {
    id,
    eventGallery,
    eventName,
    eventStartsAt,
    eventType,
    location,
    groupId,
    attendeesId,
    companyName,
    // draft,
    // eventEndsAt,
    // description,
    // eventMode,
    // link,
  } = eventData;

  let isSaved = savedEvents.includes(id);
  const checkStatus = eventType === "online" ? "block" : "none";

  const toDateTime = (secs) => {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setUTCSeconds(secs);
    return t;
  };

  return (
    <>
      <Container className="px-0 ">
        <Card className="flex-column flex-md-row border-0 bg-dark text-light w-100 h-100">
          <Button
            variant="dark"
            className="position-absolute py-1 px-2"
            id="onlineBtn"
            style={{
              top: "4px",
              left: "4px",
              fontSize: "0.75rem",
              display: checkStatus,
            }}
          >
            <AiFillVideoCamera /> ONLINE EVENT
          </Button>
          <div className="col-md-3">
            <Link
              to={`/event/${id}`}
              state={{ eventName }}
              className="text-decoration-none"
            >
              <div className="img-wrap ">
                <div className="img-content overflow-hidden rounded">
                  <animated.div
                    style={cardHover}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  >
                    <Image
                      fluid
                      draggable={false}
                      src={eventGallery}
                      style={{
                        aspectRatio: "16 / 10",
                      }}
                      className={`rounded w-100 ${imgLoading ? "d-none" : null
                        }`}
                      onLoad={() => {
                        setImgLoading(false);
                      }}
                      alt="Runolfsdottir Group"
                    />
                  </animated.div>
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="rounded w-100 h-100 "
                        style={{
                          aspectRatio: "16 / 10 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
          <Card.Body className="py-0 px-0 px-md-2 d-flex flex-column justify-content-between">
            <Container fluid className="p-0 multiLineLabel">
              <Card.Text className="mt-0 mb-2">
                <small>
                  {moment(
                    toDateTime(
                      eventStartsAt?._seconds || eventStartsAt?.seconds || (eventStartsAt / 1000)
                    )
                  ).format("llll")}
                </small>
              </Card.Text>
              <Link
                to={`/events/${id}`}
                className="text-decoration-none text-light"
              >
                <Card.Title className="fs-6 textMaxLine">
                  {eventName}
                </Card.Title>
              </Link>
              <Card.Text className="d-flex">{location}</Card.Text>
            </Container>
            <Container
              fluid
              className="row p-0 w-100 m-0 "
              style={{ fontSize: "0.9rem" }}
            >
              <Container fluid className="col d-flex px-0">
                <Card.Text className="my-auto">
                  {attendeesId?.length}{" "}
                  {attendeesId?.length < 2 ? "attendee" : "attendees"}
                </Card.Text>
                {/* <Button
                  variant="link"
                  className="text-decoration-none text-success border py-0 px-1 shadow-none ms-2"
                  style={{ fontSize: "0.8rem" }}
                >
                  <TiTick className="p-0 rounded-circle border bg-success text-white" />
                  Attending
                </Button> */}
              </Container>
              <Container
                fluid
                className="col d-flex justify-content-end align-items-center text-light"
              >
                <Button
                  variant="link"
                  className="text-decoration-none shadow-none p-0 px-1 text-light"
                >
                  <span className="fs-5">
                    {isSaved ? (
                      <BsFillStarFill
                        onClick={async () => {
                          await dispatch(
                            unSaveEventAsync({
                              companyName,
                              eventNameParams: eventName,
                            })
                          );
                          dispatch(getSavedEventsOfUserAsync({}));
                        }}
                        className="starFill"
                      />
                    ) : (
                      <BsStar
                        onClick={async () => {
                          await dispatch(
                            saveEventAsync({
                              companyName,
                              eventNameParams: eventName,
                              searchEventId: id
                            })
                          );
                          dispatch(getSavedEventsOfUserAsync({}));
                        }}
                      />
                    )}
                  </span>
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    handleShow();
                    dispatch(
                      setShareLink({
                        value: encodeURI(
                          `${window.location.protocol}//${window.location.host}/event/${id}`
                        ),
                      })
                    );
                  }}
                  className="text-decoration-none shadow-none p-0 px-1 text-light fs-5"
                >
                  <FaShareAlt />
                </Button>
              </Container>
            </Container>
          </Card.Body>
        </Card>
        <ShareModel show={show} handleClose={handleClose} />
        <hr className="mt-4" />
      </Container>
    </>
  );
};
export default EventList;
