import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVenueInformationAPI, setVenueInformationAPI } from "./reduxAPI";

let VenueInforData = {
    photoBooth: {
        platform: "",
        name: "photoBooth",
        openModal: false,
    },
    socialFeed: {
        platform: "",
        name: "socialFeed",
        openModal: false,
    },
    eventNews: {
        platform: "",
        name: "eventNews",
        openModal: false,
    },
    weather: {
        platform: '',
        name: "weather",
        openModal: false,
    },
    flightBooking: [
        {
            name: "makeMy Filghts",
            platform: "",
            openModal: false,
        },
    ],
    hotelBooking: [
        {
            name: "makeMy Hotel",
            platform: "",
            openModal: false,
        },
    ],
    cabBooking: [
        {
            name: "makeMy Cab",
            platform: "",
            openModal: false,
        },
    ],
}

// Todo : Loading animation
const initialState = {
    data: undefined
};

export const updateVenueInformationAsync = createAsyncThunk(
    "venueInformation/updateVenueInformation",
    async ({ _ }, { dispatch, getState, rejectWithValue }) => {
        try {
            const { data } = getState().venueInformation;

            const {
                eventData: { id: eventId },
            } = getState().event;

            const response = await setVenueInformationAPI(eventId, data);

            return response;
        } catch (err) {
            console.log(err);
        }
    }
);
export const getVenueInformationAsync = createAsyncThunk(
    "venueInformation/getVenueInformation",
    async ({ _ }, { dispatch, getState, rejectWithValue }) => {
        try {


            const {
                eventData: { id: eventId },
            } = getState().event;

            const response = await getVenueInformationAPI(eventId);

            return response;
        } catch (err) {
            console.log(err);
        }
    }
);
export const venueInformationSlice = createSlice({
    name: "venueInformation",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setLink: (state, action) => {
            let { field, link, index = undefined } = action.payload;
            if (index !== undefined) {
                state.data[field][index].platform = link;
            } else {
                state.data[field].platform = link;
            }
        },

        setName: (state, action) => {
            let { field, name, index = undefined } = action.payload;
            if (index !== undefined) {
                state.data[field][index].name = name;
            } else {
                state.data[field].name = name;
            }
        },
        // Todo
        // 1) add link
        // 2) remove link

        addField: (state, action) => {
            let { field, name = "Title", platform = undefined, openModal = false } = action.payload;
            state.data[field].push({ name, platform, openModal });
        },

        removeField: (state, action) => {
            let { field, index } = action.payload;
            state.data[field].splice(index, 1);
        }

    },

    extraReducers: (builder) => {
        builder
            .addCase(getVenueInformationAsync.pending, (state, action) => { })
            .addCase(getVenueInformationAsync.fulfilled, (state, action) => {
                if (action.payload?.data?.venueInformation) {
                    state.data = action.payload.data.venueInformation.venueInformation
                } else {
                    state.data = VenueInforData;
                }
            })
            .addCase(getVenueInformationAsync.rejected, (state, action) => { });
    },
});

export const { setLink, setName, addField, removeField } = venueInformationSlice.actions;

export const venueInformationState = (state) => state.venueInformation;

export default venueInformationSlice.reducer;
