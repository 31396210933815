import React from 'react';
import Group_Setting_Navigation from "../Group_Setting_Navigation/App";
import {FiAlertCircle} from "@react-icons/all-files/fi/FiAlertCircle";

const ContentVisibility = () => {
    return (
        <div>
            <div className="container py-5 min-vh-100">
                <div className="row  gx-5">


                    <div className='col-lg-3'>
                        <Group_Setting_Navigation/>
                    </div>

                    <form className="col-12 col-lg-8 mb-5 mb-lg-0 mt-lg-5"
                    >
                        <h5
                            className="mb-4 fw-bold mt-3">Content Visibility</h5>
                        <p>When you change your group's content visibility settings, all members and organizers of
                            the group will receive an email notification.</p>

                        <div className="alert alert-dark  bg-dark text-light border mb-4" role="alert">
                            <span className='mx-2'> <FiAlertCircle/></span>
                            Once you make a group private, it cannot be changed back. This ensures that members who
                            join private groups can maintain their privacy.
                        </div>

                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input"
                                       value="public" type="radio"/>
                                <label className="form-check-label fw-bold">Public</label>
                            </div>
                            <p className="fs-7">This group's content, including its members and event details, is
                                visible to public.</p>

                            <div className="form-check">
                                <input className="form-check-input"
                                       type="radio"/>
                                <label className="form-check-label fw-bold">Private</label>
                            </div>
                            <p className="fs-7">Only members of this group can see its full content, including
                                details about its members and events. Some information about the group is
                                public.</p>
                            <p>These basic details about groups and events are always public, regardless of your
                                content visibility setting:</p>
                            <ul className="fs-7">
                                <li>
                                    <p className="mb-0">Basic group information</p>
                                    <p className="text-muted">Group name, featured photo, description, number of
                                        members, date founded</p>
                                </li>
                                <li>
                                    <p className="mb-0">Basic organizer information</p>
                                    <p className="text-muted">Organizer name and profile photo</p>
                                </li>
                                <li>
                                    <p className="mb-0">Basic event details</p>
                                    <p className="text-muted">Titles, date, time and number of RSVPs</p>
                                </li>
                            </ul>
                        </div>

                        <div className="float-end">
                            <button type="submit" className="btn btn-primary">Update settings</button>
                        </div>
                    </form>


                </div>
            </div>

        </div>
    );
};

export default ContentVisibility;