import { doc, getDoc } from "firebase/firestore";
import { db_firestore } from "../../../../../firebase.config";
import { getDataofUser } from "../../User/reduxAPI";

export async function getCompanyNameFromGroupId(groupId, groupNameAvl) {
  let companyName;
  let groupName;
  const groupRef = doc(db_firestore, "groups", groupId);
  const groupSnap = await getDoc(groupRef);

  if (groupSnap.exists()) {
    if (!groupNameAvl) {
      groupName = groupSnap.data().groupName;
    }
    let data = await getDataofUser(groupSnap.data().subscriberUid);
    companyName = data.companyName;
  }

  return { companyName, groupName };
}
