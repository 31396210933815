import { httpsCallable } from "firebase/functions";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { functions, storage } from "../../../../../../firebase.config";

// Algolia
import { searchClient } from "../../../../../../algolia/algoliaKey";

export async function getSpeakersAPI(eventId) {
  const getSpeakers = httpsCallable(functions, "getSpeakers");
  return getSpeakers({
    eventId,
  });
}
export async function algoliaUsersAPI(searchQuery, algoliaIndex) {
  const searchInterestIndex = searchClient.initIndex(algoliaIndex);
  return await searchInterestIndex.search(searchQuery);
}

export async function createNewSpeakerAPI(
  eventId,

  speakerName,
  designation,
  description,
  email,
  conatctNo,
  linkedinLink,
  twitterLink,
  facebookLink,
  instagramLink,
  websiteLink,
  youtubeLink,
  whiteLabel
) {
  const createNewSpeaker = httpsCallable(functions, "createNewSpeaker");
  return createNewSpeaker({
    eventId,
    speakerName,
    designation,
    description,
    email,
    conatctNo,
    linkedinLink,
    twitterLink,
    facebookLink,
    instagramLink,
    websiteLink,
    youtubeLink,
    whiteLabel,
  });
}

export function uploadFiles(file, eventId, speakerId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/speakers/${speakerId}/` + file.name
    );
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export function uploadWhitePaper(file, eventId, speakerId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/speakers/${speakerId}/whitePaper/` + file.name
    );
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteWhitePaper(whitePaperUrl) {
  const whitePaperRef = ref(storage, whitePaperUrl);
  let res = await deleteObject(whitePaperRef);
  return res;
}

export async function deleteSpeakerProfile(eventId, speakerId) {
  // Create a reference to the file to delete
  const speakerRef = ref(storage, `events/${eventId}/speakers/${speakerId}`);

  let speakerImgList = [];
  // Find all the prefixes and items.
  listAll(speakerRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        speakerImgList = [...speakerImgList, itemRef];
        // All the items under listRef.
      });
      if (speakerImgList.length > 0) {
        speakerImgList.map((value) =>
          deleteObject(
            ref(
              storage,
              `events/${eventId}/speakers/${speakerId}/${value.name}`
            )
          )
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export function addSpeakerProfileUrl(profileUrl, eventId, speakerId) {
  const addEventGalleryfn = httpsCallable(functions, "addSpeakerProfileUrl");
  return addEventGalleryfn({
    profileUrl,
    eventId,
    speakerId,
  });
}
export function updateWhitePaperLinkAPI(Url, eventId, speakerId) {
  const updateWhitePaperLink = httpsCallable(functions, "updateWhitePaperLink");
  return updateWhitePaperLink({
    whiteLabel: Url,
    eventId,
    speakerId,
  });
}

export function deleteSpeakerProfileAPI(eventId, speakerId) {
  const deleteSpeakerProfile = httpsCallable(functions, "deleteSpeakerProfile");
  return deleteSpeakerProfile({
    eventId,
    speakerId,
  });
}

export async function updateSpeakerProfileDataAPI(
  eventId,

  speakerName,
  designation,
  description,
  email,
  conatctNo,
  linkedinLink,
  twitterLink,
  facebookLink,
  instagramLink,
  websiteLink,
  youtubeLink,

  speakerId
) {
  const updateSpeakerProfileData = httpsCallable(
    functions,
    "updateSpeakerProfileData"
  );
  return updateSpeakerProfileData({
    eventId,
    speakerName,
    designation,
    description,
    email,
    conatctNo,
    linkedinLink,
    twitterLink,
    facebookLink,
    instagramLink,
    websiteLink,
    youtubeLink,

    speakerId,
  });
}
