// Promise
// fetch request ( to laravel )
// axios request ( get , post , put , patch ...)
// firestore requests
// cloud function requests

import { auth } from "../../../../../firebase.config";
import { signOut } from "firebase/auth";

// import { httpsCallable } from "firebase/functions";
// import axios from "../../../../axios/axiosInstance";

export function logoutUser() {
  return signOut(auth);
}
