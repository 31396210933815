import React, { useEffect, useState } from "react";
import EventList from "../../../../Main/Components/Events/List/App";

import EventListFilterSkeleton from "./EventListFilterSkeleton";
import NotFoundFilterEvent from "./NotFoundFilterEvent";

// Bootstrap
import { Container, ListGroup } from "react-bootstrap";
// Css
import "../App.css";

import { useLocation } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

import {
  eventsFilterListState,
  setUpcomming,
  filteredEventAsync,
  setFound,
  clearDataArr,
} from "../../../../Main/Components/Events/List/reduxSlice";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EventListing() {
  let query = useQuery(); // if required enable  it

  const {
    loading: fetchLoading,
    found,
    eventsFilterListArr,
    upcomming,
  } = useSelector(eventsFilterListState);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(filteredEventAsync({}));
    return () => {
      dispatch(setFound({ value: true }));
      dispatch(clearDataArr({}));
    };
  }, [dispatch]);

  const handClick = (event) => {
    if (event.target.value === "upcoming") {
      if (!upcomming) {
        dispatch(setFound({ value: true }));
        dispatch(clearDataArr({}));
        dispatch(setUpcomming({ isUpComming: true }));
        dispatch(filteredEventAsync({}));
      }
    } else if (event.target.value === "past") {
      if (upcomming) {
        dispatch(setFound({ value: true }));
        dispatch(clearDataArr({}));
        dispatch(setUpcomming({ isUpComming: false }));
        dispatch(filteredEventAsync({}));
      }
    }
  };

  return (
    <>
      <Container className="w-100 ">
        <h3>My Events</h3>
        <ListGroup
          horizontal
          className="d-flex flex-row my-3 align-items-center border border-secondary w-100 rounded shadow-sm"
        >
          <ListGroup.Item
            action
            onClick={handClick}
            value="upcoming"
            active={upcomming ? true : false}
            className={`text-center fw-bold  text-light ${
              upcomming ? "border-primary text-white" : "bg-dark text-light"
            }`}
          >
            Upcoming
          </ListGroup.Item>
          <ListGroup.Item
            action
            onClick={handClick}
            value="past"
            active={upcomming ? false : true}
            className={`text-center fw-bold  text-light ${
              !upcomming ? "border-primary text-white" : "bg-dark text-light"
            }`}
          >
            Past
          </ListGroup.Item>
        </ListGroup>
        {fetchLoading ? (
          <EventListFilterSkeleton list={3} />
        ) : (
          <>
            {found ? (
              <>
                {eventsFilterListArr.map((event, index) => {
                  return <EventList eventData={event} index={index} />;
                })}
              </>
            ) : (
              <NotFoundFilterEvent />
            )}
          </>
        )}
      </Container>
      <div style={{ minHeight: "700px" }}></div>
    </>
  );
}
