import { functions } from "../../../../../firebase.config";
import { httpsCallable } from "firebase/functions";

export async function getuserDetailsAPI(docId) {
  const getuserDetails = httpsCallable(functions, "getuserDetails");
  return getuserDetails({
    docId,
  });
}

export async function eventAttendeeMatchMakingAPI(docId, eventId) {
  const eventAttendeeMatchMaking = httpsCallable(
    functions,
    "eventAttendeeMatchMaking"
  );
  return eventAttendeeMatchMaking({
    docId,
    eventId,
  });
}
