import React, { useEffect } from 'react'
import GeneralPanel from '../GeneralPanel'
// Bootstrap
import { Button, Container, Form } from 'react-bootstrap';

//Icon
import { MdChevronLeft } from "react-icons/md";
import { Icon } from "@iconify/react";

// Css
import '../../App.css'

//redux
import { useDispatch, useSelector } from "react-redux";
import {
    getUserProfileAsync,
    locationUpdateUserAsync,
    setAddress,
    setCity,
    setCountry, setDataToEdit, setEditProfile,
    setUserProfilePannel,
    userProfileState
} from "../../reduxSlice"
import { useMatch, useParams } from "react-router-dom";

export default function FormUpdate() {

    const dispatch = useDispatch();


    const { docId } = useParams();
    const editMode = useMatch("/u/setting/:docId");
    const {
        city,
        country,
        address,
        userData,
        updateLocationLoading
    } = useSelector(userProfileState)

    useEffect(() => {
        if (editMode) {
            if (Object.keys(userData).length === 0) {
                dispatch(getUserProfileAsync({ docId }));
            }
            dispatch(setEditProfile({ value: true }));
            dispatch(setDataToEdit({ userData }));
        }

    }, [dispatch, editMode, docId]);


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(locationUpdateUserAsync({
            city,
            country,
            address
        }))
        console.log(country,
            city,
            address)
    }


    const backButton = () => {
        dispatch(setUserProfilePannel({ newPannel: "General" }));
    }


    return (
        <>
            <Container className='mt-3 mt-lg-0 px-lg-5 px-2 bg-dark text-light'>
                <Button variant="link" className='ps-0 text-decoration-none shadow-none'
                    onClick={backButton}><MdChevronLeft /> Back</Button>
                <h4>Location Settings</h4>
                <hr />
                <Form validated onSubmit={handleSubmit}>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address Line</Form.Label>
                            <Form.Control type="text" className='bg-dark text-light' required
                                value={address} onChange={(e) => {
                                    dispatch(setAddress({ value: e.target.value }))
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>City/State</Form.Label>
                            <Form.Control type="text" className='bg-dark text-light' required
                                value={city} onChange={(e) => {
                                    dispatch(setCity({ value: e.target.value }))
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Country</Form.Label>
                            <Form.Select value={country} onChange={(e) => {
                                dispatch(setCountry({ value: e.target.value }))
                            }} defaultValue="Country." className='bg-dark text-light' required>
                                <option>Country</option>
                                <option>..</option>
                                <option>...</option>
                                <option>...</option>
                            </Form.Select>
                        </Form.Group>
                    </Container>
                    <Container className='mb-3' >
                        <Button type="submit" className=' d-block ms-auto me-0'>
                            {updateLocationLoading ? (<Icon icon="eos-icons:bubble-loading" />
                            ) : 'Update'}
                        </Button>
                    </Container>
                </Form>
            </Container>
        </>
    )
}
