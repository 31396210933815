import React from "react";
import {
  Form,
  Button,
  Col,
  Tooltip,
  Row,
  OverlayTrigger,
} from "react-bootstrap";
import { MdNoteAdd } from "react-icons/md";
import { IoMdRemoveCircle } from "react-icons/io";
import { BiEdit } from "react-icons/bi";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addField,
  removeField,
  setLink,
  setName,
  venueInformationState,
} from "./reduxSlice";

export default function App() {
  const dispatch = useDispatch();
  const { data } = useSelector(venueInformationState);

  const [updateField, setUpdateField] = React.useState(null);

  return (
    <>
      <Form className="bg-dark text-light">
        <Row>
          <Col sm={12} md={6}>
            <Form.Group className="mb-3" controlId="weather">
              <Form.Label className="row mx-0">
                <Col sm={6} className="px-0 d-flex col-6 flex-row align-items-center">
                  {/* <Form.Check
                    type="checkbox"
                    id="default-checkbox"
                    className="bg-dark text-light me-2"
                  /> */}
                  {updateField === "weather" ? (
                    <>
                      {" "}
                      <Form.Control
                        type="text"
                        value={data["weather"].name}
                        onChange={(e) => {
                          dispatch(
                            setName({ field: "weather", name: e.target.value })
                          );
                        }}
                        placeholder="Title"
                        className="text-light bg-dark "
                      />
                    </>
                  ) : (
                    <span className="my-0">{data["weather"].name}</span>
                  )}
                </Col>
                <Col sm={6} className="text-end col-6">
                  {updateField === "weather" ? (
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setUpdateField(null);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`weather-top`}>Edit Title</Tooltip>}
                    >
                      <Button variant="link" className={`shadow-none p-0 `}>
                        <BiEdit
                          className="text-light "
                          onClick={() => {
                            setUpdateField("weather");
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>
              </Form.Label>
              <Form.Control
                type="text"
                value={data["weather"].platform}
                onChange={(e) => {
                  dispatch(setLink({ field: "weather", link: e.target.value }));
                }}
                placeholder="Weather Link"
                className="text-light bg-dark"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="photoBooth">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "photoBooth" ? (
                    <Form.Control
                      type="text"
                      value={data["photoBooth"].name}
                      onChange={(e) => {
                        dispatch(
                          setName({
                            field: "photoBooth",
                            name: e.target.value,
                          })
                        );
                      }}
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span>{data["photoBooth"].name}</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {updateField === "photoBooth" ? (
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setUpdateField(null);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`photoBooth-top`}>Edit Title</Tooltip>
                      }
                    >
                      <Button variant="link" className={`shadow-none p-0 `}>
                        <BiEdit
                          className="text-light "
                          onClick={() => {
                            setUpdateField("photoBooth");
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>
              </Form.Label>
              <Form.Control
                type="text"
                value={data["photoBooth"].platform}
                onChange={(e) => {
                  dispatch(
                    setLink({ field: "photoBooth", link: e.target.value })
                  );
                }}
                placeholder="PhotoBooth Link"
                className="text-light bg-dark"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="socialFeed">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "socialFeed" ? (
                    <Form.Control
                      value={data["socialFeed"].name}
                      onChange={(e) => {
                        dispatch(
                          setName({
                            field: "socialFeed",
                            name: e.target.value,
                          })
                        );
                      }}
                      type="text"
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span>{data["socialFeed"].name}</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {updateField === "socialFeed" ? (
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setUpdateField(null);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`socialFeed-top`}>Edit Title</Tooltip>
                      }
                    >
                      <Button variant="link" className={`shadow-none p-0 `}>
                        <BiEdit
                          className="text-light "
                          onClick={() => {
                            setUpdateField("socialFeed");
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>
              </Form.Label>
              <Form.Control
                type="text"
                value={data["socialFeed"].platform}
                onChange={(e) => {
                  dispatch(
                    setLink({ field: "socialFeed", link: e.target.value })
                  );
                }}
                placeholder="Social Feed Link"
                className="text-light bg-dark"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="eventNews">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "eventNews" ? (
                    <Form.Control
                      type="text"
                      value={data["eventNews"].name}
                      onChange={(e) => {
                        dispatch(
                          setName({
                            field: "eventNews",
                            name: e.target.value,
                          })
                        );
                      }}
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span>{data["eventNews"].name}</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {updateField === "eventNews" ? (
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setUpdateField(null);
                      }}
                    >
                      Update
                    </Button>
                  ) : (
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`news-top`}>Edit Title</Tooltip>}
                    >
                      <Button variant="link" className={`shadow-none p-0 `}>
                        <BiEdit
                          className="text-light "
                          onClick={() => {
                            setUpdateField("eventNews");
                          }}
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>
              </Form.Label>
              <Form.Control
                type="text"
                value={data["eventNews"].platform}
                onChange={(e) => {
                  dispatch(
                    setLink({ field: "eventNews", link: e.target.value })
                  );
                }}
                placeholder="News Feed Link"
                className="text-light bg-dark"
              />
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group className="mb-1" controlId="flight">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "flight" ? (
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span className="fw-bold">Flight Link</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {/* {updateField === 'flight' ? (
                                        <Button variant='outline-primary' onClick={() => { setUpdateField(null) }} >Update</Button>
                                    ) : (
                                        <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={<Tooltip id={`flight-top`}>Edit Title</Tooltip>}
                                        >
                                            <Button variant="link" className={`shadow-none p-0 `}>
                                                <BiEdit className='text-light ' onClick={() => { setUpdateField("flight") }} />
                                            </Button>
                                        </OverlayTrigger>
                                    )} */}
                  <OverlayTrigger
                    // key="top"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>Add Flight</Tooltip>}
                  >
                    <Button
                      variant="link"
                      className={`shadow-none  pe-0 py-0`}
                      onClick={() => {
                        dispatch(addField({ field: "flightBooking" }));
                      }}
                    >
                      <AiOutlineAppstoreAdd
                        className={`bg-dark text-primary`}
                      />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Form.Label>
            </Form.Group>
            {data.flightBooking.map(({ name, platform, openModal }, i) => {
              return (
                <Form.Group
                  as={Col}
                  sm={12}
                  className="mb-2 mt-1"
                  controlId={`flight-${i}`}
                >
                  <Form.Label className="row mx-0">
                    <Col sm={6} md={6} className="col-6 px-0">
                      {updateField === `flightBooking-${i}` ? (
                        <Form.Control
                          value={name}
                          onChange={(e) => {
                            dispatch(
                              setName({
                                field: "flightBooking",
                                name: e.target.value,
                                index: i,
                              })
                            );
                          }}
                          type="text"
                          placeholder="Title"
                          className="text-light bg-dark "
                        />
                      ) : (
                        <span>{name}</span>
                      )}
                    </Col>
                    <Col sm={6} md={6} className="col-6 px-0 text-end">
                      {updateField === `flightBooking-${i}` ? (
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            setUpdateField(null);
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`dropdown-item`}>Edit Title</Tooltip>
                          }
                        >
                          <Button variant="link" className={`shadow-none p-0 `}>
                            <BiEdit
                              className="text-light "
                              onClick={() => {
                                setUpdateField(`flightBooking-${i}`);
                              }}
                            />
                          </Button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        // key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>Remove Link</Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          className={`shadow-none py-0`}
                          onClick={() => {
                            dispatch(
                              removeField({
                                field: "flightBooking",
                                index: i,
                              })
                            );
                          }}
                        >
                          <IoMdRemoveCircle className={`bg-dark text-danger`} />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Form.Label>
                  <Form.Control
                    value={platform}
                    onChange={(e) => {
                      dispatch(
                        setLink({
                          field: "flightBooking",
                          link: e.target.value,
                          index: i,
                        })
                      );
                    }}
                    type="text"
                    placeholder="URL"
                    className="text-light bg-dark "
                  />
                </Form.Group>
              );
            })}
            <hr />
            <Form.Group className="mb-1" controlId="cab">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "cab" ? (
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span className="fw-bold">Cab Link</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {/* {updateField === 'cab' ? (
                                        <Button variant='outline-primary' onClick={() => { setUpdateField(null) }} >Update</Button>
                                    ) : (
                                            <OverlayTrigger
                                            // key="top"
                                            placement="top"
                                            overlay={<Tooltip id={`cab-top`}>Edit Title</Tooltip>}
                                        >
                                            <Button variant="link" className={`shadow-none p-0 `}>
                                                <BiEdit className='text-light ' onClick={() => { setUpdateField("cab") }} />
                                            </Button>
                                        </OverlayTrigger>
                                    )} */}
                  <OverlayTrigger
                    // key="top"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>Add Cab</Tooltip>}
                  >
                    <Button
                      variant="link"
                      className={`shadow-none  pe-0 py-0`}
                      onClick={() => {
                        dispatch(addField({ field: "cabBooking" }));
                      }}
                    >
                      <AiOutlineAppstoreAdd
                        className={`bg-dark text-primary`}
                      />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Form.Label>
            </Form.Group>
            {data.cabBooking.map(({ name, platform, openModal }, i) => {
              return (
                <Form.Group
                  as={Col}
                  sm={12}
                  className="mb-2 mt-1"
                  controlId={`cab-${i}`}
                >
                  <Form.Label className="row mx-0">
                    <Col sm={6} md={6} className="col-6 px-0">
                      {updateField === `cabBooking-${i}` ? (
                        <Form.Control
                          value={name}
                          onChange={(e) => {
                            dispatch(
                              setName({
                                field: "cabBooking",
                                name: e.target.value,
                                index: i,
                              })
                            );
                          }}
                          type="text"
                          placeholder="Title"
                          className="text-light bg-dark "
                        />
                      ) : (
                        <span>{name}</span>
                      )}
                    </Col>
                    <Col sm={6} md={6} className="col-6 px-0 text-end">
                      {updateField === `cabBooking-${i}` ? (
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            setUpdateField(null);
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <OverlayTrigger
                          // key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`cabBooking`}>Edit Title</Tooltip>
                          }
                        >
                          <Button variant="link" className={`shadow-none p-0 `}>
                            <BiEdit
                              className="text-light "
                              onClick={() => {
                                setUpdateField(`cabBooking-${i}`);
                              }}
                            />
                          </Button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        // key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>Remove Link</Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          className={`shadow-none py-0`}
                          onClick={() => {
                            dispatch(
                              removeField({ field: "cabBooking", index: i })
                            );
                          }}
                        >
                          <IoMdRemoveCircle className={`bg-dark text-danger`} />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Form.Label>
                  <Form.Control
                    value={platform}
                    onChange={(e) => {
                      dispatch(
                        setLink({
                          field: "cabBooking",
                          link: e.target.value,
                          index: i,
                        })
                      );
                    }}
                    type="text"
                    placeholder="URL"
                    className="text-light bg-dark "
                  />
                </Form.Group>
              );
            })}
            <hr />
            <Form.Group className="mb-1" controlId="hotel">
              <Form.Label className="row mx-0">
                <Col sm={6} md={6} className="col-6 px-0">
                  {updateField === "Hotel" ? (
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      className="text-light bg-dark "
                    />
                  ) : (
                    <span className="fw-bold">Hotel Link</span>
                  )}
                </Col>
                <Col sm={6} md={6} className="col-6 text-end">
                  {/* {updateField === 'hotel' ? (
                                        <Button variant='outline-primary' onClick={() => { setUpdateField(null) }} >Update</Button>
                                    ) : (
                                        <OverlayTrigger
                                            // key="top"
                                            placement="top"
                                            overlay={<Tooltip id={`hotel-top`}>Edit Title</Tooltip>}
                                        >
                                            <Button variant="link" className={`shadow-none p-0 `}>
                                                <BiEdit className='text-light ' onClick={() => { setUpdateField("hotel") }} />
                                            </Button>
                                        </OverlayTrigger>
                                    )} */}
                  <OverlayTrigger
                    // key="top"
                    placement="top"
                    overlay={<Tooltip id={`hotel-top`}>Add Hotel</Tooltip>}
                  >
                    <Button
                      variant="link"
                      className={`shadow-none  pe-0 py-0`}
                      onClick={() => {
                        dispatch(addField({ field: "hotelBooking" }));
                      }}
                    >
                      <AiOutlineAppstoreAdd
                        className={`bg-dark text-primary`}
                      />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Form.Label>
            </Form.Group>
            {data.hotelBooking.map(({ name, platform, openModal }, i) => {
              return (
                <Form.Group
                  as={Col}
                  sm={12}
                  className="mb-2 mt-1"
                  controlId={`hotel-${i}`}
                >
                  <Form.Label className="row mx-0">
                    <Col sm={6} md={6} className="col-6 px-0">
                      {updateField === `hotelBooking-${i}` ? (
                        <Form.Control
                          value={name}
                          onChange={(e) => {
                            dispatch(
                              setName({
                                field: "hotelBooking",
                                name: e.target.value,
                                index: i,
                              })
                            );
                          }}
                          type="text"
                          placeholder="Title"
                          className="text-light bg-dark "
                        />
                      ) : (
                        <span>{name}</span>
                      )}
                    </Col>
                    <Col sm={6} md={6} className="col-6 px-0 text-end">
                      {updateField === `hotelBooking-${i}` ? (
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            setUpdateField(null);
                          }}
                        >
                          Update
                        </Button>
                      ) : (
                        <OverlayTrigger
                          // key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`hotelBooking`}>Edit Title</Tooltip>
                          }
                        >
                          <Button variant="link" className={`shadow-none p-0 `}>
                            <BiEdit
                              className="text-light "
                              onClick={() => {
                                setUpdateField(`hotelBooking-${i}`);
                              }}
                            />
                          </Button>
                        </OverlayTrigger>
                      )}

                      <OverlayTrigger
                        // key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>Remove Link</Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          className={`shadow-none py-0`}
                          onClick={() => {
                            dispatch(
                              removeField({ field: "hotelBooking", index: i })
                            );
                          }}
                        >
                          <IoMdRemoveCircle className={`bg-dark text-danger`} />
                        </Button>
                      </OverlayTrigger>
                    </Col>
                  </Form.Label>
                  <Form.Control
                    value={platform}
                    onChange={(e) => {
                      dispatch(
                        setLink({
                          field: "hotelBooking",
                          link: e.target.value,
                          index: i,
                        })
                      );
                    }}
                    type="text"
                    placeholder="URL"
                    className="text-light bg-dark "
                  />
                </Form.Group>
              );
            })}
          </Col>
        </Row>
      </Form>
    </>
  );
}
