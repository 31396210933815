import {
  arrayRemove,
  increment,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db_firestore, functions } from "../../../../../firebase.config";
import { getDocIdofUserAPI, getUidofUser } from "../../User/reduxAPI";

export async function getEachEventAPI(groupId, eventId) {
  const docRef = doc(db_firestore, "events", eventId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    if (docSnap.data().groupId === groupId) {
      return { id: eventId, ...docSnap.data() };
    }
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function joinEventAPI(eventId, uid) {
  const eventRef = doc(db_firestore, "events", eventId);

  await updateDoc(eventRef, {
    attendeesId: arrayUnion(uid),
  });
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    joinedEventsId: arrayUnion(eventId),
  });
}

export async function alreadyJoinedEvent(eventId, uid) {
  let alreadyJoined = false;

  const eventRef = doc(db_firestore, "events", eventId);

  const eventSnap = await getDoc(eventRef);

  if (eventSnap.exists()) {
    let userUidList = eventSnap.data().attendeesId;

    if (userUidList.includes(uid)) {
      alreadyJoined = true;
    }
  }

  return alreadyJoined;
}

export async function eventDataByCompanyandEventname(companyName, eventName) {
  let eventData;

  let uid = await getUidofUser(companyName);

  const eventDocq = query(
    collection(db_firestore, "events"),
    where("subscriberUid", "==", uid),
    where("eventName", "==", eventName)
  );
  const querySnapshot = await getDocs(eventDocq);

  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      eventData = doc.data();
    } else {
      eventData = undefined;
    }
  });
  return eventData;
}

export async function leaveEventAPI(eventId, uid) {
  const eventRef = doc(db_firestore, "events", eventId);

  await updateDoc(eventRef, {
    attendeesId: arrayRemove(uid),
  });
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    joinedGroupsId: arrayRemove(eventId),
  });
}

export async function saveEventAPI(eventId, uid) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    savedEvents: arrayUnion(eventId),
  });
}

export async function unSaveEventAPI(eventId, uid) {
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    savedEvents: arrayRemove(eventId),
  });
}

export async function getSavedEventArr(docId) {
  const userRef = doc(db_firestore, "users", docId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().savedEvents;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

export async function getRegistrationForm(groupId, eventId) {
  const formDocq = query(
    collection(db_firestore, "registrationForms"),
    where("eventId", "==", eventId),
    where("groupId", "==", groupId)
  );

  let formData = {};
  const querySnapshot = await getDocs(formDocq);
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      formData = { id: doc.id, ...doc.data() };
    } else {
      return;
    }
  });

  return formData;
}

export async function submitRegistrationFormData(
  registrationFormId,
  docId,
  data,
  groupId,
  eventId
) {
  const registrationFormRef = collection(db_firestore, "registrationForms");

  // Check if already exits then delete it
  const formDocq = query(
    registrationFormRef,
    where("eventId", "==", eventId),
    where("groupId", "==", groupId)
  );
  let formDocId = undefined;
  const querySnapshot = await getDocs(formDocq);
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      formDocId = doc.id;
    }
  });

  if (formDocId) {
    const registrationDataRef = collection(
      db_firestore,
      `registrationForms/${formDocId}/submittedData/`
    );
    let dataExists = false;
    let submittedDataDocId;
    const dataExistsQuery = query(
      registrationDataRef,
      where("docId", "==", docId)
    );
    const querySnapshot = await getDocs(dataExistsQuery);

    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        dataExists = true;
        submittedDataDocId = doc.id;
      }
    });

    if (dataExists && submittedDataDocId) {
      await deleteDoc(
        doc(
          db_firestore,
          `registrationForms/${formDocId}/submittedData/`,
          submittedDataDocId
        )
      );
    }
  }

  // Add a new document with a generated id.
  const docRef = await addDoc(
    collection(
      db_firestore,
      `registrationForms/${registrationFormId}/submittedData`
    ),
    {
      docId,
      data,
    }
  );
  if (docRef.id) {
    return docRef;
  } else {
    return { notSubmitted: true };
  }
}

export async function getGroupDetailsFromId(groupId) {
  const groupRef = doc(db_firestore, "groups", groupId);
  const groupSnap = await getDoc(groupRef);

  if (groupSnap.exists()) {
    return groupSnap.data();
  }
}

export async function getCompanyName(subscriberDocId) {
  const userRef = doc(db_firestore, "users", subscriberDocId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().companyName;
  }
}

export async function addFromCompanyAPI(docId, companyName) {
  const addFromCompany = httpsCallable(functions, "addFromCompany");
  return addFromCompany({
    docId,
    companyName,
  });
}

export async function networkingReqAPI(docId, friendDocId) {
  const networkingReq = httpsCallable(functions, "networkingReq");
  return networkingReq({
    docId,
    friendDocId,
  });
}

export async function getGroupandEventId(subscriberuid, eventName) {
  let groupId, eventId;
  const groupDocq = query(
    collection(db_firestore, "events"),
    where("subscriberUid", "==", subscriberuid),
    where("eventName", "==", eventName)
  );

  const querySnapshot = await getDocs(groupDocq);
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      groupId = doc.data().groupId;
      eventId = doc.id;
    }
  });

  return { groupId, eventId };
}

export async function getAttendeesAPI(eventId) {
  let attendeesDetails = [];
  let attendeesuid = [];
  const eventRef = doc(db_firestore, "events", eventId);
  const eventSnap = await getDoc(eventRef);

  if (eventSnap.exists()) {
    attendeesuid = eventSnap.data().attendeesId;
  }

  for (let attendee = 0; attendee < attendeesuid.length; attendee++) {
    let { data: docId } = await getDocIdofUserAPI(attendeesuid[attendee]);
    const userRef = doc(db_firestore, "users", docId);

    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      attendeesDetails.push({
        id: docId,
        firstName: userSnap.data().firstName,
        lastName: userSnap.data().lastName,
        profileUrl: userSnap.data().profileUrl,
      });
    } else {
      console.log("This User id  is not exist in db");
    }
  }

  return attendeesDetails;
}
