import React, { useEffect } from "react";
import { Container, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GroupCard from "../../../../../Main/Components/Groups/List/App";
// import {
//   clearGroupsDataArr,
//   clearOrganizedGroupsDataArr,
//   groupsListState,
//   setFound,
// } from "../../../../../Main/Components/Groups/Card/reduxSlice";
import {
  groupsFilterListState,
  clearGroupsFilterListArr,
} from "../../../../../Main/Components/Groups/List/reduxSlice";
import NotFoundGroups from "./NotFoundGroups";

export const EventListFilterSkeleton = ({ list }) => {
  return (
    <div className="container">
      <div className="row px-0 mx-0 border-bottom pb-4 my-2">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "1 / 1",
                // minHeight: "200px",
                maxHeight: "200px",
                width: "100%"
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <Placeholder animation="glow">
            <Placeholder xs={4} /><br />
            <Placeholder xs={2} /><br />
          </Placeholder>
          <div className="placeholder-glow my-2">
            <span className="placeholder col-12" /><span />
            <span className="placeholder col-12" /><span />
          </div>
          <Placeholder animation="glow" className='d-flex justify-content-between mt-auto mb-0'>
            <Placeholder xs={2} />
            <Placeholder xs={1} />
          </Placeholder>
        </div>
      </div>
      <div className="row px-0 mx-0 border-bottom pb-4 my-2">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "1 / 1",
                // minHeight: "200px",
                maxHeight: "200px",
                width: "100%"
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <Placeholder animation="glow">
            <Placeholder xs={4} /><br />
            <Placeholder xs={2} /><br />
          </Placeholder>
          <div className="placeholder-glow my-2">
            <span className="placeholder col-12" /><span />
            <span className="placeholder col-12" /><span />
          </div>
          <Placeholder animation="glow" className='d-flex justify-content-between mt-auto mb-0'>
            <Placeholder xs={2} />
            <Placeholder xs={1} />
          </Placeholder>
        </div>
      </div>
      <div className="row px-0 mx-0 border-bottom pb-4 my-2">
        <div className="col-12 col-md-3 p-0">
          <Placeholder animation="glow">
            <Placeholder
              className="rounded w-100 h-100 "
              style={{
                aspectRatio: "1 / 1",
                // minHeight: "200px",
                maxHeight: "200px",
                width: "100%"
              }}
            />
          </Placeholder>
        </div>
        <div className="d-flex flex-column col-12 col-md-9">
          <Placeholder animation="glow">
            <Placeholder xs={4} /><br />
            <Placeholder xs={2} /><br />
          </Placeholder>
          <div className="placeholder-glow my-2">
            <span className="placeholder col-12" /><span />
            <span className="placeholder col-12" /><span />
          </div>
          <Placeholder animation="glow" className='d-flex justify-content-between mt-auto mb-0'>
            <Placeholder xs={2} />
            <Placeholder xs={1} />
          </Placeholder>
        </div>
      </div>
    </div>
  );
};

const Organizer = () => {
  const {
    groupsFilterListArr,
    found,
    organizer,
    loading: fetchLoading,
  } = useSelector(groupsFilterListState);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearGroupsFilterListArr({}));
    };
  }, [dispatch, organizer]);

  return (
    <Container>
      {fetchLoading ? (
        <EventListFilterSkeleton list={3} />
      ) : (
        <>
          {found ? (
            <>
              {groupsFilterListArr.map((item, index) => (
                <GroupCard key={item.id} index={index} groupData={item} />
              ))}
            </>
          ) : (
            <NotFoundGroups />
          )}
        </>
      )}
    </Container>
  );
};

export default Organizer;
