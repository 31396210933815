import React, { useEffect } from "react";
import Organizer from "./Organizer/Organizer";
import { Button } from "react-bootstrap";

import {
  groupsFilterListState,
  setOrganizer,
  filteredGroupAsync,
} from "../../../../Main/Components/Groups/List/reduxSlice";
import { useDispatch, useSelector } from "react-redux";

const MyGroup = () => {
  const dispatch = useDispatch();
  const { organizer } = useSelector(groupsFilterListState);

  useEffect(() => {
    dispatch(filteredGroupAsync({ organizer }));
    console.log("organizer",organizer)
  }, [dispatch, organizer]);

  useEffect(() => {
    return () => {
      dispatch(setOrganizer({ value: false }));
    };
  }, [dispatch]);

  return (
    <div className="min-vh-100">
      <div className="page-wrapper">
        <section className="py-4 py-lg-5">
          <div className="container">
            <h4 className="fw-bold ">My Groups</h4>
            <div className="btn-group my-3 ">
              <Button
                onClick={() => {
                  dispatch(setOrganizer({ value: false }));
                }}
                className={
                  organizer === false
                    ? "btn-primary shadow-none"
                    : "border-primary bg-dark text-light shadow-none"
                }
              >
                Member
              </Button>
              <Button
                onClick={() => {
                  dispatch(setOrganizer({ value: true }));
                }}
                className={
                  organizer === true
                    ? "btn-primary shadow-none"
                    : "border-primary bg-dark text-light shadow-none"
                }
              >
                Organizer
              </Button>
            </div>
          </div>

          <div>
            <Organizer />
          </div>
        </section>
      </div>
    </div>
  );
};

export default MyGroup;
