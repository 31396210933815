import React from "react";

// Bootstrap
import { Card, Col, Image, Button, Row } from "react-bootstrap";

// Icons
import { Icon } from "@iconify/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  acceptRequestHandlerAsync,
  notificationState,
  removeNotificationAsync,
} from "../reduxSlice";

export default function FriendReq({ index = undefined }) {
  // var
  const dispatch = useDispatch();

  const { notifications } = useSelector(notificationState);

  if (index === undefined) {
    return null;
  }

  const { docId, id, name, headLine, profileURL } = notifications[index];

  return (
    <>
      <Card className="mx-1">
        <Card.Body className="  align-items-center">
          <Row>
            <Col className="col-1 px-0 text-center">
              <Image
                src={profileURL}
                style={{
                  aspectRatio: "1/1",
                  maxHeight: "80px",
                }}
                className="rounded-circle shadow-sm img-fluid "
              />
            </Col>
            <Col className="col-8 ">
              <Card.Title className="fs-6 mb-0 text-black">{name}</Card.Title>
              <Card.Text className="my-0 small text-black">
                {headLine}
              </Card.Text>
            </Col>
            <Col className="col-3 d-flex  px-0">
              <Button
                variant="link"
                onClick={() => {
                  dispatch(
                    acceptRequestHandlerAsync({
                      friendDocId: docId,
                      notificationId: id,
                    })
                  );
                }}
                className="  fs-4 text-tertiary px-1 py-1 d-flex align-items-center justify-content-center me-1"
              >
                <Icon icon="akar-icons:person-check" />
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    removeNotificationAsync({
                      notificationId: id,
                    })
                  );
                }}
                variant="link"
                className="  fs-4 text-tertiary px-1 py-1 d-flex align-items-center justify-content-center"
              >
                <Icon icon="akar-icons:person-cross" />
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
