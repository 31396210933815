import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaInstagramSquare,
  FaLinkedin,
  FaYoutubeSquare,
  FaTwitterSquare,
  FaFacebookSquare,
} from "react-icons/fa";
// BOOTSTRAP
import { Container, Button, Row, Col, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { rootPageState } from "../../Routes/reduxSlice";
import { userState } from "../../../Main/Components/User/reduxSlice";

// animation
import { useSpring, animated } from "@react-spring/web";
import { footerState } from "./reduxSlice";

const Footer = () => {
  // var

  // State
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);
  const { userAvl } = useSelector(userState);

  const { hideFooter } = useSelector(footerState);
  // Animation
  const styles = useSpring({});

  // Functions

  let getStarted;

  if (userAvl && (isSubscriber || isAdmin || isBizAdmin)) {
    getStarted = "/creategroup";
  } else if (!userAvl) {
    getStarted = "/login";
  } else {
    getStarted = "";
  }
  return (
    <Container
      fluid
      to={"myevents"}
      className={`bg-primary text-white ${hideFooter ? "d-none" : null}  `} // do this in spring
    >
      <animated.div style={{ ...styles }}>
        <Container className="pt-5">
          <div className="d-none">
            <div to={"myevents"} className="d-flex align-items-center pb-4 ">
              <p to={"myevents"} className="my-0">
                Create your own group.
              </p>
              <Link to={getStarted} className="text-decoration-none">
                <Button
                  variant="tertiary"
                  to={"myevents"}
                  className=" text-white ms-3 ms-md-4 "
                >
                  Get started
                </Button>
              </Link>
            </div>
            <hr className="my-2" />
          </div>
          <Row className="my-5 row-cols-1 row-cols-md-3">
            <Col>
              <h6>YOUR ACCOUNT</h6>
              <ListGroup className="border-0 bg-primary text-white mt-auto mb-5 my-md-3">
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  Settings
                </Link>
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  Profile
                </Link>
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  My events
                </Link>
              </ListGroup>
            </Col>
            <Col>
              <h6>DISCOVER</h6>
              <ListGroup className="border-0 bg-primary text-white mt-auto mb-5 my-md-3">
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  Groups
                </Link>
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0  bg-transparent list-group-item bg-primary  "
                >
                  Events
                </Link>
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  Online Events
                </Link>
                <Link
                  to={"myevents"}
                  className="p-0 text-white border-0 bg-transparent list-group-item bg-primary  "
                >
                  Browse Topics
                </Link>
              </ListGroup>
            </Col>
            <Col>
              <h6>NETWORK</h6>
              <ListGroup className="border-0 bg-primary text-white mt-auto mb-5 my-md-3">
                <Link
                  to={"myevents"}
                  className="p-0 text-white bg-transparent border-0  list-group-item bg-primary  "
                >
                  Blog
                </Link>
              </ListGroup>
            </Col>
          </Row>

          <h6 className="text-center mb-0">FOLLOW US</h6>
          <ListGroup horizontal className="justify-content-center">
            <ListGroup.Item
              action
              className="p-0 w-auto bg-transparent text-white border-0 border-none fs-5 mx-1"
              href="https://www.facebook.com/bizconnectevents.india"
            >
              <FaFacebookSquare />
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="p-0 w-auto bg-transparent text-white border-0 border-none fs-5 mx-1"
              href="https://www.linkedin.com/company/bizconnect-pvt-ltd/mycompany/"
            >
              <FaLinkedin />
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="p-0 w-auto bg-transparent text-white border-0 border-none fs-5 mx-1"
              href="https://www.instagram.com/bizconnect_global_events/"
            >
              <FaInstagramSquare />
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="p-0 w-auto bg-transparent text-white border-0 border-none fs-5 mx-1"
              href="https://twitter.com/BizConnectEven1"
            >
              <FaTwitterSquare />
            </ListGroup.Item>
            <ListGroup.Item
              action
              className="p-0 w-auto bg-transparent text-white border-0 border-none fs-5 mx-1"
              href="https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ"
            >
              <FaYoutubeSquare />
            </ListGroup.Item>
          </ListGroup>

          <p className="mb-0 text-center py-5">
            <small>© 2022, BizConnect. All rights reserved.</small>
          </p>
        </Container>
      </animated.div>
    </Container>
  );
};

export default Footer;
