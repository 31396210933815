import React from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

const SocailLoginSetting = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                    <small>Settings</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Social Login Settings
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 card bg-dark text-light border" >
                                <form className="card-body"> 
                                        <h3>Facebook Login</h3>
                                        <div className="form-group mb-3">
                                            <label>Facebook Client ID</label>
                                            <input  type="text" className="form-control bg-dark text-light"
                                                   placeholder="Facebook Client ID" value=""/>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label>Facebook Client Secret</label>
                                            <input  type="text" className="form-control bg-dark text-light"
                                                   placeholder="Facebook Client Secret" value=""/>
                                        </div>
                                        <hr></hr>

                                            <h3>Twitter Login</h3>
                                            <div className="form-group mb-3">
                                                <label>Twitter Client ID</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Twitter Client ID" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label >Twitter Client Secret</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Twitter Client Secret" value=""/>
                                            </div>
                                            <hr></hr>

                                                <h3>Google Login</h3>
                                                <div className="form-group mb-3">
                                                    <label>Google Client ID</label>
                                                    <input   type="text" className="form-control bg-dark text-light"
                                                           placeholder="Google Client ID" value=""/>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label>Google Client Secret</label>
                                                    <input type="text"
                                                           className="form-control bg-dark text-light" placeholder="Google Client Secret"
                                                           value=""/>
                                                </div>
                                                <hr></hr>

                                                    <h3>Linkedin Login</h3>
                                                    <div className="form-group mb-3">
                                                        <label>Linkedin Client ID</label>
                                                        <input  type="text"
                                                               className="form-control bg-dark text-light" placeholder="Linkedin Client ID"
                                                               value=""/>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label>Linkedin Client
                                                            Secret</label>
                                                        <input  type="text"
                                                               className="form-control bg-dark text-light"
                                                               placeholder="Linkedin Client Secret" value=""/>
                                                    </div>

                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Update
                                                            Settings
                                                        </button>
                                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            
            </div>
            <Admin_Footer/>
        </div>
    );
};

export default SocailLoginSetting;