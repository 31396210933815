import React from "react";
import { RiAccountCircleFill } from "@react-icons/all-files/ri/RiAccountCircleFill";

const Member_Card = ({ name, role }) => {
  return (
    <div className="">
      <div className="list-group-item p-3 border bg-dark text-light d-flex align-items-center justify-content-between">
        <div className="row g-2 align-items-center">
          <div className="col-auto fs-1">
            <RiAccountCircleFill />
          </div>
          <div className="col">
            <p className="mb-0 fs-6 fw-bold">{name}</p>
            <small>
              <p className="mb-0   text-muted">
                {role}
                <time className="fst-italic"></time>
              </p>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member_Card;
