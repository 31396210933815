import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createBoothImageUrlAPI,
  createBrouchersinBoothAPI,
  createNewBoothAPI,
  deleteBoothAPI,
  deleteBoothFile,
  deleteBroucherFromBoothAPI,
  deleteBrouchersUrlFile,
  getBoothsAPI,
  updateBoothAPI,
  uploadBoothBrouchersFile,
  uploadBoothsFiles,
} from "./reduxAPI";

const initialState = {
  creating_updatingBooth: false,

  editBooth: false,

  companyName: "",
  subHeading: "",
  redirectLink: "",
  columnType: 1,
  brouchers: [{ title: "broucher", broucher: undefined }],

  foundBooths: true,
  boothsList: [],

  wantToUpdateBoothId: "",
  wantToDeleteBoothId: "",

  createdNewBoothId: "",
  createdNewBoothImagesURL: [],
};

export const createBoothAsync = createAsyncThunk(
  "booths/createBooth",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { companyName, subHeading, redirectLink, columnType } =
        getState().booths;
      const response = await createNewBoothAPI(
        eventId,

        companyName,
        subHeading,
        redirectLink,
        columnType
      );

      await dispatch(
        uploadandCreateBrouchersAsync({ boothId: response.data.id })
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadandCreateBrouchersAsync = createAsyncThunk(
  "booths/uploadandCreateBrouchers",
  async ({ boothId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { brouchers } = getState().booths;

      let brouchersUrls = [];

      await Promise.all(
        // eslint-disable-next-line array-callback-return
        brouchers.map(async ({ broucher, title }, index) => {
          if (brouchers) {
            const downloadURL = await uploadBoothBrouchersFile(
              broucher,
              id,
              boothId
            );
            brouchersUrls[index] = {
              brouchersUrl: downloadURL,
              title,
            };
          }
        })
      );

      brouchersUrls = brouchersUrls.filter((x) => {
        return x !== undefined;
      });

      let boothData = {
        eventId: id,
        boothId,
        brouchers: brouchersUrls,
      };

      await createBrouchersinBoothAPI(boothData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateBoothAsync = createAsyncThunk(
  "booths/updateBooth",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const {
        companyName,
        subHeading,
        redirectLink,
        columnType,
        wantToUpdateBoothId,
        brouchers,
      } = getState().booths;
      const response = await updateBoothAPI(
        eventId,
        wantToUpdateBoothId,
        companyName,
        subHeading,
        redirectLink,
        columnType
      );

      // Todo: update brouchers
      if (brouchers && brouchers.length > 0) {
        await dispatch(updatebroucherssAsync({}));
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatebroucherssAsync = createAsyncThunk(
  "booths/updatebroucherss",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      const { wantToUpdateBoothId, brouchers } = getState().booths;

      let brouchersUrls = [];

      // todo skip validation step

      await Promise.all(
        brouchers.map(async ({ broucher, title, brouchersUrl }, index) => {
          if (broucher || brouchersUrl) {
            let newUrl = brouchersUrl;

            if (broucher) {
              if (brouchersUrl) {
                await deleteBrouchersUrlFile(brouchersUrl);
              }

              newUrl = await uploadBoothBrouchersFile(
                broucher,
                id,
                wantToUpdateBoothId
              );
            }
            brouchersUrls[index] = {
              brouchersUrl: newUrl,
              title,
            };
          }
        })
      );

      let boothData = {
        eventId: id,
        boothId: wantToUpdateBoothId,
        brouchers: brouchersUrls,
      };

      await createBrouchersinBoothAPI(boothData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const removeBroucherFromDbAsync = createAsyncThunk(
  "booths/removeBroucherFromDb",
  async ({ title, brouchersUrl }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { wantToUpdateBoothId } = getState().booths;

      let data = {
        eventId: id,
        boothId: wantToUpdateBoothId,

        title,
        brouchersUrl,
      };

      await deleteBrouchersUrlFile(brouchersUrl);
      await deleteBroucherFromBoothAPI(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const uploadBoothFileAsync = createAsyncThunk(
  "booths/uploadBoothsFile",
  async ({ boothId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        selectedFiles,
        eventData: { id: eventId },
      } = getState().event;

      let { createdNewBoothId } = getState().booths;

      let eventGallery = [];
      await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadBoothsFiles(
              file,
              eventId,
              createdNewBoothId || boothId
            );
            eventGallery = [...eventGallery, downloadURL];
          }
        })
      );

      let tempData = [];

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      if (eventGallery.length <= 0) {
        tempData.push(
          `https://picsum.photos/id/${getRandomInt(1, 100)}/1920/760`
        );
      } else {
        tempData = eventGallery;
      }

      return tempData;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateBoothImageAsync = createAsyncThunk(
  "booths/updateBoothImage",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        selectedFiles,

        eventData: { id: eventId },
      } = getState().event;

      const { wantToUpdateBoothId } = getState().booths;

      if (selectedFiles.length > 0) {
        await deleteBoothFile(eventId, wantToUpdateBoothId);
        await dispatch(uploadBoothFileAsync({ boothId: wantToUpdateBoothId }));
        await dispatch(createBoothImageUrlAsync({}));
      } else {
        dispatch(setCreating_updatingBooth({ value: false }));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBoothImageUrlAsync = createAsyncThunk(
  "booths/createBoothImageUrl",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const {
        editBooth,
        wantToUpdateBoothId,
        createdNewBoothId,
        createdNewBoothImagesURL,
      } = getState().booths;

      let boothId = editBooth ? wantToUpdateBoothId : createdNewBoothId;
      const response = await createBoothImageUrlAPI(
        eventId,
        boothId,
        createdNewBoothImagesURL[0]
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteBoothAsync = createAsyncThunk(
  "booths/deleteBooth",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { wantToDeleteBoothId } = getState().booths;

      const response = await deleteBoothAPI(eventId, wantToDeleteBoothId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getBoothsAsync = createAsyncThunk(
  "booths/getBooths",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getBoothsAPI(eventId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const boothsSlice = createSlice({
  name: "booths",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCompanyName: (state, action) => {
      state.companyName = action.payload.value;
    },
    setSubHeading: (state, action) => {
      state.subHeading = action.payload.value;
    },
    setRedirectLink: (state, action) => {
      state.redirectLink = action.payload.value;
    },
    setColumnType: (state, action) => {
      state.columnType = action.payload.value;
    },
    setWantToUpdateBoothId: (state, action) => {
      state.wantToUpdateBoothId = action.payload.value;
    },
    setWantToDeleteBoothId: (state, action) => {
      state.wantToDeleteBoothId = action.payload.value;
    },
    setEditBooth: (state, action) => {
      state.editBooth = action.payload.value;
    },

    // Broucher

    addBrouchers: (state, action) => {
      if (state.brouchers) {
        state.brouchers.push({ title: "", broucher: undefined });
      } else {
        state.brouchers = [{ title: "", broucher: undefined }];
      }
    },

    addBrouchersTitle: (state, action) => {
      const { index, title } = action.payload;
      state.brouchers[index].title = title;
    },

    addBrouchersFile: (state, action) => {
      const { index, broucher } = action.payload;
      state.brouchers[index].broucher = broucher;
    },

    removeBroucher: (state, action) => {
      const { index } = action.payload;
      state.brouchers.splice(index, 1);
    },

    setDataToEdit: (state, action) => {
      let id = state.wantToUpdateBoothId;

      if (id) {
        let boothsList = [...state.boothsList];

        let filteredList = boothsList.filter((booth) => booth.id === id);

        let { companyName, subHeading, redirectLink, columnType, brouchers } =
          filteredList[0];

        state.companyName = companyName;
        state.subHeading = subHeading;
        state.redirectLink = redirectLink;
        state.columnType = columnType;

        if (brouchers?.length !== 0) {
          state.brouchers = brouchers;
        } else {
          state.brouchers = [
            { title: "create brouchers", broucher: undefined },
          ];
        }
      }
    },

    setCreating_updatingBooth: (state, action) => {
      state.creating_updatingBooth = action.payload.value;
    },

    resetForm: (state, action) => {
      state.companyName = "";
      state.subHeading = "";
      state.redirectLink = "";
      state.columnType = 1;
      state.brouchers = [{ title: "broucher", broucher: undefined }];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createBoothAsync.pending, (state, action) => {
        state.creating_updatingBooth = true;
      })
      .addCase(createBoothAsync.fulfilled, (state, action) => {
        let id = action.payload.data.id;

        state.createdNewBoothId = id;
      })
      .addCase(createBoothAsync.rejected, (state, action) => {})

      .addCase(uploadBoothFileAsync.pending, (state, action) => {})
      .addCase(uploadBoothFileAsync.fulfilled, (state, action) => {
        let images = action.payload;

        state.createdNewBoothImagesURL = images;
      })
      .addCase(uploadBoothFileAsync.rejected, (state, action) => {})

      .addCase(createBoothImageUrlAsync.pending, (state, action) => {})
      .addCase(createBoothImageUrlAsync.fulfilled, (state, action) => {
        state.creating_updatingBooth = false;
        if (state.editBooth) {
          state.wantToUpdateBoothId = "";
        } else {
          state.createdNewBoothId = "";
        }

        state.createdNewBoothImagesURL = [];
      })
      .addCase(createBoothImageUrlAsync.rejected, (state, action) => {})

      .addCase(getBoothsAsync.pending, (state, action) => {
        state.foundBooths = true;
      })
      .addCase(getBoothsAsync.fulfilled, (state, action) => {
        let booths = action.payload.data.booths;
        if (booths.length <= 0) {
          state.foundBooths = false;
        }

        state.boothsList = booths;
      })
      .addCase(getBoothsAsync.rejected, (state, action) => {})
      .addCase(updateBoothImageAsync.pending, (state, action) => {})
      .addCase(updateBoothImageAsync.fulfilled, (state, action) => {})
      .addCase(updateBoothImageAsync.rejected, (state, action) => {})
      .addCase(deleteBoothAsync.pending, (state, action) => {})
      .addCase(deleteBoothAsync.fulfilled, (state, action) => {
        state.wantToDeleteBoothId = "";
      })
      .addCase(deleteBoothAsync.rejected, (state, action) => {})
      .addCase(updateBoothAsync.pending, (state, action) => {
        state.creating_updatingBooth = true;
      })
      .addCase(updateBoothAsync.fulfilled, (state, action) => {})
      .addCase(updateBoothAsync.rejected, (state, action) => {});
  },
});

export const {
  setCompanyName,
  setRedirectLink,
  setSubHeading,
  setColumnType,
  setWantToUpdateBoothId,
  setWantToDeleteBoothId,
  setDataToEdit,
  setEditBooth,
  resetForm,
  setCreating_updatingBooth,
  addBrouchers,
  addBrouchersTitle,
  addBrouchersFile,
  removeBroucher,
} = boothsSlice.actions;

export const boothsState = (state) => state.booths;

export default boothsSlice.reducer;
