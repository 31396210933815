import React from "react";

// Bootstrap
import { Form, InputGroup, Button } from "react-bootstrap";

// Icons
import { MdLocationPin } from "react-icons/md";

const GroupLocation = ({ location, setLocation, nextPage, backPage }) => {
  return (
    <div className="page-wrapper">
      <section className="">
        <form>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-8">
              <h1>Set your group's location.</h1>
              <p>
                Network groups meet locally, in person and online. We'll connect
                you with people in your area, and more can join you online.
              </p>
              <div className="input-icon mb-3 place_autocomplete_container">
                <Form validated className=" d-lg-flex position-relative">
                  <InputGroup className="position-relative">
                    {/* <InputGroup.Text
                      id="basic-addon1"
                      className="border-0 bg-dark text-light position-absolute "
                    >
                      <MdLocationPin />
                    </InputGroup.Text> */}
                     <MdLocationPin className="position-absolute" style={{zIndex:'10000',left:'11px',top:'11px'}} />
                    <Form.Control
                      required
                      value={location}
                      onChange={setLocation}
                      type="search"
                      placeholder="Location"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      className=" bg-dark text-light rounded "
                      style={{paddingLeft:'40px'}}
                    >
                    </Form.Control>
                  </InputGroup>
                </Form>

                <div className="mt-3">
                  <Button onClick={backPage}>Back</Button>
                  <Button
                    className="btn btn-primary float-end"
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupLocation;
