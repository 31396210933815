import { ref } from "firebase/storage";
import { firebaseConfig, storage } from "../../../../firebase.config";

export const isImageFromMyStorage = (url) => {
  try {
    if (url) {
      const presentationRef = ref(storage, url);

      if (firebaseConfig.storageBucket === presentationRef.bucket) {
        return true;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};
