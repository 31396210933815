import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  createFaqAPI,
  deleteFaqAPI,
  getFaqAPI,
  updateFaqAPI,
} from "./reduxAPI.js";

// Todo : Loading animation
const initialState = {
  question: "",
  answer: EditorState.createEmpty(),

  editFaq: false,
  wantToEdit_DeleteFaqIndex: null,

  FaqData: [],
  FaqDataMap: [],
};

export const createFaqAsync = createAsyncThunk(
  "faq/createFaq",
  async ({ no }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { question, answer } = getState().faq;

      let cFaqData = {
        eventId: id,
        question,
        answer: draftToHtml(convertToRaw(answer.getCurrentContent())),
        no,
      };

      const response = await createFaqAPI(cFaqData);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getFaqAsync = createAsyncThunk(
  "faq/getFaq",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getFaqAPI(eventId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFaqAsync = createAsyncThunk(
  "faq/updateFaq",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { answer, question, wantToEdit_DeleteFaqIndex, FaqData } =
        getState().faq;

      let faqId = FaqData[wantToEdit_DeleteFaqIndex].id;

      let uFaqData = {
        eventId,
        faqId,
        question,
        answer: draftToHtml(convertToRaw(answer.getCurrentContent())),
      };

      const response = await updateFaqAPI(uFaqData);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFaqAPIsync = createAsyncThunk(
  "faq/deleteFaqAPI",
  async ({ faqId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await deleteFaqAPI(eventId, faqId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAnswer: (state, action) => {
      state.answer = action.payload.value;
    },

    setQuestion: (state, action) => {
      state.question = action.payload.value;
    },

    setEditFaq: (state, action) => {
      state.editFaq = action.payload.value;
    },

    setWantToEdit_DeleteFaqIndex: (state, action) => {
      state.wantToEdit_DeleteFaqIndex = action.payload.index;
    },

    setFaqData: (state, action) => {
      state.FaqData = action.payload.value;
    },

    setDataToEdit: (state, action) => {
      let index = state.wantToEdit_DeleteFaqIndex;

      let { question, answer } = { ...state.FaqData[index] };

      if (answer) {
        const contentBlock = htmlToDraft(answer);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          state.answer = editorState;
        }
      }

      state.question = question;
    },

    resetForm: (state, action) => {
      state.question = "";
      state.answer = EditorState.createEmpty();
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFaqAsync.pending, (state, action) => {})
      .addCase(getFaqAsync.fulfilled, (state, action) => {
        if (action.payload.data.Faq) {
          state.FaqData = action.payload.data.Faq;
          state.FaqDataMap = action.payload.data.Faq;
        }
      })
      .addCase(getFaqAsync.rejected, (state, action) => {});
  },
});

export const {
  setAnswer,
  setQuestion,
  resetForm,
  setEditFaq,
  setWantToEdit_DeleteFaqIndex,
  setDataToEdit,
  setFaqData,
} = faqSlice.actions;

export const faqState = (state) => state.faq;

export default faqSlice.reducer;
