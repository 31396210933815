import React from "react";

import algoliasearch from "algoliasearch";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";

import "./Algoliaor.css";

const searchClient = algoliasearch(
  "WATHJNNCPH",
  "93047dbcdc7325544538039050bc3893"
);
// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
// );

const SearchUser = () => {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="users">
        <SearchBox className="d-block mx-auto my-2 text-center" />
        <Hits hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
};

export default SearchUser;

const Hit = ({ hit }) => {
  let { firstName } = hit;

  return (
    <>
      <div className="btn btn-primary"> {firstName} </div>
    </>
  );
};
