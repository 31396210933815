import React, { useEffect } from "react";
import { Button } from 'react-bootstrap/'// Router
import { useNavigate, useParams } from "react-router-dom";
import { BsFillCloudUploadFill } from 'react-icons/bs'

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  eventInvitationState,
  sendMailInvitationAsync,
  setEmailListFile,
  sheetToJsonExcelEmailAsync,
} from "./reduxSlice";
import { setHideFooter } from "../../../Footer/reduxSlice";

const EventInvitation = () => {
  const { companyName, eventNameParams } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { emailListFile } = useSelector(eventInvitationState);

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));

    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, [dispatch]);

  const sendMailHandler = async (e) => {
    e.preventDefault();
    await dispatch(sheetToJsonExcelEmailAsync({}));
    await dispatch(
      sendMailInvitationAsync({ companyName, eventName: eventNameParams })
    );

    navigate(`/${companyName}/${eventNameParams}`);

  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <div className="shadow-sm border border-1 rounded p-2">
        <form onSubmit={sendMailHandler}>
          <div className="rounded position-relative" style={{ border: '1px dashed', height: '300px', width: "350px" }}>
            <input
              type={"file"}
              accept=".xls,.xlsx"
              multiple={false}
              onChange={(e) => {
                dispatch(setEmailListFile({ file: e.target.files[0] }));
              }}
              className="position-relative "
              style={{ width: '100%', height: '100%', opacity: '0' }}
              id="myFile"
            />
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <BsFillCloudUploadFill className="text-primary fs-2 d-block mx-auto my-1" />
              <label tabindex="0" for="myFile" className="bg-primary  text-white px-3 py-1 rounded pe-auto" style={{ cursor: 'pointer' }}>Select a file...</label>
              <p className=" my-1">Select file:  {emailListFile?.name}</p>
            </div>
          </div>
          <Button type="submit" variant="primary" className="my-2 ms-auto me-0 d-block">Send Invitation</Button>
        </form>
      </div >
    </div >
  );
};

export default EventInvitation;
