import React, {useEffect, useState} from 'react';
import {rootPageState} from "../../../../Main/Routes/reduxSlice";
import {useDispatch, useSelector} from "react-redux";


import {Form, InputGroup, ListGroup} from "react-bootstrap";
import {FaFilter} from "react-icons/fa";
import {Link, useParams} from "react-router-dom";
import {
    clearAllDataArr,
    clearAllFound,
    eventsListAsync,
    eventsListState,
    groupsEventsAsync,
    joinedEventsAsync,
    organizedEventsAsync
} from "../../../../Main/Components/Events/Card/reduxSlice";
import {getSavedEventsOfUserAsync, userState} from "../../../../Main/Components/User/reduxSlice";

import EventGroupCard from "./EventGroupCard/App";
import {groupOnlyEventAsync, groupState} from "../MyGroupOnBoard/reduxSlice";
import {BsSearch} from "react-icons/bs";
import Upcoming from "./Upcoming/App";
import Draft from "./Draft/App";
import Past from "./Past/App";


const Event = () => {
    const {isAdmin, isSubscriber, isAttendee, isBizAdmin} =
        useSelector(rootPageState);
    const [page, setPage] = useState(0);

    const pageDisplay = () => {
        if (page === 0) {
            return <Upcoming/>;
        } else if (page === 1) {
            return <Draft/>
        } else {
            return <Past/>
        }
    }
    return (
        <div>
            <div id="members" className="container py-5">
                <div className="row align-items-start">
                    <div className="col-lg-3 mb-4">
                        <div className="card border bg-dark text-light card-fluid">
                            <nav className="nav side-nav flex-row flex-nowrap flex-lg-column flex-lg-wrap">
                                {isAdmin || isSubscriber || isBizAdmin ?
                                    <span onClick={() => setPage(1)}
                                          className={page === 1 ? "nav-link fs-6  fw-bold text-primary cursor-event" : "nav-link fs-6  fw-bold text-light cursor-event"}>
                                    Draft
                                </span>
                                    : null
                                }

                                <span onClick={() => setPage(0)}
                                      className={page === 0 ? "nav-link fs-6 fw-bold text-primary cursor-event" : "nav-link fs-6  fw-bold text-light cursor-event"}>
                                    Upcoming
                                </span>
                                <span onClick={() => setPage(2)}
                                      className={page === 2 ? "nav-link fs-6 fw-bold text-primary cursor-event" : "nav-link fs-6  fw-bold text-light cursor-event"}>
                                    Past
                                </span>
                            </nav>
                        </div>
                    </div>

                    <div className="col-md-9 col-xs-12">
                        <div className="">
                            {pageDisplay()}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Event;