import React, { useEffect } from "react";
import Member_Card from "../../MyGroup_Props/Members_Card/App";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGroupsDataArr,
  groupsListState,
  setFound,
} from "../../../../../Main/Components/Groups/Card/reduxSlice";
import {
  groupOrganizerDetailsAsync,
  groupState,
} from "../../MyGroupOnBoard/reduxSlice";
import { useParams } from "react-router-dom";
//
// const data = [
//     {id: 0, name: "Aliya Sporer", role: "Organizer"},
//     {id: 1, name: "Aliya Sporer", role: "Organizer"}
// ]

const Organizer_Member = () => {
  const { companyName, groupNameParams } = useParams();
  const {
    groupOrganizerDetailsLoading,
    groupOrganizerDetails,
    foundGroupOrganizerDetails,
    generatedGroupId,
  } = useSelector(groupState);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(groupOrganizerDetailsAsync({ companyName, groupNameParams }));
  }, [dispatch, companyName, groupNameParams]);
  return (
    <div>
      <div>
        <Member_Card
          name={
            groupOrganizerDetails.firstName +
            " " +
            groupOrganizerDetails.lastName
          }
          role="Organizer"
        />
      </div>
    </div>
  );
};

export default Organizer_Member;
