import React, { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  createEventState,
  setEventName,
  setStartDate,
  setStartTime,
  setEndDate,
  setEndTime,
  setDescription,
  setEventType,
  setEventDraft,
  setEventMode,
  setLocation,
  setLink,
  setSelectedFiles,
  clearSelectedFiles,
  setPreviewFiles,
  clearPreviewFiles,
  setShowRegistrationForm,
  setAddRegistrationForm,
  setMapLink,
  setEditEvent,
  setDataToEdit,
  createEventAsync,
  updateEventAsync,
  clearEvent,
  setShowOrganizeTabs,
  setAddDocumentForm,
  setShowDocumentForm,
  checkAllTabs,
  uncheckAllTabs,
  setShowCommunicationTabs,
  setEventLogoRedirectLink,
  setEventLogoSelectedFile,
} from "./reduxSlice";
// react-bootstrap
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Placeholder from "react-bootstrap/Placeholder";
// icon
import { Icon } from "@iconify/react";
import { BiArrowBack } from "react-icons/bi";

// form builder preview
import * as variables from "../RegistrationForm/components/variables";

import TextEditor from "../../../../Main/Components/TextEditor/App";
import RegistrationForm from "../RegistrationForm/App";
import PreviewForm from "../RegistrationForm/components/PreviewForm";
import { useParams, useNavigate, Link, useMatch } from "react-router-dom";
import {
  eventAsync,
  eventState,
} from "../../../../Main/Components/Events/Page Component/reduxSlice";
import OrganizeTabs from "./Components/OrganizeTabs/OrganizeTabs";
import MultipleInputFormBuilder from "../../../../Main/Components/Events/Page Component/Documents/components/MultipleInputFormBuilder/App";
import {
  getFormDataAsync,
  setFormData,
  setShowUploadDocumentForm,
} from "../../../../Main/Components/Events/Page Component/Documents/components/FormGenerator/reduxSlice";
import {
  formBuilderState,
  getDocumentFormInputDataAsync,
} from "../../../../Main/Components/Events/Page Component/Documents/components/MultipleInputFormBuilder/reduxSlice";
import FormGenerator from "../../../../Main/Components/Events/Page Component/Documents/components/FormGenerator/App";
import { Form } from "react-bootstrap";
import CommunicationTabs from "./Components/CommunicationTabs";
import {
  resetEventLogonLink,
  setLogoLink,
  setRedirectLink,
} from "../../../../Main/Components/Navbar/reduxSlice";
import UpdateEmail from "./Components/UpdateEmail/App";

// import { eventAsync } from "D:\bizconnectpreEventsrccomponentsTestcomponentsMyEvents\reduxSlice.js";

const ScheduleEvent = () => {
  const { companyName, groupNameParams, eventNameParams } = useParams();
  const editMode = useMatch("/:companyName/:eventName/edit");
  const { eventData } = useSelector(eventState);
  const dispatch = useDispatch();

  const { eventLogo } = eventData;

  useEffect(() => {
    (async () => {
      if (editMode) {
        if (Object.keys(eventData).length === 0) {
          await dispatch(eventAsync({ companyName, eventNameParams }));
        }

        dispatch(getDocumentFormInputDataAsync({}));
        dispatch(setEditEvent({ value: true }));
        dispatch(setDataToEdit({ eventData }));
      }
    })();

    return () => {
      dispatch(clearEvent({}));
    };
  }, [dispatch, editMode, eventData, companyName, eventNameParams]);

  const {
    // Ui State
    showOrganizeTabs,

    editEvent,

    loading,
    showRegistrationForm,
    addRegistrationForm,

    addDocumentForm,
    showDocumentForm,

    preViewFiles,

    eventName,
    eventStartsAtDate,
    eventStartsAtTime,
    eventEndsAtDate,
    eventEndsAtTime,
    location,
    mapLink,
    description,

    eventMode,
    link,
    eventLogoRedirectLink,

    showCommunicationTabs,
  } = useSelector(createEventState);

  useEffect(() => {
    if (eventLogo && eventLogoRedirectLink) {
      dispatch(setLogoLink({ value: eventLogo }));
      dispatch(setRedirectLink({ value: eventLogoRedirectLink }));
    }

    return () => {
      dispatch(resetEventLogonLink({}));
    };
  }, [dispatch, eventLogo, eventLogoRedirectLink]);

  const { inputFields } = useSelector(formBuilderState);

  //loading
  const [imgLoading, setImgLoading] = useState(true);

  const navigate = useNavigate();

  const submitEventHandler = (event) => {
    event.preventDefault();
    if (eventMode === "online") {
      dispatch(setLocation({ value: null }));
    } else if (eventMode === "offline") {
      dispatch(setLink({ value: null }));
    }

    if (!editEvent) {
      // dispatch(uploadAsync({ groupId, navigate }));
      dispatch(createEventAsync({ companyName, groupNameParams, navigate }));
    } else {
      // dispatch(updateEventImgAsync({ eventId, groupId, navigate }));
      dispatch(updateEventAsync({ companyName, eventNameParams, navigate }));
    }
  };

  const previewFiles = (evt) => {
    dispatch(clearSelectedFiles({}));
    dispatch(clearPreviewFiles({}));

    var files = evt.target.files;

    for (let i = 0; i < evt.target.files.length; i++) {
      const newImage = evt.target.files[i];

      newImage["id"] = Math.random();
      dispatch(setSelectedFiles({ newImage }));
    }

    for (var i = 0, len = files.length; i < len; i++) {
      var file = files[i];

      var reader = new FileReader();

      reader.onload = (function (f) {
        return function (e) {
          // Here you can use `e.target.result` will give you <img src={e.target.result} link
          // and `f.name` will give you file name

          dispatch(setPreviewFiles({ newPreview: e.target.result }));
        };
      })(file);

      reader.readAsDataURL(file);
    }
  };

  const previewForm = (e) => {
    dispatch(setFormData({ value: inputFields }));
    dispatch(setShowUploadDocumentForm({ value: true }));
  };

  const popover = (
    <Popover className="" id="popover-basic">
      <Popover.Body>
        Event Page Has Many Tabs You Can Manage All From Here And Reposition
        Tabs.
      </Popover.Body>
    </Popover>
  );
  const popover_askQue = (
    <Popover className="" id="popover-basic">
      <Popover.Body>Ask Your Question to Attendee via Form</Popover.Body>
    </Popover>
  );
  const popover_takeDoc = (
    <Popover className="" id="popover-basic">
      <Popover.Body> Collect Documents from attendee </Popover.Body>
    </Popover>
  );
  const popover_addOnDoc = (
    <Popover className="" id="popover-basic">
      <Popover.Body> Provide Url or Javascript .</Popover.Body>
    </Popover>
  );

  const popover_EventLogo = (
    <Popover className="" id="popover-basic">
      <Popover.Body>
        {editEvent ? (
          <>
            Select only if you want to update previous event logo .<br />
          </>
        ) : null}
        This image will appear in Header
        <br />( Top of The Event Page )
      </Popover.Body>
    </Popover>
  );
  const popover_EventLogoLInk = (
    <Popover className="" id="popover-basic">
      <Popover.Body>
        On Click of this logo page will open in new tab.
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <div className="page-wrapper">
        <div className="container py-4 py-lg-6">
          <form onSubmit={submitEventHandler}>
            <div className="row align-items-start">
              <div className="col-12 col-md-8 pe-md-5">
                <h4> {editEvent ? "Edit" : "Create"} an event</h4>

                <Link to={`/${companyName}/${eventNameParams}`}>
                  <BiArrowBack /> back
                </Link>

                <hr></hr>

                <div className="form-group mb-4">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control bg-dark text-light "
                    value={eventName}
                    onChange={(e) => {
                      dispatch(setEventName({ value: e.target.value }));
                    }}
                    required
                    placeholder="Event Name"
                    type="text"
                  />
                </div>

                <div className="row align-items-end mb-4">
                  <div className="col-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Event Logo
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={popover_EventLogo}
                        >
                          <Icon
                            className=" ms-2 "
                            icon="entypo:info-with-circle"
                          />
                        </OverlayTrigger>
                      </label>
                      <div className="">
                        <input
                          className=" bg-dark text-light form-control "
                          multiple={false}
                          onChange={(e) => {
                            dispatch(
                              setEventLogoSelectedFile({
                                value: e.target.files[0],
                              })
                            );
                          }}
                          placeholder=""
                          tabIndex="0"
                          accept="image/*"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">
                        Event Link
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          overlay={popover_EventLogoLInk}
                        >
                          <Icon
                            className=" ms-2 "
                            icon="entypo:info-with-circle"
                          />
                        </OverlayTrigger>
                      </label>

                      <input
                        className="form-control bg-dark text-light input "
                        value={eventLogoRedirectLink}
                        onChange={(e) => {
                          dispatch(
                            setEventLogoRedirectLink({ value: e.target.value })
                          );
                        }}
                        type="link"
                      />
                    </div>
                  </div>
                </div>

                <div className="row align-items-end mb-4">
                  <div className="col-5">
                    <div className="form-group ">
                      <label className="form-label">Start</label>
                      <div>
                        <input
                          className=" bg-dark text-light form-control input"
                          value={eventStartsAtDate}
                          onChange={(e) => {
                            dispatch(setStartDate({ value: e.target.value }));
                          }}
                          required
                          placeholder=""
                          tabIndex="0"
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-md-3">
                    <div className="form-group">
                      <input
                        value={eventStartsAtTime}
                        onChange={(e) => {
                          dispatch(setStartTime({ value: e.target.value }));
                        }}
                        className="form-control bg-dark text-light input "
                        type="time"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 mb-md-2 fs-7">GMT +05:30</p>
                  </div>
                  <div className="col-12"></div>
                </div>

                <div className="row align-items-end mb-4">
                  <div className="col-5">
                    <div className="form-group ">
                      <label className="form-label">End</label>
                      <div>
                        <input
                          className="bg-dark text-light form-control form-control input"
                          placeholder=""
                          tabIndex="0"
                          value={eventEndsAtDate}
                          onChange={(e) => {
                            dispatch(setEndDate({ value: e.target.value }));
                          }}
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4 col-md-3">
                    <div className="form-group">
                      <input
                        className="form-control bg-dark text-light "
                        type="time"
                        value={eventEndsAtTime}
                        onChange={(e) => {
                          dispatch(setEndTime({ value: e.target.value }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 mb-md-2 fs-7">GMT +05:30</p>
                  </div>
                  <div className="col-12"></div>
                </div>

                <div className="col-12 mb-4">
                  <label className="form-label">Featured Photo</label>
                  <label className="form-group border d-flex justify-content-center h-450  border-3 border-dotted text-center w-100">
                    <input
                      accept="image/*"
                      multiple={true}
                      onChange={previewFiles}
                      className="d-none bg-dark text-light"
                      type="file"
                    />

                    <div className="img-wrap ratio-16-9 w-100">
                      {preViewFiles?.length <= 0 ? (
                        <div className="img-content h-100 ">
                          <>
                            <Image
                              fluid
                              draggable={false}
                              src={"https://picsum.photos/300/200"}
                              style={{
                                aspectRatio: "16 / 9  ",
                              }}
                              className={`w-100 ${
                                imgLoading ? "d-none" : null
                              }`}
                              onLoad={() => {
                                setImgLoading(false);
                              }}
                              alt="Runolfsdottir Group"
                            />
                            {imgLoading ? (
                              <Placeholder className="d-block" animation="glow">
                                <Placeholder
                                  className="  d-block  "
                                  style={{
                                    aspectRatio: "16 / 9 ",
                                  }}
                                />
                              </Placeholder>
                            ) : null}
                          </>
                        </div>
                      ) : null}
                      {preViewFiles?.map((tempImg) => (
                        <div className="img-content ">
                          <Image className="w-100" fluid src={tempImg} />
                        </div>
                      ))}
                    </div>
                    <div className="select_photo_container d-none p-5">
                      <a href="/" className="btn btn-outline-primary mb-3">
                        <i className="far fa-image"></i>
                        Choose photo
                      </a>
                      <br></br>
                      <small className="form-text">
                        At least 1200 x 675 pixels
                      </small>
                    </div>
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label className="form-label">Description</label>
                  <div className="form-check form-switch p-0">
                    <TextEditor
                      editorState={description}
                      setEditorState={(newState) => {
                        dispatch(setDescription({ value: newState }));
                      }}
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Event type</label>
                  <div className="d-flex ">
                    <div class="form-check mx-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        value="online"
                        id="flexRadioDefault1"
                        checked={eventMode === "online"}
                        onClick={(e) => {
                          dispatch(setEventMode({ value: e.target.value }));
                        }}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Online
                      </label>
                    </div>
                    <div class="form-check mx-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={eventMode === "hybrid"}
                        value="hybrid"
                        onClick={(e) => {
                          dispatch(setEventMode({ value: e.target.value }));
                        }}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Hybrid
                      </label>
                    </div>
                    <div class="form-check mx-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        checked={eventMode === "offline"}
                        value="offline"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        onClick={(e) => {
                          dispatch(setEventMode({ value: e.target.value }));
                        }}
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        Offline
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className={` ${
                    eventMode === "online" || eventMode === "hybrid"
                      ? ""
                      : "d-none"
                  }  form-group mb-4`}
                >
                  <label className="form-label">Online Meeting Link</label>
                  <input
                    type="url"
                    required={eventMode === "online" || eventMode === "hybrid"}
                    value={link}
                    onChange={(e) => {
                      dispatch(setLink({ value: e.target.value }));
                    }}
                    className="form-control bg-dark text-light "
                    placeholder="Ex. https://zoom.us/"
                  />
                  <small className="text-muted">
                    Link will only be visible to people who RSVP.
                  </small>
                </div>

                <div className="">
                  <div
                    className={` ${
                      eventMode === "offline" || eventMode === "hybrid"
                        ? ""
                        : "d-none"
                    }  form-group mb-0`}
                  >
                    <label className="form-label">Location</label>
                    <div className="input-icon mb-3 place_autocomplete_container">
                      <span className="input-icon-addon"></span>
                      <input
                        id="place_autocomplete"
                        value={location}
                        onChange={(e) => {
                          dispatch(setLocation({ value: e.target.value }));
                        }}
                        className="form-control place_autocomplete bg-dark text-light "
                        autoComplete="off"
                        placeholder="Location"
                        type="text"
                      />
                    </div>
                    <label className="form-label">Map Link</label>
                    <div className="input-icon mb-3 place_autocomplete_container">
                      <span className="input-icon-addon"></span>
                      <input
                        id="place_autocomplete"
                        value={mapLink}
                        onChange={(e) => {
                          dispatch(setMapLink({ value: e.target.value }));
                        }}
                        className="form-control place_autocomplete bg-dark text-light "
                        autoComplete="off"
                        placeholder="Map link"
                        type="link"
                      />
                    </div>
                  </div>

                  {/* <div className="form-group mb-4">
                    <label className="form-label">
                      How to find us
                      <span className="text-muted">(Optional)</span>
                    </label>
                    <textarea
                      className="form-control bg-dark text-light "
                      rows="3"
                      placeholder="How to find us"
                    ></textarea>
                  </div> */}
                </div>

                <div className="row border-bottom py-3">
                  <div className="col-9">
                    <p className="mb-0 fs-6">
                      {editEvent ? "Update" : "Create"} Registration Form
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover_askQue}
                      >
                        <Icon
                          className=" ms-2 "
                          icon="entypo:info-with-circle"
                        />
                      </OverlayTrigger>
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    <div className="form-check form-switch float-end">
                      <input
                        className="form-check-input"
                        checked={addRegistrationForm}
                        onChange={(e) => {
                          dispatch(
                            setAddRegistrationForm({ value: e.target.checked })
                          );
                          dispatch(
                            setShowRegistrationForm({ value: e.target.checked })
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <div className="row border-bottom py-3">
                  <div className="col-9">
                    <p className="mb-0 fs-6 d-flex align-items-center">
                      Organize Tabs
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover}
                      >
                        <Icon
                          className=" ms-2 "
                          icon="entypo:info-with-circle"
                        />
                      </OverlayTrigger>
                      {showOrganizeTabs ? (
                        <Form.Check
                          className=" ms-2 mb-0"
                          type={"checkbox"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(checkAllTabs({}));
                            } else {
                              dispatch(uncheckAllTabs({}));
                            }
                          }}
                          id={"all-check"}
                          label={"Check All"}
                        />
                      ) : null}
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    <div className="form-check form-switch float-end">
                      <input
                        onClick={() => {
                          dispatch(setShowOrganizeTabs({}));
                        }}
                        checked={showOrganizeTabs}
                        className="form-check-input"
                        id="eventMode"
                        type="checkbox"
                      />
                    </div>
                  </div>

                  {showOrganizeTabs ? <OrganizeTabs /> : null}
                </div>
                {/* upload document */}
                <div className="row border-bottom py-3">
                  <div className="col-9">
                    <p className="mb-0 fs-6">
                      {editEvent ? "Update" : "Create"} Documents Form
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover_takeDoc}
                      >
                        <Icon
                          className=" ms-2 "
                          icon="entypo:info-with-circle"
                        />
                      </OverlayTrigger>
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    <div className="form-check form-switch float-end">
                      <input
                        className="form-check-input"
                        checked={addDocumentForm}
                        onChange={(e) => {
                          dispatch(
                            setAddDocumentForm({ value: e.target.checked })
                          );
                          dispatch(
                            setShowDocumentForm({ value: e.target.checked })
                          );
                        }}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <div className="row border-bottom py-3">
                  <div className="col-9">
                    <p className="mb-0 fs-6 d-flex align-items-center">
                      Addons
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover_addOnDoc}
                      >
                        <Icon
                          className=" ms-2 "
                          icon="entypo:info-with-circle"
                        />
                      </OverlayTrigger>
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    <div className="form-check form-switch float-end">
                      <input
                        onClick={() => {
                          dispatch(
                            setShowCommunicationTabs({
                              value: !showCommunicationTabs,
                            })
                          );
                        }}
                        checked={showCommunicationTabs}
                        className="form-check-input"
                        id="eventMode"
                        type="checkbox"
                      />
                    </div>
                  </div>

                  {showCommunicationTabs ? <CommunicationTabs /> : null}
                </div>
                
                {editEvent ? <UpdateEmail /> : null}

                <div className="col-12 mt-4">
                  <div className="float-end">
                    <Button
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        dispatch(setEventDraft({ value: true }));
                      }}
                      className="btn-tertiary text-white"
                    >
                      {loading ? (
                        <Icon icon="eos-icons:bubble-loading" />
                      ) : (
                        <>{editEvent ? "Update as draft" : "Save as draft"}</>
                      )}
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary ms-2"
                    >
                      {loading ? (
                        <Icon icon="eos-icons:bubble-loading" />
                      ) : (
                        <>{editEvent ? "Update" : "Publish"}</>
                      )}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-none d-md-block  mb-3 top-5 z-index-10">
                <div className="card p-4 border bg-dark text-light shadow-sm">
                  <div className="card-body p-2">
                    <h3>Tips for a great event</h3>
                    <hr></hr>

                    <p className="fw-bold fst-italic mb-1">Be descriptive</p>
                    <p className="fw-light mb-4">
                      A good title immediately gives people an idea of what the
                      event is about
                    </p>

                    <p className="fw-bold fst-italic mb-1">Get organized</p>
                    <p className="fw-light mb-4">
                      Describe things in a clear order so it's easy to digest.
                      Start with an overall description of the event and include
                      a basic agenda, before you move into really specific
                      details.
                    </p>
                    <p className="fw-bold fst-italic mb-1">Add an image</p>
                    <p className="fw-light mb-4">
                      Upload a photo or image (Ratio 16:9) to give members a
                      better feel for the event. <br />
                      Optional for home page - add image size 1920x760px for
                      full stretched image.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <footer className="position-relative z-index-10 d-print-none"></footer>
      </div>
      <Modal
        fullscreen={true}
        className="registrationForm_biz"
        show={showRegistrationForm}
        onHide={() => {
          dispatch(setShowRegistrationForm({ value: false }));
        }}
      >
        <Modal.Body className="p-0     ">
          {/* overflow-hidden  */}
          <div>
            <RegistrationForm />
          </div>
        </Modal.Body>
        <Modal.Footer className="py-0">
          <PreviewForm variables={variables} />

          <Button
            variant="secondary"
            onClick={() => {
              dispatch(setShowRegistrationForm({ value: false }));
              dispatch(setAddRegistrationForm({ value: false }));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(setShowRegistrationForm({ value: false }));
            }}
          >
            {editEvent ? "Update Changes" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        fullscreen={true}
        className=""
        show={showDocumentForm}
        onHide={() => {
          dispatch(setShowDocumentForm({ value: false }));
        }}
      >
        <Modal.Body className="p-0 bg-dark text-light">
          {/* overflow-hidden  */}
          <div className="bg-dark text-light">
            <MultipleInputFormBuilder />
          </div>
        </Modal.Body>
        <Modal.Footer className="py-0 bg-dark text-light">
          <Button variant="primary" onClick={previewForm}>
            Preview Form
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              dispatch(setShowDocumentForm({ value: false }));
              dispatch(setAddDocumentForm({ value: false }));
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(setShowDocumentForm({ value: false }));
            }}
          >
            {editEvent ? "Update Changes" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      <FormGenerator submit={false} />
    </div>
  );
};

export default ScheduleEvent;
