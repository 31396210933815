import React from "react";

// Css
// import "../../App.css";

// Bootstrap
import { Button, CloseButton, Toast } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

// Redux
import { useSelector } from "react-redux";
import { darkModeState } from "../../../../../Main/Components/DarkMode/reduxSlice";
import { eventState } from "../reduxSlice";

// Utils
import isValidUrl from "../../../utils/isValidUrl";

export default function Poll(props) {
  const {
    eventData: { pollLink },
  } = useSelector(eventState);
  const { dark } = useSelector(darkModeState);

  let isValidPollLink = isValidUrl(pollLink);
  return (
    <>
      {isValidPollLink ? (
        // <Modal
        //   {...props}
        //   size="lg"
        //   aria-labelledby="contained-modal-title-vcenter"
        //   top
        // >
        //   <Modal.Header className="bg-dark text-light border-0">
        //     <Modal.Title id="contained-modal-title-vcenter">Poll</Modal.Title>
        //     <CloseButton
        //       className="shadow-none ms-auto me-0  "
        //       variant={`${dark ? "white" : ""}`}
        //       onClick={props.onHide}
        //     />
        //   </Modal.Header>
        //   <Modal.Body
        //     className="position-relative px-0 "
        //     style={{ height: "15rem" }}
        //   >
        //     <iframe
        //       title="chat"
        //       id="modalIframe"
        //       src={`${pollLink}${dark ? "?mode=dark" : "?mode=light"}`}
        //       width={"100%"}
        //       height={"100%"}
        //       style={{ overflowX: "hidden" }}
        //       allowtransparency="true"
        //       className={`position-absolute top-0 left-0 ${
        //         dark ? "bg-light" : "bg-dark"
        //       }`}
        //     ></iframe>
        //   </Modal.Body>
        //   <Modal.Footer className="text-light bg-dark border-0">
        //     <Button onClick={props.onHide}>Close</Button>
        //   </Modal.Footer>
        // </Modal>
        <Toast  {...props} onClose={props.onHide} className="position-fixed bottom-0" style={{zIndex:'10000000000000'}}>
          <Toast.Header>
            <strong className="me-auto">Poll</strong>
          </Toast.Header>
          <Toast.Body className="position-relative px-0"
            style={{ height: "25rem" }}>
            <iframe
              title="chat"
              id="modalIframe"
              src={`${pollLink}${dark ? "?mode=dark" : "?mode=light"}`}
              width={"100%"}
              height={"100%"}
              style={{ overflowX: "hidden" }}
              allowtransparency="true"
              className={`position-absolute top-0 left-0 ${
                dark ? "bg-light" : "bg-dark"
              }`}
            ></iframe>
          </Toast.Body>
        </Toast>
      ) : (
        eval(pollLink)
      )}
    </>
  );
}
