import React from "react";

import { useSelector } from "react-redux";

import moment from "moment";
import { eventState } from "../Page Component/reduxSlice";

export function FakeEmailPreviewTemp() {
  const { eventData } = useSelector(eventState);

  const {
    eventGallery = [
      "https://i.picsum.photos/id/22/1920/760.jpg?hmac=TxDu0nz3WyJW0Plm35KPIkX64rmG36z0OyOTKKek-ak",
    ],
    id = "jdDXrKLXWhxmhy64HhhX",
    eventMode,
    generalChatLink,
    groupId,
    link = "http://localhost:3000",
    sponsorType2,
    description,
    subscriberUid,
    sponsorType3,
    sponsorType1,
    QnaLink,
    draft,
    sponsorsId,
    eventName = "hubplus 3",
    boothownersId,
    eventStartsAt,
    eventEndsAt,
    pollLink,
    eventType,
    mapLink = "http://localhost:3000/emailPreview",
    displayPdf,
    subSubscribersId,
    sponsorType4,
    location = "Surat",
    eventLogoRedirectLink,
    eventLogo = ["https://picsum.photos/id/21/400/300"],
  } = eventData;

  let clientName = "dhruv sakariya";

  return (
    <div style={{ padding: "0", margin: "0", fontSize: "16px" }}>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ margin: "0 auto", width: "100%" }}
          src={eventGallery}
          alt="Event"
        />
      </div>
      <div style={{ padding: "1em 2em" }}>
        <p
          style={{
            fontWeight: "700",
            marginBottom: "0.5em",
            fontSize: "1.1rem",
          }}
        >
          Hello {clientName}
        </p>
        <p style={{ fontSize: "0.85rem", marginTop: "0" }}>
          You have Invited to {eventName}
        </p>
        <p>
          Pre-event and Networking :{" "}
          <a
            href={`https://preevent.netlify.app/event/${id}`}
            style={{ textDecoration: "none" }}
          >
            Log In and explore more details
          </a>
        </p>

        <table
          style={{
            fontFamily: "arial, sans-serif",
            borderCollapse: "collapse",
            width: "100%",
          }}
          width="100%"
        >
          <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              Starts At Date &amp; Time
            </td>
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              {moment(eventStartsAt?._seconds).format("llll")}
            </td>
          </tr>

          {location ? (
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Location
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                {location}
              </td>
            </tr>
          ) : null}
          {mapLink ? (
            <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Google Map
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                <a
                  href={mapLink}
                  style={{ textDecoration: "none" }}
                  target="_black"
                >
                  {mapLink}
                </a>
              </td>
            </tr>
          ) : null}
          {link ? (
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Virtual meeting link
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                <a
                  href={link}
                  style={{ textDecoration: "none" }}
                  target="_black"
                >
                  {link}
                </a>
              </td>
            </tr>
          ) : null}
          <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              Ends At Date &amp; Time
            </td>
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              {moment(eventEndsAt?._seconds).format("llll")}
            </td>
          </tr>
        </table>
        <p className="mt-2">Thank You !</p>
        {eventLogo ? (
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "100px" }} src={eventLogo} alt="Logo" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
