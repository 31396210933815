import React, { useEffect } from "react";

// Routes
import { Route, Routes } from "react-router-dom";

// Css
import "./App.css";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

// Components
import { ErrorToast } from "./components/Main/Components/Error/App";
import DarkModeSwitch from "./components/Main/Components/DarkMode/App";
import Footer from "./components/Main/Components/Footer/App";
import { Wrapper } from "./components/Main/Components/utils/ScrollToTop";

import { Login } from "./components/Main/Components/auth/Login/App";
import { Register } from "./components/Main/Components/auth/Register/App";
import { RootPage } from "./components/Main/Routes/App";
import PricingPage from "./components/Main/Components/Pricing/App"

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getDocIdofUserAsync,
  setUserAvl,
  userState,
} from "./components/Main/Components/User/reduxSlice";
import {
  autoLoginState,
  clearLoadingState,
  loginState,
} from "./components/Main/Components/auth/Login/reduxSlice";
import { toggleMode } from "./components/Main/Components/DarkMode/reduxSlice";

// Firebase
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "./firebase.config";
import { eventState } from "./components/Main/Components/Events/Page Component/reduxSlice";
import { setInstallBtn } from "./components/Main/Components/Navbar/reduxSlice";
import { useAddToHomescreenPrompt } from "./components/Main/Components/hooks/useAddToHomescreenPrompt";
import { notificationState } from "./components/Main/Components/User/Notification/reduxSlice";

function App() {
  const dispatch = useDispatch();
  const { loading } = useSelector(loginState);
  const { loadingDocId } = useSelector(userState);
  const { registeringNewUser } = useSelector(eventState);
  const { showNotification, notifications } = useSelector(notificationState);

  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  // Set Theme
  useEffect(() => {
    const setTheme = () => {
      const darkTheme = localStorage.getItem("theme");

      if (darkTheme === "dark") {
        dispatch(toggleMode({}));
      } else if (darkTheme === "light") {
      } else {
        localStorage.setItem("theme", "light");
      }
    };

    setTheme();
  }, [dispatch]);

  useEffect(() => {
    if (prompt) {
      dispatch(setInstallBtn({ value: true, cb: promptToInstall }));
    }
  }, [dispatch, prompt, promptToInstall]);

  useEffect(() => {
    if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
      console.log("Your web browser and O.S. support Badges");
    } else {
      console.log("Your web browser and O.S. DON'T support Badges :( ");
    }
  }, []);

  useEffect(() => {
    const autoLogin = async () => {
      const token = localStorage.getItem("preEventToken");

      if (token) {
        dispatch(autoLoginState({}));
        try {
          const userCredential = await signInWithCustomToken(auth, token);
          const user = userCredential.user;
          if (user) {
            dispatch(setUserAvl({ userAvl: true, user }));
            dispatch(getDocIdofUserAsync({ uid: user.uid }));
          }
        } catch (error) {}
        dispatch(clearLoadingState({}));
      }
    };
    autoLogin();
  }, [dispatch]);

  const LoadingLogin = () => {
    return (
      <div className="">
        {/* d-flex justify-content-center bg-dark text-light align-items-center vh-100 */}
        <Spinner animation="grow" />
      </div>
    );
  };

  return (
    <>
      {(loading || loadingDocId) && !registeringNewUser ? (
        <div
          class="vw-100 bg-dark text-light vh-100 position-fixed row justify-content-center align-items-center text-center "
          style={{ zIndex: 100000000 }}
        >
          <LoadingLogin />
        </div>
      ) : null}
      <div className="  bg-dark text-light min-vh-100 ">
        <DarkModeSwitch />
        <ErrorToast />
        {/* <Root/> */}
        <Wrapper>
          <Routes>
            <Route>
              {/* Frontend Ui */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="*" element={<RootPage />} />
              {/* Backend Ui */}
            </Route>
          </Routes>
        </Wrapper>
        <Footer /> {/**dhruv : remove footer from here later */}
      </div>
    </>
  );
}

export default App;
