import React, { useState } from "react";

// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Image, Placeholder } from "react-bootstrap";

// Redux
import { useDispatch } from "react-redux";

// Components
import GroupSkeleton from "../PlaceHolder/GroupSkeleton";
import NotFoundGroups from "../NotFound/App";

const GroupCard = ({ groupsDataArr, found, loading, title }) => {
  const dispatch = useDispatch();
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <div>
      <section>
        <div className="d-flex justify-content-between align-items-center my-4 my-sm-3 my-md-4">
          <div>
            <h4 className="h5">{title}</h4>
          </div>
          <div>
            <Link to="/groups/ga" className="btn btn-outline-primary">
              <small>See all your groups</small>
            </Link>
          </div>
        </div>

        <div className="row m-4 ">
          {loading ? (
            <GroupSkeleton />
          ) : (
            <>
              {found ? (
                <>
                  {groupsDataArr.map(
                    (
                      {
                        id,
                        groupGallery,
                        groupName,
                        groupType,
                        // subscriberUid,
                        // description,
                        // eventUid,
                        // totalMember,
                        // memberUid,
                        // location,
                        companyName,
                      },
                      index
                    ) => (
                      <div key={id} className="col-12 col-lg-3">
                        <Link
                          to={`/${companyName}/group/${groupName}`}
                          className="card card-link text-decoration-none mb-3 border shadow-sm overflow-hidden"
                        >
                          <div className="card-body bg-dark text-light p-0 ">
                            <div className="row align-items-center flex-nowrap gap-2 ">
                              <div className="col-5 p-0 ">
                                <Image
                                  fluid
                                  draggable={false}
                                  src={groupGallery}
                                  style={{
                                    aspectRatio: "3 / 2",
                                  }}
                                  className={`rounded-start shadow-sm ${
                                    imgLoading ? "d-none" : null
                                  }`}
                                  onLoad={() => {
                                    setImgLoading(false);
                                  }}
                                  alt="..."
                                />
                                {imgLoading ? (
                                  <Placeholder animation="glow">
                                    <Placeholder
                                      className="rounded-start w-100 h-100 "
                                      style={{
                                        aspectRatio: "3 / 2 ",
                                      }}
                                    />
                                  </Placeholder>
                                ) : null}
                              </div>
                              <div className="col-6 py-2">
                                <h6 className="fw-bold mb-0 text-truncate text-truncate-two-line">
                                  {groupName}
                                </h6>
                                <p className="text-muted fst-italic mb-0">
                                  {groupType} group
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  )}
                </>
              ) : (
                <NotFoundGroups />
              )}
            </>
          )}

          {/*Add more*/}
        </div>
      </section>
    </div>
  );
};

export default GroupCard;
