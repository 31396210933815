import React, { useEffect } from "react";

// Routes
import { useParams } from "react-router-dom";

// Animation
import { useSpring } from "@react-spring/web";

// Bootstrap
import {
  Button,
  Container,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// Time
import moment from "moment";

import { darkModeState } from "../../../DarkMode/reduxSlice";

// Icons
import { AiOutlineUpload } from "react-icons/ai";
import {
  BsFillStarFill,
  BsStar,
  BsQuestionSquareFill,
  BsFillChatLeftTextFill,
} from "react-icons/bs";
import { FaShareAlt, FaPoll } from "react-icons/fa";
import { Icon } from "@iconify/react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  eventState,
  joinEventAsync,
  leaveEventAsync,
  saveEventAsync,
  setEventpageNavPannel,
  setLoginUserViaModal,
  setRegisteringNewUser,
  setShowRegistrationForm,
  unSaveEventAsync,
} from "../reduxSlice";
import { getSavedEventsOfUserAsync, userState } from "../../../User/reduxSlice";
import { setShareLink } from "../../../ShareModal/reduxSlice";
import { setStickyTop } from "../../../Navbar/reduxSlice";
import {
  formGeneratorState,
  setShowUploadDocumentForm,
} from "../Documents/components/FormGenerator/reduxSlice";

const EventTabs = ({
  handleShareShow,
  handlePollShow,
  handlePollClose,
  handleQAShow,
  handleQAClose,
  handleChatShow,
  handleChatClose,
}) => {
  // Var
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStickyTop({ value: false }));
    return () => {
      dispatch(setStickyTop({ value: true }));
    };
  }, [dispatch]);

  // State
  const { companyName, eventNameParams } = useParams();

  const {
    registeringNewUser,
    joinuser,
    eventPageNav,
    eventActionLoading,
    eventData: {
      id,
      subscriberUid,
      attendeesId,
      tabsManagement,
      eventName,
      location,
      eventStartsAt,
      eventEndsAt,
      eventMode,
      displayPdf,
    },
    formData: { id: formDataID },
  } = useSelector(eventState);
  const { dark } = useSelector(darkModeState);
  const { userAvl, user, savedEvents } = useSelector(userState);
  const { foundForm } = useSelector(formGeneratorState);

  let isSaved = savedEvents.includes(id);
  let ismyEvent;
  let going;
  if (userAvl) {
    ismyEvent = user?.uid === subscriberUid;
    going = attendeesId?.includes(user?.uid);
  }

  // Animation
  // const border = useSpring({
  //   from: { opacity: "1" },
  //   to: { opacity: "1" },
  //   delay: "200",
  // });
  const backgroundColor = useSpring({
    from: { opacity: "0" },
    to: { opacity: "0.5" },
    delay: "200",
  });

  // Function
  function navPannel(page) {
    switch (page) {
      case "Event Details":
        dispatch(setEventpageNavPannel({ newPannel: "Event Details" }));
        break;
      case "Speakers":
        dispatch(setEventpageNavPannel({ newPannel: "Speakers" }));
        break;
      case "Sponsors":
        dispatch(setEventpageNavPannel({ newPannel: "Sponsors" }));
        break;
      case "Booths":
        dispatch(setEventpageNavPannel({ newPannel: "Booths" }));
        break;
      case "Agenda":
        dispatch(setEventpageNavPannel({ newPannel: "Agenda" }));
        break;
      case "Why attend":
        dispatch(setEventpageNavPannel({ newPannel: "Why attend" }));
        break;
      case "FAQ":
        dispatch(setEventpageNavPannel({ newPannel: "FAQ" }));
        break;
      case "Poll":
        dispatch(setEventpageNavPannel({ newPannel: "Poll" }));
        handleQAClose();
        handleChatClose();
        handlePollShow();
        break;
      case "Q&A":
        dispatch(setEventpageNavPannel({ newPannel: "Q&A" }));
        handlePollClose();
        handleChatClose();
        handleQAShow();
        break;
      case "General Chat":
        dispatch(setEventpageNavPannel({ newPannel: "General Chat" }));
        handleQAClose();
        handlePollClose();
        handleChatShow();
        break;
      case "Upload":
        dispatch(setEventpageNavPannel({ newPannel: "Upload" }));
        if (foundForm) {
          dispatch(setShowUploadDocumentForm({ value: true }));
        }

        break;

      default:
        dispatch(setEventpageNavPannel({ newPannel: "Event Details" }));
        break;
    }
  }

  const actionHandlerofGroup = () => {
    if (!going && !userAvl) {
      dispatch(setLoginUserViaModal({ value: false }));
      dispatch(setShowRegistrationForm({ value: true }));
    }
  };

  const loginHandler = () => {
    dispatch(setLoginUserViaModal({ value: true }));
    dispatch(setShowRegistrationForm({ value: true }));
  };

  const joinandLeaveHandler = async () => {
    dispatch(setRegisteringNewUser({ value: true }));
    if (going) {
      await dispatch(leaveEventAsync({ companyName, eventNameParams }));
    } else {
      await dispatch(joinEventAsync({ companyName, eventNameParams }));
    }
    dispatch(setRegisteringNewUser({ value: false }));
  };

  return (
    <>
      <div className="border shadow-sm rounded col-11 mx-auto p-3 my-2 d-lg-none">
        <div className="row  mt-2 justify-content-between">
          <div className="col-9">
            <h5 className="my-0 text-quaternary"> {moment(eventStartsAt?.toDate()).format("Do")} - {moment(eventEndsAt?.toDate()).format("Do MMMM, YYYY")}</h5>
            <p className="my-0">{location}</p>
          </div>
          <div className="col-3 col-md-2 px-0 text-center">
            <FaShareAlt
              className="cursor_pointer me-3"
              onClick={() => {
                handleShareShow();
                dispatch(
                  setShareLink({
                    value: encodeURI(
                      `${window.location.protocol}//${window.location.host}/${companyName}/${eventNameParams}`
                    ),
                  })
                );
              }}
            />
            {isSaved ? ( //
              <BsFillStarFill
                onClick={async () => {
                  await dispatch(
                    unSaveEventAsync({ companyName, eventNameParams })
                  );
                  dispatch(getSavedEventsOfUserAsync({}));
                }}
                className="starFill cursor_pointer"
              />
            ) : (
              <BsStar
                onClick={async () => {
                  await dispatch(
                    saveEventAsync({ companyName, eventNameParams })
                  );
                  dispatch(getSavedEventsOfUserAsync({}));
                }}
                className="cursor_pointer"
              />
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 d-flex ">
            {!ismyEvent ? (
              <>
                {!userAvl ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}> Attende Event</Tooltip>
                    }
                  >
                    <div className="d-flex ">
                      <Button
                        variant="primary"
                        onClick={actionHandlerofGroup}
                        style={{ minWidth: "100px" }}
                        className="rounded-2 shadow-none text-nowrap  d-block d-lg-none"
                      >
                        {registeringNewUser ? (
                          <Icon
                            icon="line-md:loading-twotone-loop"
                            className="fs-5"
                          />
                        ) : (
                          "Register"
                        )}
                      </Button>
                      <Button
                        variant={`${dark ? "outline-secondary" : "outline-primary"
                          }`}
                        onClick={loginHandler}
                        className="rounded-2 ms-2 shadow-none text-nowrap d-block d-lg-none"
                      >
                        Join
                      </Button>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      onClick={joinandLeaveHandler}
                      style={{ minWidth: "100px" }}
                      className="rounded-2 shadow-none text-nowrap d-block d-lg-none"
                    >
                      {registeringNewUser ? (
                        <Icon
                          icon="line-md:loading-twotone-loop"
                          className="fs-5"
                        />
                      ) : (
                        <> {going ? "Leave Event" : "Join Event"}</>
                      )}
                    </Button>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>

        {/* UI is Changed 
        for Mobile need to redo again
       */}
      </div>
      <Container
        fluid
        className="px-0 px-md-5 mx-0 
        d-flex flex-row 
        justify-content-between align-items-center 
        shadow-sm sticky-top bg-dark text-light 
        w-100 overflow-auto"
        style={{ zIndex: 999 }}
      >
        <ListGroup
          horizontal
          className="overflow-auto rounded-0 mx-0"
          id="eventPageNavbar"
        >
          <ListGroup.Item
            action
            className={`py-3 ${eventPageNav === "Event Details"
              ? "rounded-0 text-nowrap border-0 bg-dark text-quaternary  border-quaternary border-3 border-bottom"
              : "rounded-0 text-nowrap border-0 bg-dark text-light "
              }`}
            onClick={() => {
              navPannel("Event Details");
            }}
          >
            Event Details
          </ListGroup.Item>
          {tabsManagement?.map(({ name, show }, index) => {
            if (name === "Upload") {
              return (
                <ListGroup.Item
                  style={{ backgroundColor }}
                  action
                  className={`py-3 ${eventPageNav === name
                    ? "rounded-0 text-nowrap border-0 bg-dark text-light"
                    : "rounded-0 text-nowrap border-0 bg-dark text-light"
                    } ${!show ? "d-none" : ""}  `}
                  onClick={() => {
                    navPannel(name);
                  }}
                >
                  <span className="bg-primary text-white rounded-pill px-3 py-2 shadow">
                    {name} <AiOutlineUpload className="ms-1" />
                  </span>
                </ListGroup.Item>
              );
            } else if (
              name === "Poll" ||
              name === "Q&A" ||
              name === "General Chat"
            ) {
              return (
                <ListGroup.Item
                  style={{ backgroundColor }}
                  action
                  className={`py-3 ${eventPageNav === name
                    ? "border-0 bg-dark text-quaternary rounded-0 text-nowrap border-quaternary border-3 border-bottom"
                    : "bg-dark text-light border-0 rounded-0 text-nowrap"
                    } ${!show ? "d-none" : ""}  `}
                  onClick={() => {
                    navPannel(name);
                  }}
                >
                  {(name === "Poll" && <FaPoll />) ||
                    (name === "Q&A" && <BsQuestionSquareFill />) ||
                    (name === "General Chat" && <BsFillChatLeftTextFill />)}
                </ListGroup.Item>
              );
            } else {
              return (
                <ListGroup.Item
                  style={{ backgroundColor }}
                  action
                  className={`py-3 ${eventPageNav === name
                    ? "border-0 bg-dark text-quaternary rounded-0 text-nowrap border-quaternary border-3 border-bottom"
                    : "bg-dark text-light border-0 rounded-0 text-nowrap"
                    } ${!show ? "d-none" : ""}  `}
                  onClick={() => {
                    navPannel(name);
                  }}
                >
                  {name}
                </ListGroup.Item>
              );
            }
          })}
        </ListGroup>

        {!ismyEvent ? (
          <>
            {!userAvl ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}> Attende Event</Tooltip>}
              >
                <div className="d-flex ">
                  <Button
                    variant="primary"
                    onClick={actionHandlerofGroup}
                    style={{ minWidth: "100px" }}
                    className="   rounded-2 shadow-none ms-2 text-nowrap  d-none d-lg-block"
                  >
                    {registeringNewUser ? (
                      <Icon
                        icon="line-md:loading-twotone-loop"
                        className="fs-5"
                      />
                    ) : (
                      "Register"
                    )}
                  </Button>
                  <Button
                    variant="tertiary"
                    onClick={loginHandler}
                    className="  text-white rounded-2 shadow-none ms-2 text-nowrap  d-none d-lg-block"
                  >
                    Join
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              <>
                <Button
                  variant="primary"
                  onClick={joinandLeaveHandler}
                  style={{ minWidth: "100px" }}
                  className="   rounded-2 shadow-none ms-2 text-nowrap  d-none d-lg-block"
                >
                  {registeringNewUser ? (
                    <Icon
                      icon="line-md:loading-twotone-loop"
                      className="fs-5"
                    />
                  ) : (
                    <> {going ? "Leave Event" : "Join Event"}</>
                  )}
                </Button>

              </>
            )}
          </>
        ) : null}

      </Container>
    </>
  );
};

export default EventTabs;
