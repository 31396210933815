import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AddCommentDiscussionAPI,
  createDiscussion,
  getCmpandGrpfromGroupId,
  getDiscussionAPI,
  getEachGroupAPI,
  getGroupId,
  getGroupInterestAPI,
  getGroupMemberAPI,
  getGroupOnlyEventAPI,
  getGroupOrganizerAPI,
  getGroupPastEventAPI,
  getGroupUpcomingEventAPI,
  getOnlyDiscussionAPI,
  joinGroupAPI,
  leaveGroupAPI,
} from "./reduxAPI";
import { Icon } from "@iconify/react";
import { pushErrorNotification } from "../../../../Main/Components/Error/reduxSlice";
import { addInterest } from "../../CommonComponents/Interest/reduxApi";

const initialState = {
  generatedGroupId: "",

  loading: false,
  groupActionLoading: false,

  found: true,

  organizer: true,

  groupData: {},

  groupPage: "1",

  groupMemberLoading: false,
  groupMemberDataArr: [],
  foundGroupMember: true,

  groupOnlyEventLoading: false,
  groupOnlyEventDataArr: [],
  foundOnlyGroupEvent: true,

  groupOrganizerDetailsLoading: false,
  groupOrganizerDetails: {},
  foundGroupOrganizerDetails: true,

  upcomingEventDataArr: [],
  foundUpcomingGroupEvent: true,

  pastEventDataArr: [],
  foundPastGroupEvent: true,

  groupInterestLoading: false,
  groupInterestArr: [],

  //Create Discussion
  title: "",
  comment: "",
  discussionDescription: "",
  //fetch
  discussionArr: [],
  groupOnlyDiscussion: {},
};

export const leaveGroupAsync = createAsyncThunk(
  "group/leaveGroup",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { userAvl, user } = getState().user;
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      if (!userAvl) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Authentication required",
              errorMessage:
                "please login to your account to join this event. If you don't have account you can Register !",
              theme: "info",
              time: "now",
              autoHideTime: 4000,
              statusCode: 401,
              show: true,
            },
          })
        );
        return;
      }
      // const response =
      await leaveGroupAPI(result.payload, user.uid);
      dispatch(groupAsync({ companyName, groupNameParams }));

      // return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const joinGroupAsync = createAsyncThunk(
  "group/joinGroup",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { userAvl, user } = getState().user;
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      if (!userAvl) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "Authentication required",
              errorMessage:
                "please login to your account to join this event. If you don't have account you can Register !",
              theme: "info",
              time: "now",
              autoHideTime: 4000,
              statusCode: 401,
              show: true,
            },
          })
        );
        return;
      }
      // const response =
      await joinGroupAPI(result.payload, user.uid);
      dispatch(groupAsync({ companyName, groupNameParams }));

      // return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const getCompanyNameAndGroupNameAsync = createAsyncThunk(
  "group/getCompanyNameAndGroupName",
  async ({ groupId }, { dispatch, getState, rejectWithValue }) => {
    try {
      return await getCmpandGrpfromGroupId(groupId);
    } catch (error) {
      console.log(error);
    }
  }
);

export const getGroupIdAsync = createAsyncThunk(
  "group/getGroupId",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      return await getGroupId(companyName, groupNameParams);
    } catch (error) {
      console.log(error);
    }
  }
);

export const groupAsync = createAsyncThunk(
  "group/fetchGroup",
  async (
    { companyName, groupNameParams, groupId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      return await getEachGroupAPI(result.payload || groupId);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//GROUP MEMBER
export const groupMemberAsync = createAsyncThunk(
  "group/fetchMember",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      const response = await getGroupMemberAPI(result.payload);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
//Group Event
export const groupOnlyEventAsync = createAsyncThunk(
  "group/fetchEvent",
  async ({ groupId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getGroupOnlyEventAPI(groupId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
//GET UPCOMING EVENTS
export const groupUpcomingEventAsync = createAsyncThunk(
  "group/fetchUpcomingEvent",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      const response = await getGroupUpcomingEventAPI(result.payload);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//GET Past EVENTS
export const groupPastEventAsync = createAsyncThunk(
  "group/fetchPastEvent",
  async (
    { companyName, groupNameParams, groupId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await getGroupPastEventAPI(groupId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
//GET ORGANIZER DETAIL
export const groupOrganizerDetailsAsync = createAsyncThunk(
  "group/fetchOrganizer",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      const response = await getGroupOrganizerAPI(result.payload);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//Interest Async
export const groupInterestAsync = createAsyncThunk(
  "group/fetchInterest",
  async (
    { companyName, groupNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      return await getGroupInterestAPI(result.payload);
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//Create Discussion Async
export const createDiscussionAsync = createAsyncThunk(
  "groups/createDiscussion",
  async (
    { title, discussionDescription, groupId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await createDiscussion(
        title,
        discussionDescription,
        groupId
      );

      if (response) {
        console.log(response);
      }
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//FETCH DISCUSSION
export const getGroupDiscussionAsync = createAsyncThunk(
  "group/fetchDiscussion",
  async ({ groupId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getDiscussionAPI(groupId);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const getGroupOnlyDiscussionAsync = createAsyncThunk(
  "group/fetchOnlyDiscussion",
  async (
    { groupId, groupDiscussionId },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await getOnlyDiscussionAPI(groupId, groupDiscussionId);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

//Add Comment
export const addCommentDiscussionAsync = createAsyncThunk(
  "group/addCommentDiscussion",
  async (
    { groupId, groupDiscussionId, comment, uid },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await AddCommentDiscussionAPI(
        groupId,
        groupDiscussionId,
        comment,
        uid
      );
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
export const groupSlice = createSlice({
  name: "group",
  initialState,

  reducers: {
    setOrganizer: (state, action) => {
      state.organizer = action.payload.value;
    },
    setFound: (state, action) => {
      state.found = action.payload.value;
    },
    setGroupPage: (state, action) => {
      state.groupPage = action.payload.newPage;
    },

    //Create Discussion
    setTitle: (state, action) => {
      state.title = action.payload.value;
    },
    setDiscussionDescription: (state, action) => {
      state.discussionDescription = action.payload.value;
    },
    setComment: (state, action) => {
      state.comment = action.payload.value;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(groupAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(groupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groupData = { ...action.payload };
        if (!action.payload) {
          state.found = false;
        }
      })
      .addCase(groupAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(joinGroupAsync.pending, (state, action) => {
        state.groupActionLoading = true;
      })
      .addCase(joinGroupAsync.fulfilled, (state, action) => {
        state.groupActionLoading = false;
        // state.groupData = { ...action.payload };
        // if (!action.payload) {
        //   state.found = false;
        // }
      })
      .addCase(joinGroupAsync.rejected, (state, action) => {
        state.groupActionLoading = false;
      })

      .addCase(leaveGroupAsync.pending, (state, action) => {
        state.groupActionLoading = true;
      })
      .addCase(leaveGroupAsync.fulfilled, (state, action) => {
        state.groupActionLoading = false;
        // state.groupData = { ...action.payload };
        // if (!action.payload) {
        //   state.found = false;
        // }
      })
      .addCase(leaveGroupAsync.rejected, (state, action) => {
        state.groupActionLoading = false;
      })
      //MEMBER
      .addCase(groupMemberAsync.pending, (state, action) => {
        state.groupMemberLoading = true;
      })
      .addCase(groupMemberAsync.fulfilled, (state, action) => {
        state.groupMemberLoading = false;
        state.groupMemberDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundGroupMember = false;
        }
      })
      .addCase(groupMemberAsync.rejected, (state, action) => {
        state.groupMemberLoading = false;
      })
      //
      //EVENT
      .addCase(groupOnlyEventAsync.pending, (state, action) => {
        state.groupOnlyEventLoading = true;
      })
      .addCase(groupOnlyEventAsync.fulfilled, (state, action) => {
        state.groupOnlyEventLoading = false;
        state.groupOnlyEventDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundOnlyGroupEvent = false;
        }
      })
      .addCase(groupOnlyEventAsync.rejected, (state, action) => {
        state.groupOnlyEventLoading = true;
      })
      //UPCOMING EVENTS
      .addCase(groupUpcomingEventAsync.fulfilled, (state, action) => {
        state.upcomingEventDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundUpcomingGroupEvent = false;
        }
      })
      //PAST EVENTS
      .addCase(groupPastEventAsync.fulfilled, (state, action) => {
        state.pastEventDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundPastGroupEvent = false;
        }
      })

      //Group Organizer Details
      .addCase(groupOrganizerDetailsAsync.pending, (state, action) => {
        state.groupOrganizerDetailsLoading = true;
      })
      .addCase(groupOrganizerDetailsAsync.fulfilled, (state, action) => {
        state.groupOrganizerDetailsLoading = false;
        state.groupOrganizerDetails = action.payload;
        if (!action.payload) {
          state.foundGroupOrganizerDetails = false;
        }
      })
      .addCase(groupOrganizerDetailsAsync.rejected, (state, action) => {
        state.groupOrganizerDetailsLoading = true;
      })

      //INTEREST
      .addCase(groupInterestAsync.fulfilled, (state, action) => {
     
        state.groupInterestArr = action.payload;
      })
      .addCase(getGroupIdAsync.fulfilled, (state, action) => {
     
        state.generatedGroupId = action.payload;
      })
      .addCase(getGroupDiscussionAsync.fulfilled, (state, action) => {
        state.discussionArr = action.payload;
        
     
      })
      .addCase(getGroupOnlyDiscussionAsync.fulfilled, (state, action) => {
        state.groupOnlyDiscussion = action.payload;
      });
  },
});

export const {
  setOrganizer,
  setFound,
  setGroupPage,
  setDiscussionDescription,
  setTitle,
  setComment,
} = groupSlice.actions;
export const groupState = (state) => state.group;

export default groupSlice.reducer;
