import React, { useEffect } from "react";
// Bootstrap
import { Container } from "react-bootstrap";

// css

import "./App.css";

import SettingNav from "./components/SettingNav";

// redux
import { userProfileState } from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { groupPreviewInterestAsync } from "../../../Main/Components/Groups/CreateGroup/reduxSlice";
import GeneralPanel from "./components/GeneralPanel";
import InterestPanel from "./components/InterestPanel";
import NotificationPanel from "./components/NotificationPanel";
import PrivacyPanel from "./components/PrivacyPanel";
import OrganizerPanel from "./components/OrganizerPanel";
import PersonalForm from "./components/FormComponent/PersonalForm";
import LocationForm from "./components/FormComponent/LocationForm";
import PasswordForm from "./components/FormComponent/PasswordForm";
import SocialForm from "./components/FormComponent/SocialForm";

export default function UserPofile() {
  const dispatch = useDispatch();
  const { userProfilePannel } = useSelector(userProfileState);

  useEffect(() => {
    dispatch(groupPreviewInterestAsync({}));
  }, []);
  return (
    <div className="h-100 bg-dark text-light">
      <Container className="py-4 py-lg-5 d-flex flex-column flex-lg-row justify-content-between">
        <SettingNav />
        {(userProfilePannel === "General" && <GeneralPanel />) ||
          (userProfilePannel === "Interests" && <InterestPanel />) ||
          (userProfilePannel === "Notification Settings" && (
            <NotificationPanel />
          )) ||
          (userProfilePannel === "Privacy Settings" && <PrivacyPanel />) ||
          (userProfilePannel === "Organizer" && <OrganizerPanel />) ||
          (userProfilePannel === "Personal" && <PersonalForm />) ||
          (userProfilePannel === "Address" && <LocationForm />) ||
          (userProfilePannel === "Social" && <SocialForm />) ||
          (userProfilePannel === "Password" && <PasswordForm />)}
      </Container>
    </div>
  );
}
