import React from "react";

import '../App.css'

export function Schedule({ time1, t, l1, l2, time2, l3 }) {
    return (
        <div className="">
            <div className="py-4 px-4 d-flex flex-row justify-content-between border">
                <div>
                    <span>{time1}</span>

                </div>
                <div>
                    <p>{t}</p>
                    <ul>
                        <li>{l1}</li>
                        <li>{l2}</li>

                    </ul>

                </div>
            </div>


            <div className="d-flex py-4 px-4 flex-row justify-content-between">
                <div>
                    <span>{time2}</span>

                </div>
                <div>
                    <p>{l3}</p>


                </div>

            </div>
        </div>
    )
}