import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initialState = {
    name:"",
    description: "",
    monthlyPrice:"",
    yearlyPrice:"",
    trialPeriod:"",
    Order:"",
    paypalPlanID_M:"",
    paypalPlanID_Y:"",
    groupsSubscribeCreate:"",
    MaxSponsors:"",

}

export const createPlanSlice = createSlice({
    name:"createPlan",
    initialState,
    reducers:{
        setName:(state, action) => {
            state.name = action.payload.name
        },
        setDescription: (state, action) => {
            state.description = action.payload.description
        },
        setMonthlyPrice:(state, action) => {
            state.monthlyPrice = action.payload.monthlyPrice
        },
        setYearlyPrice:(state, action) => {
            state.yearlyPrice = action.payload.yearlyPrice
        },
        setTrialPeriod:(state, action) => {
            state.trialPeriod = action.payload.trialPeriod
        },
        setOrder:(state, action) => {
            state.Order = action.payload.Order
        },
        setPaypalPlanID_M:(state, action) => {
            state.paypalPlanID_M = action.payload.paypalPlanID_M
        },
        setPaypalPlanID_Y:(state, action) => {
            state.paypalPlanID_Y = action.payload.paypalPlanID_Y
        },
        setGroupsSubscribeCreate:(state, action) => {
            state.groupsSubscribeCreate = action.payload.groupsSubscribeCreate
        },
        setMaxSponsors:(state, action) => {
            state.MaxSponsors = action.payload.MaxSponsors
        }
    }

})


export const {
    setName,
    setDescription,
    setMonthlyPrice,
    setYearlyPrice,
    setTrialPeriod,
    setOrder,
    setPaypalPlanID_M,
    setPaypalPlanID_Y,
    setGroupsSubscribeCreate,
    setMaxSponsors
} = createPlanSlice.actions;

export default createPlanSlice.reducer;