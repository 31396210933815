import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    addCheckInCheckOutAPI,
    getAllEventsIdAPI,
    getMyStatusAPI,
} from "./reduxAPI";

const initialState = {
    tableData: {},

    showThisUserDetails: null,

    // select event
    eventsIds: [],

    // Stats
    location: null,
    statusPath: [],
    lastCheck: null,
    id: null,

    showScanQrModal: false,
};

export const getMyStatusAsync = createAsyncThunk(
    "checkInCheckOut/getMyStatus",
    async ({ _ }, { dispatch, getState, rejectWithValue }) => {
        try {
            const {
                eventData: { id: eventId },
            } = getState().event;
            const { docId } = getState().user;

            const response = getMyStatusAPI(eventId, docId);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addCheckInCheckOutAsync = createAsyncThunk(
    "checkInCheckOut/addCheckInCheckOut",
    async (
        { attendeeId = undefined, selectedEvent = undefined, adminUpdate = false },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            const {
                eventData: { id: eventId },
            } = getState().event;
            const { docId } = getState().user;
            const { location, lastCheck, id } = getState().checkInCheckOut;

            let dataOfCIO = {
                eventId: selectedEvent ? selectedEvent : eventId,
                docId: attendeeId ? attendeeId : docId,
                location,
                lastCheck,
                id,
                adminUpdate: adminUpdate,
            };
            const response = await addCheckInCheckOutAPI(dataOfCIO);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getAllEventsIdAsync = createAsyncThunk(
    "checkInCheckOut/getAllEventsId",
    async ({ _ }, { dispatch, getState, rejectWithValue }) => {
        try {
            const response = await getAllEventsIdAPI();
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const checkInCheckOutSlice = createSlice({
    name: "checkInCheckOut",
    initialState,
    reducers: {
        setLastCheck: (state, action) => {
            state.lastCheck = action.payload.value;
        },

        setLocation: (state, action) => {
            state.location = action.payload.value;
        },
        pushTableData: (state, action) => {
            const { id } = action.payload.value;
            state.tableData[id] = action.payload.value;
        },
        setShowThisUserDetails: (state, action) => {
            state.showThisUserDetails = action.payload.id;
        },
        setShowScanQrModal: (state, action) => {
            state.showScanQrModal = action.payload.value;
        },
        clearTableData: (state, action) => {
            state.tableData = {};
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getMyStatusAsync.pending, (state, action) => { })
            .addCase(getMyStatusAsync.fulfilled, (state, action) => {
                if (action.payload.data.found) {
                    let { id, location, statusPath } = action.payload.data.document;
                    // odd -> next checkout
                    // even -> next checkIn || 0 -> next checkIn

                    state.id = id;
                    state.location = location;
                    state.statusPath = statusPath;
                } else {
                    state.id = null;
                    state.lastCheck = null;
                    state.location = null;
                    state.statusPath = [];
                }
            })
            .addCase(getMyStatusAsync.rejected, (state, action) => { })
            .addCase(addCheckInCheckOutAsync.pending, (state, action) => { })
            .addCase(addCheckInCheckOutAsync.fulfilled, (state, action) => { })
            .addCase(addCheckInCheckOutAsync.rejected, (state, action) => { })
            .addCase(getAllEventsIdAsync.pending, (state, action) => { })
            .addCase(getAllEventsIdAsync.fulfilled, (state, action) => {
                state.eventsIds = action.payload.data.eventIds;
            })
            .addCase(getAllEventsIdAsync.rejected, (state, action) => { });
    },
});

export const {
    setLastCheck,
    pushTableData,
    setLocation,
    setShowThisUserDetails,
    clearTableData,
    setShowScanQrModal,
} = checkInCheckOutSlice.actions;

export const checkInCheckOutState = (state) => state.checkInCheckOut;

export default checkInCheckOutSlice.reducer;
