import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Placeholder, Image } from "react-bootstrap";

const NotFoundGroups = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="container ">
      <div className="d-flex flex-column flex-md-row justify-content-around align-items-center border rounded-3 py-4 my-4">
        <div className="p-2 col-4 col-md-3">
          <Image
            fluid
            draggable={false}
            style={{
              aspectRatio: "1 / 1",
              maxHeight: "150px",
              width:"100%"
            }}
            className={` rounded d-block mx-auto ${loading ? "d-none" : null}`}
            onLoad={() => {
              setLoading(false);
            }}
            src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fno-events.svg?alt=media&token=4c59ee87-eaeb-4425-b9fb-73f5b137941d"
            alt="not Found"
          />

          {loading ? (
            <Placeholder className="d-block text-center" animation="glow">
              <Placeholder
                className="rounded "
                style={{
                  aspectRatio: "3 / 3 ",
                  maxHeight: "150px",
                  width: "100%"
                }}
              />
            </Placeholder>
          ) : null}
        </div>
        <p className="text-center">
          There are no Groups right now. Find one today.
        </p>
        <div className="text-center">
          <Link to={{ pathname: "/find/group" }}>
            <button className="btn btn-quaternary">Join Groups</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundGroups;
