import React from "react";
import GeneralPanel from "./GeneralPanel";
import InterestPanel from "./InterestPanel";
import NotificationPanel from "./NotificationPanel";
import PrivacyPanel from "./PrivacyPanel";
import OrganizerPanel from "./OrganizerPanel";

// Bootstrap
import { ListGroup } from "react-bootstrap";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setUserProfilePannel } from "../reduxSlice";

// Css
import styles from "../App.module.css";
import "../App.css";
import { rootPageState } from "../../../../Main/Routes/reduxSlice";

export default function SettingNav() {
  const { isAdmin, isSubscriber, isAttendee, isBizAdmin } =
    useSelector(rootPageState);

  const dispatch = useDispatch();

  function navPannel(page) {
    switch (page) {
      case "General":
        dispatch(setUserProfilePannel({ newPannel: "General" }));
        break;
      case "Interests":
        dispatch(setUserProfilePannel({ newPannel: "Interests" }));
        break;
      case "Notification Settings":
        dispatch(setUserProfilePannel({ newPannel: "Notification Settings" }));
        break;
      case "Privacy Settings":
        dispatch(setUserProfilePannel({ newPannel: "Privacy Settings" }));
        break;
      case "Organizer":
        dispatch(setUserProfilePannel({ newPannel: "Organizer" }));
        break;
      default:
        dispatch(setUserProfilePannel({ newPannel: "General" }));
        break;
    }
  }


  return (
    <>
      <ListGroup className="settingNav bg-dark text-light ">
        <ListGroup.Item className="py-3 h6 my-0 bg-dark text-light border">
          Settings
        </ListGroup.Item>
        <ListGroup className="w-100 d-flex flex-row flex-lg-column overflow-auto border">
          <ListGroup.Item
            className="border-0 text-nowrap bg-dark text-light"
            onClick={()=>(navPannel("General"))}
            action
          >
            General
          </ListGroup.Item>
          <ListGroup.Item
            className="border-0 text-nowrap bg-dark text-light"
            onClick={()=>(navPannel("Interests"))}
            action
          >
            Interests
          </ListGroup.Item>
          <ListGroup.Item
            className="border-0 text-nowrap bg-dark text-light"
            onClick={()=>(navPannel("Notification Settings"))}
            action
          >
            Notification Settings
          </ListGroup.Item>
          <ListGroup.Item
            className="border-0 text-nowrap bg-dark text-light"
            onClick={()=>(navPannel("Privacy Settings"))}
            action
          >
            Privacy Settings
          </ListGroup.Item>
          {isAdmin || isSubscriber || isBizAdmin ? (
            <ListGroup.Item
              className="border-0 text-nowrap bg-dark text-light"
              onClick={()=>(navPannel("Organizer"))}
              action
            >
              Organizer
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </ListGroup>
    </>
  );
}
