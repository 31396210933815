import React from "react";
import Searchbar from "./Searchbar";
import { Icon } from "@iconify/react";
import "./App.css";
import Footer from "./Footer";
import communicateimage from "../images/communicateimage.jpg";
import { Link } from "react-router-dom";
class Home extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="bg-dark text-light ">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="d-flex p-3">
              <h1 className="navbar-brand">Network</h1>
              <div className="mx-auto search-bar hidden-xs">
                <Searchbar />
              </div>
            </div>
            <div>
              <div className="navbar-nav ml-auto">
                <p className="nav-item nav-link ml-auto" to="/login">
                  Login
                </p>
                <p className="nav-item nav-link ml-auto" to="/register">
                  Register
                </p>
              </div>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row flex-column flex-lg-row">
            <div className="col heading text-center">
              <h1>Dive in! There are so many things to do on Network</h1>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events
                happening every day, both online and in person!
              </p>
            </div>
            <div className="col img">
              <img
                src={communicateimage}
                alt=""
                className="w-75 h-75 float-left bg-light"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row flex-column flex-lg-row">
            <div className="col text-center">
              <h2>what do you want to do?</h2>
              <input
                type="text"
                placeholder="search for yoga"
                className="w-75"
              />
              <button className="btn btn-warning w-75 mt-3">search</button>
            </div>
            <div className="col text-center">
              <h2>Explore events by topic</h2>
              <div className="container">
                <div className="row flex-column flex-lg-row">
                  <div className="w-100 explore-event flex-column flex-lg-row">
                    <p className="bg-secondary d-inline-block text-light w-25 px-2">
                      MENTAL HEALTH PROFESSIONALS
                    </p>
                    <p className="bg-secondary d-inline-block m-2 text-light w-25 px-3">
                      TECHNOLOGY PROFESSIONALS
                    </p>
                    <span>
                      <p className="bg-secondary d-inline-block text-light w-25 px-3">
                        YOUNG PROFESSIONALS
                      </p>
                    </span>
                    <p className="bg-secondary d-inline-block text-light w-25 px-3">
                      AMERICAN ELECT
                    </p>
                    <p className="bg-secondary d-inline-block m-3 text-light w-25 px-3">
                      ART WALKS AND TOURS
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="text-center m-5 flex-column flex-lg-row">
            <h2>How networks work</h2>
            <p>
              Meet new people who share your interests through online and
              in-person events.It's free to create an account.
            </p>
          </div>
          <div className="container">
            <div className="row flex-column flex-lg-row">
             
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="container">
              <div className="row flex-column flex-lg-row">
                <div className="text-center">
                  <h2>Upcoming online events</h2>
                  <p>See all upcomig online events</p>
                </div>
                <div>
                  <div className="d-flex flex-row flex-column flex-lg-row">
                    <div className="upcoming text-center">
                      <img
                        src="https://picsum.photos/300/200"
                        alt=""
                      />
                      <p>SAT,JUL2@ 12.00 PM GMT+00</p>
                      <h4>
                        Alice said very humbly;'i won't interrupt again.I
                        dare...
                      </h4>
                      <p>Barrows group</p>
                      <div className="d-flex flex-row">
                        <p>7 attendees</p>
                        <div className="share-icon">
                          <Icon icon="ant-design:star-outlined" />
                          <Icon icon="cil:share-boxed" />
                        </div>
                      </div>
                    </div>
                    <div className="upcoming text-center">
                      <img
                        src="https://picsum.photos/300/200"
                        alt=""
                      />
                      <p>SAT,JUL2@ 12.00 PM GMT+00</p>
                      <h4>
                        Alice said very humbly;'i won't interrupt again.I
                        dare...
                      </h4>
                      <p>Barrows group</p>
                      <div className="d-flex flex-row">
                        <p>7 attendees</p>
                        <div className="share-icon">
                          <Icon icon="ant-design:star-outlined" />
                          <Icon icon="cil:share-boxed" />
                        </div>
                      </div>
                    </div>
                    <div className="upcoming text-center">
                      <img
                        src="https://picsum.photos/300/200"
                        alt=""
                      />
                      <p>SAT,JUL2@ 12.00 PM GMT+00</p>
                      <h4>
                        Alice said very humbly;'i won't interrupt again.I
                        dare...
                      </h4>
                      <p>Barrows group</p>
                      <div className="d-flex flex-row">
                        <p>7 attendees</p>
                        <div className="share-icon">
                          <Icon icon="ant-design:star-outlined" />
                          <Icon icon="cil:share-boxed" />
                        </div>
                      </div>
                    </div>
                    <div className="upcoming text-center">
                      <img
                        src="https://picsum.photos/300/200"
                        alt=""
                      />
                      <p>SAT,JUL2@ 12.00 PM GMT+00</p>
                      <h4>
                        Alice said very humbly;'i won't interrupt again.I
                        dare...
                      </h4>
                      <p>Barrows group</p>
                      <div className="d-flex flex-row">
                        <p>7 attendees</p>
                        <div className="share-icon">
                          <Icon icon="ant-design:star-outlined" />
                          <Icon icon="cil:share-boxed" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row flex-column flex-lg-row">
              <div className="text-center p-5">
                <h1>Stories from Network</h1>
                <p>
                  People on Network have fostered community, learned new skills,
                  started business, and made life-long friends.Learn how.
                </p>
              </div>
              <div className="d-flex flex-row flex-column flex-lg-row">
                <div className="stories">
                  <img
                    src="https://picsum.photos/300/200"
                    alt=""
                    className="image"
                  />
                  <h4 className="mt-2">
                    I don't want you with us!"' 'They were obliged to have no
                    sort of lullaby to it in a great many.
                  </h4>
                  <p>
                    Alice, 'we learned French and music'.'And washing?' said the
                    mock Turtke, suddenly dropping his voice; and the Hatter.
                  </p>
                </div>
                <div className="stories">
                  <img
                    src="https://picsum.photos/300/200"
                    alt=""
                    className="image"
                  />
                  <h4 className="mt-2">
                    I don't want you with us!"' 'They were obliged to have no
                    sort of lullaby to it in a great many.
                  </h4>
                  <p>
                    Alice, 'we learned French and music'.'And washing?' said the
                    mock Turtke, suddenly dropping his voice; and the Hatter.
                  </p>
                </div>
                <div className="stories">
                  <img
                    src="https://picsum.photos/300/200"
                    alt=""
                    className="image"
                  />
                  <h4 className="mt-2">
                    I don't want you with us!"' 'They were obliged to have no
                    sort of lullaby to it in a great many.
                  </h4>
                  <p>
                    Alice, 'we learned French and music'.'And washing?' said the
                    mock Turtke, suddenly dropping his voice; and the Hatter.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Home;
