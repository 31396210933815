import React, { useEffect } from 'react'
import GeneralPanel from '../GeneralPanel'

// Bootstrap
import { Button, Container, Form } from 'react-bootstrap';

//Icon
import { MdChevronLeft } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { Icon } from "@iconify/react";

// Css
import '../../App.css'

//redux
import { useDispatch, useSelector } from "react-redux";
import {
    clearPreviewFiles,
    clearSelectedFiles, getUserProfileAsync,
    setBio,
    setBirthDate, setDataToEdit, setEditProfile,
    setEmail,
    setFirstName,
    setGender,
    setLastName,
    setPhoneNumber,
    setPreviewFiles, setProfileUrl,
    setSelectedFiles,
    setTimeZone,
    setUserName,
    setUserProfilePannel,
    updateUserAsync,
    userProfileState
} from "../../reduxSlice"
import { useMatch, useParams, useNavigate } from "react-router-dom";




export default function FormUpdate() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { docId } = useParams();
    const editMode = useMatch("/u/setting/:docId");
    const {
        userData,
        updatePersonalLoading,
        selectedFiles,
        userName,
        profileUrl,
        preViewFiles,
        firstName,
        lastName,
        email,
        phoneNumber,
        birthDate,
        gender,
        timeZone,
        headLine,
        bio
    } = useSelector(userProfileState)

    const handelSubmit = async (e) => {
        e.preventDefault();
        await dispatch(updateUserAsync({}));
        backButton();
        dispatch(getUserProfileAsync({ docId }));
    }

    //profile pic
    const previewFiles = (evt) => {

        const file = evt.target.files[0];
        console.log(file)
        dispatch(setSelectedFiles({ files: evt.target.files[0] }));

        const reader = new FileReader();

        console.log(selectedFiles)

    };
    useEffect(() => {
        if (editMode) {
            if (Object.keys(userData).length === 0) {
                dispatch(getUserProfileAsync({ docId }));
            }
            dispatch(setEditProfile({ value: true }));
            dispatch(setDataToEdit({ userData }));
        }

    }, [dispatch, editMode, docId]);

    const backButton = () => {
        dispatch(setUserProfilePannel({ newPannel: "General" }));
    }
    // if(updateUserLoading){
    //     setTimeout(function(){
    //         dispatch(setUserProfilePannel({ newPannel: "General" }));
    //     },1000)
    // }
    return (
        <>
            <Container className='mt-3 mt-lg-0 px-lg-5 px-2 bg-dark text-light'>
                <Button variant="link" className='ps-0 text-decoration-none shadow-none'
                    onClick={backButton}><MdChevronLeft /> Back</Button>
                <h4>Profile Settings</h4>
                <hr />
                <Form validated onSubmit={handelSubmit}>
                    <Container className='d-flex mb-3'>
                        <BsFillPersonFill className='display-1 border p-2 mt-2 mb-auto my-lg-auto me-3 rounded ' />
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Profile Picture</Form.Label>
                            <Form.Control type="file" className='bg-dark text-light'
                                onChange={previewFiles} />
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                required
                                value={firstName}
                                onChange={(e) => {
                                    dispatch(setFirstName({ value: e.target.value }))
                                }}


                                type="text" className='bg-dark text-light' />
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" className='bg-dark text-light' required
                                value={lastName} onChange={(e) => {
                                    dispatch(setLastName({ value: e.target.value }))
                                }} />
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" readOnly className='bg-dark text-light' required
                                value={email} onChange={(e) => {
                                    dispatch(setEmail({ value: e.target.value }))
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone number</Form.Label>
                            <Form.Control type="text" className='bg-dark text-light'
                                value={phoneNumber} onChange={(e) => {
                                    dispatch(setPhoneNumber({ value: e.target.value }))
                                }}
                            />
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Birthdate</Form.Label>
                            <Form.Control value={birthDate} onChange={(e) => {
                                dispatch(setBirthDate({ value: e.target.value }))
                            }} type="date" className='bg-dark text-light' />
                        </Form.Group>
                        <Form.Group className="mb-3 ps-0 ps-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select className='bg-dark text-light'
                                value={gender}
                                onChange={(e) => {
                                    dispatch(setGender({ value: e.target.value }))
                                }}>
                                <option>Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="prefer not to say">Prefer not to say</option>
                            </Form.Select>
                        </Form.Group>
                    </Container>
                    <Container className='d-flex justify-content-between flex-column flex-md-row mb-1'>
                        <Form.Group className="mb-3 pe-0 pe-md-2 w-100" controlId="exampleForm.ControlInput1">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" className='bg-dark text-light' value={userName}
                                onChange={(e) => {
                                    dispatch(setUserName({ value: e.target.value }))
                                }} />
                        </Form.Group>
                    </Container>
                    <Container className='mb-3'>
                        <Form.Label>Bio</Form.Label>
                        <Form.Control

                            value={bio} onChange={(e) => {
                                dispatch(setBio({ value: e.target.value }))
                            }}
                            as="textarea"
                            style={{ height: '100px' }}
                            className='bg-dark text-light'
                        />
                        <Button type="submit" className='mt-4 d-block ms-auto me-0'>
                            {updatePersonalLoading ? (<Icon icon="eos-icons:bubble-loading" />
                            ) : 'Update'}
                        </Button>
                    </Container>
                </Form>
            </Container>
        </>
    )
}
