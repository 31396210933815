import {
  db_firestore,
  functions,
  storage,
} from "../../../../../firebase.config";
import { httpsCallable } from "firebase/functions";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

//CREATE GROUP
export function createGroup(
  groupName,
  categories,

  description,
  groupType,
  location
) {
  const descriptionHTML = draftToHtml(
    convertToRaw(description.getCurrentContent())
  );

  const createGroupFunction = httpsCallable(functions, "createGroup");

  return createGroupFunction({
    groupName,
    categories,

    description: descriptionHTML,
    groupType,
    location,
  });
}

export async function addCreatedGroupsId(groupId, uid) {
  // update user
  const userDocq = query(
    collection(db_firestore, "users"),
    where("uid", "==", uid)
  );
  let docId;
  const querySnapshot = await getDocs(userDocq);
  querySnapshot.forEach((doc) => {
    docId = doc.id;
  });
  await updateDoc(doc(db_firestore, "users", docId), {
    createdGroupsId: arrayUnion(groupId),
  });
}

//UPDATE GROUP
export async function updateGroup(
  groupName,
  categories,

  description,
  groupType,
  location,
  groupId,
  uid
) {
  const descriptionHTML = draftToHtml(
    convertToRaw(description.getCurrentContent())
  );

  const updateGroupfn = httpsCallable(functions, "updateGroup");
  return updateGroupfn({
    groupName,
    categories,
    description: descriptionHTML,
    groupType,
    location,
    groupId,
    uid,
  });
}

//IMAGES
export async function uploadFiles(file, groupId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(storage, `groups/${groupId}/` + file.name);
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteGroupImages(groupId) {
  // Create a reference to the file to delete
  const groupImgRef = ref(storage, `groups/${groupId}/`);

  let groupImgList = [];
  // Find all the prefixes and items.
  listAll(groupImgRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        groupImgList = [...groupImgList, itemRef];
        // All the items under listRef.
      });
      if (groupImgList.length > 0) {
        groupImgList.map((value) =>
          deleteObject(ref(storage, `groups/${groupId}/${value.name}`))
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export function addGroupGallery(groupGallery, groupId) {
  const addGroupGalleryfn = httpsCallable(functions, "addGroupGallery");
  return addGroupGalleryfn({
    groupGallery,
    groupId,
  });
}

//GET INTEREST
export async function getInterestApi() {
  const docRef = collection(db_firestore, "interests");

  const querySnapshot = await getDocs(docRef);

  let interest = [];

  querySnapshot.forEach(async (doc) => {
    // doc.data() is never undefined for query doc snapshots
    const id = doc.id;
    interest.push({
      id,
      ...doc.data(),
    });
  });
  return interest;
}

//Add new Interest
export async function addInterest(interest) {
  const addInterestFunction = httpsCallable(functions, "addInterest");

  return addInterestFunction({
    interest,
  });
}
