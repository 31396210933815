import React, { useEffect } from "react";
import EventCard from "../../MyGroup_Props/EventCard/App";
import { useDispatch, useSelector } from "react-redux";
import { userState } from "../../../../../Main/Components/User/reduxSlice";
import { useParams } from "react-router-dom";
import {
  getGroupIdAsync,
  groupOnlyEventAsync,
  groupPastEventAsync,
  groupState,
} from "../../MyGroupOnBoard/reduxSlice";
import EventGroupCard from "../EventGroupCard/App";
import NoEvent from "../No Event/App";
import { BsFillCalendarEventFill } from "react-icons/bs";

const Past = () => {
  const dispatch = useDispatch();
  const { companyName, groupNameParams } = useParams();
  const { pastEventDataArr  } = useSelector(groupState);

  useEffect(() => {
    dispatch(groupPastEventAsync({ companyName,groupNameParams }));
  }, [dispatch, companyName, groupNameParams]);

  return (
    <div>
      <div className="">
        {pastEventDataArr.length > 0 ? (
          pastEventDataArr.map((item, index) => (
            <EventGroupCard index={index} eventsData={item} />
          ))
        ) : (
          <div className="col-12 d-flex flex-column align-items-center bg-dark rounded p-5 border mb-4">
            <BsFillCalendarEventFill />
            <p className="fs-6 mb-0 mt-2">No events yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Past;
