import React from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";
import {Pagination} from "react-bootstrap";
import {BsThreeDotsVertical} from  'react-icons/bs'

const ManangeUser = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                    <small>Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Users
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12">
                                <form className="card bg-dark text-light border mb-3">
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">
                                            <div className="form-group">
                                                <input   type="text" className="form-control bg-dark text-light "
                                                       value="" placeholder="Search"/>
                                            </div>
                                            <div className="form-group mx-2">
                                                <select  className="form-control bg-dark text-light">
                                                    <option selected="" value="">Select Role</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="user">User</option>
                                                </select>
                                            </div>
                                            <button type="submit" className="btn btn-link">
                                                <i className="fa fa-filter me-2"></i>
                                                Filter
                                            </button>
                                            <a className="btn btn-link"
                                                >
                                                <i className="fa fa-redo me-2"></i>
                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bold">Users</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Plan</th>
                                                <th>Subscription</th>
                                                <th>Role</th>
                                                <th>Registered at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="users">
                                            <tr>
                                                <td>
                                                    <a  
                                                       className="text-decoration-none">205</a>
                                                </td>
                                                <td>
                                                    <a  
                                                       target="_blank" className="text-decoration-none"
                                                
                                                        >sayed hashem</a>
                                                </td>
                                                <td>
                                                    <p className="mb-0">microcol@gmail.com</p>
                                                </td>
                                                <td>
                                                    <a className="mb-0"
                                                       >Basic
                                                        Plan</a>
                                                </td>
                                                <td>
                                                    <div className="badge bg-info">On trial</div>
                                                </td>
                                                <td className="text-capitalize">user</td>
                                                <td>2022-07-13</td>
                                                <td className="text-end">
                                                    <div className="dropdown">
                                                        <BsThreeDotsVertical/>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>


                                        <div className="card-footer d-flex align-items-center justify-content-center">
                                            <nav>
                                                <ul className="pagination">

                                                    <li className="page-item disabled" aria-disabled="true"
                                                        aria-label="« Previous">
                                                        <span className  = "page-link bg-dark text-light bg-primary" aria-hidden="true">‹</span>
                                                    </li>


                                                    <li className="page-item active" aria-current="page"><span
                                                        className="page-link page-link bg-dark text-light bg-primary">1</span></li>
                                                    <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                    >2</a>
                                                    </li>
                                                    <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                    >3</a>
                                                    </li>
                                                    <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                    >4</a>
                                                    </li>
                                                    <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                    >5</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className  = "page-link bg-dark text-light bg-primary"

                                                           rel="next" aria-label="Next »">›</a>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Admin_Footer/>
            </div>

        </div>
    );
};

export default ManangeUser;