import React from 'react';
// import Carousel from "react-elastic-carousel";
import {groupState} from "../../MyGroupOnBoard/reduxSlice";
import {useSelector} from "react-redux";
import {Carousel, Image, Modal} from "react-bootstrap";
import NoPhoto from "../NoPhoto/App";
import '../App.css'
//breakpoint css

const PhotoCrousel = ({show, handleClose}) => {
    const {
        groupPage,
        loading: fetchLoading,
        groupActionLoading,
        groupData: {groupName, groupType, subscriberUid, location, groupGallery, description}
    } = useSelector(groupState);
    return (
        <div className='  backgroundEffect'>
            <Modal size='xl' show={show} onHide={handleClose} animation={false}>

                <Modal.Body className='bg-dark'>
                    <Carousel slide={false} className='w-100'>
                        {fetchLoading ?
                            null :
                            groupGallery.map(image => (

                                <Carousel.Item className=''>
                                    <Image Fulid className='' style={{width: '100%'}} src={image}/>
                                </Carousel.Item>


                            ))
                        }
                    </Carousel>
                </Modal.Body>

            </Modal>

        </div>
    );
};

export default PhotoCrousel;