import React from 'react';
import {Button, Modal} from "react-bootstrap";
import '../../../Calendar/Form/App.css'

const CalendarModals = ({onHide, showCalendar}) => {
    return (
        <div>
            <Modal
                show={showCalendar}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size='xl'
                onHide={onHide}
                className='px-0'

            >
                <Modal.Header className='bg-dark text-light' closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Your Calendar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-light'>
                    <div className='containerTest'>
                        <iframe className='responsive-iframe' src="https://calendly.com/yoursarjun23/30min"></iframe>
                    </div>

                </Modal.Body>
                <Modal.Footer className='bg-dark text-light'>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CalendarModals;