import React, { useEffect } from "react";

// Firebase
import { auth } from "../../../firebase.config";
import { getIdTokenResult } from "firebase/auth";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setRole } from "./reduxSlice";
import { userState } from "../Components/User/reduxSlice";

// Component
import NavbarComponent from "../Components/Navbar/App";
import ScheduleEvent from "../../Test/components/MyGroup/Schedule_Event/App";
import MyEvent from "../../Test/components/MyEvents/App";

// Pages
import Onboard from "../Pages/HomePage/HomePage";
import EventPage from "../Pages/EventPage//EventPage";

// React router dom
import { Route, Routes } from "react-router-dom";

// Main Root
import CreateGroup from "../Components/Groups/CreateGroup/App";
import EventAttendeesList from "../Components/Events/Attendees/App";
// Test Root
import Profile from "../../Test/components/Profile/App";
import MygroupOnBaord from "../../Test/components/MyGroup/MyGroupOnBoard/App";
import MyGroup from "../../Test/components/MyGroup/MyGroup/App";
import DiscussionDetails from "../../Test/components/MyGroup/Discussion/Discussion_Details/App";
// import Home from "../components/Homepage/pages/Home";
import Home from "../../Test/components/Homepage/pages/Home";
import UserProfile from "../../Test/components/UserProfile/App";
import { Event } from "../../Test/components/Event/Event/App";

//Admin Dashboard
import { Plans } from "../../Test/components/AdminDashboard/Subscriptions/Plan/Plan/App";
import CreatePlan from "../../Test/components/AdminDashboard/Subscriptions/Plan/CreatePlan/App";
import Orders from "../../Test/components/AdminDashboard/Subscriptions/Orders/App";
import { Subscription } from "../../Test/components/AdminDashboard/Subscriptions/Subscription/App";
import ManageGroups from "../../Test/components/AdminDashboard/Manage/Groups/App";

import AdminDashboard from "../../Test/components/AdminDashboard/Dashboard/App";
import MailSetting from "../../Test/components/AdminDashboard/Settings/Mail/App";
import Company from "../../Test/components/AdminDashboard/Settings/Company/App";
import CurrenciesSetting from "../../Test/components/AdminDashboard/Settings/Currencies/App";
import LocationSetting from "../../Test/components/AdminDashboard/Settings/Location/App";
import SocailLoginSetting from "../../Test/components/AdminDashboard/Settings/Social_LogIn/App";
import Createblog from "../../Test/components/AdminDashboard/Pages/Blog/createBlog/App";
import BlogCateogries from "../../Test/components/AdminDashboard/Pages/BlogCateogries/App";
import CreateBlogCategories from "../../Test/components/AdminDashboard/Pages/BlogCateogries/createBlogCategories/App";
import Blog from "../../Test/components/AdminDashboard/Pages/Blog/App";

import Pages from "../../Test/components/AdminDashboard/Pages/createPages/App";
import ManangeUser from "../../Test/components/AdminDashboard/Manage/Users/App";
import ManageEvent from "../../Test/components/AdminDashboard/Manage/Event/App";
import TopicCateogries from "../../Test/components/AdminDashboard/Manage/TopicCategories/App";
import Topics from "../../Test/components/AdminDashboard/Manage/Topics/App";

//MY GROUPS

import BasicSetting from "../../Test/components/MyGroup/Group_Settings/Basic_Setting/App";
import ContentVisibility from "../../Test/components/MyGroup/Group_Settings/Content_Visibiltiy/App";
import OptionalSeting from "../../Test/components/MyGroup/Group_Settings/Optional_Setting/App";
import TopicsSetting from "../../Test/components/MyGroup/Group_Settings/Topics/App";
import NewMmeber from "../../Test/components/MyGroup/Group_Settings/New_member/App";

import MailButton from "../../Test/components/Mail/App";
import SettingApplication from "../../Test/components/AdminDashboard/Settings/Application/App";
import Attending from "../../Test/components/MyEvents/components/Pannel/Attending";
import Saved from "../../Test/components/MyEvents/components/Pannel/Saved";
import JoinedGroup from "../../Test/components/MyEvents/components/Pannel/JoinedGroup";
import OrganizeGroup from "../../Test/components/MyEvents/components/Pannel/OrganizeGroup";
import EditEvent from "../../Test/components/MyEvents/components/EditEvent";
//Find
import FindEvent from "../../Test/components/FindPage/FindEvent/App";
import FindGroup from "../../Test/components/FindPage/FindGroup/App";
import CheckoutPlans from "../../Test/components/checkouplan/CheckoutPlans";
import LandingPage from "../../Test/components/LandingPage/LandingPage";
import Starting from "../../Test/components/StartingPage/Starting";
import CalenderForm from "../../Test/components/Calendar/App";
import Cards from "../../Test/components/Calendar/CalendarCard/Card";

import Interest from "../../Test/components/CommonComponents/Interest/App"; // todo: arjun remove and delete component
import SearchEvent from "../../Test/components/search/Event";
import SearchInterest from "../../Test/components/search/Interest";
import SearchGroup from "../../Test/components/search/Group";
import SearchUser from "../../Test/components/search/User";
import SearchInterestManual from "../../Test/components/search/InterestManual";
import EventIdPage from "../Components/Events/Redirect/App";
import GroupIdPage from "../Components/Groups/Redirect/App";
import MultipleInputFormBuilder from "../Components/Events/Page Component/Documents/components/MultipleInputFormBuilder/App";

import FloorMap from '../Components/Events/Page Component/FloorMap/App'
// Test Link
import EventInvitation from "../Components/Events/Emails/EventInvitation/App";
import { FakeEmailPreviewTemp } from "../Components/Events/Emails/FakeEmailPreviewTemp";
import NetworkingManagement from "../Components/Events/Networking/NetworkingManagement/App";

import CheckInCheckOut from "../Components/Events/Page Component/CheckInCheckOut/App";

export const RootPage = () => {
  const dispatch = useDispatch();
  const { userAvl, user } = useSelector(userState);

  useEffect(() => {
    const getClaims = async () => {
      if (user && userAvl) {
        const idTokenResult = await getIdTokenResult(auth.currentUser);
        dispatch(setRole({ idTokenResult }));
      }
    };
    getClaims();
  }, [dispatch, userAvl, user]);

  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route>
          {/* Frontend Ui */}
          <Route path="/" element={<Onboard />} />
          <Route path="creategroup" element={<CreateGroup />} />

          <Route
            path="/:eventId/checkIn"
            element={<CheckInCheckOut />}
          />

          <Route
            path="/:companyName/group/:groupNameParams/"
            element={<MygroupOnBaord />}
          />
          <Route
            exact
            path="/:companyName/group/:groupNameParams/newEvent"
            element={<ScheduleEvent />}
          />
          <Route
            path="/:companyName/:eventNameParams/attendees"
            element={<EventAttendeesList />}
          />

          <Route
            path="/:companyName/:eventNameParams"
            element={<EventPage />}
          />
          <Route
            path="/:companyName/:eventNameParams/floormap"
            element={<FloorMap />}
          />
          <Route
            path="/:companyName/:eventNameParams/edit"
            element={<ScheduleEvent />}
          />
          <Route
            path="/:companyName/:eventNameParams/invite"
            element={<EventInvitation />}
          />
          <Route
            path="/:companyName/:eventNameParams/networkingManagement"
            element={<NetworkingManagement />}
          />

          <Route path="/event/:eventId" element={<EventIdPage />} />
          <Route path="/group/:groupId" element={<GroupIdPage />} />

          <Route path="/events" element={<MyEvent />} />
          <Route path="/groups" element={<MyGroup />} />

          <Route path="/u/:docId" element={<Profile />} />
          {/* Test Root */}
          {/* Frontend Ui */}

          <Route path="interest" element={<Interest />} />
          <Route path="u/setting/:docId" element={<UserProfile />} />

          <Route path="/test">
            {/* http://localhost:3000/Test/appcomponent */}
            {/* Arjun */}

            <Route path="landingPage" element={<LandingPage />} />
            {/* Checkout plan Page */}
            <Route path="checkout/plans" element={<CheckoutPlans />} />
            {/* Startingpage */}
            <Route path="start" element={<Starting />} />
            {/*  Create Group*/}

            <Route path="event" element={<Event />} />
            <Route path="onboard" element={<Onboard />} />
            {/* Dhruv */}
            <Route path="mail" element={<MailButton />} />

            {/* Pavani */}
            <Route path="main-page" element={<Home />} />
            {/* Karan */}

            {/* MyEvent Section */}
            <Route exact="true" path="myevents">
              <Route index element={<MyEvent />} />
            </Route>
            <Route path="myevents/attennding" element={<Attending />} />
            <Route path="myevents/saved" element={<Saved />} />
            <Route
              path="myevents/from_groups_you_organize"
              element={<OrganizeGroup />}
            />
            <Route
              path="myevents/from_groups_you_joined"
              element={<JoinedGroup />}
            />
            <Route path="myevents/:menu/:eventID" element={<EventPage />} />
            <Route
              path="myevents/:menu/:eventID/attendees"
              element={<EventAttendeesList />}
            />
            <Route
              path="myevents/:menu/:eventID/edit"
              element={<EditEvent />}
            />
          </Route>

          {/*  Admin section */}

          <Route path="admin">
            {/* Dashboard */}
            <Route path="dashboard" element={<AdminDashboard />} />
            {/* Dashboard */}

            {/*subscription section*/}
            <Route path="plan" element={<Plans />} />
            <Route path="plan/createplan" element={<CreatePlan />} />
            <Route path="orders" element={<Orders />} />
            <Route path="subscription" element={<Subscription />} />
            {/*subscription section*/}

            {/*Manage*/}
            <Route path="group" element={<ManageGroups />} />
            <Route path="users" element={<ManangeUser />} />
            <Route path="event" element={<ManageEvent />} />
            <Route path="topiccategories" element={<TopicCateogries />} />
            <Route path="topics" element={<Topics />} />
            {/*Manage*/}

            {/*createPages*/}
            <Route path="blog" element={<Blog />} />
            <Route path="blog/createblog" element={<Createblog />} />
            <Route path="blogcategories" element={<BlogCateogries />} />
            <Route
              path="blogcategories/createblogcateogries"
              element={<CreateBlogCategories />}
            />

            <Route path="pages" element={<Pages />} />
            {/*createPages*/}

            {/*Setting*/}
            <Route
              path="setting/application"
              element={<SettingApplication />}
            />

            <Route path="setting/mail" element={<MailSetting />} />
            <Route path="setting/company" element={<Company />} />
            <Route path="setting/currencies" element={<CurrenciesSetting />} />
            <Route path="setting/location" element={<LocationSetting />} />
            <Route path="setting/socallogin" element={<SocailLoginSetting />} />
            {/*Setting*/}
          </Route>
          {/*  Admin section */}
          <Route
            exact
            path="/:companyName/group/:groupNameParams/discussion/:discussionId"
            element={<DiscussionDetails />}
          />
          {/*My Group section*/}
          <Route path="groups">
            <Route path="ga" element={<MyGroup />} />
            <Route path="g" element={<MygroupOnBaord />} />
            <Route path="schedule" element={<ScheduleEvent />} />

            {/*Group Setting*/}
            <Route path="g/:groupId/setting" element={<BasicSetting />} />
            <Route path="content-visibility" element={<ContentVisibility />} />
            <Route path="optional-setting" element={<OptionalSeting />} />
            <Route path="topics" element={<TopicsSetting />} />
            <Route path="new-member" element={<NewMmeber />} />
            {/*Group Setting*/}

            {/*Event setting*/}
            {/*Event setting*/}
          </Route>
          {/*My Group section*/}

          {/*Profile*/}
          <Route path="user">
            <Route path="profile" element={<Profile />} />
          </Route>
          {/*Profile*/}

          <Route path="find">
            <Route path="event" element={<FindEvent />} />
            <Route path="group" element={<FindGroup />} />
          </Route>

          <Route path="searchInterest" element={<SearchInterest />} />
          <Route path="searchEvent" element={<SearchEvent />} />
          <Route path="searchGroup" element={<SearchGroup />} />
          <Route path="searchUser" element={<SearchUser />} />

          <Route
            path="searchInterestManual"
            element={<SearchInterestManual />}
          />

          {/*TEST FOR CALENDAR*/}
          <Route path="cal" element={<CalenderForm />} />
          <Route path="card" element={<Cards />} />

          {/* Temp Email Preview */}
          <Route path="emailPreview" element={<FakeEmailPreviewTemp />} />
        </Route>
      </Routes>
      {/* <Footer/> */}
    </>
  );
};
