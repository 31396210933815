import React from "react";

import algoliasearch from "algoliasearch";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";
import { Button, Card, Container, Image, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import ShareModel from "../../../Main/Components/ShareModal/App";
import { useState } from "react";

import "./Algoliaor.css";
import parse from "html-react-parser";

const searchClient = algoliasearch(
  "WATHJNNCPH",
  "93047dbcdc7325544538039050bc3893"
);
// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
// );

const SearchGroup = () => {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="groups">
      <SearchBox className="d-block mx-auto my-2 text-center " />
        <Hits hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
};

export default SearchGroup;

// {eventsFilterListArr.map(({ id }, index) => {
//   return <EventCardList key={id} index={index} />;
// })}

const Hit = ({ hit }) => {
  //loading
  const [imgLoading, setImgLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let {
    groupGallery,
    objectID,
    groupName,
    subscriberName,
    description,
    totalMember,
    groupType,
  } = hit;

  return (
    <>
      <Container>
        <Card className="flex-column flex-md-row border-0 bg-dark text-light w-100 h-100">
          <div className="col-md-3">
            <Link to={`/group/${objectID}`} className="text-decoration-none">
              <div className="img-wrap ">
                <div className="img-content">
                  <Image
                    fluid
                    draggable={false}
                    src={groupGallery[0]}
                    style={{
                      aspectRatio: "16 / 10",
                    }}
                    className={`rounded ${imgLoading ? "d-none" : null}`}
                    onLoad={() => {
                      setImgLoading(false);
                    }}
                    alt="Runolfsdottir Group"
                  />
                  {imgLoading ? (
                    <Placeholder animation="glow">
                      <Placeholder
                        className="rounded w-100 h-100 "
                        style={{
                          aspectRatio: "16 / 10 ",
                        }}
                      />
                    </Placeholder>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
          <Card.Body className="py-0 px-0 px-md-2 d-flex flex-column justify-content-between multiLineLabel">
            <Container fluid className="p-0 py-2 ">
              <Link
                to={`/groups/ga`}
                className="text-decoration-none text-light"
              >
                <Card.Title className="fs-6 fw-bold">{groupName}</Card.Title>
              </Link>
              <Card.Text className="d-flex fs-6 mb-1">
                {subscriberName}
              </Card.Text>
              <Card.Text className="textMaxLine description_filter">
                <small>{parse(description)}</small>
              </Card.Text>
            </Container>
            <Container
              fluid
              className="d-flex flex-row align-items-center justify-content-between px-0"
              style={{ fontSize: "0.9rem" }}
            >
              <div className="px-0 ">
                <p className="my-0">
                  <i>
                    {totalMember} Members - {groupType} Group
                  </i>
                </p>
              </div>
              <div className="text-light">
                <Button
                  variant="link"
                  onClick={handleShow}
                  className="text-decoration-none shadow-none p-0 px-1 text-light"
                >
                  <FaShareAlt />
                </Button>
              </div>
            </Container>
          </Card.Body>
          <ShareModel show={show} handleClose={handleClose} />
        </Card>
        <hr className="mt-4" />
      </Container>
    </>
  );
};
