import React from "react";

import algoliasearch from "algoliasearch";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";

import "./Algoliaor.css";

const searchClient = algoliasearch(
  "WATHJNNCPH",
  "93047dbcdc7325544538039050bc3893"
);
// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
// );

const SearchInterest = () => {
  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="interests">
        <SearchBox className="d-block mx-auto my-2 text-center" />
        <Hits hitComponent={Hit} />
      </InstantSearch>
    </div>
  );
};

export default SearchInterest;

const Hit = ({ hit }) => {
  let { interest } = hit;
  return <button className="btn btn-primary m-1"> {interest} </button>;
};
