import React, { useState } from "react";
// Routes
import { Link } from "react-router-dom";

// Css
import "./App.css";

//Icons
import { FaShareAlt } from "react-icons/fa";
import { BsFillStarFill, BsStar } from "react-icons/bs";

//Components
import ShareModel from "../../ShareModal/App";

// Bootstrap
import { Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";

// Svg
import placeHolderSvg from "../../../../../assets/svg/PlaceholderSvg.svg";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getSavedEventsOfUserAsync, userState } from "../../User/reduxSlice";
import { setShareLink } from "../../ShareModal/reduxSlice";
import { saveEventAsync, unSaveEventAsync } from "../Page Component/reduxSlice";

// Other laibrary
import moment from "moment";
import parse from "html-react-parser";

export default function EventCard({ index, eventData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { savedEvents } = useSelector(userState);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const toDateTime = (secs) => {
    var t = new Date(Date.UTC(1970, 0, 1)); // Epoch
    t.setUTCSeconds(secs);
    return t;
  };

  if (!eventData) {
    return null;
  }
  let {
    id,
    eventGallery,
    eventName,
    eventStartsAt,
    attendeesId,
    // groupId,
    description,
    companyName,
    // draft,
    // eventEndsAt,
    // arrow_right,
    // eventMode,
    // eventType,
    // link,
    // location,
  } = eventData;
  let isSaved = savedEvents.includes(id);

  return (
    <div className="event_list_img" style={{ width: "19rem" }}>
      <Card className="bg-dark text-light border-0 mx-2">
        <Link draggable={false} to={`/${companyName}/${eventName}`}>
          <Image
            fluid
            draggable={false}
            style={{
              aspectRatio: "16 / 10",
            }}
            className={` rounded-3 w-100 ${loading ? "d-none" : null}`}
            onLoad={() => {
              setLoading(false);
            }}
            src={eventGallery}
            alt="Runolfsdottir Group"
          />
          {loading ? (
            <Image
              fluid
              draggable={false}
              style={{
                aspectRatio: "16 / 10",
              }}
              className={` rounded-3 shadow-sm ${loading ? "" : "d-none"}`}
              src={placeHolderSvg}
              alt="Placeholder "
            />
          ) : null}
        </Link>
        <Card.Body className="multiLineLabel px-0">
          <Card.Title>
            <time className="text-light text-uppercase tracking-tight fs-6 py-1">
              <small>
                {moment(
                  toDateTime(eventStartsAt?._seconds || eventStartsAt?.seconds)
                ).format("llll")}</small>

            </time>
          </Card.Title>
          <Card.Text className="mb-2 overflow-hidden">
            <Link to={"/"} className="text-decoration-none" draggable="false">
              <h6 className="fw-bold text-light textMaxLine my-0">
                {eventName}
              </h6>
            </Link>
          </Card.Text>
          <Card.Text
            className="textMaxLine mb-2 overflow-hidden"
            style={{ height: "48px" }}
          >
            {parse(description)}
          </Card.Text>
          <div className="d-flex flex-row align-items-center justify-content-between text-muted">
            <span>{attendeesId?.length} attendees</span>
            <div className="">
              <span className="mx-2 fs-5 ">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {isSaved ? <span>Saved</span> : <span>Save</span>}
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                    >
                      <div ref={ref}>
                        {isSaved ? (
                          <BsFillStarFill
                            onClick={async () => {
                              await dispatch(
                                unSaveEventAsync({
                                  companyName,
                                  eventNameParams: eventName,
                                })
                              );
                              dispatch(getSavedEventsOfUserAsync({}));
                            }}
                            className="starFill"
                          />
                        ) : (
                          <BsStar
                            onClick={async () => {
                              await dispatch(
                                saveEventAsync({
                                  companyName,
                                  eventNameParams: eventName,
                                })
                              );
                              dispatch(getSavedEventsOfUserAsync({}));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </span>
              <span
                className=" fs-5"
                onClick={() => {
                  handleShow();
                  dispatch(
                    setShareLink({
                      value: encodeURI(
                        `${window.location.protocol}//${window.location.host}/${companyName}/${eventName}`
                      ),
                    })
                  );
                }}
              >
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-2">Share</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <div
                      variant="light"
                      {...triggerHandler}
                      className="d-inline-flex align-items-center"
                    >
                      <div ref={ref}>
                        <FaShareAlt />
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ShareModel show={show} handleClose={handleClose} index={index} />
    </div>
  );
}
