import React from 'react';
import { Icon } from '@iconify/react';

const Searchbar = () => {


    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <form className="form-inline my-2 my-lg-0 d-flex">
                        <input className="form-control mr-sm-2" type="search"  icon="bx:search-alt-2" placeholder="Search here" aria-label="Search"/>
                        <button className="btn btn-warning my-2 my-sm-0" type="submit"><Icon icon="bx:search-alt-2" /></button>
                    </form>
                </div>
            </nav>
        </div>
    )
}
export default Searchbar