import React from "react";

// Bootstrap
import { Form, Button } from "react-bootstrap";

// Components
import TextEditor from "../../../TextEditor/App";

const GroupDescription = ({
  description,
  setDescription,
  nextPage,
  backPage,
}) => {
  return (
    <div className="page-wrapper">
      <section className="">
        <form>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-8">
              <h1>Now describe what your group will be about</h1>
              <p>
                People will see this when we promote your group, but you'll be
                able to add to it later, too.
              </p>
              <ol className="px-3">
                <li>What's the purpose of the group?</li>
                <li>Who should join?</li>
                <li>What will you do at your events?</li>
              </ol>
              <div className="input-icon mb-3 place_autocomplete_container">
                <Form validated className="">
                  <TextEditor
                    editorState={description}
                    setEditorState={setDescription}
                  />
                </Form>
                <div className="mt-3">
                  <Button onClick={backPage}>Back</Button>
                  <Button
                    className="btn btn-primary float-end"
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupDescription;
