import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../firebase.config";

export function createFaqAPI(obj) {
  const createFaq = httpsCallable(functions, "createFaq");
  return createFaq({
    ...obj,
  });
}

export function getFaqAPI(eventId) {
  const getFaq = httpsCallable(functions, "getFaq");
  return getFaq({
    eventId,
  });
}

export function updateFaqAPI(obj) {
  const updateFaq = httpsCallable(functions, "updateFaq");
  return updateFaq({
    ...obj,
  });
}

export function deleteFaqAPI(eventId, faqId) {
  const deleteFaq = httpsCallable(functions, "deleteFaq");
  return deleteFaq({
    eventId,
    faqId,
  });
}
