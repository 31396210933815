import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { registerUser, assignRole, validateCompanyNameAPI } from "./reduxAPI";
import { pushErrorNotification } from "../../Error/reduxSlice";
import { getDocIdofUserAsync, setUserAvl } from "../../User/reduxSlice";
import { loginUser, rememberAfterLogin } from "../Login/reduxAPI";

// icon
import { Icon } from "@iconify/react";

const initialState = {
  role: "attendee",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  companyName: "",
  rememberMe: true,
  loading: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const registerUserAsync = createAsyncThunk(
  "register/createUser",
  async (
    {
      navigate,
      navigatePath = "/",
      hideEmailInUserError = false,
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        rememberMe,
        email,
        password,
        firstName,
        lastName,
        role,
        companyName,
      } = getState().register;
      // create user

      if (!email || !password) {
        return;
      }

      const userCredential = await registerUser(email, password);

      // assigning attendee role
      // const { data } =
      await assignRole(
        userCredential.user.uid,
        firstName,
        lastName,
        email,
        rememberMe,
        role,
        companyName
      );

      // Relogin

      const userCredentialLogin = await loginUser(email, password);
      const user = userCredentialLogin.user;
      if (user) {
        dispatch(setUserAvl({ userAvl: true, user }));
        await dispatch(getDocIdofUserAsync({ uid: user.uid }));
      }

      const { data } = await rememberAfterLogin(
        userCredentialLogin.user.uid,
        rememberMe
      );

      if (navigate) {
        if (!(window.location.hostname !== "localhost")) {
          navigate("/");
        } else {
          navigate(navigatePath, { replace: true });
        }
      }

      localStorage.setItem("preEventToken", data.token);
    } catch (error) {
      if (error.name === "FirebaseError" && !hideEmailInUserError) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const validateCompanyNameAsync = createAsyncThunk(
  "register/validateCompanyNameAsync",
  async ({ navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { companyName } = getState().register;
      // validating company
      const companyNameValid = await validateCompanyNameAPI(companyName);

      if (!companyNameValid) {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: "unvalid Input",
              errorMessage: "This company already exists",
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 400,
              show: true,
            },
          })
        );
      } else {
        dispatch(registerUserAsync({ navigate }));
      }
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload.firstName;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.lastName;
    },

    setCompanyName: (state, action) => {
      state.companyName = action.payload.companyName;
    },

    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setPassword: (state, action) => {
      state.password = action.payload.password;
    },
    setConfirmPassword: (state, action) => {
      state.confirmPassword = action.payload.confirmPassword;
    },
    toggleRememberMe: (state, action) => {
      state.rememberMe = !state.rememberMe;
    },
    toggleRole: (state, action) => {
      if (state.role === "attendee") {
        state.role = "subscriber";
      } else if (state.role === "subscriber") {
        state.role = "attendee";
      }
    },
    clearRegisterInputs: (state, action) => {
      state.role = "attendee";
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.password = "";
      state.confirmPassword = "";
      state.rememberMe = true;
      state.loading = false;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(registerUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setEmail,
  setPassword,
  setFirstName,
  setLastName,
  setConfirmPassword,
  setCompanyName,
  toggleRememberMe,
  toggleRole,
  clearRegisterInputs,
} = registerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const registerState = (state) => state.register;

export default registerSlice.reducer;
