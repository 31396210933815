import { functions, storage, db_firestore } from "../../../../firebase.config";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";

import { searchClient } from "../../../../algolia/algoliaKey";

//Upload file
export function uploadFiles(file, id) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(storage, `users/${id}/profilePic/` + file.name);
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export function userProfile(
  profileUrl,
  userName,
  firstName,
  lastName,
  email,
  phoneNumber,
  gender,
  bio,
  timeZone,
  birthDate
) {
  const updatePersonalInfofn = httpsCallable(functions, "profileUpdate");
  return updatePersonalInfofn({
    profileUrl,
    userName,
    firstName,
    lastName,
    email,
    phoneNumber,
    gender,
    bio,
    timeZone,
    birthDate,
  });
}

//social
export function updateSocialInfo(facebook, instagram, twitter, linkedin) {
  const updateSocialInfofn = httpsCallable(functions, "userSocialLinkUpdate");
  return updateSocialInfofn({
    facebook,
    instagram,
    twitter,
    linkedin,
  });
}

//location
export function updateLocationInfo(city, country, address) {
  const updateLocationInfofn = httpsCallable(functions, "userLoctionUpdate");
  return updateLocationInfofn({
    city,
    country,
    address,
  });
}

// update profile url
export function updateProfileUrlAPI(docId, profileUrl) {
  const updateProfileUrl = httpsCallable(functions, "updateProfileUrl");
  return updateProfileUrl({
    docId,
    profileUrl,
  });
}

//FETCH DATA
export async function getUserProfileAPI(docId) {
  const userRef = doc(db_firestore, "users", docId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return { notFound: true };
  }
}

export function addNewInterestAPI(interestId, docId) {
  const addNewInterest = httpsCallable(functions, "addNewInterest");
  return addNewInterest({
    interestId,
    docId,
  });
}

export function removeInterestAPI(interestId, docId) {
  const removeInterest = httpsCallable(functions, "removeInterest");
  return removeInterest({
    interestId,
    docId,
  });
}
export function getInterestsAPI(docId) {
  const getInterests = httpsCallable(functions, "getInterests");
  return getInterests({
    docId,
  });
}
export function getRecommendedInterestAPI(docId) {
  const getRecommendedInterest = httpsCallable(
    functions,
    "getRecommendedInterest"
  );
  return getRecommendedInterest({
    docId,
  });
}

export function addInterestsAPI(interest, docId) {
  const addInterests = httpsCallable(functions, "addInterests");
  return addInterests({
    interest,
    docId,
  });
}

export async function algoliaInterestAPI(searchQuery, algoliaIndex) {
  const searchInterestIndex = searchClient.initIndex(algoliaIndex);
  return await searchInterestIndex.search(searchQuery);
}
