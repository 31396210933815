import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Placeholder, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  algoliaAsync,
  setSearchFound,
  setSearchQuery,
} from "../../../../Main/Components/Navbar/reduxSlice";

const NotFoundFilterEvent = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  return (
    <div className="container px-0">
      <div className="d-flex flex-column flex-md-row justify-content-around align-items-center border rounded-3 p-4 ">
        <div className=" p-2">
          <Image
            fluid
            draggable={false}
            style={{
              aspectRatio: "1 / 1",
              maxHeight: "150px",
              width: "100%",
            }}
            className={` rounded d-block mx-auto ${loading ? "d-none" : null}`}
            onLoad={() => {
              setLoading(false);
            }}
            src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fno-events.svg?alt=media&token=4c59ee87-eaeb-4425-b9fb-73f5b137941d"
            alt="not Found"
          />

          {loading ? (
            <Placeholder className="d-block text-center" animation="glow">
              <Placeholder
                className="rounded h-100  "
                style={{
                  aspectRatio: "1 / 1 ",
                  minHeight: "150px",
                }}
              />
            </Placeholder>
          ) : null}
        </div>
        <p className="text-center">
          There are no Events right now. Find one today.
        </p>
        <div className="text-center">
          <Link to={{ pathname: "/find/event" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(setSearchQuery({ value: "" }));
                dispatch(algoliaAsync({}));
                dispatch(setSearchFound({ value: true }));
              }}
            >
              Discover Events
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundFilterEvent;
