import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pushErrorNotification } from "../../../../Main/Components/Error/reduxSlice";
import { getGroupsAPI, getJoinedGroupsAPI } from "./reduxAPI";

const initialState = {
  organizer: false,

  organizedLoading: false,
  organizedFound: true,
  organizedGroupsDataArr: [],

  publicGroupLoading: false,
  publicGroupFound: true,
  publicGroupsDataArr: [],

  joinedGroupsLoading: false,
  joinedGroupsFound: true,
  joinedGroupsDataArr: [],
};

export const joinedGroupsListAsync = createAsyncThunk(
  "groupsList/joinedGroupsListAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;

      const response = await getJoinedGroupsAPI(docId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              // autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const publicGroupsListAsync = createAsyncThunk(
  "groupsList/publicGroupsListAsync",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { user } = getState().user;
      const response = await getGroupsAPI(false, user?.uid, all, true);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const groupsListAsync = createAsyncThunk(
  "groupsList/fetchGroupsList",
  async ({ all, organizer }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { user } = getState().user;
      const response = await getGroupsAPI(organizer, user?.uid, all);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              // autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const groupsListSlice = createSlice({
  name: "groupsList",
  initialState,

  reducers: {
   

   

    clearPublicGroupDataArr: (state, action) => {
      state.publicGroupLoading = false;
      state.publicGroupFound = true;
      state.publicGroupsDataArr = [];
    },
    clearJoinedGroupDataArr: (state, action) => {
      state.joinedGroupsLoading = false;
      state.joinedGroupsFound = true;
      state.joinedGroupsDataArr = [];
    },
    clearOrganizedGroupsDataArr: (state, action) => {
      state.organizedLoading = false;
      state.organizedFound = true;
      state.organizedGroupsDataArr = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(groupsListAsync.pending, (state, action) => {
        state.organizedLoading = true;
      })
      .addCase(groupsListAsync.fulfilled, (state, action) => {
        state.organizedLoading = false;
        state.organizedGroupsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.organizedFound = false;
        }
      })
      .addCase(groupsListAsync.rejected, (state, action) => {
        state.organizedLoading = false;
      })
      .addCase(publicGroupsListAsync.pending, (state, action) => {
        state.publicGroupLoading = true;
      })
      .addCase(publicGroupsListAsync.fulfilled, (state, action) => {
        state.publicGroupLoading = false;
        state.publicGroupsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.publicGroupFound = false;
        }
      })
      .addCase(publicGroupsListAsync.rejected, (state, action) => {
        state.publicGroupLoading = false;
      })

      .addCase(joinedGroupsListAsync.pending, (state, action) => {
        state.joinedGroupsLoading = true;
      })
      .addCase(joinedGroupsListAsync.fulfilled, (state, action) => {
        state.joinedGroupsLoading = false;
        state.joinedGroupsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.joinedGroupsFound = false;
        }
      })
      .addCase(joinedGroupsListAsync.rejected, (state, action) => {
        state.joinedGroupsLoading = false;
      });
  },
});

export const {
  setOrganizer,
  clearGroupsDataArr,
  clearPublicGroupDataArr,
  clearOrganizedGroupsDataArr,
  clearJoinedGroupDataArr,
} = groupsListSlice.actions;
export const groupsListState = (state) => state.groupsList;

export default groupsListSlice.reducer;
