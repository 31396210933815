import React , {useState}  from 'react';
import {Link} from "react-router-dom";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import AlertModal from "../../CommonComponents/AlertModal/App";
import Admin_Footer from "../../CommonComponents/Footer/App";


const Blog = () => {
    const [showModel , setShowModel] = useState(false)

    const ShowModel = () => {
        setShowModel(true)
    }
    return (

        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                   <small> Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Blogs
                                </h1>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <Link to = "createblog"
                                   className="btn btn-primary">
                                    Create new
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bold">Blogs</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Created at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>

                                            {/* main table */}
                                            <tbody className="list" id="blogs">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">10</p>
                                                </td>
                                                <td>
                                                    <Link to = "#" className="mb-0 text-light text-truncate text-decoration-none fs-6" target="_blank"
                                                       >
                                                        YOUR adventures.' 'I could tell you my adventures--beginning
                                                        from this side of t...
                                                    </Link>
                                                </td>
                                                <td>
                                                    <p className="mb-0">alias</p>
                                                </td>
                                                <td>2022-07-13</td>
                                                <td className="text-end">
                                                    <Link to = "#"  className = "text-decoration-none">
                                                        Edit
                                                    </Link>
                                                    <span onClick={ShowModel} className="ms-2 text-danger delete-confirm">
                                                        Delete
                                                    </span>
                                                    <AlertModal
                                                        show={showModel}
                                                        onHide={() => setShowModel(false)}
                                                    />
                                                </td>
                                            </tr>
                                           
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
            <Admin_Footer/>
            
        </div>
    );
};

export default Blog;