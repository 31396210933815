import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { ItemTypes } from "./ItemTypes.js";
import { Form } from "react-bootstrap";
import { setTabsManagementShow } from "../../reduxSlice";
import { Icon } from "@iconify/react";

const handleStyle = {
  cursor: "grab",
};
export const Card = ({ show, moveCard, index, name }) => {
  const dispatch = useDispatch();

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.35 : 1;
  drag(ref);
  return (
    <div
      ref={drop}
      style={{ opacity }}
      className="col-6 col-md-4 mb-2 d-flex align-items-center"
    >
      <Form.Check
        className=""
        checked={show}
        data-handler-id={handlerId}
        type={"checkbox"}
        onChange={(e) => {
          dispatch(setTabsManagementShow({ value: e.target.checked, index }));
        }}
        id={name}
        label={name}
      />
      <div className="mx-3 " ref={ref} style={handleStyle}>
        <Icon icon="fluent:re-order-dots-vertical-24-filled" />
      </div>
    </div>
  );
};
