import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFilteredEventsAPI } from "./reduxAPI";

import { pushErrorNotification } from "../../Error/reduxSlice";
import { Icon } from "@iconify/react";
const initialState = {
  loading: false,
  found: true,

  eventsFilterListArr: [],

  filterEvent: "attennding", // attennding , saved , from_groups_you_organize , from_groups_you_joined
  upcomming: true,
};

export const filteredEventAsync = createAsyncThunk(
  "eventsFilterList/fetchEvent",
  async ({ allEvent }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { upcomming, filterEvent } = getState().eventsFilterList;
      const { docId } = getState().user;
      const response = await getFilteredEventsAPI(
        filterEvent,
        upcomming,
        docId,
        allEvent
      );

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

const eventsFilterListSlice = createSlice({
  name: "eventsFilterList",
  initialState,
  reducers: {
    setEventPost: (state, action) => {
      state.filterEvent = action.payload.filterValue;
    },
    setUpcomming: (state, action) => {
      state.upcomming = action.payload.isUpComming;
    },
    setFound: (state, action) => {
      state.found = action.payload.value;
    },
    clearDataArr: (state, action) => {
      state.eventsFilterListArr = [];
    },

    setEventsFilterListArr: (state, action) => {
      state.eventsFilterListArr = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filteredEventAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filteredEventAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.eventsFilterListArr = [...action.payload];
        if (action.payload.length <= 0) {
          state.found = false;
        }
      })
      .addCase(filteredEventAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setEventPost, setUpcomming, clearDataArr, setFound , setEventsFilterListArr } =
  eventsFilterListSlice.actions;

// create state here and export
export const eventsFilterListState = (state) => state.eventsFilterList;

export default eventsFilterListSlice.reducer;
