import React, { useState } from "react";
import VenueInfo from "./venueInfo/VenueInfo";

// Routes
import { Link, NavLink, useParams } from "react-router-dom";

// Css
// import "../../App.css";

// Animation
import { animated, useSpring } from "@react-spring/web";

// Bootstrap
import {
  Container,
  Dropdown,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Placeholder,
  Image,
} from "react-bootstrap";

// Icons
import {
  BsFillPersonFill,
  BsFillCalendarFill,
  BsCameraVideoFill,
  BsPerson,
  BsFillCalendar2XFill,
  BsFillCalendarWeekFill,
  BsNewspaper,
  BsPinMapFill,
} from "react-icons/bs";
import { GrAnnounce, GrTableAdd } from "react-icons/gr";
import { FaHotel } from "react-icons/fa";
import { TbSocial } from "react-icons/tb";
import { Icon } from "@iconify/react";
import { IoLocation } from "react-icons/io5";
import { BiChevronDown, BiEdit, BiLinkExternal } from "react-icons/bi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { BiError } from "react-icons/bi";
import { AiOutlineForm, AiFillCar } from "react-icons/ai";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { MdFlight, MdPhotoLibrary } from "react-icons/md";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { eventState } from "../reduxSlice";
import { userState } from "../../../User/reduxSlice";
import { venueInformationState } from "./venueInfo/reduxSlice";

import { setEventpageNavPannel } from "../reduxSlice";

/**
 * Other Laibrary
 */

import { getCity } from "../CheckInCheckOut/reduxAPI"
import moment from "moment";
import parse from "html-react-parser";
import {
  getVenueInformationAsync,
  updateVenueInformationAsync,
} from "./venueInfo/reduxSlice";
import { setShowForm } from "../AnnouncementForm/reduxSlice";
import AnnouncementForm from "../AnnouncementForm/App";
import { Calender } from "../Schedule/components/sessions/Calendar/AddtoCalendar";
import { addCheckInCheckOutAsync, checkInCheckOutState, getMyStatusAsync, setLastCheck, setLocation } from "../CheckInCheckOut/reduxSlice";
import CheckInCheckOut from "../CheckInCheckOut/App";
import { formGeneratorState } from "../Documents/components/FormGenerator/reduxSlice";

export default function Details() {
  const { companyName } = useParams();
  const dispatch = useDispatch();


  // loading
  const [imgLoading, setImgLoading] = useState(true);

  const {
    // eventPageNav,
    // eventActionLoading,
    eventData: {
      description,
      eventMode,
      eventName,
      eventStartsAt,
      link,
      location,
      subscriberUid,
      mapLink,
      feedBackLink,
      // draft,
      // eventEndsAt,
      // eventGallery,
      // eventType,
      id,
      // groupId: groupIdDb,
      attendeesId,
    },
    groupData: { groupGallery, groupName, groupType },
    // showRegistrationForm,
    // formData,
  } = useSelector(eventState);

  const { userAvl, user } = useSelector(userState);

  const { statusPath } = useSelector(checkInCheckOutState);

  const { data } = useSelector(venueInformationState);

  let ismyEvent;
  if (userAvl) {
    ismyEvent = user?.uid === subscriberUid;
  }

  const [showAnnounce, setAnnounceShow] = useState(false);
  const [showVenueInfo, setVenueInfoShow] = useState(false);
  const [showFeedBack, setFeedBackShow] = useState(false);
  const [showFloorMap, setFloorMapShow] = useState(false);
  const [showCancelEvent, setCancelEventShow] = useState(false);
  const handleAnnounceClose = () => setAnnounceShow(false);
  const handleAnnounceShow = () => setAnnounceShow(true);
  const handleVenueInfoClose = () => setVenueInfoShow(false);
  const handleVenueInfoShow = () => setVenueInfoShow(true);
  const handleFeedBackClose = () => setFeedBackShow(false);
  const handleFeedBackShow = () => setFeedBackShow(true);
  const handleFloorMapClose = () => setFloorMapShow(false);
  const handleFloorMapShow = () => setFloorMapShow(true);
  const handleCancelEventClose = () => setCancelEventShow(false);
  const handleCancelEventShow = () => setCancelEventShow(true);
  const handleAnnouncementForm = () => {
    dispatch(setShowForm({ value: true }));
  };

  const submitHandlerVenueInformation = async () => {
    await dispatch(updateVenueInformationAsync({}));
    handleVenueInfoClose();
    dispatch(getVenueInformationAsync({}));
  };

  // Animation
  const descriptionStyle = useSpring({
    config: { duration: 500 },
    from: { opacity: 0, y: 50 },
    to: {
      opacity: 1,
      y: 0,
    },
  });


  let nextCheckIn = false;
  if (statusPath.length % 2 === 0 || statusPath.length === 0) {
    nextCheckIn = true;
  }


  let going;
  if (userAvl) {
    ismyEvent = user?.uid === subscriberUid;
    going = attendeesId?.includes(user?.uid);
  }

  return (
    <Container fluid className="px-0 mx-0 mt-5">
      <div className="body my-4 container-fluid container-lg">
        <Row className="flex-column flex-lg-row">
          <Col className="col col-lg-8 mb-5 mb-0">
            <animated.div style={descriptionStyle}>
              <Container fluid className="px-0">
                <h4>{eventName}</h4>
                <div className="mt-4">
                  {/* <h6 className="fw-bold">Details</h6> */}
                  <p className="bg-dark text-light">
                    {parse(description || "")}
                  </p>
                </div>
              </Container>
              <div className="d-flex justify-content-between py-2">
                <h5>
                  Attendees <span>({attendeesId?.length})</span>
                </h5>
                <Link
                  to={"attendees"}
                  className="text-decoration-none text-light"
                >
                  See all
                </Link>
              </div>
              <div className="d-flex flex-wrap">
                {/* data?.attendees */}
                {Array.from({ length: attendeesId?.length }, (_, i) => (
                  <BsFillPersonFill
                    className="fs-1 border rounded-circle p-1 me-2"
                    key={i}
                  />
                ))}
              </div>

              {attendeesId?.length === 0 ? (
                <div className="text-center my-4">No Attendees </div>
              ) : null}

              <Row>
                {data?.photoBooth.platform ? (
                  <Col sm={12} md={4}>
                    <div className="border rounded p-3 shadow-sm mt-3">
                      <h6>{data.photoBooth.name}</h6>
                      <div className="d-flex align-items-center mt-2">
                        <MdPhotoLibrary className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <MdPhotoLibrary className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <Button
                          variant="link"
                          href={data.photoBooth.platform}
                          target="_blank"
                          className="px-2 text-decoration-none text-light shadow-none"
                        >
                          View More
                        </Button>
                      </div>
                    </div>
                  </Col>
                ) : null}
                {data?.socialFeed.platform ? (
                  <Col sm={12} md={4}>
                    <div className="border rounded p-3 shadow-sm mt-3">
                      <h6>{data.socialFeed.name}</h6>
                      <div className="d-flex align-items-center mt-2">
                        <TbSocial className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <TbSocial className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <Button
                          variant="link"
                          target="_blank"
                          className="px-2 text-decoration-none text-light shadow-none"
                          href={data.socialFeed.platform}
                        >
                          View More
                        </Button>
                      </div>
                    </div>
                  </Col>
                ) : null}
                {data?.eventNews.platform ? (
                  <Col sm={12} md={4}>
                    <div className="border rounded p-3 shadow-sm mt-3">
                      <h6>{data.eventNews.name}</h6>
                      <div className="d-flex align-items-center mt-2">
                        <BsNewspaper className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <BsNewspaper className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <Button
                          variant="link"
                          href={data.eventNews.platform}
                          target="_blank"
                          className="px-2 text-decoration-none text-light shadow-none"
                        >
                          View More
                        </Button>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </animated.div>
          </Col>

          <Col>
            <animated.div
              className={"col position-md-fixed position-lg-fixed"}
              style={descriptionStyle}
            >
              {ismyEvent ? (
                <>
                  <Dropdown className="w-100 mb-2">
                    <Dropdown.Toggle
                      variant="dark"
                      id="dropdown-basic"
                      className="border bg-dark text-light shadow-sm w-100"
                    >
                      Organizer Settings <BiChevronDown className="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="start-0 w-100 bg-dark border "
                      style={{ zIndex: 1000 }}
                    >
                      <Link
                        className="text-light dropdown-item"
                        to={"attendees"}
                      >
                        <BsPerson className="me-2" />
                        Manage attendees
                      </Link>
                      <Dropdown.Item className="text-light">
                        <BsFillCalendar2XFill className="me-2" />
                        Close for RSVPs
                      </Dropdown.Item>
                      <Link className="dropdown-item text-light" to={"edit"}>
                        <BiEdit className="me-2" />
                        Edit event
                      </Link>
                      <Link className="dropdown-item text-light" to={"invite"}>
                        <Icon className="me-2" icon="mingcute:invite-line" />
                        Invite People
                      </Link>
                      <Link
                        className="dropdown-item text-light"
                        to={"networkingManagement"}
                      >
                        <Icon className="me-2" icon="dashicons:networking" />
                        Networking Management
                      </Link>
                      <Button
                        variant={"link"}
                        className=" shadow-none dropdown-item text-light"
                        onClick={handleVenueInfoShow}
                      >
                        <BiLinkExternal className="me-2" />
                        Additional Information
                      </Button>
                      <Button
                        variant={"link"}
                        className=" shadow-none dropdown-item text-light"
                        onClick={handleAnnouncementForm}
                      >
                        <GrAnnounce className="me-2" />
                        Make an Announcement
                      </Button>
                      <Button
                        variant={"link"}
                        onClick={handleCancelEventShow}
                        className=" shadow-none dropdown-item text-light"
                      >
                        <IoCloseCircleOutline className="me-2" />
                        Cancel event
                      </Button>
                    </Dropdown.Menu>
                    <Modal
                      show={showCancelEvent}
                      onHide={handleCancelEventClose}
                      backdrop="static"
                      keyboard={false}
                      className="px-0"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Cancel event</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        This will automatically update your attendees that it is
                        no longer happening. (You can not undo this.)
                        <Form>
                          <Form.Check type="radio">
                            <Form.Check.Input type="radio" name="name1" />
                            <Form.Check.Label>Cancel event</Form.Check.Label>
                          </Form.Check>
                          <Form.Check type="radio" className="text-light">
                            <Form.Check.Input
                              type="radio"
                              name="name1"
                              className="text-light"
                              isValid
                            />
                            <Form.Check.Label className="text-light">
                              Cancel and delete event
                            </Form.Check.Label>
                            <Form.Control.Feedback
                              type="valid"
                              className="text-light"
                            >
                              Deleting means that no one, including you, will be
                              able to see the event page ever again.
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary">Confirm</Button>
                      </Modal.Footer>
                    </Modal>
                  </Dropdown>

                  {/* <div className="p-2 my-0">
                      <p className=" my-0">
                        <small>
                          <BiError /> You did not announce this event to your
                          members, yet. Click the link to announce!
                        </small>
                        <Button
                          variant={"link"}
                          onClick={handleAnnounceShow}
                          className=" text-decoration-none p-0 m-0 shadow-none m-0 text-light text-underline ms-1"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Click here
                        </Button>
                      </p>
                    </div> */}
                  <Modal
                    show={showAnnounce}
                    onHide={handleAnnounceClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Announce this event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      This will automatically send emails to your members. (You
                      can not undo this.)
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary">Confirm</Button>
                    </Modal.Footer>
                  </Modal>
                </>
              ) : null}

              <div

                className="text-decoration-none text-light"
              >
                <div className="border rounded d-flex align-items-center my-3 p-0 shadow-sm">
                  <Link
                    to={`/${companyName}/group/${groupName}`}
                  >
                    <Image
                      draggable={false}
                      style={{
                        aspectRatio: 1 / 1,
                        width: 100,
                      }}
                      className={`img-fluid rounded-start h-100  ${imgLoading ? "d-none" : null
                        }`}
                      onLoad={() => {
                        setImgLoading(false);
                      }}
                      src={groupGallery}
                      alt="group photo"
                    />
                  </Link>
                  {imgLoading ? (
                    <Placeholder animation="glow" className="h-100">
                      <Placeholder
                        className="col-12"
                        style={{
                          aspectRatio: 1 / 1,
                          width: 100,
                        }}
                      />
                    </Placeholder>
                  ) : null}
                  <div className="my-0 ms-3 d-flex justify-content-between align-items-center w-100 pe-2 ">
                    <div>
                      <Link
                        to={`/${companyName}/group/${groupName}`}
                        className="text-decoration-none text-light"
                      >  <h6 className="mb-0">{groupName}</h6> </Link>
                      <p className="mb-0 text-capitalize">{groupType} group</p>

                    </div>

                    {going || ismyEvent ?
                      (<Button variant="primary" size="sm" onClick={async () => {
                        // AIzaSyD7t0okmT7MkW5wEkFtEae71aDJ2US6BaE
                        let address = undefined;
                        navigator.geolocation.getCurrentPosition(async (position) => {
                          var crd = position.coords;
                          var lat = crd.latitude.toString();
                          var lng = crd.longitude.toString();
                          address = await getCity(lat, lng);

                          dispatch(setLocation({ value: address }));

                          const newCheckIn = new Date().toISOString();
                          dispatch(setLastCheck({ value: newCheckIn }));


                          await dispatch(addCheckInCheckOutAsync({}));
                          dispatch(getMyStatusAsync({}));

                        }, async () => {
                          dispatch(setLocation({ value: undefined }));

                          const newCheckIn = new Date().toISOString();
                          dispatch(setLastCheck({ value: newCheckIn }));


                          await dispatch(addCheckInCheckOutAsync({}));
                          dispatch(getMyStatusAsync({}));
                        });




                      }}>
                        {nextCheckIn ? "CheckIn" : "CheckOut"}
                      </Button>) : null}

                  </div>
                </div>
              </div>

              <div className="border rounded p-3 shadow-sm">
                <div className="d-flex align-items-center">
                  <BsFillCalendarFill className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                  <BsFillCalendarFill className="bg-dark p-2 display-2 d-md-none border rounded" />
                  <p className="my-0 ms-2">
                    {moment(eventStartsAt?.toDate()).format("llll")}
                  </p>
                </div>
                {eventMode === "hybrid" || eventMode === "online" ? (
                  <div className="d-flex align-items-center mt-2   ">
                    <BsCameraVideoFill className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                    <BsCameraVideoFill className="bg-dark p-2 display-2 d-md-none border rounded" />
                    <p className="my-0 lh-sm ms-2">
                      <span className="fw-bold text-capitalize ">
                        {eventMode} Event
                      </span>
                      <br />
                      <a href={link} target="_black" className="text-light">
                        Virtual Space
                      </a>
                    </p>
                  </div>
                ) : null}
                {/* <NavLink
                  onClick={handleFloorMapShow}
                  className="text-decoration-none text-light"
                > */}
                <div
                  className="d-flex align-items-center mt-2"
                  onClick={handleFloorMapShow}
                >
                  <BsPinMapFill className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                  <BsPinMapFill className="bg-dark p-2 display-2 d-md-none border rounded" />
                  <Button
                    variant="link"
                    className="px-2 text-decoration-none text-light shadow-none"
                  >
                    Event Floor Map
                  </Button>
                </div>
                {/* </NavLink> */}
                {eventMode === "hybrid" || eventMode === "offline"}
                {mapLink ? (
                  <div className="d-flex align-items-center mt-2">
                    <IoLocation className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                    <IoLocation className="bg-dark p-2 display-2 d-md-none border rounded" />
                    <p className="my-0 lh-sm ms-2">
                      <span>{location ? location : "Location"}</span>
                      <br />
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={mapLink}
                        className="text-light"
                      >
                        Get directions
                      </a>
                    </p>
                  </div>
                ) : null}
                <div className="d-flex align-items-center mt-2">
                  <BsFillCalendarWeekFill className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                  <BsFillCalendarWeekFill className="bg-dark p-2 display-2 d-md-none border rounded" />
                  {/* <Button
                    variant="link"
                    className="px-2 text-decoration-none text-light shadow-none"
                  >
                    Add to Calendar
                  </Button> */}
                  <Calender isEventCalender={true} />
                </div>

                {feedBackLink ? (
                  <div className="d-flex align-items-center mt-2">
                    <AiOutlineForm className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                    <AiOutlineForm className="bg-dark p-2 display-2 d-md-none border rounded" />
                    <Button
                      variant="link"
                      className="px-2 text-decoration-none text-light shadow-none"
                      onClick={handleFeedBackShow}
                    >
                      FeedBack Form
                    </Button>
                  </div>
                ) : null}
              </div>

              {data ? (
                <div className="border rounded p-3 shadow-sm mt-3">
                  <h6>Venue Information</h6>
                  {data?.weather.platform ? (
                    <div className="d-flex align-items-center mt-2">
                      <TiWeatherPartlySunny className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                      <TiWeatherPartlySunny className="bg-dark p-2 display-2 d-md-none border rounded" />
                      <Button
                        variant="link"
                        href={data.weather.platform}
                        target="_blank"
                        className="px-2 text-decoration-none text-light shadow-none"
                      >
                        {data.weather.name}
                      </Button>
                    </div>
                  ) : null}
                  {data?.flightBooking.length ? (
                    <Dropdown className="d-flex align-items-center mt-2">
                      <Dropdown.Toggle
                        variant="link"
                        className="ps-0 pe-2 text-decoration-none text-light shadow-none w-100 d-flex justify-content-between align-items-center "
                      >
                        <MdFlight className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <MdFlight className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <span className="ms-2 me-auto">Flight Booking</span>
                        <BiChevronDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="start-0 w-100 bg-dark text-light"
                        style={{ transform: "translate(0) !important" }}
                      >
                        {data?.flightBooking.map((dropdownItem, i) => {
                          return (
                            <Dropdown.Item
                              href={dropdownItem.platform}
                              target="_blank"
                              className="bg-dark text-light"
                              key={i}
                            >
                              {dropdownItem.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}

                  {data?.hotelBooking.length ? (
                    <Dropdown className="d-flex align-items-center mt-2">
                      <Dropdown.Toggle
                        variant="link"
                        className="ps-0 pe-2 text-decoration-none text-light shadow-none w-100 d-flex justify-content-between align-items-center "
                      >
                        <FaHotel className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <FaHotel className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <span className="ms-2 me-auto">Book Nearby Hotel</span>
                        <BiChevronDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="start-0 w-100 bg-dark text-light"
                        style={{ transform: "translate(0) !important" }}
                      >
                        {data?.hotelBooking.map((dropdownItem, i) => {
                          return (
                            <Dropdown.Item
                              href={dropdownItem.platform}
                              target="_blank"
                              className="bg-dark text-light"
                              key={i}
                            >
                              {dropdownItem.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}

                  {data?.cabBooking.length ? (
                    <Dropdown className="d-flex align-items-center mt-2">
                      <Dropdown.Toggle
                        variant="link"
                        className="ps-0 pe-2 text-decoration-none text-light shadow-none w-100 d-flex justify-content-between align-items-center "
                      >
                        <AiFillCar className="bg-dark p-2 fs-2 d-none d-md-block border rounded" />
                        <AiFillCar className="bg-dark p-2 display-2 d-md-none border rounded" />
                        <span className="ms-2 me-auto">Book Nearby Cab</span>
                        <BiChevronDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="start-0 w-100 bg-dark text-light"
                        style={{ transform: "translate(0) !important" }}
                      >
                        {data?.cabBooking.map((dropdownItem, i) => {
                          return (
                            <Dropdown.Item
                              href={dropdownItem.platform}
                              target="_blank"
                              className="bg-dark text-light"
                              key={i}
                            >
                              {dropdownItem.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null}
                </div>
              ) : null}
            </animated.div>
          </Col>
        </Row>
      </div>

      {/* Venue Information */}
      <Modal
        show={showVenueInfo}
        onHide={handleVenueInfoClose}
        className="px-0 mx-0"
        size="xl"
      >
        <Modal.Header className="bg-dark text-light">
          <Modal.Title>Additional Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light">
          <VenueInfo />
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="primary" onClick={submitHandlerVenueInformation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Feedback Form */}
      <Modal
        show={showFeedBack}
        onHide={handleFeedBackClose}
        className="px-0 mx-0"
        size="xl"
      >
        <Modal.Header className="bg-dark text-light">
          <Modal.Title>FeedBack Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light p-0">
          <iframe
            src={feedBackLink}
            title={"feedbackform"}
            width="100%"
            height="900"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="primary" onClick={handleFeedBackClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* FloorMap */}
      <Modal
        show={showFloorMap}
        onHide={handleFloorMapClose}
        className="px-0 mx-0"
        size="xl"
      >
        <Modal.Header className="bg-dark text-light">
          <Modal.Title>Floor Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-light p-0">
          <Image
            draggable={false}
            style={{
              aspectRatio: 16 / 9,
            }}
            onClick={() => {
              dispatch(setEventpageNavPannel({ newPannel: "Booths" }));
            }}
            className={`img-fluid rounded-start h-100  ${imgLoading ? "d-none" : null
              }`}
            onLoad={() => {
              setImgLoading(false);
            }}
            src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FGroup%201%20(3).png?alt=media&token=e6b0d30e-9b0e-4f9c-8d61-8eb7a6495494"
            alt="floor photo"
          />
          {imgLoading ? (
            <Placeholder animation="glow" className="h-100">
              <Placeholder
                className="col-12"
                style={{
                  aspectRatio: 16 / 9,
                }}
              />
            </Placeholder>
          ) : null}
        </Modal.Body>
        <Modal.Footer className="bg-dark text-light">
          <Button variant="primary" onClick={handleFeedBackClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <AnnouncementForm />
    </Container >
  );
}
