import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../../firebase.config";


export function setVenueInformationAPI(eventId, venueInformation) {
    const setVenueInformation = httpsCallable(functions, "setVenueInformation");
    return setVenueInformation({
        eventId,
        venueInformation
    });
}
export function getVenueInformationAPI(eventId) {
    const getVenueInformation = httpsCallable(functions, "getVenueInformation");
    return getVenueInformation({
        eventId
    });
}