import React, { useEffect, useState } from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkInCheckOutState, setShowThisUserDetails } from "./reduxSlice";
import { darkModeState } from "../../../DarkMode/reduxSlice";

import { Row, Col, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BsTwitter,
  BsLinkedin,
  BsInstagram,
  BsFacebook,
  BsYoutube,
} from "react-icons/bs";
import { TbWorld } from "react-icons/tb";

import "./App.css";
import moment from "moment";

const EachUserDetail = ({ valueOfSelect }) => {
  const dispatch = useDispatch();
  const { dark } = useSelector(darkModeState);
  const { showThisUserDetails, tableData } = useSelector(checkInCheckOutState);
  const [details, setDetails] = useState(null);

  const handleClose = () => {
    dispatch(setShowThisUserDetails({ id: null }));
  };

  useEffect(() => {
    if (showThisUserDetails && tableData[showThisUserDetails]) {
      setDetails(tableData[showThisUserDetails]);
    }

    return () => {};
  }, [tableData, showThisUserDetails]);

  let statusPath = details?.statusPath;

    return (

        <Modal show={showThisUserDetails} size={"xl"} onHide={handleClose}>
            <Modal.Header closeButton className="bg-dark text-light">
                <Modal.Title>CheckIn CheckOut Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-light">

                <Container className="my-2">
                    <div className="row mx-0">
                        <div className="col-2 p-0">
                            <Image
                                src={details?.profileURL}
                                className="img-fluid rounded w-100 "
                            />
                        </div>
                        <div className="col-10 profileDetails ">
                            <div>
                                <p className="mb-1">
                                    <span className="fw-bold">Name</span>: {details?.firstName} {details?.lastName}
                                </p>
                                <p className="mb-1">
                                    <span className="fw-bold">Email ID</span>:
                                    {details?.email}
                                </p>
                                {details?.location ? (<p className="mb-1">
                                    <span className="fw-bold">Location: </span>{details?.location}
                                </p>) : null}
                                {details?.phoneNumber ? (<p className="mb-1">
                                    <span className="fw-bold">Contact no: </span>{details?.phoneNumber}
                                </p>) : null}

                            </div>

              <div className="d-flex g-0 justify-content-start pb-0">
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <BsTwitter />
                </Link>
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <BsLinkedin />
                </Link>
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <BsInstagram />
                </Link>
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <BsFacebook />
                </Link>
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <TbWorld />
                </Link>
                <Link
                  to={"/"}
                  className="bg-transparent text-light shadow-none me-3"
                >
                  <BsYoutube />
                </Link>
              </div>
            </div>
          </div>
          <Row className="mx-0 my-4 gap-2">
            <Col sm={12} className="shadow-sm rounded border p-3 row mx-0">
              <div className="d-flex flex-column col-12 col-md-6">
                <p className="fw-bold mb-1">Event Name</p>
                <p className="mb-0">{valueOfSelect?.eventName}</p>
              </div>

              <div className="d-flex justify-content-between col-12 col-md-6">
                {statusPath && statusPath[0]?.statusTime ? (
                  <div className="d-flex flex-column ">
                    <p className="fw-bold mb-1">First CheckIn</p>
                    <p className="mb-0">
                      {moment(statusPath[0]?.statusTime.toDate()).format(
                        "llll"
                      )}
                    </p>
                  </div>
                ) : null}
                {statusPath &&
                statusPath.length % 2 === 0 &&
                statusPath[statusPath.length - 1]?.statusTime ? (
                  <div className="d-flex flex-column">
                    <p className="fw-bold mb-1">Last CheckOut</p>
                    <p className="mb-1">
                      {moment(
                        statusPath[statusPath.length - 1]?.statusTime.toDate()
                      ).format("llll")}
                    </p>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col ms={12} className="mt-2 mb-1">
              <h4>My Attendance</h4>
            </Col>
            {statusPath &&
              statusPath
                .map(({ statusTime }, index) => {
                  return (
                    <Col
                      sm={12}
                      className="shadow-sm rounded border d-flex p-4 justify-content-between"
                    >
                      <div className="d-flex flex-column">
                        <p className="fw-bold mb-1">Date</p>
                        <p className="mb-0">
                          {moment(statusTime.toDate()).format("Do MMMM YYYY")}
                        </p>
                      </div>
                      <div
                        className="d-flex flex-column "
                        style={{ minWidth: "80px" }}
                      >
                        <p className="fw-bold mb-1">
                          {(index + 1) % 2 === 0 ? "Check Out" : "Check In"}
                        </p>
                        <p className="mb-0">
                          {moment(statusTime.toDate()).format("LT")}
                        </p>
                      </div>
                    </Col>
                  );
                })
                .reverse()}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="bg-dark text-light">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EachUserDetail;
