import React from 'react';
import Admin_Footer from "../../CommonComponents/Footer/App";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import {Pagination} from "react-bootstrap";

import {BsThreeDotsVertical} from  'react-icons/bs'

const ManageEvent = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                   <small> Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Events
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards ">
                            <div className="col-12">
                                <form className="card mb-3 bg-dark text-light border">
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">
                                            <div className="form-group">
                                                <input   type="text" className="form-control bg-dark text-light"
                                                       value="" placeholder="Title" />
                                            </div>
                                            <div className="form-group ms-2">
                                                <input   type="text" className="form-control bg-dark text-light"
                                                       value="" placeholder="Group" />
                                            </div>
                                            <div className="form-group ms-2">
                                                <select 
                                                        className="form-control bg-dark text-light">
                                                    <option selected="" value="">Select Status</option>
                                                    <option value="draft">Draft</option>
                                                    <option value="past">Past</option>
                                                    <option value="cancelled">Cancelled</option>
                                                    <option value="upcoming">Upcoming</option>
                                                    <option value="deleted">Deleted</option>
                                                </select>
                                            </div>
                                            <button type="submit" className="btn btn-link">
                                                <i className="fa fa-filter me-2"></i>
                                                Filter
                                            </button>
                                            <a className="btn btn-link">
                                                <i className="fa fa-redo me-2"></i>
                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title">Events</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark text-light border card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Group</th>
                                                <th>Status</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="events">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">1030</p>
                                                </td>
                                                <td>
                                                    <a className="mb-0" target="_blank"
                                                        >
                                                        King. (The jury all wrote down on one knee. 'I'm a poor man,'
                                                        the Hatter and the words.)
                                                    </a>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">Hagenes, Shields and Watsica</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 text-success">Upcoming</p>
                                                </td>
                                                <td className="text-end">
                                                    <BsThreeDotsVertical/>
                                                    
                                                </td>
                                            </tr>
                                           
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-center">
                                        <nav>
                                            <ul className="pagination">

                                                <li className="page-item disabled" aria-disabled="true"
                                                    aria-label="« Previous">
                                                    <span className  = "page-link bg-dark text-light bg-primary" aria-hidden="true">‹</span>
                                                </li>


                                                <li className="page-item active" aria-current="page"><span
                                                    className="page-link page-link bg-dark text-light bg-primary">1</span></li>
                                                <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                                              >2</a>
                                                </li>
                                                <li className="page-item"><a className = "page-link bg-dark text-light bg-primary"
                                                                            >3</a>
                                                </li>
                                                <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                                           >4</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                             >5</a>
                                                </li>
                                                <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                                             >6</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                              >7</a>
                                                </li>
                                                <li className="page-item"><a className =  "page-link bg-dark text-light bg-primary"
                                                                              >8</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                             >9</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                              >10</a>
                                                </li>

                                                <li className="page-item disabled" aria-disabled="true"><span
                                                    className="page-link page-link bg-dark text-light bg-primary">...</span></li>


                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                             >68</a>
                                                </li>
                                                <li className="page-item"><a className  = "page-link bg-dark text-light bg-primary"
                                                                             >69</a>
                                                </li>


                                                <li className="page-item">
                                                    <a className  = "page-link bg-dark text-light bg-primary"

                                                       rel="next" aria-label="Next »">›</a>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>
            <Admin_Footer/>
        </div>
    );
};

export default ManageEvent;