import React from 'react';
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";

const Company = () => {
    return (
        <div className="min-vh-100">
            <AdminNavigation/>
            <div className="page-wrapper">
                <div className="container">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col mt-3 mb-3">
                                <div className="page-pretitle">
                                   <small> Settings</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Company Settings
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 card bg-dark text-light border">
                                <form className="card-body "
                                      >
                                    
                                        <h3>Company Address</h3>
                                        <div className="form-group mb-3">
                                            <input type="text" className="form-control bg-dark text-light"
                                                   placeholder="Company Address" value=""/>
                                        </div>
                                        <hr></hr>

                                            <h3>Social Media Accounts</h3>
                                            <div className="form-group mb-3">
                                                <label >Facebook Link</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Facebook Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>Twitter Link</label>
                                                <input   type="text" className="form-control bg-dark text-light"
                                                       placeholder="Twitter Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label  >Instagram Link</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Instagram Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label >Pinterest Link</label>
                                                <input   type="text" className="form-control bg-dark text-light"
                                                       placeholder="Pinterest Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>Linkedin Link</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Linkedin Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>Youtube Link</label>
                                                <input   type="text" className="form-control bg-dark text-light"
                                                       placeholder="Youtube Link" value=""/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>Vimeo Link</label>
                                                <input  type="text" className="form-control bg-dark text-light"
                                                       placeholder="Vimeo Link" value=""/>
                                            </div>

                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Update Settings
                                                </button>
                                            </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Admin_Footer/>
        </div>
    );
};

export default Company;