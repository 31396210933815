import React from 'react'
import FooterComponent from '../FooterComponent/App'
import { Container, Row, Col, ListGroup, Button, Form } from 'react-bootstrap'
import { IoClose, IoCheckmarkSharp } from "react-icons/io5"
import './App.css'
export default function CheckoutPlans() {
  return (
    <>
      <Container>
        <div className='text-center my-5 text-light'>
          <h3>Subscribe to find people who share your interests</h3>
          <p>Choose a plan to start your group and your community.</p>
          <div className='d-flex align-items-center justify-content-center'>
            <h6 className='fs-normal my-0'>Monthly</h6>
            <Form.Check
              type="switch"
              id="custom-switch"
              className='mx-2 fs-5'
            />
            <h6 className='fs-normal my-0'>Yearly</h6>
          </div>
        </div>
        <Row className='my-5 mx-0 gap-3 justify-content-conter flex-column flex-lg-row '>
          <Col className='shadow p-4 text-light border rounded'>
            <div className='text-center border-bottom py-2'>
              <h4>Startup Plan</h4>
              <h4 className='fw-bold my-3'>$9.99<span className='fs-6 fw-normal'>/ Month</span></h4>
              <p className='bg-tertiary d-inline-block rounded-pill px-3 text-white fw-bolder'><small>SAVE $9.89 ON YEARLY PLAN</small></p>
            </div>
            <ListGroup className='border-0 bg-dark text-light mt-3'>
            <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoCheckmarkSharp className='me-1' />  1 Groups</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' />  Communication Tools</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> <span>Gain access to attendee email addresses to grow your database</span></ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> Track your community with a performance dashboard</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' />  Display sponsors</ListGroup.Item>
            </ListGroup>
            <Button variant="primary" className='d-block w-100 fw-bold mt-5'>Get Started</Button>
          </Col>
          <Col className='shadow p-4 text-light border rounded'>
            <div className='text-center border-bottom py-2'>
              <h4>Growth  Plan</h4>
              <h4 className='fw-bold my-3'>$19.99 <span className='fs-6 fw-normal'>/ Month</span></h4>
              <p className='bg-tertiary d-inline-block rounded-pill px-3 text-white fw-bolder'><small>SAVE $19.89 ON YEARLY PLAN</small></p>
            </div>
            <ListGroup className='border-0 bg-dark mt-3'>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoCheckmarkSharp className='me-1' />  1 Groups</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' />  Communication Tools</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> <span>Gain access to attendee email addresses to grow your database</span></ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> Track your community with a performance dashboard</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' />  Display sponsors</ListGroup.Item>
            </ListGroup>
            <Button variant="primary" className='d-block w-100 fw-bold mt-5'>Get Started</Button>
          </Col>
          <Col className='shadow p-4 text-light border rounded'>
            <div className='text-center border-bottom py-2'>
              <h4>Basic Plan</h4>
              <h4 className='fw-bold my-3'>$4.99<span className='fs-6 fw-normal'>/ Month</span></h4>
              <p className='bg-tertiary d-inline-block rounded-pill px-3 text-white fw-bolder'><small>SAVE $39.89 ON YEARLY PLAN</small></p>
            </div>
            <ListGroup className='border-0 bg-dark mt-3'>
            <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoCheckmarkSharp className='me-1' />  1 Groups</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' /> Communication Tools</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> <span>Gain access to attendee email addresses to grow your database</span></ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light intend'><IoClose className='me-1' /> Track your community with a performance dashboard</ListGroup.Item>
              <ListGroup.Item className=' ps-0 border-0 bg-dark text-light '><IoClose className='me-1' />  Display sponsors</ListGroup.Item>
            </ListGroup>
            <Button variant="primary" className='d-block w-100 fw-bold mt-5'>Get Started</Button>
          </Col>
        </Row>
      </Container>
      <FooterComponent />
    </>
  )
}
