import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEventState,
  setFeedBackLink,
  setGeneralChatLink,
  setPollLink,
  setQnaLink,
} from "../reduxSlice";

const CommunicationTabs = () => {
  const dispatch = useDispatch();
  const { pollLink, generalChatLink, QnaLink, feedBackLink } = useSelector(createEventState);

  return (
    <div>
      {/* Poll */}
      <div className={`  form-group mb-3 mt-2`}>
        <label className="form-label">Poll Link</label>
        <input
          type="text"
          value={pollLink}
          onChange={(e) => {
            dispatch(setPollLink({ value: e.target.value }));
          }}
          className="form-control bg-dark text-light "
          placeholder="Ex. https://poll.com"
        />
      </div>

      {/* General Chat */}

      <div className={` form-group mb-3`}>
        <label className="form-label">General Chat Link</label>
        <input
          type="text"
          value={generalChatLink}
          onChange={(e) => {
            dispatch(setGeneralChatLink({ value: e.target.value }));
          }}
          className="form-control bg-dark text-light "
          placeholder="Ex. https://chat.com"
        />
      </div>
      {/* Qna */}
      <div className={`   form-group mb-3`}>
        <label className="form-label">QnA Link</label>
        <input
          type="url"
          value={QnaLink}
          onChange={(e) => {
            dispatch(setQnaLink({ value: e.target.value }));
          }}
          className="form-control bg-dark text-light "
          placeholder="Ex. https://qna.com"
        />
      </div>
      {/* FeedBack */}
      <div className={`   form-group mb-3`}>
        <label className="form-label">FeedBack Link</label>
        <input
          type="url"
          value={feedBackLink}
          onChange={(e) => {
            dispatch(setFeedBackLink({ value: e.target.value }));
          }}
          className="form-control bg-dark text-light "
          placeholder="Ex. https://google.feedback.com"
        />
      </div>
    </div>
  );
};

export default CommunicationTabs;
