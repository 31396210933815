import { RiArrowDropDownLine } from "@react-icons/all-files/ri/RiArrowDropDownLine";
import React from "react";
import { Button, Dropdown, Placeholder } from "react-bootstrap";
import { BsFillPersonFill } from "react-icons/bs";
import { IoLocationSharp, IoPeople } from "react-icons/io5";
import { Link } from "react-router-dom";
import { auth } from "../../../../../../../firebase.config";

const GroupSkeleton = () => {
  return (
    <div>
      <div>
        <div>
          <div className="container pt-5 pb-3">
            <div className="row">
              <div className="col-md-6">
                <div className="img-wrap ">
                  <div className="img-content">
                    <Placeholder animation="glow">
                      <Placeholder
                        className="rounded w-100 h-100 "
                        style={{
                          aspectRatio: "16 / 10 ",
                        }}
                      />
                    </Placeholder>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h1 className="fs-2 mb-2 mt-2 mt-lg-0 mb-3 fw-bolder">
                  <Placeholder className="mb-2" animation="glow">
                    <Placeholder xs={8} className="mb-2" />
                  </Placeholder>
                </h1>
                <div className="row align-items-center mb-2">
                  <div className="col-auto">
                    <span className=" fs-3">
                      <IoLocationSharp />
                    </span>
                  </div>
                  <div className="col fs-6">
                    <Placeholder className="mb-2" animation="glow">
                      <Placeholder xs={3} className="mb-2" />
                    </Placeholder>
                  </div>
                </div>

                <div className="row align-items-center mb-2">
                  <div className="col-auto">
                    <span className=" fs-3">
                      <IoPeople />
                    </span>
                  </div>
                  <div className="col fs-6">
                    <span className="fst-italic">
                      <Placeholder className="mb-2" animation="glow">
                        <Placeholder xs={5} className="mb-2" />
                      </Placeholder>
                    </span>
                  </div>
                </div>

                <div className="row align-items-center mb-2">
                  <div className="col-auto">
                    <span className="fs-3">
                      <BsFillPersonFill />
                    </span>
                  </div>
                  <div className="col fs-6">
                    <strong>
                      <Placeholder className="mb-2" animation="glow">
                        <Placeholder xs={7} className="mb-2" />
                      </Placeholder>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dark sticky-top border-top border-bottom py-2">
        <div className="container">
          <div className=" row   ">
            <ul
              className="nav col-8 mobile-horizontal"
              style={{ overflowX: "auto" }}
            >
              <li className="nav-item">
                <span className="nav-link fs-6 ps-0 fw-bold text-primary">
                  About
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link fs-6 ">Events</span>
              </li>
              <li className="nav-item">
                <span className="nav-link fs-6 ">Members</span>
              </li>
              <li className="nav-item">
                <span className="nav-link fs-6 ">Photos</span>
              </li>
              <li className="nav-item">
                <span className="nav-link fs-6 ">Discussions</span>
              </li>
            </ul>

            <div className="col-2"></div>

            <div className="col-2">
              <Placeholder animation="glow">
                <Placeholder.Button
                  className="btn text-white btn-tertiary px-5 float-end  text-nowrap"
                  xs={8}
                  aria-hidden="true"
                ></Placeholder.Button>
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "5px", height: "500px" }}></div>
    </div>
  );
};

export default GroupSkeleton;
