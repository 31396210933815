import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateEventrole } from "../../Page Component/reduxSlice";
import { algoliaUsersAPI } from "../../Page Component/Speakers/reduxAPI";
import {
  addEventRoleAPI,
  fakeaaaaaaaaaaaaaaaaaaa,
  getUpdatedEventRoleAPI,
  removeEventRoleAPI,
} from "./reduxAPI";

const initialState = {
  userNameQuery: "",
  role: "",

  loadingSearchedUsers: false,
  foundSearchedUser: true,
  searchedUsers: [],
};

export const addRoleInEventUidAsync = createAsyncThunk(
  "eventNetworkingMang/addRoleInEventUid",
  async ({ uid }, { dispatch, getState, rejectWithValue }) => {
    try {
      // return response;

      const {
        eventData: { id: eventId },
      } = getState().event;

      const { role } = getState().eventNetworkingMang;

      await addEventRoleAPI(uid, eventId, role);

      return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const removeRoleInEventUidAsync = createAsyncThunk(
  "eventNetworkingMang/removeRoleInEventUid",
  async ({ uid }, { dispatch, getState, rejectWithValue }) => {
    try {
      // return response;

      const {
        eventData: { id: eventId },
      } = getState().event;

      const { role } = getState().eventNetworkingMang;

      await removeEventRoleAPI(uid, eventId, role);

      return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getUpdatedEventRoleAsync = createAsyncThunk(
  "eventNetworkingMang/getUpdatedEventRole",
  async ({ uid }, { dispatch, getState, rejectWithValue }) => {
    try {
      // return response;

      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getUpdatedEventRoleAPI(eventId);

      const { sponsorsId, boothOwnersId, speakersId } = response.data;

      dispatch(updateEventrole({ sponsorsId, boothOwnersId, speakersId }));

      return;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const roleManagementEventAsync = createAsyncThunk(
  "eventNetworkingMang/roleManagementEvent",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { userNameQuery } = getState().eventNetworkingMang;
      let result = await algoliaUsersAPI(userNameQuery, "users");
      result.hits.map(async (hit, index) => {
        hit["id"] = hit["objectID"];
        delete (await hit["objectID"]);
        return hit;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const eventNetworkingManagementSlice = createSlice({
  name: "eventNetworkingMang",
  initialState,
  reducers: {
    setUserNameQuery: (state, action) => {
      state.userNameQuery = action.payload.value;
    },
    setRole: (state, action) => {
      state.role = action.payload.value;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(addRoleInEventUidAsync.pending, (state, action) => {})
      .addCase(addRoleInEventUidAsync.fulfilled, (state, action) => {})
      .addCase(addRoleInEventUidAsync.rejected, (state, action) => {})
      .addCase(removeRoleInEventUidAsync.pending, (state, action) => {})
      .addCase(removeRoleInEventUidAsync.fulfilled, (state, action) => {})
      .addCase(removeRoleInEventUidAsync.rejected, (state, action) => {})
      .addCase(roleManagementEventAsync.pending, (state, action) => {
        state.loadingSearchedUsers = true;
      })
      .addCase(roleManagementEventAsync.fulfilled, (state, action) => {
        state.loadingSearchedUsers = false;

        state.searchedUsers = [...action.payload.hits];
        if (action.payload.hits.length <= 0) {
          state.foundSearchedUser = false;
        }
      })
      .addCase(roleManagementEventAsync.rejected, (state, action) => {
        state.loadingSearchedUsers = false;
      });
  },
});

export const { setUserNameQuery, setRole } =
  eventNetworkingManagementSlice.actions;

export const eventNetworkingMangState = (state) => state.eventNetworkingMang;

export default eventNetworkingManagementSlice.reducer;
