import React from 'react'
import {Button} from 'react-bootstrap'
import {TiArrowBack} from 'react-icons/ti'
import { useDispatch } from 'react-redux'

import { setEventpageNavPannel } from '../reduxSlice'

export default function App() {
    const dispatch = useDispatch()
    const handleClick = () =>{
        window.close() 
    }
    return (
        <div className='position-relative'>
          <Button variant="primary" onClick={handleClick} className='position-absolute top-0 start-0 d-flex align-items-center ps-2 pe-3 ' style={{zIndex:1}}>
            <TiArrowBack className='m-0 me-1'/>
            <span>Back</span>
            </Button>
            <img
                src='https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2FGroup%201%20(3).png?alt=media&token=e6b0d30e-9b0e-4f9c-8d61-8eb7a6495494'
                className='img-fluid ratio ratio-16x9' 
                onClick={()=>{
                    dispatch(setEventpageNavPannel({ newPannel: "Booths" }));
                }}
            />
        </div>
    )
}