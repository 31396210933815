import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getGroupsAPI } from "./reduxAPI";

import { pushErrorNotification } from "../../Error/reduxSlice";
import { Icon } from "@iconify/react";
const initialState = {
  organizer: false,

  loading: false,
  found: true,
  groupsFilterListArr: [],
};

export const filteredGroupAsync = createAsyncThunk(
  "groupsFilterList/fetchGroup",
  async ({ organizer }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { user } = getState().user;
      const response = await getGroupsAPI(organizer, user?.uid);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

const groupsFilterListSlice = createSlice({
  name: "groupsFilterList",
  initialState,
  reducers: {
    setOrganizer: (state, action) => {
      state.organizer = action.payload.value;
    },
    clearGroupsFilterListArr: (state, action) => {
      state.loading = false;
      state.found = true;
      state.groupsFilterListArr = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(filteredGroupAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(filteredGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groupsFilterListArr = [...action.payload];
        if (action.payload.length <= 0) {
          state.found = false;
        }
      })
      .addCase(filteredGroupAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setOrganizer ,clearGroupsFilterListArr } = groupsFilterListSlice.actions;

// create state here and export
export const groupsFilterListState = (state) => state.groupsFilterList;

export default groupsFilterListSlice.reducer;
