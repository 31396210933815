import React from "react";

// Bootstrap
import { Modal, Form, CloseButton } from "react-bootstrap";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserData,
  formGeneratorState,
  setShowUploadDocumentForm,
  setUserData,
  submitFormDataAsync,
} from "./reduxSlice";
import { formBuilderState } from "../MultipleInputFormBuilder/reduxSlice";

import { darkModeState } from "../../../../../../Components/DarkMode/reduxSlice";
const FormGenerator = ({ submit = true }) => {
  const dispatch = useDispatch();

  const { dark } = useSelector(darkModeState);

  const { formData, showUploadDocumentForm, submittingForm, heading } =
    useSelector(formGeneratorState);

  const submitHandler = (event) => {
    event.preventDefault();
    if (submit) {
      dispatch(submitFormDataAsync({}));
    }
  };

  const previewFiles = (evt, index) => {
    let newDocs = [];

    for (let i = 0; i < evt.target.files.length; i++) {
      const newDoc = evt.target.files[i];

      newDoc["id"] = Math.random();
      newDocs.push(newDoc);
    }
    dispatch(setUserData({ index, newDocs }));
  };

  return (
    <Modal
      className="px-0"
      show={showUploadDocumentForm}
      onHide={() => {
        dispatch(setShowUploadDocumentForm({ value: false }));
      }}
    >
      <Modal.Header className="bg-dark text-light ">
        <Modal.Title>{heading}</Modal.Title>
        <CloseButton
          className="shadow-none ms-auto me-0  "
          variant={`${dark ? "white" : ""}`}
          onClick={() => {
            dispatch(setShowUploadDocumentForm({ value: false }));
          }}
        />
      </Modal.Header>
      <Modal.Body className="bg-dark text-light border-0">
        <form onSubmit={submitHandler}>
          {formData.map(({ type, label, required, _id }, index) => {
            return (
              <div>
                <Form.Group className="bg-dark text-light mb-3">
                  <Form.Label>{label}</Form.Label>
                  <Form.Control
                    multiple={true}
                    required={required}
                    onChange={(evt) => {
                      previewFiles(evt, index);
                    }}
                    type={type}
                    className="bg-dark text-light"
                  />
                </Form.Group>
              </div>
            );
          })}
          <button
            type={"submit"}
            className="btn btn-primary d-block ms-auto me-0"
          >
            {submittingForm ? "loading..." : "Submit"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default FormGenerator;
