import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import FilterNavbar from "./components/FilterNavbar";
import EventListing from "./components/EventListing";

// redux
import { eventState } from "../../../Main/Components/Events/Page Component/reduxSlice";
import { useDispatch, useSelector } from "react-redux";
export default function MyEvent() {
  return (
    <>
      <div className="h-100 bg-dark text-light">
        <Container className="py-4 py-lg-5 d-flex flex-column flex-lg-row justify-content-between">
          <FilterNavbar />
          <EventListing  />
        </Container>
      </div>
    </>
  );
}
