import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdmin: null,
  isBizAdmin: null,
  isSubscriber: null,
  isAttendee: null,
};

export const rootPageSlice = createSlice({
  name: "rootPage",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRole: (state, action) => {
      const idTokenResult = action.payload.idTokenResult;

      state.isAdmin = idTokenResult.claims.superAdmin || null;
      state.isSubscriber = idTokenResult.claims.subscriber || null;
      state.isAttendee = idTokenResult.claims.attendee || null;
      state.isBizAdmin = idTokenResult.claims.bizAdmin || null;
    },

    removeRole: (state, action) => {
      state.isAdmin = null;
      state.isSubscriber = null;
      state.isAttendee = null;
      state.isBizAdmin = null;
    },
  },
});

export const { setRole, removeRole } = rootPageSlice.actions;

export const rootPageState = (state) => state.rootPage;

export default rootPageSlice.reducer;
