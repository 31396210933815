import React, { useEffect, useState } from "react";

// State management (Redux)
import { useSelector, useDispatch } from "react-redux";
import {
  setFirstName,
  setLastName,
  setConfirmPassword,
  registerState,
  setEmail,
  setPassword,
  toggleRememberMe,
  registerUserAsync,
  toggleRole,
  clearRegisterInputs,
  setCompanyName,
  validateCompanyNameAsync,
} from "./reduxSlice";

// other laibrary
import { Link, useNavigate } from "react-router-dom";

// css
import styles from "./App.module.css";
import "./App.css";

// icon
import { Icon } from "@iconify/react";
import { pushErrorNotification } from "../../Error/reduxSlice";

// react-bootstrap
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Image, Placeholder } from "react-bootstrap";
import { loginState } from "../Login/reduxSlice";

export function Register() {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        With Subscriber role you can create Events <b>Free</b>. <br />
        <u>
          You can switch your profile any time later from <b>setting</b> .
        </u>
      </Popover.Body>
    </Popover>
  );
  const {
    firstName,
    lastName,
    email,
    confirmPassword,
    password,
    companyName,
    rememberMe,
    loading,
    role,
  } = useSelector(registerState);

  const { loading: loginLoading } = useSelector(loginState);
  // assigning
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //loading
  const [imgLoading, setImgLoading] = useState(true);

  // clear input

  useEffect(() => {
    return () => {
      dispatch(clearRegisterInputs());
    };
  }, [dispatch]);

  // functions
  const submitHandler = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      dispatch(validateCompanyNameAsync({ navigate }));


    } else {
      dispatch(
        pushErrorNotification({
          notify: {
            iconifyIconLink: (
              <Icon icon="fluent:password-20-filled" className="rounded" />
            ),
            errorTitle: "password",
            errorMessage: "password doesn't match. both password must match ",
            theme: "danger",
            time: "now",
            autoHideTime: 3000,
            show: true,
          },
        })
      );
    }
  };

  return (
    <div className="bg-dark text-light h-100 mb-3">
      <div className="text-center justify-content-center align-items-center ">
        <Image
          fluid
          draggable={false}
          src={
            "https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Flogo512.png?alt=media&token=ebab4817-f456-4398-bc63-13ff120be3b9"
          }
         
          style={{
            aspectRatio: "1 /1 ",
          }}
          className={`col-4 col-md-2 m-1 col-lg-1 ${imgLoading ? "d-none" : null
            }`}
          onLoad={() => {
            setImgLoading(false);
          }}
          alt="Runolfsdottir Group"
        />
        {imgLoading ? (
          <Placeholder animation="glow">
            <Placeholder
              className=" rounded-3 col-4 col-md-2 m-1 col-lg-1  "
              style={{
                aspectRatio: "1 / 1 ",
              }}
            />
          </Placeholder>
        ) : null}
        <h5 className={`ms-2 text-tertiary ${styles.branding_biz} `}>
          Biz Pre-Event and Networking
        </h5>
      </div>
      <form
        onSubmit={submitHandler}
        className={` d-block px-2 border shadow-sm rounded-3 col-11 col-md-8 col-lg-6 mx-auto`}
      >
        <div className="px-0">
          <h5 className="my-4 text-primary px-2">Create an account</h5>
          <div className="row mb-3 px-2">
            <div className="col-sm-6 col-12 mb-3 mb-md-0">
              {/* First Name */}
              <label htmlFor="firstName" className="form-label">
                First name
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => {
                  dispatch(setFirstName({ firstName: e.target.value }));
                }}
                required
                className="form-control bg-dark text-light "
                id="firstName"
                placeholder="First Name"
              />
            </div>

            <div className="col-sm-6 col-12">
              {/* Last Name */}
              <label htmlFor="lastName" className="form-label">
                Last name
              </label>
              <input
                type="text"
                value={lastName}
                required
                onChange={(e) => {
                  dispatch(setLastName({ lastName: e.target.value }));
                }}
                className="form-control bg-dark text-light "
                id="lastName"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="row mb-3 px-2">
            <div className="col-7 col-md-8">
              {/* Email */}
              <label htmlFor="userEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                value={email}
                required
                onChange={(e) => {
                  dispatch(setEmail({ email: e.target.value }));
                }}
                className="form-control bg-dark text-light "
                id="userEmail"
                aria-describedby="emailHelp"
                placeholder="name@address.com"
              />
            </div>

            <div className="col-5 col-md-4">
              {/* Role Switch*/}
              <label
                htmlFor="flexSwitchCheckChecked"
                className="form-label d-flex align-items-center "
              >
                Role
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={popover}
                >
                  <Icon
                    className="float-end ms-2 "
                    icon="entypo:info-with-circle"
                  />
                </OverlayTrigger>
              </label>
              <div class="form-check form-switch mt-3">
                <label htmlFor="flexSwitchCheckChecked" className="form-label">
                  {role === "attendee" ? "Attendee" : "Subscriber"}
                </label>
                <input
                  class="form-check-input"
                  onChange={() => {
                    dispatch(toggleRole());
                  }}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                />
              </div>
            </div>
          </div>

          {!(role === "attendee") ? (
            <div className="mb-3 px-2">
              {/* Company Name */}
              <label htmlFor="userCompanyName" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                value={companyName}
                required
                onChange={(e) => {
                  dispatch(setCompanyName({ companyName: e.target.value }));
                }}
                className="form-control bg-dark text-light "
                id="userCompanyName"
                aria-describedby="companyHelp"
                placeholder="Facebook"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {/* password */}
        <div className="mb-3 p-2">
          <label htmlFor="userPassword" className="form-label">
            Password
          </label>

          <input
            value={password}
            onChange={(e) => {
              dispatch(setPassword({ password: e.target.value }));
            }}
            required
            type="password"
            placeholder="password"
            className="form-control bg-dark text-light  "
            id="userPassword"
          />
        </div>
        {/* confirm password */}
        <div className="mb-3 p-2">
          <label htmlFor="userPassword" className="form-label">
            Confirm Password
          </label>

          <input
            value={confirmPassword}
            required
            onChange={(e) => {
              dispatch(setConfirmPassword({ confirmPassword: e.target.value }));
            }}
            type="password"
            placeholder="confirm password"
            className="form-control bg-dark text-light "
            id="userPassword"
          />
        </div>
        <div className="mb-3 form-check px-2">
          <input
            checked={rememberMe}
            onChange={() => {
              dispatch(toggleRememberMe());
            }}
            type="checkbox"
            className="form-check-input mx-0 me-2"
            id="exampleCheck1"
          />
          <label className="form-check-label mx-0 " htmlFor="exampleCheck1">
            Remember me on this device
          </label>
        </div>
        <div className="mb-3 px-2">
          <button
            type="submit"
            disabled={loading || loginLoading}
            className="btn btn-primary w-100 d-block mx-auto my-1"
          >
            {loading || loginLoading ? (
              <Icon icon="eos-icons:bubble-loading" />
            ) : (
              "Register"
            )}
          </button>
        </div>
      </form>
      <div className="text-center py-2">
        <span className="fs-4">Have an account? </span>
        <Link
          className="register_link mx-2 fw-bold fs-4 "
          to={{ pathname: "/login" }}
        >
          Login
        </Link>
      </div>
    </div>
  );
}
