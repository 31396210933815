import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../../../firebase.config";

export function sendMailAPI(to, subject, text, html) {
  const sendMail = httpsCallable(functions, "sendMail");
  return sendMail({ to, subject, text, html });
}

export function sendEventUpdateNotificationAPI(eventId) {
  const sendEventUpdateNotification = httpsCallable(
    functions,
    "sendEventUpdateNotification"
  );
  return sendEventUpdateNotification({ eventId });
}

