import React from 'react';
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "@react-icons/all-files/ai/AiOutlineLeft";
import { useSelector } from 'react-redux';
import { groupState } from '../../MyGroupOnBoard/reduxSlice';

const Group_Setting_Navigation = () => {

    const { groupData : { id : groupId }} = useSelector(groupState);
    
    return (
        <div className=''>
            <div className="align-items-center">
                <Link to={`/group/${groupId}`} className="text-primary mb-0 text-decoration-none"
                > <AiOutlineLeft/>Back to group </Link>
            </div>

            <div className="col  mb-4">
                <div className="card bg-dark text-light border card-fluid">
                    <div className="card-header border">
                        <div className="card-title">Settings</div>
                    </div>
                    <nav className="nav side-nav flex-row overflow-auto flex-nowrap flex-lg-column flex-lg-wrap">
                        <Link to={`/groups/g/${groupId}/setting`} className="nav-link text-nowrap  text-primary active ">
                            Basic Settings
                        </Link>
                        <Link to="/groups/new-member" className="nav-link text-nowrap active">
                            New member settings
                        </Link>
                        <Link to="/groups/topics" className="nav-link text-nowrap active ">
                            Topics
                        </Link>
                        <Link to="/groups/optional-setting" className="nav-link text-nowrap active ">
                            Optional Settings
                        </Link>
                    </nav>
                </div>
            </div>
        </div>


    );
};

export default Group_Setting_Navigation;