import React from "react";

import "./Algoliaor.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createGroupState,
  groupPreviewInterestAsync,
  setPreviewCategories,
} from "../../../Main/Components/Groups/CreateGroup/reduxSlice";
import { useState } from "react";
import { searchClient } from "../../../../algolia/algoliaKey";

// const searchClient = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_SEARCH_KEY
// );

const searchInterestIndex = searchClient.initIndex("interests");

const SearchInterestManual = () => {
  return (
    <div>
      <SearchInterest />
      <DisplayInterest />
    </div>
  );
};

export default SearchInterestManual;

const DisplayInterest = () => {
  const { previewCategories } = useSelector(createGroupState);
  return (
    <div>
      {previewCategories.map((interest, index) => {
        console.log("running...");
        return (
          <div className="btn btn-primary mx-2 my-1"> {interest.interest} </div>
        );
      })}
    </div>
  );
};

const SearchInterest = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(groupPreviewInterestAsync({}));
    return () => {
      dispatch(setPreviewCategories({ value: [] }));
    };
  }, [dispatch]);

  const handleSearch = async (queryText) => {
    try {
      // Shift this in redux Api and redux slice
      const result = await searchInterestIndex.search(queryText);
      dispatch(setPreviewCategories({ value: result.hits }));
    } catch (error) {
      console.log(error);
    }
  };

  const [search, setSearch] = useState("");
  return (
    <input
      value={search}
      placeholder="search... "
      onChange={(e) => {
        setSearch(e.target.value);
        if (e.target.value === "") {
          dispatch(groupPreviewInterestAsync({}));
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSearch(search);
        }
      }}
    />
  );
};
