import React, {useEffect, useState} from 'react';
//Components
import Photo_Upload from "./Photo_Upload/App";
//Redux
import {userState} from "../../../../Main/Components/User/reduxSlice";
import {useDispatch, useSelector} from "react-redux";
import {groupState} from "../MyGroupOnBoard/reduxSlice";
import {rootPageState} from "../../../../Main/Routes/reduxSlice";
//Bootstrap and Icons
import {Button, Image} from "react-bootstrap";
import {BsImages} from "@react-icons/all-files/bs/BsImages";
import NoPhoto from "./NoPhoto/App";
import {useParams} from "react-router-dom";
import {eventAsync} from "../../../../Main/Components/Events/Page Component/reduxSlice";
import PhotoCrousel from "./PhotoCrousel/App";

//Css
import './App.css'


const Photo = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showCarousel, setShowCarousel] = useState(false);
    //Redux
    const {
        groupPage,
        loading,
        groupActionLoading,
        groupData
    } = useSelector(groupState);
    const {isAdmin, isSubscriber, isAttendee, isBizAdmin} =
        useSelector(rootPageState);

    const {userAvl, user} = useSelector(userState);
    const {groupName, groupType, subscriberUid, location, groupGallery, description} = groupData;

//Render
// console.log(groupGallery.length)
    //Show Carousel

    const handleModalClose = () => setShowCarousel(false);
    const handleModalShow = () => setShowCarousel(true);

    return (
        <div>
            <Photo_Upload handleClose={handleClose} show={show}/>
            <div className="container py-5">
                <div className="row">

                    {/*PHOTO UPLOAD ACTION*/}
                    {isAdmin || isSubscriber ?
                        <div className="row mb-4">
                            <div className="col">
                                <h2>Photos</h2>
                            </div>
                            <div className="col col-auto">
                                <Button onClick={handleShow} className="btn btn-primary" type="button"
                                >Upload new
                                </Button>
                            </div>
                        </div>
                        :
                        null}
                    {/*PHOTO UPLOAD ACTION*/}

                    <div className="row gallery">
                        {/*PHOTO DISPLAY*/}
                        <div className='row'>
                            {loading ? <h1>Loading</h1> :
                                groupGallery ?
                                    groupGallery.map((images, index) => (
                                        <div className='col-md-4 p-0' key={index} onClick={handleModalShow}>
                                            <Image className='' style={{width: '100%'}} Fuild src={images}/>
                                        </div>
                                    )) : <NoPhoto/>
                            }
                        </div>
                        {/*PHOTO DISPLAY*/}
                    </div>
                </div>
            </div>
            <div>
                {showCarousel ?
                    <PhotoCrousel show={showCarousel} handleClose={handleModalClose}/>
                    : null}
            </div>

        </div>
    );
};

export default Photo;