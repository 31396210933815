import React from 'react';
import Group_Setting_Navigation from "../Group_Setting_Navigation/App";
import {Button} from "react-bootstrap";

const NewMmeber = () => {
    return (
        <div className="container py-5 min-vh-100 ">
            <div className="row gx-5 ">


                <div className='col-lg-3'>
                    <Group_Setting_Navigation/>
                </div>

                <form className="col-12 col-lg-8 mb-5 mb-lg-0 mt-5"
                >
                    <h5 className="mb-4 mt-4 fw-bold">New member settings</h5>

                    <div className="row py-2 border-bottom">
                        <div className="col-8"><p>New members must be approved by organizers</p></div>
                        <div className="col-4 form-check form-switch">

                            <input className="form-check-input float-end"
                                   type="checkbox"/>
                        </div>
                    </div>

                    <div className="row py-2 border-bottom">
                        <div className="col-8"><p>When members join, require profile photo</p></div>
                        <div className="col-4 form-check form-switch">

                            <input className="form-check-input bg-dark  float-end"
                                   type="checkbox"/>
                        </div>
                    </div>

                    <div className="row py-2 mb-4">
                        <div className="col-8"><p>Allow members to create discussions</p></div>
                        <div className="col-4 form-check form-switch">

                            <input className="form-check-input float-end"
                                   type="checkbox"/>
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <label className="form-label">Welcome message to new
                            members</label>
                        <textarea className="form-control bg-dark text-light" rows="4"
                                  spellCheck="false"></textarea>
                    </div>

                    <div className="float-end">
                        <Button type="submit" className="btn btn-primary">Update settings</Button>
                    </div>
                </form>


            </div>
        </div>
    );
};

export default NewMmeber;