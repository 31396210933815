import React from "react";
import { RiAccountCircleFill } from "@react-icons/all-files/ri/RiAccountCircleFill";

const Discussion_details_card = ({ data: { comment, profileUrl, name } }) => {

  
  
  return (
    <div>
      <div className="border-top py-3">
        <div className="d-flex flex-row justify-content-between mb-1">
          <div className="d-flex flex-row align-items-center">
            <span className="fs-1 d-flex justify-content-center align-items-center ">
              <img src={profileUrl} width={30} alt="profile" />
            </span>
            <span className="ms-2 align-items-center">
              <h6 className="mb-0">{name}</h6>
            </span>
          </div>

          <a className="text-danger delete-confirm text-decoration-none float-right">
            Delete comment
          </a>
        </div>
        <span className="container">{comment}</span>
      </div>
    </div>
  );
};

export default Discussion_details_card;
