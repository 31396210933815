import React, { useEffect } from "react";

// Text editor
import { Editor } from "react-draft-wysiwyg";

// Redux
import { useSelector } from "react-redux";
import { darkModeState } from "../DarkMode/reduxSlice";

// Css
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./App.css";

const TextEditor = ({ editorState, setEditorState }) => {
  const { dark } = useSelector(darkModeState);

  useEffect(() => {
    const Img = document.querySelectorAll(".rdw-option-wrapper img");

    if (dark) {
      for (let img = 0; img < Img.length; img++) {
        Img[img].classList.add("dark_editor");
      }
    } else {
      for (let img = 0; img < Img.length; img++) {
        Img[img].classList.remove("dark_editor");
      }
    }
  }, [dark]);

  return (
    <>
      <div className="border bg-dark text-light">
        <Editor
          handlePastedText={() => false}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbarClassName="toolbarClassName bg-dark text-light"
          wrapperClassName="wrapperClassName bg-dark text-light"
          editorClassName="editorClassName bg-dark text-light"
          toolbar={{
            inline: {
              options: ["bold", "italic", "underline"],
              bold: { className: "demo-option-custom" },
              italic: { className: "demo-option-custom" },
              underline: { className: "demo-option-custom" },
              strikethrough: {
                className: "demo-option-custom",
              },
            },
            blockType: {
              className: "demo-option-custom-wide",
              dropdownClassName: "text-black",
            },
            fontSize: { className: "text-black" },
            list: {
              unordered: { className: "demo-option-custom" },
              ordered: { className: "demo-option-custom" },
              indent: { className: "demo-option-custom" },
              outdent: { className: "demo-option-custom" },
            },
            textAlign: {
              left: { className: "demo-option-custom" },
              center: { className: "demo-option-custom" },
              right: { className: "demo-option-custom" },
              justify: { className: "demo-option-custom" },
            },
            fontFamily: {
              className: "demo-option-custom-wide",
              dropdownClassName: "text-black",
            },
            history: {
              undo: { className: "demo-option-custom" },
              redo: { className: "demo-option-custom" },
            },
            image: {
              className: "d-none",
            },
            colorPicker: {
              className: "",
            },
            link: {
              className: "d-none",
            },
            embedded: {
              className: "d-none",
            },
            emoji: {
              className: "demo-option-custom",
              dropdownClassName: "text-light",
            },
          }}
        />
      </div>
    </>
  );
};

export default TextEditor;
