/*
if user want their calendar -> embed
else
give form -> Event name -> set by user
             name -> fetch from database
             user set their timing and dates -> set by user
             Link to connect -> optional
             Description -> set by user
             store in DB
*  */
import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";

import Popover from "react-bootstrap/Popover";
import SetHours from "./Form/SetHours";


//redux
import {useDispatch, useSelector} from "react-redux";

//Css

import PreviewModal from "./PreviewModal/App";
import {addCalendarAsync, calendarState, setCalendarLink} from "./reduxSlice";
import AddYourOwnCalendar from "./Form/AddYourOwnCalendar";
import CreateYourCalendar from "./Form/CreateYourCalendar";

//Main
const Calender = () => {


    const [selectOption, setSelectOption] = useState('0');

    //Add Your Calendar
    const dispatch = useDispatch();
    const {calendarLink} = useSelector(calendarState);

    //Add Your Calendar
    const addCalendar = (e) => {
        e.preventDefault();
        dispatch(addCalendarAsync({calendarLink}))
        console.log(calendarLink)
    }
    const displayForm = () => {

        if (selectOption === '0') {
            return <AddYourOwnCalendar addCal={calendarLink} setAddCal={(e) => {
                dispatch(setCalendarLink({value: e.target.value}))
            }} onAdd={addCalendar}/>
        } else {
            return <CreateYourCalendar/>
        }
    }

    const popover = (<Popover className="" id="popover-basic">
        <Popover.Body>
            Add your Schedule here
        </Popover.Body>
    </Popover>);


    return (<div className='container'>
        <div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='fs-2 bold'>Add Calendar</Form.Label>
                <Form.Select className='bg-dark text-light border w-25' aria-label="Default select example"
                             defaultValue='0' value={selectOption}
                             onChange={(e) => {
                                 setSelectOption(e.target.value)
                             }}>
                    <option value="0">Add your own calendar</option>
                    <option value="1">Create your own calendar</option>
                </Form.Select>
            </Form.Group>
        </div>


        <div className=' container d-flex flex-column align-items-center justify-content-center'>
            {displayForm()}
        </div>


    </div>);
};

export default Calender;
