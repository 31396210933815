import { httpsCallable } from "firebase/functions";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { functions, storage } from "../../../../../../../../firebase.config";

export function uploadDocuments(
  file,
  eventId = "XBd9vsLQLOkIenQnrhYW",
  docId,
  uploadDocumentId
) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/uploadDoc/${docId}/${uploadDocumentId}/` + file.name
    );
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function saveDocumentsUrlInDbAPI(
  eventId,
  docId,
  uploadDocumentId,
  url
) {
  const saveDocumentUrlInDb = httpsCallable(functions, "saveDocumentUrlInDb");
  return saveDocumentUrlInDb({
    eventId,
    docId,
    uploadDocumentId,
    url,
  });
}

export async function getDocumentFormInputDataAPI(eventId) {
  const getDocumentFormInputData = httpsCallable(
    functions,
    "getDocumentFormInputData"
  );
  return getDocumentFormInputData({
    eventId,
  });
}

// userdata

export async function deleteDocumentsAPI(eventId, docId) {
  const deleteDocuments = httpsCallable(functions, "deleteDocuments");
  return deleteDocuments({
    eventId,
    docId,
  });
}

export async function updateDocumentFormAPI(eventId, newformData, heading) {
  const updateDocumentForm = httpsCallable(functions, "updateDocumentForm");
  return updateDocumentForm({
    eventId,
    newformData,
    heading,
  });
}
