import { httpsCallable } from "firebase/functions";
import { functions, storage } from "../../../../../../firebase.config";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
} from "firebase/storage";

export function createSponsorAPI(websiteLink, type, eventId) {
  const createSponsor = httpsCallable(functions, "createSponsor");
  return createSponsor({
    websiteLink,
    type,
    eventId,
  });
}

export function createBrouchersAPI(data) {
  const createBrouchers = httpsCallable(functions, "createBrouchers");
  return createBrouchers({
    ...data,
  });
}

export function updateSponsorAPI(websiteLink, type, eventId, sponsorId) {
  const updateSponsor = httpsCallable(functions, "updateSponsor");
  return updateSponsor({
    websiteLink,
    type,
    eventId,
    sponsorId,
  });
}
export function deleteSponsorAPI(eventId, sponsorId) {
  const deleteSponsor = httpsCallable(functions, "deleteSponsor");
  return deleteSponsor({
    eventId,
    sponsorId,
  });
}
export function addSponsorUrlAPI(logoUrl, eventId, sponsorId) {
  const addSponsorUrl = httpsCallable(functions, "addSponsorUrl");
  return addSponsorUrl({
    logoUrl,
    eventId,
    sponsorId,
  });
}
export function getSponsorAPI(eventId) {
  const getSponsor = httpsCallable(functions, "getSponsor");
  return getSponsor({
    eventId,
  });
}
export function updateSponsorTypeAPI(eventId, type1, type2, type3, type4) {
  const updateSponsorType = httpsCallable(functions, "updateSponsorType");
  return updateSponsorType({
    eventId,
    type1,
    type2,
    type3,
    type4,
  });
}

export function uploadFiles(file, eventId, sponsorId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return
    const mountainsRef = ref(
      storage,
      `events/${eventId}/sponsors/${sponsorId}/` + file.name
    );
    const metadata = {
      contentType: "image/*",
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteSponsors(eventId, sponsorId) {
  // Create a reference to the file to delete
  const sponsorRef = ref(storage, `events/${eventId}/sponsors/${sponsorId}`);

  let speakerImgList = [];
  // Find all the prefixes and items.
  listAll(sponsorRef)
    .then((res) => {
      res.items.forEach((itemRef) => {
        speakerImgList = [...speakerImgList, itemRef];
        // All the items under listRef.
      });
      if (speakerImgList.length > 0) {
        speakerImgList.map((value) =>
          deleteObject(
            ref(
              storage,
              `events/${eventId}/sponsors/${sponsorId}/${value.name}`
            )
          )
        );
      }
    })
    .then((response) => {
      console.log("deleted");
      return response;
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
}

export function uploadSponsorBrouchersFile(file, eventId, sponsorId) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line array-callback-return

    let uniqueName = Math.random().toString() + "_" + file.name;

    const mountainsRef = ref(
      storage,
      `events/${eventId}/sponsors/${sponsorId}/brouchers/` + uniqueName
    );
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(mountainsRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export async function deleteBrouchersUrlFile(brouchersUrl) {
  const brouchersref = ref(storage, brouchersUrl);
  let res = await deleteObject(brouchersref);
  return res;
}

export function deleteBroucherAPI(presentationData) {
  const deleteBroucher = httpsCallable(functions, "deleteBroucher");
  return deleteBroucher({
    ...presentationData,
  });
}
