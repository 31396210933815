import {httpsCallable} from "firebase/functions";
import {db_firestore, functions} from "../../../../firebase.config";
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";

export async function addOwnCalender(
    calendarLink
) {
    const addOwnCalenderfn = httpsCallable(functions, "addCalender")
    return addOwnCalenderfn({
        calendarLink
    })

}

export async function createCalender(
    meetingName,
    meetingLocation,
    meetingDescription,
    meetingLink,
    duration,
    customDuration
) {
    const createCalenderfn = httpsCallable(functions, "createCalendar")
    return createCalenderfn({
        meetingName,
        meetingLocation,
        meetingDescription,
        meetingLink,
        duration,
        customDuration
    })

}

//FETCH DATA FOR CALENDAR CARD
export async function getCalendarCardDetailsAPI(docId) {
    const calendarRef = doc(db_firestore, "calendar", docId);

    const groupSnap = await getDoc(calendarRef);
    let group;
    if (groupSnap.exists()) {
        group = groupSnap.data();
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }

    if (group.memberUid.length <= 0) {
        return [];
    }

    let member = [];

    for (let index = 0; index < group.memberUid.length; index++) {
        const userDocq = query(
            collection(db_firestore, "users"),
            where("uid", "==", group.memberUid[index])
        );
        let docId;
        const querySnapshot = await getDocs(userDocq);
        querySnapshot.forEach((doc) => {
            docId = doc.id;
        });
        const userRef = doc(db_firestore, "users", docId);

        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            member.push({
                id: group.memberUid[index],
                ...userSnap.data(),
            });
        } else {
            console.log(group.memberUid[0]);
        }
    }

    return member;
}