import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { notificationState, removeNotificationAsync } from "../reduxSlice";
import moment from "moment";

// Css
import "./Announcement.css";

const Announcement = ({ index = undefined }) => {
  const dispatch = useDispatch();

  const { notifications } = useSelector(notificationState);

  if (index === undefined) {
    return null;
  };



  const { id, text, name, date, profileUrl } = notifications[index];
  // let id;
  // let text = "lorem ipsum adfjji jjwrewuur asdfj askdjfoewu l asdf asdf asdf asdf.";
  // let name = "dhruv sakariya";
  // let url = "";
  // let date = "3 hour ago";


  return (
    <>
      <Container className="p-1">
        <Row className="rounded border m-1 py-1">
          <Col sm={12} className="mb-2 text-light d-flex">
            <Col sm={11} >{text}</Col>
            <Col sm={1}>
              <IoIosCloseCircle className="float-end close_announcement cursor_pointer fs-3 " onClick={() => {
                dispatch(removeNotificationAsync({ notificationId: id }));
              }} />
            </Col>
          </Col>
          <Col sm={12} className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex align-items-center text-light"
            >
              <Image
                src={profileUrl}
                alt="avatar"
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
              <p className="ms-3 fw-bold my-0 text-light">{name}</p>
            </div>
            <p className="small text-light m-0">{moment(date).fromNow()}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Announcement;
