import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import EventCard from "../../MyGroup/MyGroup_Props/EventCard/App";
import { useDispatch } from "react-redux";

import {
  algoliaAsync,
  setAlgoliaIndex,
  setNavigatePath,
  setResult,
  setSearchQuery,
} from "../../../../Main/Components/Navbar/reduxSlice";

const FindEvent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAlgoliaIndex({ value: "events" })); // algoliaIndex
    dispatch(setNavigatePath({ navigate: "/find/event" }));
    dispatch(algoliaAsync({}));

    return () => {
      dispatch(setResult({ result: [] }));
    };
  }, [dispatch]);

  return (
    <div className="min-vh-100">
      <section className="container py-5">
        <div className="d-flex justify-content-between ">
          <nav className="nav">
            <Link
              to="/find/event"
              className="text-decoration-none shadow-none fw-bold btn btn-primary   rounded-start rounded-0 "
            >
              Events
            </Link>
            <Link
              to="/find/group"
              onClick={() => {
                dispatch(setSearchQuery({ value: "" }));
                dispatch(setResult({ result: [] }));
              }}
              className="text-decoration-none shadow-none fw-bold btn btn-outline-primary rounded-end rounded-0  "
            >
              Groups
            </Link>
          </nav>
        </div>
        <div className=" mt-5">
          <EventCard />
        </div>
      </section>
    </div>
  );
};

export default FindEvent;
