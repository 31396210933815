import React, { useEffect } from 'react'

// Bootstrap
import { Button, Container, Image, ListGroup } from 'react-bootstrap';
// Icon
import { MdCake, MdEmail, MdOutlineCall } from "react-icons/md";
import { GiLinkedRings } from "react-icons/gi";
import { FaAddressBook, FaAddressCard } from "react-icons/fa";
import { BsFacebook, BsInstagram, BsLinkedin, BsPersonBoundingBox, BsTwitter } from "react-icons/bs";
import PersonalForm from './FormComponent/PersonalForm'
import LocationForm from './FormComponent/LocationForm'
import SocialForm from './FormComponent/SocialForm'
import PasswordForm from './FormComponent/PasswordForm'
// Css
import '../App.css'
//redux
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAsync, setUserProfilePannel, userProfileState } from "../reduxSlice"
import { useParams } from "react-router-dom";


export default function GeneralPanel() {

    const dispatch = useDispatch();
    const { docId } = useParams();

    const {
        userDataLoading,
        userDataFound,
        userData,
    } = useSelector(userProfileState)

    useEffect(() => {
        dispatch(getUserProfileAsync({ docId }));
    }, [dispatch, docId]);

    function updatedForm(page) {
        switch (page) {
            case "Personal":
                dispatch(setUserProfilePannel({ newPannel: "Personal" }));
                break;
            case "Address":
                dispatch(setUserProfilePannel({ newPannel: "Address" }));
                break;
            case "Social":
                dispatch(setUserProfilePannel({ newPannel: "Social" }));
                break;
            case "Password":
                dispatch(setUserProfilePannel({ newPannel: "Password" }));
                break;
            default:
                break;
        }
    }


    //DATA
    const {
        firstName, lastName, email, userName,
        phoneNumber,
        birthDate,
        gender,
        profileUrl,
        timeZone,
        headLine,
        bio,
        city,
        country,
        address,
        facebook,
        instagram,
        twitter,
        linkedin,
    } = userData

    return (
        <>
            <Container className='mt-3 px-lg-5 px-2 h-100'>
                <h4>General Settings</h4>
                <p>Manage your profile info and setting here</p>
                <hr />
                <Container>
                    <div className='d-flex align-items-center justify-content-between pb-2'>
                        <h6 className='my-0'>Personal Details</h6>
                        <Button variant="link" className='text-light shadow-none text-decoration-none py-0 '
                            onClick={() => (updatedForm("Personal"))} >Update</Button>
                    </div>
                    <Container className='row mx-0 pb-3 bg-dark text-light overflow-hidden  px-0'>
                        <ListGroup variant="flush" id='personal-detail' className='bg-dark text-light col-12 col-md-9  order-1 order-md-0'>
                            <ListGroup.Item className='bg-dark text-light border-0'><FaAddressCard />
                                <span className="mx-2 text-capitalize">{firstName} {lastName}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><MdEmail />
                                <span className="mx-2">{email}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><MdOutlineCall />
                                <span className="mx-2">{phoneNumber}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><MdCake />
                                <span className="mx-2">{birthDate}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><GiLinkedRings />
                                <span className="mx-2 text-capitalize">{gender}</span></ListGroup.Item>
                        </ListGroup>
                        <Image
                            fluid
                            src={profileUrl}
                            style={{
                                objectFit: 'contain',
                                objectPosition: 'top',
                                aspectRatio: '4/4'
                            }}
                            className='rounded col-8 col-md-3 rounded border' />
                        {/*<BsPersonBoundingBox className='display-1 border p-2 mt-2 mb-auto my-lg-auto me-3 rounded '/>*/}
                    </Container>
                </Container>
                <hr />
                <Container>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h6 className='my-0'>Address</h6>
                        <Button variant="link" className='text-light shadow-none text-decoration-none py-0'
                            onClick={() => (updatedForm("Address"))}>Update</Button>
                    </div>
                    <Container className='d-flex flex-row pb-3  px-0'>
                        <ListGroup variant="flush" id='personal-detail' className='w-100'>
                            <ListGroup.Item className='bg-dark text-light border-0'><FaAddressBook />
                                <span className="mx-2">{address} {country ? "," + country : null} </span></ListGroup.Item>
                        </ListGroup>
                    </Container>
                </Container>
                <hr />
                <Container>
                    <div className='d-flex align-items-center justify-content-between'>
                        <h6 className='my-0'>Social Media Accounts</h6>
                        <Button variant="link" className='text-light shadow-none text-decoration-none py-0'
                            onClick={() => (updatedForm("Social"))} >Update</Button>
                    </div>
                    <Container className='d-flex flex-row pb-3  px-0'>
                        <ListGroup variant="flush" id='personal-detail' className='w-100 '>
                            <ListGroup.Item className='bg-dark text-light border-0'><BsFacebook />
                                <span className="mx-2"> {facebook}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><BsInstagram />
                                <span className="mx-2"> {instagram}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><BsTwitter />
                                <span className="mx-2"> {twitter}</span></ListGroup.Item>
                            <ListGroup.Item className='bg-dark text-light border-0'><BsLinkedin />
                                <span className="mx-2"> {linkedin}</span></ListGroup.Item>
                        </ListGroup>
                    </Container>
                </Container>
                <hr />
                <Container>
                    <div className='d-flex align-items-center justify-content-between px-0'>
                        <h6 className='my-0'>Password</h6>
                        <Button variant="link" className='text-light shadow-none text-decoration-none py-0'
                            onClick={() => (updatedForm("Password"))} >Change</Button>
                    </div>
                    <Container className='d-flex flex-row pb-3 bg-dark text-light '>
                        <p><a href='#' className='text-light'>Last updated</a> : Never</p>
                    </Container>
                </Container>
            </Container>
        </>
    )

}