import update from "immutability-helper";
import { useCallback } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "./Card.js";
import { formBuilderState, setHeading, setInputFields } from "./reduxSlice.js";

const styleHeading = {
  border: "1px dashed gray",
  cursor: "default",
};
export const Container = () => {
  const dispatch = useDispatch();
  const { inputFields, heading } = useSelector(formBuilderState);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      dispatch(
        setInputFields({
          newInputFields: update(inputFields, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, inputFields[dragIndex]],
            ],
          }),
        })
      );
    },
    [dispatch, inputFields]
  );
  const renderCard = useCallback(
    (input, index) => {
      return (
        <Card
          key={input._id}
          index={index}
          _id={input._id}
          label={input.label}
          type={input.type}
          multiple={true}
          required={input.required}
          moveCard={moveCard}
        />
      );
    },
    [moveCard]
  );
  return (
    <>
      <div className="bg-dark text-light">
        {/* Form Heading */}
        <div style={{ ...styleHeading }} className='p-4 mb-2'>
          <Form.Control
            type={"text"}
            onChange={(event) => {
              dispatch(setHeading({ value: event.target.value }));
            }}
            value={heading}
            className="p-2 bg-dark text-light "
          />
        </div>
        {inputFields.map((input, i) => renderCard(input, i))}
      </div>
    </>
  );
};
