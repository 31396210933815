import React from "react";

// Bootstrap
import { Form, InputGroup, Button } from "react-bootstrap";

// Icons
import { MdClose } from "react-icons/md";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  createGroupState,
  removeCategories,
  setCategories,
  setSearchCategories,
  setSelectedCategories,
} from "../reduxSlice";

const GroupCatagories = ({ nextPage, backPage }) => {
  const { previewCategories, selectedCategories, searchCategories } =
    useSelector(createGroupState);
  const dispatch = useDispatch();

  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

  //Function to filter the array
  const filteredInterest = previewCategories.filter((data) => {
    return (
      data.interest.toLowerCase().indexOf(searchCategories.toLowerCase()) !== -1
    );
  });

  let unique1 = filteredInterest.filter(
    (o) => selectedCategories.indexOf(o) === -1
  );
  let unique2 = selectedCategories.filter(
    (o) => filteredInterest.indexOf(o) === -1
  );

  const unique = unique1.concat(unique2);
  const newList = shuffle(unique);

  return (
    <div className="page-wrapper">
      <section className="">
        <form>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-8">
              <h1>Choose a few topics that describe your group's interests</h1>
              <p>
                Be specific! This will help us promote your group to the right
                people.
              </p>
              <div className="input-icon mb-3 place_autocomplete_container">
                <div>
                  <div className="text-block pt-3">
                    <ul className="list-inline">
                      {selectedCategories.map((data) => (
                        <li key={data.id} className="list-inline-item mb-2 ">
                          <div
                            onClick={() => {
                              dispatch(removeCategories(data.id));
                            }}
                            className="badge bg-primary fs-6 text-white p-2 text-decoration-none cursor-event"
                          >
                            {data.interest}
                            <span className="fs-5">
                              <MdClose />
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <Form className="d-lg-flex">
                  <InputGroup>
                    <Form.Control
                      value={searchCategories}
                      onChange={(e) =>
                        dispatch(setSearchCategories({ value: e.target.value }))
                      }
                      type="Topics"
                      placeholder="Topics"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      className=" bg-dark text-light"
                    ></Form.Control>
                  </InputGroup>
                </Form>

                <div>
                  <div className="text-block pt-3">
                    <ul className="list-inline">
                      {newList.length !== 0 ? (
                        newList.slice(0, 10).map((data) => (
                          <li key={data.id} className="list-inline-item mb-2 ">
                            <div
                              onClick={() =>
                                dispatch(
                                  setSelectedCategories({ newCateories: data }),
                                  dispatch(
                                    setCategories({ newCateoriesId: data.id })
                                  )
                                )
                              }
                              className="badge bg-primary fs-6 text-white p-2 cursor-event text-decoration-none"
                            >
                              {data.interest}
                            </div>
                          </li>
                        ))
                      ) : (
                        //ADD NEW CATEGORIES
                        <div>
                          <Button
                              onClick={() =>
                                  dispatch(
                                      setSelectedCategories({
                                        newCateories: searchCategories,
                                      })
                                  )
                              }
                          >
                            Add your own categories
                          </Button>
                        </div>
                      )}
                    </ul>
                  </div>
                  <div className="">
                    <Button onClick={backPage}>Back</Button>
                    <Button
                      className="btn btn-primary float-end"
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default GroupCatagories;
