import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOrganizedEventsAPI,
  getEventsAPI,
  getJoinedEventsAPI,
  getGroupsEventsAPI,
} from "./reduxAPI";

import { pushErrorNotification } from "../../Error/reduxSlice";
// icon
import { Icon } from "@iconify/react";

const initialState = {
  organizedEventsLoading: false,
  createdEventsDataArr: [],
  foundOrganizedEvent: true,

  joinedEventsLoading: false,
  joinedEventsDataArr: [],
  foundJoinedEvents: true,

  groupsEventsLoading: false,
  groupsEventsDataArr: [],
  foundGroupsEvents: true,

  loading: false, // use this at upcomming events places
  eventsDataArr: [],
  found: true,
};

export const organizedEventsAsync = createAsyncThunk(
  "eventsList/fetchOrganizedEventsList",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      const response = await getOrganizedEventsAPI(docId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
export const joinedEventsAsync = createAsyncThunk(
  "eventsList/fetchjoinedEventsList",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      const response = await getJoinedEventsAPI(docId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
export const groupsEventsAsync = createAsyncThunk(
  "eventsList/groupsEventsEventsList",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { docId } = getState().user;
      const response = await getGroupsEventsAPI(docId);

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
export const eventsListAsync = createAsyncThunk(
  "eventsList/fetchEventsList",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      // const {  } = getState().eventsList;
      const response = await getEventsAPI();

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.code,
              errorMessage: error.message,
              theme: "danger",
              time: "now",
              autoHideTime: 3000,
              statusCode: 200,
              show: true,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const eventsListSlice = createSlice({
  name: "eventsList",
  initialState,
  reducers: {
    setData: (state, action) => {
      let formDataBuff = action.payload.previewData;

      console.log(formDataBuff);
      state.previewData = [...formDataBuff];
    },

    setFormId: (state, action) => {
      state.formId = action.payload.value;
    },
    clearAllFound: (state, action) => {
      state.found = true;
      state.foundGroupsEvents = true;
      state.foundJoinedEvents = true;
      state.foundOrganizedEvent = true;
    },
    clearAllDataArr: (state, action) => {
      state.eventsDataArr = [];
      state.groupsEventsDataArr = [];
      state.joinedEventsDataArr = [];
      state.createdEventsDataArr = [];
    },
    setFound: (state, action) => {
      state.found = action.payload.value;
    },

    clearEventsDataArr: (state, action) => {
      state.eventsDataArr = [];
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(organizedEventsAsync.pending, (state, action) => {
        state.organizedEventsLoading = true;
      })
      .addCase(organizedEventsAsync.fulfilled, (state, action) => {
        state.organizedEventsLoading = false;
        state.createdEventsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundOrganizedEvent = false;
        }
      })
      .addCase(organizedEventsAsync.rejected, (state, action) => {
        state.organizedEventsLoading = false;
      })

      .addCase(joinedEventsAsync.pending, (state, action) => {
        state.joinedEventsLoading = true;
      })
      .addCase(joinedEventsAsync.fulfilled, (state, action) => {
        state.joinedEventsLoading = false;
        state.joinedEventsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundJoinedEvents = false;
        }
      })
      .addCase(joinedEventsAsync.rejected, (state, action) => {
        state.joinedEventsLoading = false;
      })

      .addCase(groupsEventsAsync.pending, (state, action) => {
        state.groupsEventsLoading = true;
      })
      .addCase(groupsEventsAsync.fulfilled, (state, action) => {
        state.groupsEventsLoading = false;
        state.groupsEventsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.foundGroupsEvents = false;
        }
      })
      .addCase(groupsEventsAsync.rejected, (state, action) => {
        state.groupsEventsLoading = false;
      })

      .addCase(eventsListAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(eventsListAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.eventsDataArr = action.payload;
        if (action.payload.length <= 0) {
          state.found = false;
        }
      })
      .addCase(eventsListAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setFormId,
  setData,
  setFound,
  clearEventsDataArr,
  clearAllFound,
  clearAllDataArr,
} = eventsListSlice.actions;

export const eventsListState = (state) => state.eventsList;

export default eventsListSlice.reducer;
