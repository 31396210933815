import React from 'react';
import {Container, Form} from "react-bootstrap";
import {MdAddCircle} from "@react-icons/all-files/md/MdAddCircle";
import {MdClose} from "@react-icons/all-files/md/MdClose";


//Time Range


function SelectTime() {
    return (
        <>
            <div className='d-flex flex-row  align-items-center'>
                <input defaultValue="09:00" className='mx-2 my-1 p-1 rounded-2' type='time'/>
                <input defaultValue="17:00" type='time' className='mx-2 my-1 p-1 rounded-2'/>
            </div>
        </>
    )
}

const SetHours = ({Days}) => {

    return (
        <div className='border my-2 rounded-3 border-primary align-items-center'>
            {/*checkbox*/}
            {/*week days*/}
            {/*timing -> add or delete*/}
            <Container
                className='d-flex container-sm justify-content-between flex-column flex-md-row mb-1 align-items-center'>
                <Form.Group className="mt-1 w-100 d-flex align-items-center bg-dark text-light"
                            controlId="exampleForm.ControlInput1">
                    <Form.Check
                        type="checkbox" className='bg-dark text-light mx-2'/>
                    <label className=''>
                        {Days}
                    </label>
                </Form.Group>

                {/*TIME SELECT FORM*/}
                <div className='d-flex flex-column align-items-center'>
                    <SelectTime/>
                </div>
                {/*TIME SELECT FORM*/}
            </Container>
        </div>
    );
};

export default SetHours;
