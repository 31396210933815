import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewQnAAPI,
  deleteQnaAPI,
  getQnAAPI,
  updateQnAAPI,
} from "./reduxAPI";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
// import {} from "./reduxAPI";

const initialState = {
  foundwhyAttend: true,
  whyAttendList: [],

  creating_updatingQna: false,

  editWhyAttend: false,
  wantToEditQnAIndex: null,

  question: "",
  answer: EditorState.createEmpty(),
};

export const createNewQnAAsync = createAsyncThunk(
  "whyAttend/createNewQnA",
  async ({ sponsorId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { answer, question } = getState().whyAttend;
      const response = await createNewQnAAPI(eventId, answer, question);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateQnAAsync = createAsyncThunk(
  "whyAttend/updateQnAA",
  async ({ sponsorId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { answer, question, wantToEditQnAIndex, whyAttendList } =
        getState().whyAttend;

      let whyAttendId = whyAttendList[wantToEditQnAIndex].id;

      const response = await updateQnAAPI(
        eventId,
        answer,
        question,
        whyAttendId
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getQnAAsync = createAsyncThunk(
  "whyAttend/getQnA",
  async ({ sponsorId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await getQnAAPI(eventId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteQnaAsync = createAsyncThunk(
  "whyAttend/deleteQnaA",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await deleteQnaAPI(eventId, id);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const whyAttendSlice = createSlice({
  name: "whyAttend",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAnswer: (state, action) => {
      state.answer = action.payload.value;
    },

    setQuestion: (state, action) => {
      state.question = action.payload.value;
    },

    setEditWhyAttend: (state, action) => {
      state.editWhyAttend = action.payload.value;
    },

    setWantToEditQnAIndex: (state, action) => {
      state.wantToEditQnAIndex = action.payload.index;
    },

    setDataToEdit: (state, action) => {
      let index = state.wantToEditQnAIndex;

      let { question, answer } = { ...state.whyAttendList[index] };

      if (answer) {
        const contentBlock = htmlToDraft(answer);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          state.answer = editorState;
        }
      }

      state.question = question;
    },

    resetForm: (state, action) => {
      state.question = "";
      state.answer = EditorState.createEmpty();
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getQnAAsync.pending, (state, action) => {
        state.foundwhyAttend = true;
      })
      .addCase(getQnAAsync.fulfilled, (state, action) => {
        let result = action.payload.data.whyAttends;

        if (result.length <= 0) {
          state.foundwhyAttend = false;
        }

        state.whyAttendList = result;
      })
      .addCase(getQnAAsync.rejected, (state, action) => {})

      .addCase(deleteQnaAsync.pending, (state, action) => {})
      .addCase(deleteQnaAsync.fulfilled, (state, action) => {})
      .addCase(deleteQnaAsync.rejected, (state, action) => {})
      .addCase(createNewQnAAsync.pending, (state, action) => {
        state.creating_updatingQna = true;
      })
      .addCase(createNewQnAAsync.fulfilled, (state, action) => {
        state.creating_updatingQna = false;
      })
      .addCase(createNewQnAAsync.rejected, (state, action) => {
        state.creating_updatingQna = false;
      })
      .addCase(updateQnAAsync.pending, (state, action) => {
        state.creating_updatingQna = true;
      })
      .addCase(updateQnAAsync.fulfilled, (state, action) => {
        state.creating_updatingQna = false;
        state.wantToEditQnAIndex = null;
      })
      .addCase(updateQnAAsync.rejected, (state, action) => {
        state.creating_updatingQna = false;
      });
  },
});

export const {
  setAnswer,
  setQuestion,
  setWantToEditQnAIndex,
  setDataToEdit,
  setEditWhyAttend,
  resetForm,
} = whyAttendSlice.actions;

export const whyAttendState = (state) => state.whyAttend;

export default whyAttendSlice.reducer;
