import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Routes
import { useParams, Navigate, useLocation } from "react-router-dom";
import { setHideFooter } from "../../Footer/reduxSlice";

// Redux
import { getCompanyNameFromGroupId } from "./reduxAPI";

const GroupIdPage = () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const { state } = useLocation();

  const [companyName, setCompanyName] = useState();
  const [groupName, setGroupName] = useState(state?.groupName);

  useEffect(() => {
    (async () => {
      let groupNameAvl;

      if (!state?.groupName) {
        groupNameAvl = false;
      } else {
        groupNameAvl = true;
      }

      const result = await getCompanyNameFromGroupId(groupId, groupNameAvl);

      setCompanyName(result.companyName);

      if (!state?.groupName) {
        setGroupName(result.groupName);
      }
    })();

    return () => {};
  }, [groupId, state?.groupName]);

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));

    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, [dispatch]);

  return (
    <>
      {companyName && (state?.groupName || groupName) ? (
        <Navigate
          to={`/${companyName}/group/${state?.groupName || groupName}`}
          replace={false}
        />
      ) : null}
    </>
  );
};

export default GroupIdPage;
