import React, { useState } from "react";
// Routes
import { Link } from "react-router-dom";

// Bootstrap
import { Placeholder, Image } from "react-bootstrap";

const NotFoundEvents = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="container my-3 my-md-0 mt-md-2 mb-md-4 px-4">
      <div className="row align-items-center flex-column flex-md-row border rounded-3 py-4 mx-0">
        <div className="col p-2">
          <Image
            fluid
            draggable={false}
            style={{
              aspectRatio: "1 / 1",
              maxHeight: "150px",
            }}
            className={` rounded d-block mx-auto ${loading ? "d-none" : null}`}
            onLoad={() => {
              setLoading(false);
            }}
            src="https://firebasestorage.googleapis.com/v0/b/networking-pre-event-app.appspot.com/o/staticPNG%2Fno-events.svg?alt=media&token=4c59ee87-eaeb-4425-b9fb-73f5b137941d"
            alt="not Found"
          />

          {loading ? (
            <Placeholder className="d-block text-center" animation="glow">
              <Placeholder
                className="rounded h-100  "
                style={{
                  aspectRatio: "1 / 1 ",
                  minHeight: "150px",
                }}
              />
            </Placeholder>
          ) : null}
        </div>
        <p className="col text-center">
          There are no Events right now. Find one today.
        </p>
        <div className="col text-center">
          <Link to={{ pathname: "/find/event" }}>
            <button className="btn btn-primary">Discover Events</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundEvents;
