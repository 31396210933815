import React, { useEffect } from "react";

// Bootstrap
import { ListGroup, Placeholder, Spinner } from "react-bootstrap";

// Icons
import { BsStar } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setHideFooter } from "../../Footer/reduxSlice";
import Banner from "../Page Component/Banner/App";
import EventTabs from "../Page Component/Tabs/App";

const EventPageSkeleton = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHideFooter({ value: true }));

    return () => {
      dispatch(setHideFooter({ value: false }));
    };
  }, [dispatch]);

  return (
    <>
      <div
        className="text-center  d-flex justify-content-center align-items-center"
        style={{ minHeight: "75vh" }}
      >
        <Spinner animation="border" />
      </div>
    </>
  );
};

export default EventPageSkeleton;
