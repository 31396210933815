import * as React from "react";
import {
  Accordion,
  Col,
  Container,
  Row,
  Form,
  Button,
  Modal,
  ListGroup,
  CloseButton,
} from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";
import "./App.css";

import { darkModeState } from "../DarkMode/reduxSlice";
import { useSelector } from "react-redux";
import NavbarComponent from "../Navbar/App";

export default function App() {
  const { dark } = useSelector(darkModeState);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <NavbarComponent/>
      <Container className="my-5" id="pricing">
        <Row className="justify-content-between mx-0">
          <Col sm={12} md={6} className="py-md-5">
            <h1 className="text-light fw-bold">
              Dive in! There are so many things to do on Network
            </h1>
            <p>
              Join a group to meet people, make friends, find support, grow a
              business, and explore your interests. Thousands of events are
              happening every day, both online and in person!
            </p>
            <Accordion
              defaultActiveKey="0"
              className="bg-dark text-light mt-3 mb-3 border-0"
            >
              <Accordion.Item
                eventKey="0"
                className="bg-dark text-light border-0"
              >
                <Accordion.Header className="bg-dark text-light ">
                  So how does the 15-Day free trial work?
                </Accordion.Header>
                <Accordion.Body>
                  You can use Pre-Event free for up to 14 Days, with full access
                  to everything offered on the plan you sign up for. If you
                  don't cancel within 14 Days, we'll charge you for the plan you
                  selected.
                  <br />
                  You can upgrade, downgrade or cancel at any time with just a
                  few clicks.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="1"
                className="bg-dark text-light border-0"
              >
                <Accordion.Header className="bg-dark text-light">
                  So how does the 15-Day free trial work?
                </Accordion.Header>
                <Accordion.Body>
                  You can use Pre-Event free for up to 14 Days, with full access
                  to everything offered on the plan you sign up for. If you
                  don't cancel within 14 Days, we'll charge you for the plan you
                  selected.
                  <br />
                  You can upgrade, downgrade or cancel at any time with just a
                  few clicks.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="2"
                className="bg-dark text-light border-0"
              >
                <Accordion.Header className="bg-dark text-light">
                  So how does the 15-Day free trial work?
                </Accordion.Header>
                <Accordion.Body>
                  You can use Pre-Event free for up to 14 Days, with full access
                  to everything offered on the plan you sign up for. If you
                  don't cancel within 14 Days, we'll charge you for the plan you
                  selected.
                  <br />
                  You can upgrade, downgrade or cancel at any time with just a
                  few clicks.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col sm={12} md={5} className="py-3 py-md-5">
            <fieldset>
              <Form.Group as={Row} className=" mx-0 ">
                <Col
                  sm={12}
                  as="label"
                  forHtml="formHorizontalRadios1"
                  className="row align-items-center rounded shadow-sm py-3 border border-1 my-2 mx-0"
                >
                  <Form.Check
                    type="radio"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    className="col-1"
                  />
                  <div className="col-7 py-0 my-0">
                    <h3 className="my-0">Express </h3>
                    <p className="small mb-0">
                      For the new marketer on a budget who just wants basic
                      tracking...
                    </p>
                  </div>
                  <div className="col-4">
                    <h5 className="my-0">
                      30,000 <span className="small">rs/mo.</span>
                    </h5>
                  </div>
                </Col>
                <Col
                  sm={12}
                  as="label"
                  forHtml="formHorizontalRadios2"
                  className="row align-items-center rounded shadow-sm py-3 border border-1 my-2 mx-0"
                >
                  <Form.Check
                    type="radio"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    className="col-1"
                  />
                  <div className="col-7 py-0 my-0">
                    <h3 className="my-0">Premium </h3>
                    <p className="small mb-0">
                      For the new marketer on a budget who just wants basic
                      tracking...
                    </p>
                  </div>
                  <div className="col-4">
                    <h5 className="my-0">
                      75,000 <span className="small">rs/mo.</span>
                    </h5>
                  </div>
                </Col>
                <Col
                  sm={12}
                  as="label"
                  forHtml="formHorizontalRadios3"
                  className="row align-items-center rounded shadow-sm py-3 border border-1 my-2 mx-0"
                >
                  <Form.Check
                    type="radio"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    className="col-1"
                  />
                  <div className="col-8 py-0 my-0">
                    <h3 className="my-0">Custom ( talk to us )</h3>
                    <p className="small mb-0">
                      For the new marketer on a budget who just wants basic
                      tracking...
                    </p>
                  </div>
                </Col>
              </Form.Group>
            </fieldset>
            <Button
              variant="link"
              className="p-0 text-decoration-none ms-auto me-0 d-block shadow-none text-quaternary"
              onClick={handleShow}
            >
              See full comparison
            </Button>
          </Col>
        </Row>

        {/* See full comparison */}
        <Modal show={show} onHide={handleClose} size="xl" className="px-0">
          <Modal.Header className="bg-dark text-light border-0">
            <CloseButton
              className="shadow-none ms-auto me-0  "
              variant={`${dark ? "white" : ""}`}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body className="pb-5 bg-dark text-light">
            <div className="pb-5">
              <h6 className="text-center small">
                START WITH 15-DAY FREE TRAIL
              </h6>
              <h2 className="text-center text-primary fw-bold my-3">
                BizConnect Show Pricing
              </h2>
              <h5 className="text-center">
                Select a plan to fix your tracking problems forever...
              </h5>
            </div>
            <Row className="justify-content-evenly gap-2 gap-md-0">
              <Col
                sm={3}
                className="shadow-sm border border-1 rounded text-center py-4"
              >
                <h5 className="fw-bold">Express</h5>
                <h2 className="fw-bold my-3">
                  30,000 <span className="small fs-6">rs/month</span>
                </h2>
                <p className="small">
                  For the new marketer on a budget who just wants basic
                  tracking...
                </p>
                <Button variant="primary w-100">Select</Button>

                <ListGroup className="border-0 text-start my-4 bg-dark text-light">
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Mode- DIY</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">No of Attendees/Month (500)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">No of Event (Unlimited)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between ">
                    <span className="w-75">
                      Event Microsite which doubles as WebApp
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Attendee online</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Sponsors</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Agenda (Pdf/Image upload only)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Event Details</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Attendee details / profile</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Speaker Profile</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Live Poll/Q&A/General chat</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Reports & Google Analytics</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Virtual Photo booth (add-on)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Leaderboard with game zones (add-on)
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col
                sm={3}
                className="shadow-sm border border-1 rounded text-center py-4"
              >
                <h5 className="fw-bold">Premium </h5>
                <h2 className="fw-bold my-3">
                  75,000 <span className="small fs-6">rs/month</span>
                </h2>
                <p className="small">
                  For the new marketer on a budget who just wants basic
                  tracking...
                </p>
                <Button variant="primary w-100">Select</Button>

                <ListGroup className="border-0 text-start my-4">
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Mode- DIY</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">No of Attendees/Month (750)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">No of Event (Unlimited)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between ">
                    <span className="w-75">
                      Event Microsite which doubles as WebApp
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Attendee online</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">At site Registration</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Sponsors</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Booths (10)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Agenda (Pdf/Image upload & Editor)
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Event Details</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Attendee details / profile</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Speaker Profile</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Venue Information /Bookings/earnings
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Live Poll/Q&A/General chat</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Event Floor Map</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">FAQ</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Add to calendar</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Event News /Socail Feed</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Reports & Google Analytics</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Physical Event Reg module (add-on)
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">Virtual Photo booth (add-on)</span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Leaderboard with game zones (add-on)
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col
                sm={3}
                className="shadow-sm border border-1 rounded text-center py-4"
              >
                <h5 className="fw-bold">Custom ( talk to us )</h5>
                <h2 className="fw-bold my-3">
                  Custom <span className="small fs-6">rs/month</span>
                </h2>
                <p className="small">
                  Execution assistance is provided Talk to us
                </p>
                <Button variant="primary w-100">Select</Button>

                <ListGroup className="border-0 text-start my-4">
                  <ListGroup.Item className="bg-dark text-light border-0 d-flex justify-content-between">
                    <span className="w-75">
                      Execution assistance is provided Talk to us
                    </span>
                    <BsInfoCircle
                      className="ms-1"
                      style={{ height: "15px", width: "15px" }}
                    />
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}
