import React from 'react'
import { Container } from 'react-bootstrap';
import FilterNavbar from '../FilterNavbar'
import EventListing from '../EventListing';

// redux 

export default function MyEvent() {

    return (
        <>
            <div className='h-100 bg-dark text-light'>
                <Container className='py-4 py-lg-5 d-flex flex-column flex-lg-row justify-content-between'>
                    <FilterNavbar />
                    <EventListing menu='organize'/>
                </Container>
            </div>
        </>
    )
}


