import React from "react";
import * as ReactDOMServer from "react-dom/server";

import { useSelector } from "react-redux";
import { eventState } from "../../Page Component/reduxSlice";
import moment from "moment";



export function template1(eventData, clientName) {
  const {
    eventGallery,
    id,
    eventMode,
    generalChatLink,
    groupId,
    link,
    sponsorType2,
    description,
    subscriberUid,
    sponsorType3,
    sponsorType1,
    QnaLink,
    draft,
    sponsorsId,
    eventName,
    boothownersId,
    pollLink,
    eventType,
    mapLink,
    displayPdf,
    subSubscribersId,
    sponsorType4,
    location,
    eventLogoRedirectLink,
    eventStartsAt,
    eventEndsAt,
    eventLogo,
  } = eventData;
  const htmlString = ReactDOMServer.renderToStaticMarkup(
    <div style={{ padding: "0", margin: "0", fontSize: "16px" }}>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ margin: "0 auto", width: "100%" }}
          src={eventGallery}
          alt="Event"
        />
      </div>
      <div style={{ padding: "1em 2em" }}>
        <p
          style={{
            fontWeight: "700",
            marginBottom: "0.5em",
            fontSize: "1.1rem",
          }}
        >
          Hello {clientName}
        </p>
        <p style={{ fontSize: "0.85rem", marginTop: "0" }}>
          Thank you for registering for {eventName}. Dive in! There are so many
          things to do on the Network.
        </p>
        <p>
          Pre-event and Networking :{" "}
          <a
            href={`https://preevent.netlify.app/event/${id}`}
            style={{ textDecoration: "none" }}
          >
            Log In and explore more details
          </a>
        </p>

        <table
          style={{
            fontFamily: "arial, sans-serif",
            borderCollapse: "collapse",
            width: "100%",
          }}
          width="100%"
        >
          <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              Starts At Date &amp; Time
            </td>
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              {moment(eventStartsAt?._seconds).format("llll")}
            </td>
          </tr>

          {location ? (
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Location
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                {location}
              </td>
            </tr>
          ) : null}
          {mapLink ? (
            <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Google Map
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                <a
                  href={mapLink}
                  style={{ textDecoration: "none" }}
                  target="_black"
                >
                  {mapLink}
                </a>
              </td>
            </tr>
          ) : null}
          {link ? (
            <tr>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                Virtual meeting link
              </td>
              <td
                style={{
                  border: "1px solid #dddddd",
                  textAlign: "left",
                  padding: "8px",
                }}
                align="left"
              >
                <a
                  href={link}
                  style={{ textDecoration: "none" }}
                  target="_black"
                >
                  {link}
                </a>
              </td>
            </tr>
          ) : null}
          <tr style={{ backgroundColor: "#dddddd" }} bgcolor="#dddddd">
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              Ends At Date &amp; Time
            </td>
            <td
              style={{
                border: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
              }}
              align="left"
            >
              {moment(eventEndsAt?._seconds).format("llll")}
            </td>
          </tr>
        </table>
        <p className="mt-2">Thank You !</p>
        {eventLogo ? (
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "100px" }} src={eventLogo} alt="Logo" />
          </div>
        ) : null}
      </div>
    </div>
  );
  return htmlString;
}
