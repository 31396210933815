import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    documentCollapse: null,
    collapseData: [
        { name: 'Karan Pandit', 
        doc: [
            { name: 'Passport', url: 'dfjdkhfjksd' }, 
            { name: 'Company Card', url: 'jkdfhjkfhsdkjf' }, 
            { name: 'Voter Card', url: 'fddsjfhdsjkf' }
        ] 
        },
        { name: 'Raj', 
        doc: [
            { name: 'Passport', url: 'dfjdkhfjksd' }, 
            { name: 'Company Card', url: 'jkdfhjkfhsdkjf' }, 
        ] 
        }
        ],

};

export const uploadDocDataSlice = createSlice({
    name: "uploadDocData",
    initialState,
    reducers: {
        setCollapse: (state, action) => {
            state.documentCollapse = action.payload.collapse;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setCollapse,
} = uploadDocDataSlice.actions;

// create state here and export
// userProfile
export const uploadDocDataState = (state) => state.uploadDocData;

// this default you have to import in store.js
export default uploadDocDataSlice.reducer;
