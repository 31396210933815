import React from 'react';
import {Button, Modal} from "react-bootstrap";

const Photo_Upload = ({show, handleClose}) => {


    return (
        <div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}

            >
                <Modal.Header className='bg-dark text-light border' closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-light border'>

                    <div className="mb-4">
                        <label className="form-label">Title</label>
                        <input className="form-control bg-dark text-light" name="title" type="text" placeholder="Title"
                               required=""/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Select photo</label>
                        <input className="form-control bg-dark text-light" type="file" accept="image/png, image/jpeg"
                        />
                    </div>
                    <div>
                        <Button className='mx-2' variant="primary">Upload</Button>

                        <Button variant="bg-dark text-light border" onClick={handleClose}>
                            Close
                        </Button>
                    </div>

                </Modal.Body>


            </Modal>

        </div>
    );
};

export default Photo_Upload;