import { Icon } from "@iconify/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pushErrorNotification } from "../../../../Main/Components/Error/reduxSlice";
import {
  addCreatedEventsId,
  createEvent,
  joinEventAPI,
  uploadFiles,
  updateEvent,
  deleteEventImages,
  addEventGallery,
  eventNameExistsAPI,
  uploadEventLogo,
  deleteEventLogo,
} from "./reduxApi";

import {
  postFormDataAsync,
  updatePostFormDataAsync,
} from "../RegistrationForm/reduxSlice";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { getFormDocId } from "../RegistrationForm/reduxAPI";
import { getGroupIdAsync } from "../MyGroupOnBoard/reduxSlice";
import { getGroupandEventId } from "../../../../Main/Components/Events/Page Component/reduxAPI";
import { submitFormInputsAsync } from "../../../../Main/Components/Events/Page Component/Documents/components/MultipleInputFormBuilder/reduxSlice";
import { updateDocumentFormAsymc } from "../../../../Main/Components/Events/Page Component/Documents/components/FormGenerator/reduxSlice";
import {
  sendUpdateEventMailAsync,
  sendUpdateEventNotificationAsync,
} from "../../../../Main/Components/Events/Emails/JoinEvent/reduxSlice";

const initialState = {
  // UI State
  showOrganizeTabs: true,
  tabsManagement: [
    {
      name: "Speakers",
      show: false,
    },
    {
      name: "Sponsors",
      show: false,
    },
    {
      name: "Booths",
      show: false,
    },
    {
      name: "Agenda",
      show: false,
    },
    {
      name: "Why attend",
      show: false,
    },
    {
      name: "FAQ",
      show: false,
    },
    {
      name: "Upload",
      show: false,
    },
    {
      name: "Poll",
      show: false,
    },
    {
      name: "Q&A",
      show: false,
    },
    {
      name: "General Chat",
      show: false,
    },
  ],

  editEvent: false,

  loading: false,

  addRegistrationForm: false,
  showRegistrationForm: false,

  addDocumentForm: false,
  showDocumentForm: false,

  eventName: "",
  eventStartsAtDate: "",
  eventStartsAtTime: "",
  eventEndsAtDate: "",
  eventEndsAtTime: "",
  eventGallery: [],
  description: EditorState.createEmpty(),
  eventType: "free",
  draft: false,
  eventMode: "online",
  link: "",
  location: "",
  mapLink: "",

  showCommunicationTabs: false,

  pollLink: "",
  generalChatLink: "",
  QnaLink: "",
  feedBackLink: "",

  eventLogoSelectedFile: null,
  eventLogoRedirectLink: "",

  selectedFiles: [],
  preViewFiles: [],

  sendUpdateMail: false,
  sendUpdateNotification: false,
};

export const createEventAsync = createAsyncThunk(
  "createEvent/createEventInDB",
  async (
    { companyName, groupNameParams, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      let {
        tabsManagement,

        eventStartsAtDate,
        eventStartsAtTime,
        eventEndsAtDate,
        eventEndsAtTime,
        eventName,

        description,
        eventType,
        draft,
        eventMode,
        location,
        link,
        addRegistrationForm,
        addDocumentForm,
        mapLink,

        pollLink,
        generalChatLink,
        QnaLink,
        feedBackLink,

        eventLogoRedirectLink,
      } = getState().createEvent;

      let { user } = getState().user;

      let eventNameExists = await eventNameExistsAPI(companyName, eventName);

      if (eventNameExists) {
        let error = {
          name: "bizError",
          errorTitle: "eventNameExists",
          errorMessage:
            "This Event name already exists in your company. Try to add different Name for this event",
          theme: "danger",
          time: "now",
          autoHideTime: 4000,
          statusCode: 400,
          show: true,
        };
        throw error;
      }

      const eventStartsAt = new Date(
        eventStartsAtDate + "T" + eventStartsAtTime
      );
      const eventEndsAt = new Date(eventEndsAtDate + "T" + eventEndsAtTime);

      const result = await dispatch(
        getGroupIdAsync({ companyName, groupNameParams })
      );
      let groupId = result.payload;

      const response = await createEvent(
        eventName,
        eventStartsAt,
        eventEndsAt,

        description,
        eventType,
        draft,
        eventMode,
        location,
        link,
        groupId,
        user.uid,
        mapLink,
        tabsManagement,
        pollLink,
        generalChatLink,
        QnaLink,
        feedBackLink,
        eventLogoRedirectLink
      );

      if (response.data.id) {
        await joinEventAPI(groupId, response.data.id);
        await addCreatedEventsId(response.data.id, user.uid);
        if (addDocumentForm) {
          dispatch(submitFormInputsAsync({ eventId: response.data.id }));
        }
      }

      if (addRegistrationForm) {
        dispatch(
          postFormDataAsync({
            groupId,
            eventId: response.data.id,
            uid: user.uid,
          })
        );
      }

      dispatch(uploadAsync({ eventId: response.data.id, navigate }));

      if (response.data.error) {
        throw response.data;
      }
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const uploadAsync = createAsyncThunk(
  "createEvent/uploadFiles",
  async ({ eventId, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles, eventLogoSelectedFile } = getState().createEvent;

      let eventLogo = null;
      if (eventLogoSelectedFile) {
        eventLogo = await uploadEventLogo(eventLogoSelectedFile, eventId);
      }

      let eventGallery = [];
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, eventId);
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setEventGallery({ value: downloadURL }));
            eventGallery = [...eventGallery, downloadURL];
          }
        })
      );

      let tempData = [];

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      if (eventGallery.length <= 0) {
        tempData.push(
          `https://picsum.photos/id/${getRandomInt(1, 100)}/1920/760`
        );
      } else {
        tempData = eventGallery;
      }

      await addEventGallery(tempData, eventId, eventLogo);

      navigate(`/event/${eventId}`);
      dispatch(clearEvent({}));

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);
export const updateEventAsync = createAsyncThunk(
  "createEvent/updateEventInDB",
  async (
    { companyName, eventNameParams, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      let { user } = getState().user;

      const { groupId, eventId } = await getGroupandEventId(
        user.uid,
        eventNameParams
      );
      let {
        tabsManagement,
        eventStartsAtDate,
        eventStartsAtTime,
        eventEndsAtDate,
        eventEndsAtTime,
        eventName,
        description,
        eventType,
        draft,
        eventMode,
        location,
        link,
        addRegistrationForm,
        addDocumentForm,
        mapLink,

        selectedFiles,
        pollLink,
        generalChatLink,
        QnaLink,
        feedBackLink,
        eventLogoRedirectLink,

        editEvent,
        sendUpdateMail,
        sendUpdateNotification,
      } = getState().createEvent;

      const eventStartsAt = new Date(
        eventStartsAtDate + "T" + eventStartsAtTime
      );
      const eventEndsAt = new Date(eventEndsAtDate + "T" + eventEndsAtTime);

      const response = await updateEvent(
        eventName,
        eventStartsAt,
        eventEndsAt,

        description,
        eventType,
        draft,
        eventMode,
        location,
        link,

        user.uid,
        mapLink,
        eventId,
        tabsManagement,
        pollLink,
        generalChatLink,
        QnaLink,
        feedBackLink,
        eventLogoRedirectLink
      );

      if (addRegistrationForm) {
        const formDocId = await getFormDocId("1", eventId, groupId);
        dispatch(updatePostFormDataAsync({ formDocId }));
      }

      if (addDocumentForm) {
        dispatch(updateDocumentFormAsymc({}));
      }

      if (selectedFiles.length > 0) {
        dispatch(deleteEventImagesAsync({ eventId }));
      }
      dispatch(
        updateEventImgAsync({ eventId, navigate, companyName, eventNameParams })
      );

      if (editEvent && sendUpdateMail) {
        dispatch(sendUpdateEventMailAsync({}));
      }

      if (editEvent && sendUpdateNotification) {
        dispatch(sendUpdateEventNotificationAsync({}));
      }

      if (response.data.error) {
        throw response.data;
      }
    } catch (error) {
      if (error.name === "bizError") {
        dispatch(
          pushErrorNotification({
            notify: {
              iconifyIconLink: (
                <Icon icon="bx:notification" className="rounded" />
              ),
              errorTitle: error.errorTitle,
              errorMessage: error.errorMessage,
              theme: error.theme,
              time: error.time,
              autoHideTime: error.autoHideTime,
              statusCode: error.statusCode,
              show: error.show,
            },
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

export const updateEventImgAsync = createAsyncThunk(
  "createEvent/updateEventImgAsync",
  async (
    { eventId, navigate, companyName, eventNameParams },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { selectedFiles, eventLogoSelectedFile } = getState().createEvent;

      let eventLogo = null;
      if (eventLogoSelectedFile) {
        await deleteEventLogo(eventId);
        eventLogo = await uploadEventLogo(eventLogoSelectedFile, eventId);
      }
      let eventGallery = [];
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, eventId); // todo: updateFiles
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setEventGallery({ value: downloadURL }));
            eventGallery = [...eventGallery, downloadURL];
          }
        })
      );
      await addEventGallery(eventGallery, eventId, eventLogo);
      navigate(`/${companyName}/${eventNameParams}`);
      dispatch(clearEvent({}));

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteEventImagesAsync = createAsyncThunk(
  "createEvent/deleteEventImagesAsync",
  async ({ eventId }, { dispatch, getState, rejectWithValue }) => {
    try {
      let response = await deleteEventImages(eventId);

      console.log("delete response", response);
    } catch (err) {
      console.log(err);
    }
  }
);

export const createEventSlice = createSlice({
  name: "createEvent",
  initialState,

  reducers: {
    setEventName: (state, action) => {
      state.eventName = action.payload.value;
    },
    setStartDate: (state, action) => {
      state.eventStartsAtDate = action.payload.value;
    },
    setStartTime: (state, action) => {
      state.eventStartsAtTime = action.payload.value;
    },
    setEndDate: (state, action) => {
      state.eventEndsAtDate = action.payload.value;
    },
    setEndTime: (state, action) => {
      state.eventEndsAtTime = action.payload.value;
    },
    setDescription: (state, action) => {
      state.description = action.payload.value;
    },

    setEventType: (state, action) => {
      state.eventType = action.payload.value;
    },

    setEventDraft: (state, action) => {
      state.draft = action.payload.value;
    },

    setEventMode: (state, action) => {
      state.eventMode = action.payload.value;
    },
    setEventGallery: (state, action) => {
      state.eventGallery = [...state.eventGallery, action.payload.value];
    },
    setLocation: (state, action) => {
      state.location = action.payload.value;
    },
    setMapLink: (state, action) => {
      state.mapLink = action.payload.value;
    },
    setLink: (state, action) => {
      state.link = action.payload.value;
    },

    setSelectedFiles: (state, action) => {
      state.selectedFiles = [...state.selectedFiles, action.payload.newImage];
    },

    clearSelectedFiles: (state, action) => {
      state.selectedFiles = [];
    },
    setPreviewFiles: (state, action) => {
      state.preViewFiles = [...state.preViewFiles, action.payload.newPreview];
    },

    setShowRegistrationForm: (state, action) => {
      state.showRegistrationForm = action.payload.value;
    },

    setAddRegistrationForm: (state, action) => {
      state.addRegistrationForm = action.payload.value;
    },

    setShowDocumentForm: (state, action) => {
      state.showDocumentForm = action.payload.value;
    },

    setAddDocumentForm: (state, action) => {
      state.addDocumentForm = action.payload.value;
    },

    setEditEvent: (state, action) => {
      state.editEvent = action.payload.value;
    },

    setShowOrganizeTabs: (state, action) => {
      state.showOrganizeTabs = !state.showOrganizeTabs;
    },

    setTabsManagementShow: (state, action) => {
      let index = action.payload.index;
      let value = action.payload.value;

      state.tabsManagement[index].show = value;
    },

    setDndTabsManagementShow: (state, action) => {
      state.tabsManagement = action.payload.newTabsManagement;
    },

    setPollLink: (state, action) => {
      state.pollLink = action.payload.value;
    },
    setGeneralChatLink: (state, action) => {
      state.generalChatLink = action.payload.value;
    },
    setQnaLink: (state, action) => {
      state.QnaLink = action.payload.value;
    },
    setFeedBackLink: (state, action) => {
      state.feedBackLink = action.payload.value;
    },

    setEventLogoRedirectLink: (state, action) => {
      state.eventLogoRedirectLink = action.payload.value;
    },
    setEventLogoSelectedFile: (state, action) => {
      state.eventLogoSelectedFile = action.payload.value;
    },

    setShowCommunicationTabs: (state, action) => {
      state.showCommunicationTabs = action.payload.value;
    },

    setSendUpdateMail: (state, action) => {
      state.sendUpdateMail = action.payload.value;
    },

    setSendUpdateNotification: (state, action) => {
      state.sendUpdateNotification = action.payload.value;
    },

    checkAllTabs: (state, action) => {
      for (let i = 0; i < state.tabsManagement.length; i++) {
        const Tab = state.tabsManagement[i];

        Tab.show = true;
      }
    },

    uncheckAllTabs: (state, action) => {
      for (let i = 0; i < state.tabsManagement.length; i++) {
        const Tab = state.tabsManagement[i];
        Tab.show = false;
      }
    },

    clearPreviewFiles: (state, action) => {
      state.preViewFiles = [];
    },

    clearEvent: (state, action) => {
      // UI state
      state.showOrganizeTabs = false;

      state.editEvent = false;

      state.loading = false;

      state.addRegistrationForm = false;
      state.showRegistrationForm = false;

      state.addDocumentForm = false;
      state.showRegistrationForm = false;

      state.eventName = "";
      state.eventStartsAtDate = "";
      state.eventStartsAtTime = "";
      state.eventEndsAtDate = "";
      state.eventEndsAtTime = "";
      state.eventGallery = [];
      state.description = EditorState.createEmpty();
      state.eventType = "free";
      state.draft = false;
      state.eventMode = "online";
      state.link = "";
      state.mapLink = "";
      state.location = "";

      state.selectedFiles = [];
      state.preViewFiles = [];
      state.eventLogoSelectedFile = null;
      state.eventLogoRedirectLink = "";

      state.sendUpdateMail = false;
      state.sendUpdateNotification = false;
    },

    // Edit event
    setDataToEdit: (state, action) => {
      if (state.editEvent) {
        let {
          eventName,
          eventStartsAt,
          eventEndsAt,
          description,
          eventGallery,
          eventMode,
          eventType,
          link,
          location,
          mapLink,
          draft,
          tabsManagement,
          pollLink,
          QnaLink,
          generalChatLink,
          eventLogoRedirectLink,
        } = action.payload.eventData;

        state.eventName = eventName;

        const eventStart = new Date(eventStartsAt?.toDate());
        const eventEnd = new Date(eventEndsAt?.toDate());

        function getDateFromDateObj(dateObj) {
          return (
            dateObj.getFullYear() +
            "-" +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + dateObj.getDate()).slice(-2)
          );
        }

        function getTimeFromDateObj(dateObj) {
          return (
            (dateObj.getHours() < 10 ? "0" : "") +
            dateObj.getHours() +
            ":" +
            (dateObj.getMinutes() < 10 ? "0" : "") +
            dateObj.getMinutes()
          );
        }

        state.eventStartsAtTime = getTimeFromDateObj(eventStart);
        state.eventStartsAtDate = getDateFromDateObj(eventStart);

        state.eventEndsAtTime = getTimeFromDateObj(eventEnd);
        state.eventEndsAtDate = getDateFromDateObj(eventEnd);

        if (description) {
          const contentBlock = htmlToDraft(description);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            state.description = editorState;
          }
        }

        // state.eventGallery = eventGallery;
        state.preViewFiles = eventGallery;

        state.eventMode = eventMode;
        state.eventType = eventType;
        state.link = link;
        state.location = location;
        state.mapLink = mapLink;
        state.draft = draft;

        state.tabsManagement = tabsManagement;
        state.pollLink = pollLink;
        state.QnaLink = QnaLink;
        state.generalChatLink = generalChatLink;
        state.eventLogoRedirectLink = eventLogoRedirectLink;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(uploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(uploadAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(uploadAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createEventAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createEventAsync.fulfilled, (state, action) => {
        // state.loading = false;
      })
      .addCase(createEventAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEventImgAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateEventImgAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEventImgAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateEventAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateEventAsync.fulfilled, (state, action) => {
        // state.loading = false;
      })
      .addCase(updateEventAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  // Ui state
  setShowOrganizeTabs,

  setEventName,
  setStartDate,
  setStartTime,
  setEndDate,
  setEndTime,
  setDescription,
  setEventType,
  setEventDraft,
  setEventMode,
  setEventGallery,
  setLocation,
  setLink,
  setMapLink,
  setEditEvent,
  setTabsManagementShow,
  setDndTabsManagementShow,

  setSelectedFiles,
  clearSelectedFiles,

  setPreviewFiles,
  clearPreviewFiles,

  setAddRegistrationForm,

  setShowRegistrationForm,

  setDataToEdit,

  clearEvent,

  setAddDocumentForm,
  setShowDocumentForm,
  checkAllTabs,
  uncheckAllTabs,

  setShowCommunicationTabs,
  setPollLink,
  setGeneralChatLink,
  setQnaLink,
  setFeedBackLink,
  setEventLogoRedirectLink,
  setEventLogoSelectedFile,

  setSendUpdateMail,
  setSendUpdateNotification,
} = createEventSlice.actions;

export const createEventState = (state) => state.createEvent;

export default createEventSlice.reducer;
