import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  addSessionRatingAPI,
  createAgendaSessionAPI,
  createPrensentationsAPI,
  deleteAgendaPresentationFile,
  deleteAgendaSessionAPI,
  deletePrensentationsAPI,
  getAgendaAPI,
  updateAgendaSessionAPI,
  uploadAgendaPresentationFile,
} from "./reduxAPI";
import htmlToDraft from "html-to-draftjs";

const initialState = {
  sessionFormLoading: false,
  // All Steps
  // 1) selectDate
  // 2) selectSlot + Info
  // 3) select speakers
  // 4) select google form + presentation

  editSession: { edit: false, agendaId: undefined, sessionId: undefined },

  currentStep: 1,

  selectEventDate: undefined,

  selectStartAt: undefined,
  selectEndAt: undefined,
  selectTitle: undefined,
  selectDescription: EditorState.createEmpty(), // undefined

  selectSpeakers: [],
  confirmationSpeakers: {
    show: false,
    isConfirmed: false,
    wantToMoveNext: false,
  },

  feedBackFormUrl: undefined,

  prensentations: [{ title: "presentation 1", prensentation: undefined }],



  getAgendaDataLoading: false,
  getAgendaDataFound: true,
  agendaData: [],
  thankYouMessage: { show: false, index: undefined },
};
export const createAgendaSessionAsync = createAsyncThunk(
  "sessionForm/createAgendaSession",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let {
        selectEventDate,
        selectStartAt,
        selectEndAt,
        selectTitle,
        selectDescription,
        feedBackFormUrl,
        selectSpeakers,
        prensentations,
      } = getState().sessionForm;

      let sessionData = {
        eventId: id,
        eventDate: selectEventDate,
        startAt: selectStartAt,
        endAt: selectEndAt,
        title: selectTitle,
        description: draftToHtml(
          convertToRaw(selectDescription.getCurrentContent())
        ),
        googleFeedBackUrl: feedBackFormUrl,
        speakers: selectSpeakers,
      };

      const { data } = await createAgendaSessionAPI(sessionData);

      if (prensentations.length > 0) {
        await dispatch(
          uploadandCreatePresentationsAsync({
            agendaId: data.agendaId,
            sessionId: data.sessionId,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateSessionofAgendaAsync = createAsyncThunk(
  "sessionForm/updateSessionofAgenda",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      // eventId, agendaId, sessionId
      const {
        eventData: { id },
      } = getState().event;
      let {
        editSession: { agendaId, sessionId },
        selectStartAt,
        selectEndAt,
        selectTitle,
        selectDescription,
        feedBackFormUrl,
        selectSpeakers,
        prensentations,
      } = getState().sessionForm;

      let dataToUpdate = {
        eventId: id,
        agendaId,
        sessionId,

        startAt: selectStartAt, // 21:00
        endAt: selectEndAt, // 22:00
        title: selectTitle, // session 1
        description: draftToHtml(
          convertToRaw(selectDescription.getCurrentContent())
        ), // elecrama
        googleFeedBackUrl: feedBackFormUrl, // form.google.com/...
        speakers: selectSpeakers, // [ { id : "ssf"} ]
      };

      const { data } = await updateAgendaSessionAPI(dataToUpdate);

      // Todo: update presentation
      if (prensentations.length > 0) {
        await dispatch(
          updatePresentationsAsync({
            agendaId,
            sessionId,
          })
        );
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updatePresentationsAsync = createAsyncThunk(
  "sessionForm/updatePresentations",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let {
        prensentations,
        editSession: { agendaId, sessionId },
      } = getState().sessionForm;

      let prensentationsUrls = []; // { title : "title 1" , prensentationsUrl : "/image.png"}

      await Promise.all(
        prensentations.map(
          async ({ prensentation, title, prensentationsUrl }, index) => {
            let newUrl = prensentationsUrl;

            if (prensentation) {
              if (prensentationsUrl) {
                await deleteAgendaPresentationFile(prensentationsUrl);
              }

              newUrl = await uploadAgendaPresentationFile(
                prensentation,
                id,
                agendaId,
                sessionId
              );
            }
            prensentationsUrls[index] = {
              prensentationsUrl: newUrl,
              title,
            };
          }
        )
      );

      let sessionData = {
        eventId: id,
        agendaId,
        sessionId,
        prensentations: prensentationsUrls,
      };

      await createPrensentationsAPI(sessionData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const removePresentationFromDbAsync = createAsyncThunk(
  "sessionForm/removePresentationFromDb",
  async (
    { title, prensentationsUrl },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let {
        editSession: { agendaId, sessionId },
      } = getState().sessionForm;

      let sessionData = {
        eventId: id,
        agendaId,
        sessionId,

        title,
        prensentationsUrl,
      };

      await deleteAgendaPresentationFile(prensentationsUrl);
      await deletePrensentationsAPI(sessionData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const uploadandCreatePresentationsAsync = createAsyncThunk(
  "sessionForm/uploadandCreatePresentations",
  async ({ agendaId, sessionId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { prensentations } = getState().sessionForm;

      let prensentationsUrls = []; // { title : "title 1" , prensentationsUrl : "/image.png"}

      await Promise.all(
        // eslint-disable-next-line array-callback-return
        prensentations.map(async ({ prensentation, title }, index) => {
          if (prensentation) {
            const downloadURL = await uploadAgendaPresentationFile(
              prensentation,
              id,
              agendaId,
              sessionId
            );
            prensentationsUrls[index] = {
              prensentationsUrl: downloadURL,
              title,
            };
          }
        })
      );

      let sessionData = {
        eventId: id,
        agendaId,
        sessionId,
        prensentations: prensentationsUrls,
      };

      await createPrensentationsAPI(sessionData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAgendaByEventIdAsync = createAsyncThunk(
  "sessionForm/getAgendaByEventId",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      const { docId } = getState().user;

      const { data } = await getAgendaAPI(id, docId);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);
export const deleteSessionofAgendaAsync = createAsyncThunk(
  "sessionForm/deleteSessionofAgenda",
  async (
    { agendaId, sessionId, deleteAgenda },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      // eventId, agendaId, sessionId
      const {
        eventData: { id },
      } = getState().event;

      let dataId = {
        eventId: id,
        agendaId,
        sessionId,
        deleteAgenda,
      };

      const { data } = await deleteAgendaSessionAPI(dataId);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addSessionRatingAsync = createAsyncThunk(
  "sessionForm/addSessionRating",
  async (
    { agendaId, sessionId, rate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      const { docId } = getState().user;

      let ratingData = {
        eventId: id,
        agendaId,
        sessionId,
        rate,
        docId,
      };

      const { data } = await addSessionRatingAPI(ratingData);

      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const sessionFormSlice = createSlice({
  name: "sessionForm",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSessionFormLoading: (state, action) => {
      state.sessionFormLoading = action.payload.value;
    },

    setEditSession: (state, action) => {
      state.editSession = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload.value;
    },
    setSelectEventDate: (state, action) => {
      state.selectEventDate = action.payload.value;
    },

    setSelectStartAt: (state, action) => {
      state.selectStartAt = action.payload.value;
    },

    setSelectEndAt: (state, action) => {
      state.selectEndAt = action.payload.value;
    },
    setSelectTitle: (state, action) => {
      state.selectTitle = action.payload.value;
    },
    setSelectDescription: (state, action) => {
      state.selectDescription = action.payload.value;
    },

    setFeedBackFormUrl: (state, action) => {
      state.feedBackFormUrl = action.payload.value;
    },

    setSelectSpeakers: (state, action) => {
      state.selectSpeakers.push({ type: "thisEvent", docId: "adsk" });
    },

    setSpeakersId: (state, action) => {
      state.selectSpeakers = action.payload.values;
    },

    showSpeakersConfirmationModal: (state, action) => {
      state.confirmationSpeakers.show = action.payload.show;
      state.confirmationSpeakers.wantToMoveNext = action.payload.wantToMoveNext;
    },

    // Presentations
    addPrensentations: (state, action) => {
      state.prensentations.push({ title: "", prensentation: undefined });
    },

    addPrensentationsTitle: (state, action) => {
      const { index, title } = action.payload;
      state.prensentations[index].title = title;
    },

    addPrensentationsFile: (state, action) => {
      const { index, prensentation } = action.payload;
      state.prensentations[index].prensentation = prensentation;
    },

    removePresentation: (state, action) => {
      const { index } = action.payload;
      state.prensentations.splice(index, 1);
    },

    setHandleRating: (state, action) => {
      const { agendaIndex, sessionIndex, rate } = action.payload;

      let session = state.agendaData[agendaIndex].sessions[sessionIndex];

      session.ratingcount += rate;
      session.totalRating++;
      state.thankYouMessage.show = true;
      state.thankYouMessage.index = sessionIndex;
    },

    setRatingDone: (state, action) => {
      const { agendaIndex, sessionIndex } = action.payload;
      let session = state.agendaData[agendaIndex].sessions[sessionIndex];
      session.rated = true;
      state.thankYouMessage.show = false;
      state.thankYouMessage.index = undefined;
    },

    setDataToEditSession: (state, action) => {
      const {
        startAt,
        endAt,
        title,
        description,
        feedBackUrl,
        speakers,
        prensentations,
      } = action.payload;

      if (description) {
        const contentBlock = htmlToDraft(description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          state.selectDescription = editorState;
        }
      }

      state.selectStartAt = startAt;
      state.selectEndAt = endAt;
      state.selectTitle = title;
      state.feedBackFormUrl = feedBackUrl;
      state.selectSpeakers = speakers;
      state.prensentations = prensentations;
    },

    clearSessionForm: (state, action) => {
      state.selectEventDate = undefined;

      state.selectStartAt = undefined;
      state.selectEndAt = undefined;
      state.selectTitle = undefined;
      state.selectDescription = EditorState.createEmpty();

      state.selectSpeakers = [];
      state.confirmationSpeakers = {
        show: false,
        isConfirmed: false,
        wantToMoveNext: false,
      };

      state.feedBackFormUrl = undefined;

      state.prensentations = [
        { title: "presentation 1", prensentation: undefined },
      ];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAgendaByEventIdAsync.pending, (state, action) => {
        state.getAgendaDataLoading = true;
      })
      .addCase(getAgendaByEventIdAsync.fulfilled, (state, action) => {
        state.getAgendaDataLoading = false;
        state.agendaData = action.payload.agendaList;

        if (!action.payload.agendaList.length) {
          state.getAgendaDataFound = false;
          state.agendaData = [];
        } else {
          state.getAgendaDataFound = true;
        }
      })
      .addCase(getAgendaByEventIdAsync.rejected, (state, action) => {
        state.getAgendaDataLoading = false;
      });
  },
});

export const {
  setSessionFormLoading,
  setCurrentStep,
  setSelectEventDate,
  setSelectStartAt,
  setSelectEndAt,
  setSelectTitle,
  setSelectDescription,
  setSelectSpeakers,
  setSpeakersId,
  showSpeakersConfirmationModal,
  setFeedBackFormUrl,
  addPrensentations,
  addPrensentationsTitle,
  addPrensentationsFile,
  removePresentation,
  setDataToEditSession,
  setEditSession,
  clearSessionForm,
  setHandleRating,
  setRatingDone,
 
} = sessionFormSlice.actions;

export const sessionFormState = (state) => state.sessionForm;

export default sessionFormSlice.reducer;
