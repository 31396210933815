import React, {useState} from "react";
import { Link, Element } from 'react-scroll'
import './App.css'
import { Schedule } from "./props/Schedule";
import { HostCard } from "./props/HostCard";
import { Banner } from "../Banner/App";
import {Booth} from "./props/Booth";


//test data
const content = [
    {   id:0,
        title:"Phaistos Networks | GRXchange",
        desc:"Groundbreaking Advertising Technology" ,
        image:"https://hopin.imgix.net/vendors/background_images/000/776/477/original/programmatic_conference_2022_bg_image.png?ixlib=rails-4.1.0&auto=compress&s=931036637a87ce92fd7877f2001ff2e6",
        logo:"https://hopin.imgix.net/vendors/logos/000/776/477/original/logo_for_programmatic_advertising_2022.png?ixlib=rails-4.1.0&auto=compress&width=152&height=152&fit=clip&s=03a07c2d0f84aa846f73054ff275ab29"
    },
    {   id:1,
        title:"Phaistos Networks | GRXchange",
        desc:"Groundbreaking Advertising Technology" ,
        image:"https://hopin.imgix.net/vendors/background_images/000/776/477/original/programmatic_conference_2022_bg_image.png?ixlib=rails-4.1.0&auto=compress&s=931036637a87ce92fd7877f2001ff2e6",
        logo:"https://hopin.imgix.net/vendors/logos/000/776/477/original/logo_for_programmatic_advertising_2022.png?ixlib=rails-4.1.0&auto=compress&width=152&height=152&fit=clip&s=03a07c2d0f84aa846f73054ff275ab29"
    },
    {   id:2,
        title:"Phaistos Networks | GRXchange",
        desc:"Groundbreaking Advertising Technology" ,
        image:"https://hopin.imgix.net/vendors/background_images/000/776/477/original/programmatic_conference_2022_bg_image.png?ixlib=rails-4.1.0&auto=compress&s=931036637a87ce92fd7877f2001ff2e6",
        logo:"https://hopin.imgix.net/vendors/logos/000/776/477/original/logo_for_programmatic_advertising_2022.png?ixlib=rails-4.1.0&auto=compress&width=152&height=152&fit=clip&s=03a07c2d0f84aa846f73054ff275ab29"
    },
    {   id:3,
        title:"Phaistos Networks | GRXchange",
        desc:"Groundbreaking Advertising Technology" ,
        image:"https://hopin.imgix.net/vendors/background_images/000/776/477/original/programmatic_conference_2022_bg_image.png?ixlib=rails-4.1.0&auto=compress&s=931036637a87ce92fd7877f2001ff2e6",
        logo:"https://hopin.imgix.net/vendors/logos/000/776/477/original/logo_for_programmatic_advertising_2022.png?ixlib=rails-4.1.0&auto=compress&width=152&height=152&fit=clip&s=03a07c2d0f84aa846f73054ff275ab29"
    },

]



export function Event() {

    const [navbar , setNavbar] = useState(false);

    const cgBackground = () => {
        // console.log(window.scrollY)
        if(window.scrollY >= 491){
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll' , cgBackground)

    return (

        <div className="container">

            <Banner />

            <div className="row d-flex align-items-start gx-5">

                <div className="col-md-8">

                    {/*--------------------------------------------------------------------------Navigation---------------------------------------------------*/}


                    <div className={navbar ? "d-flex flex-row position-sticky top-0 mt-4 row-0 bg-dark" : "d-flex flex-row position-sticky top-0 mt-4 row-0"}>
                        <Link className="flex-grow-1 mb-3 mt-3 justify-content-center text-decoration-none align-items-center active" to='desc' spy={true} smooth={true} offset={-100} duration={500}>Description</Link>
                        <Link className="flex-grow-1 mb-3 mt-3 justify-content-center text-decoration-none align-items-center " to="Schedule" spy={true} smooth={true} offset={28} duration={500}>Schedule</Link>
                        <Link className="flex-grow-1 mb-3 mt-3 justify-content-center text-decoration-none align-items-center " to="Sponsors" spy={true} smooth={true} offset={50} duration={500}>Sponsors</Link>
                        <Link className="flex-grow-1 mb-3 mt-3 justify-content-center text-decoration-none align-items-center " to="Booths" spy={true} smooth={true} offset={50} duration={500}>Booths</Link>
                    </div>

                    {/*-----------------------------------------------------------------------------Description-----------------------------------------------------*/}

                    <Element name='desc'>
                        <section className="">
                            In its 8th edition, the Programmatic Advertising Conference will provide key updates and information on
                            all latest trends and best practices, in order to assist marketers, agencies and publishers in further developing and enhancing
                            their Programmatic strategies within the context of emerging major opportunities and changes in evolving business models.
                        </section>
                    </Element>

                    {/*-----------------------------------------------------------------------------Schedule-----------------------------------------------------*/}



                    <Element name="Schedule" >
                        <section className="mt-5 align-items-center">
                            <h3>Schedule</h3>

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />


                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />

                            <Schedule
                                time1="12:30 PM-12:45 PM"
                                t="Welcome & Opening Remarks"
                                l1="Michael Sarantinos - IAB Hellas"
                                l2="Dimitris Ganetsos - Hellenic Advertiser Association (SDE)"
                                time2="12:30 PM-12:45 PM"
                                l3="Session 1 - Trends & Brands"
                            />
                        </section>
                    </Element>


                    {/*-----------------------------------------------------------------------------Sponsors-----------------------------------------------------*/}



                    <Element name="Sponsors">
                        <section className="mt-5 mb-5">
                            <h3>Sponsors</h3>
                        </section>
                    </Element>





                </div>



                {/*-----------------------------------------------------------------------------Host desc-----------------------------------------------------*/}


                <div className="col-md-4 py-md-5">
                    <HostCard />
                </div>

            </div>
            {/*-----------------------------------------------------------------------------Booths-----------------------------------------------------*/}
            <Element name="Booths">
                <section className="mt-5 w-100">
                    <h3>Booths</h3>
                    {content.map((item) => (
                        <div id={item.id}>
                            <Booth
                                boothImage={item.image}
                                boothLogo={item.logo}
                                title={item.title}
                                desc={item.desc}
                            />

                        </div>
                    ))}

                </section>
            </Element>
        </div>
    )
}