import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addSponsorUrlAPI,
  createBrouchersAPI,
  createSponsorAPI,
  deleteBroucherAPI,
  deleteBrouchersUrlFile,
  deleteSponsorAPI,
  deleteSponsors,
  getSponsorAPI,
  updateSponsorAPI,
  updateSponsorTypeAPI,
  uploadFiles,
  uploadSponsorBrouchersFile,
} from "./reduxAPI";

const initialState = {
  updating_creatingSponsors: false,

  updateSponsorModal: false,
  updateSponsorId: "",
  updateSponsorType: 1,

  hoverSponsorId: "",

  showTypeErrorMessage: false,

  editType: false,

  sponsorImageStorageUrl: [],
  sponsorId: "",

  websiteLink: "",
  type: 1,
  brouchers: [{ title: "", broucher: undefined }],

  type1: "Platinum",
  type2: "Gold",
  type3: "Silver",
  type4: "Bronze",

  fetchingSponsors: false,
  foundSponsorsType1: true,
  foundSponsorsType2: true,
  foundSponsorsType3: true,
  foundSponsorsType4: true,
  sponsorsType1: [],
  sponsorsType2: [],
  sponsorsType3: [],
  sponsorsType4: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(createUserAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const createSponsorAsync = createAsyncThunk(
  "sponsors/createSponsor",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { websiteLink, type } = getState().sponsors;
      const response = await createSponsorAPI(websiteLink, type, eventId);
      await dispatch(
        uploadandCreateBrouchersAsync({ sponsorId: response.data.id })
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSponsorAsync = createAsyncThunk(
  "sponsors/updateSponsor",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { websiteLink, type, updateSponsorId, brouchers } =
        getState().sponsors;
      const response = await updateSponsorAPI(
        websiteLink,
        type,
        eventId,
        updateSponsorId
      );

      // Todo: update brouchers
      if (brouchers && brouchers.length > 0) {
        await dispatch(updatebroucherssAsync({}));
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatebroucherssAsync = createAsyncThunk(
  "sponsors/updatebroucherss",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      const { updateSponsorId, brouchers } = getState().sponsors;

      let brouchersUrls = [];

      // todo skip validation step

      await Promise.all(
        brouchers.map(async ({ broucher, title, brouchersUrl }, index) => {
          if (broucher || brouchersUrl) {
            let newUrl = brouchersUrl;

            if (broucher) {
              if (brouchersUrl) {
                await deleteBrouchersUrlFile(brouchersUrl);
              }

              newUrl = await uploadSponsorBrouchersFile(
                broucher,
                id,
                updateSponsorId
              );
            }
            brouchersUrls[index] = {
              brouchersUrl: newUrl,
              title,
            };
          }
        })
      );

      let sponsorData = {
        eventId: id,
        sponsorId: updateSponsorId,
        brouchers: brouchersUrls,
      };

      await createBrouchersAPI(sponsorData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteSponsorAsync = createAsyncThunk(
  "sponsors/deleteSponsor",
  async ({ sponsorId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const response = await deleteSponsorAPI(eventId, sponsorId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadUpdateSponsorImageAsync = createAsyncThunk(
  "sponsors/uploadUpdateSponsorImage",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        selectedFiles,

        eventData: { id: eventId },
      } = getState().event;

      const { websiteLink, type, updateSponsorId } = getState().sponsors;

      if (selectedFiles.length > 0) {
        await deleteSponsors(eventId, updateSponsorId);
        dispatch(setSponsorId({ value: updateSponsorId }));
        await dispatch(uploadAsync({}));
      }

      const response = await updateSponsorAPI(
        websiteLink,
        type,
        eventId,
        updateSponsorId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadAsync = createAsyncThunk(
  "sponsors/uploadSponsorsFile",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        selectedFiles,
        eventData: { id: eventId },
      } = getState().event;

      const { sponsorId } = getState().sponsors;

      let eventGallery = [];
      await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, eventId, sponsorId);
            eventGallery = [...eventGallery, downloadURL];
          }
        })
      );

      let tempData = [];

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      if (eventGallery.length <= 0) {
        tempData.push(
          `https://picsum.photos/id/${getRandomInt(1, 100)}/1920/760`
        );
      } else {
        tempData = eventGallery;
      }

      return tempData;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getSponsorAsync = createAsyncThunk(
  "sponsors/getSponsor",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;
      const response = await getSponsorAPI(eventId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateSponsorTypeAsync = createAsyncThunk(
  "sponsors/updateSponsorType",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { type1, type2, type3, type4 } = getState().sponsors;
      const response = await updateSponsorTypeAPI(
        eventId,
        type1,
        type2,
        type3,
        type4
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSponsorUrlAsync = createAsyncThunk(
  "sponsors/addSponsorUrl",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const { sponsorId } = getState().sponsors;

      const { sponsorImageStorageUrl } = getState().sponsors;
      const response = await addSponsorUrlAPI(
        sponsorImageStorageUrl,
        eventId,
        sponsorId
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadandCreateBrouchersAsync = createAsyncThunk(
  "sponsors/uploadandCreateBrouchers",
  async ({ sponsorId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { brouchers } = getState().sponsors;

      let brouchersUrls = [];

      await Promise.all(
        // eslint-disable-next-line array-callback-return
        brouchers.map(async ({ broucher, title }, index) => {
          if (brouchers) {
            const downloadURL = await uploadSponsorBrouchersFile(
              broucher,
              id,
              sponsorId
            );
            brouchersUrls[index] = {
              brouchersUrl: downloadURL,
              title,
            };
          }
        })
      );

      brouchersUrls = brouchersUrls.filter((x) => {
        return x !== undefined;
      });

      let sponsorData = {
        eventId: id,
        sponsorId,
        brouchers: brouchersUrls,
      };

      await createBrouchersAPI(sponsorData);
    } catch (err) {
      console.log(err);
    }
  }
);

export const removeBroucherFromDbAsync = createAsyncThunk(
  "sponsors/removeBroucherFromDb",
  async ({ title, brouchersUrl }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id },
      } = getState().event;

      let { updateSponsorId } = getState().sponsors;

      let data = {
        eventId: id,
        sponsorId: updateSponsorId,

        title,
        brouchersUrl,
      };

      await deleteBrouchersUrlFile(brouchersUrl);
      await deleteBroucherAPI(data);
    } catch (err) {
      console.log(err);
    }
  }
);

export const sponsorsSlice = createSlice({
  name: "sponsors",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setWebsiteLink: (state, action) => {
      state.websiteLink = action.payload.value;
    },

    setEditType: (state, action) => {
      state.editType = action.payload.value;
    },

    setShowTypeErrorMessage: (state, action) => {
      state.showTypeErrorMessage = action.payload.value;
    },

    setType: (state, action) => {
      state.type = action.payload.value;
    },

    setType1: (state, action) => {
      state.type1 = action.payload.value;
    },
    setType2: (state, action) => {
      state.type2 = action.payload.value;
    },
    setType3: (state, action) => {
      state.type3 = action.payload.value;
    },
    setType4: (state, action) => {
      state.type4 = action.payload.value;
    },
    setUpdateSponsorModal: (state, action) => {
      state.updateSponsorModal = action.payload.value;
    },

    setUpdateSponsorId: (state, action) => {
      state.updateSponsorId = action.payload.value;
    },

    setUpdateSponsorType: (state, action) => {
      state.updateSponsorType = action.payload.value;
    },

    setSponsorDataToEdit: (state, action) => {
      let index = action.payload.value;
      let type = state.updateSponsorType;

      let data;

      switch (type) {
        case 1:
          data = [...state.sponsorsType1];
          break;
        case 2:
          data = [...state.sponsorsType2];
          break;
        case 3:
          data = [...state.sponsorsType3];
          break;

        case 4:
          data = [...state.sponsorsType4];
          break;

        default:
          data = [...state.sponsorsType1];
          break;
      }

      state.websiteLink = data[index].websiteLink;
      state.type = data[index].type;
      if (data[index].brouchers.length !== 0) {
        state.brouchers = data[index].brouchers;
      } else {
        state.brouchers = [{ title: "create brouchers", broucher: undefined }];
      }
    },

    setHoverSponsorId: (state, action) => {
      state.hoverSponsorId = action.payload.value;
    },

    setSponsorId: (state, action) => {
      state.sponsorId = action.payload.value;
    },

    // brouchers

    addBrouchers: (state, action) => {
      if (state.brouchers) {
        state.brouchers.push({ title: "", broucher: undefined });
      } else {
        state.brouchers = [{ title: "", broucher: undefined }];
      }
    },

    addBrouchersTitle: (state, action) => {
      const { index, title } = action.payload;
      state.brouchers[index].title = title;
    },

    addBrouchersFile: (state, action) => {
      const { index, broucher } = action.payload;
      state.brouchers[index].broucher = broucher;
    },

    removeBroucher: (state, action) => {
      const { index } = action.payload;
      state.brouchers.splice(index, 1);
    },

    resetSponsorUpload: (state, action) => {
      state.sponsorImageStorageUrl = [];
      state.sponsorId = "";
    },

    resetFound: (state, action) => {
      state.foundSponsorsType1 = true;
      state.foundSponsorsType2 = true;
      state.foundSponsorsType3 = true;
      state.foundSponsorsType4 = true;
    },

    resetForm: (state, action) => {
      state.type = 1;
      state.websiteLink = "";
      state.brouchers = [{ title: "broucher", broucher: undefined }];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createSponsorAsync.pending, (state, action) => {
        state.updating_creatingSponsors = true;
      })
      .addCase(createSponsorAsync.fulfilled, (state, action) => {
        let id = action.payload.data.id;

        state.sponsorId = id;
      })
      .addCase(createSponsorAsync.rejected, (state, action) => {})
      .addCase(getSponsorAsync.pending, (state, action) => {
        state.fetchingSponsors = true;
      })
      .addCase(getSponsorAsync.fulfilled, (state, action) => {
        let { sponsorsType1, sponsorsType2, sponsorsType3, sponsorsType4 } =
          action.payload.data;

        state.fetchingSponsors = false;

        if (sponsorsType1.length <= 0) {
          state.foundSponsorsType1 = false;
        }

        if (sponsorsType2.length <= 0) {
          state.foundSponsorsType2 = false;
        }

        if (sponsorsType3.length <= 0) {
          state.foundSponsorsType3 = false;
        }

        if (sponsorsType4.length <= 0) {
          state.foundSponsorsType4 = false;
        }

        state.sponsorsType1 = sponsorsType1;
        state.sponsorsType2 = sponsorsType2;
        state.sponsorsType3 = sponsorsType3;
        state.sponsorsType4 = sponsorsType4;
      })
      .addCase(getSponsorAsync.rejected, (state, action) => {
        state.fetchingSponsors = false;
      })
      .addCase(uploadAsync.pending, (state, action) => {})
      .addCase(uploadAsync.fulfilled, (state, action) => {
        let url = action.payload;

        let data = [...state.sponsorImageStorageUrl, ...url];

        state.sponsorImageStorageUrl = data;
      })
      .addCase(uploadAsync.rejected, (state, action) => {})
      .addCase(updateSponsorAsync.pending, (state, action) => {
        state.updating_creatingSponsors = true;
      })
      .addCase(updateSponsorAsync.fulfilled, (state, action) => {})
      .addCase(updateSponsorAsync.rejected, (state, action) => {})
      .addCase(addSponsorUrlAsync.pending, (state, action) => {})
      .addCase(addSponsorUrlAsync.fulfilled, (state, action) => {
        state.updating_creatingSponsors = false;
      })
      .addCase(addSponsorUrlAsync.rejected, (state, action) => {});
  },
});

export const {
  setSelectedFile,
  setWebsiteLink,
  setType,
  setEditType,
  setShowTypeErrorMessage,
  setType1,
  setType2,
  setType3,
  setType4,
  setSponsorId,
  resetFound,
  resetSponsorUpload,
  resetForm,
  setHoverSponsorId,
  setUpdateSponsorId,
  setSponsorDataToEdit,
  setUpdateSponsorType,
  setUpdateSponsorModal,
  addBrouchers,
  addBrouchersTitle,
  addBrouchersFile,
  removeBroucher,
} = sponsorsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const sponsorsState = (state) => state.sponsors;

export default sponsorsSlice.reducer;
