import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addSpeakerProfileUrl,
  createNewSpeakerAPI,
  getSpeakersAPI,
  uploadFiles,
  deleteSpeakerProfileAPI,
  updateSpeakerProfileDataAPI,
  deleteSpeakerProfile,
  algoliaUsersAPI,
  uploadWhitePaper,
  updateWhitePaperLinkAPI,
  deleteWhitePaper,
} from "./reduxAPI";

// icon
import { Icon } from "@iconify/react";
import { getuserDetailsAPI } from "../../Attendees/reduxAPI";
import {
  clearPreviewFiles,
  setPreviewFiles,
  setSelectedFiles,
} from "../reduxSlice";
import isValidUrl from "../../../utils/isValidUrl";
import { isImageFromMyStorage } from "../../../utils/isImageFromMyStorage";

const initialState = {
  creating_updatingNewSpeaker: false,

  selectedSpeakerIsSearched: false,

  serachUserQuery: "",

  loadingSearchedUsers: false,
  foundSearchedUser: true,
  searchedUsers: [],

  editSpeaker: false,
  editSpeakerId: "",
  editSpeakerIndex: null,

  loading: false,
  found: true,
  speakerData: [],
  // Add speaker
  speakerNav: "manual",
  // profile Url
  speakerName: "",
  designation: "",
  description: "",
  email: "",
  conatctNo: "",

  linkedinLink: "",
  twitterLink: "",
  facebookLink: "",
  instagramLink: "",
  websiteLink: "",
  youtubeLink: "",

  whiteLabel: undefined,

  selectedSpeakerIndex: null,
};

export const getSpeakersAsync = createAsyncThunk(
  "eventSpeakers/getSpeakers",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;
      let response = await getSpeakersAPI(eventId);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSpeakerAsync = createAsyncThunk(
  "eventSpeakers/updateSpeaker",
  async ({ speakerId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      const {
        speakerName,
        designation,
        description,
        email,
        conatctNo,
        linkedinLink,
        twitterLink,
        facebookLink,
        instagramLink,
        websiteLink,
        youtubeLink,
        whiteLabel,
        editSpeakerIndex,
        speakerData,
      } = getState().eventSpeakers;

      let response = await updateSpeakerProfileDataAPI(
        eventId,

        speakerName,
        designation,
        description,
        email,
        conatctNo,
        linkedinLink,
        twitterLink,
        facebookLink,
        instagramLink,
        websiteLink,
        youtubeLink,

        speakerId
      );

      await dispatch(
        uploadSpeakerProfileAsync({ eventId, speakerId: speakerId })
      );

      // update white paper logic

      // whiteLabel , editSpeakerIndex ,speakerData

      let changedLabel = undefined;

      if (
        speakerData[editSpeakerIndex].whiteLabel &&
        !isImageFromMyStorage(speakerData[editSpeakerIndex].whiteLabel)
      ) {
        changedLabel = speakerData[editSpeakerIndex].whiteLabel;
      }

      if (whiteLabel !== changedLabel) {
        console.log("White Label Changed...");

        // delete old img
        if (isImageFromMyStorage(speakerData[editSpeakerIndex].whiteLabel)) {
          await deleteWhitePaper(speakerData[editSpeakerIndex].whiteLabel);
        }

        // upload new img
        let downloadUrl = whiteLabel;
        if (
          whiteLabel &&
          !(typeof whiteLabel === "string" || whiteLabel instanceof String)
        ) {
          downloadUrl = await uploadWhitePaper(
            whiteLabel,
            eventId,
            speakerData[editSpeakerIndex].id
          );
        }

        // update url
        await updateWhitePaperLinkAPI(
          downloadUrl,
          eventId,
          speakerData[editSpeakerIndex].id
        );
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSpeakerAsync = createAsyncThunk(
  "eventSpeakers/deleteSpeakerAsync",
  async ({ speakerId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        eventData: { id: eventId },
      } = getState().event;

      let response = await deleteSpeakerProfileAPI(eventId, speakerId);
      dispatch(getSpeakersAsync());

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadSpeakerProfileAsync = createAsyncThunk(
  "eventSpeakers/uploadSpeakerProfile",
  async ({ eventId, speakerId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { selectedFiles } = getState().event;
      const { editSpeaker, selectedSpeakerIsSearched } =
        getState().eventSpeakers;

      if (selectedSpeakerIsSearched) {
        await addSpeakerProfileUrl(selectedFiles, eventId, speakerId);
        return;
      }

      if (editSpeaker) {
        if (selectedFiles.length <= 0) {
          return;
        }
        await deleteSpeakerProfile(eventId, speakerId);
      }

      let profileUrl = [];
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        selectedFiles.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadFiles(file, eventId, speakerId);
            console.log("**downloadUrl ", index, " ", downloadURL);
            profileUrl = [...profileUrl, downloadURL];
          }
        })
      );

      let tempData = [];

      function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      if (profileUrl.length <= 0) {
        tempData.push(
          `https://picsum.photos/id/${getRandomInt(1, 100)}/460/350`
        );
      } else {
        tempData = profileUrl;
      }

      await addSpeakerProfileUrl(tempData, eventId, speakerId);

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }
);

export const createNewSpeakerAsync = createAsyncThunk(
  "eventSpeakers/createNewSpeaker",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        speakerName,
        designation,
        description,
        email,
        conatctNo,
        linkedinLink,
        twitterLink,
        facebookLink,
        instagramLink,
        websiteLink,
        youtubeLink,
        whiteLabel,
      } = getState().eventSpeakers;

      const {
        eventData: { id: eventId },
      } = getState().event;

      let validLabel = undefined;

      if (typeof whiteLabel === "string" || whiteLabel instanceof String) {
        validLabel = whiteLabel;
      }

      const response = await createNewSpeakerAPI(
        eventId,
        speakerName,
        designation,
        description,
        email,
        conatctNo,
        linkedinLink,
        twitterLink,
        facebookLink,
        instagramLink,
        websiteLink,
        youtubeLink,
        validLabel
      );

      if (response.data.id) {
        if (
          !validLabel &&
          whiteLabel !== undefined &&
          !(typeof whiteLabel === "string" || whiteLabel instanceof String)
        ) {
          let url = await uploadWhitePaper(
            whiteLabel,
            eventId,
            response.data.id
          );
          await updateWhitePaperLinkAPI(url, eventId, response.data.id);
        }

        await dispatch(
          uploadSpeakerProfileAsync({ eventId, speakerId: response.data.id })
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const algoliaUsersAsync = createAsyncThunk(
  "eventSpeakers/algoliaUsersAsync",
  async ({ _ }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { serachUserQuery } = getState().eventSpeakers;
      let result = await algoliaUsersAPI(serachUserQuery, "users");
      result.hits.map(async (hit, index) => {
        hit["id"] = hit["objectID"];
        delete (await hit["objectID"]);
        return hit;
      });
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const algoliaUserAPICase = (builder) => {
  builder
    .addCase(algoliaUsersAsync.pending, (state, action) => {
      state.loadingSearchedUsers = true;
    })
    .addCase(algoliaUsersAsync.fulfilled, (state, action) => {
      state.loadingSearchedUsers = false;

      state.searchedUsers = [...action.payload.hits];
      if (action.payload.hits.length <= 0) {
        state.foundSearchedUser = false;
      }
    })
    .addCase(algoliaUsersAsync.rejected, (state, action) => {
      state.loadingSearchedUsers = false;
    });
};

export const getDataSelectedSpeakerAsync = createAsyncThunk(
  "eventSpeakers/getDataSelectedSpeaker",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getuserDetailsAPI(id);
      dispatch(setPreviewFiles({ newPreview: response.data.profileUrl }));
      dispatch(setSelectedFiles({ newImage: response.data.profileUrl }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getDataSelectedSpeakerAPICase = (builder) => {
  // builder
};

export const eventSpeakersSlice = createSlice({
  name: "eventSpeakers",
  initialState,

  reducers: {
    setSpeakerName: (state, action) => {
      state.speakerName = action.payload.value;
    },
    setDesignation: (state, action) => {
      state.designation = action.payload.value;
    },
    setDescription: (state, action) => {
      state.description = action.payload.value;
    },
    setConatctNo: (state, action) => {
      state.conatctNo = action.payload.value;
    },
    setLinkedinLink: (state, action) => {
      state.linkedinLink = action.payload.value;
    },
    setTwitterLink: (state, action) => {
      state.twitterLink = action.payload.value;
    },
    setFacebookLink: (state, action) => {
      state.facebookLink = action.payload.value;
    },
    setInstagramLink: (state, action) => {
      state.instagramLink = action.payload.value;
    },
    setWebsiteLink: (state, action) => {
      state.websiteLink = action.payload.value;
    },
    setYoutubeLink: (state, action) => {
      state.youtubeLink = action.payload.value;
    },
    setSpeakerNavPannel: (state, action) => {
      state.speakerNav = action.payload.newPannel;
    },

    setSpeakerEmail: (state, action) => {
      state.email = action.payload.value;
    },

    setEditSpeaker: (state, action) => {
      state.editSpeaker = action.payload.value;
      state.editSpeakerId = action.payload.speakerId;
      state.editSpeakerIndex = action.payload.index;
    },

    setSelectedSpeaker: (state, action) => {
      state.selectedSpeakerIndex = action.payload.selectSpeaker;
    },

    setSerachUserQuery: (state, action) => {
      state.serachUserQuery = action.payload.value;
    },

    setSelectedSpeakerIsSearched: (state, action) => {
      state.selectedSpeakerIsSearched = action.payload.value;
    },

    setWhiteLabel: (state, action) => {
      state.whiteLabel = action.payload.value;
    },

    clearSelectedSpeaker: (state, action) => {
      state.selectedSpeakerIndex = "";
    },

    setDataToEditSpeaker: (state, action) => {
      let index = action.payload.index;

      state.speakerName = state.speakerData[index].speakerName;
      state.designation = state.speakerData[index].designation;
      state.description = state.speakerData[index].description;
      state.email = state.speakerData[index].email;
      state.conatctNo = state.speakerData[index].conatctNo;
      state.linkedinLink = state.speakerData[index].linkedinLink;
      state.twitterLink = state.speakerData[index].twitterLink;
      state.facebookLink = state.speakerData[index].facebookLink;
      state.instagramLink = state.speakerData[index].instagramLink;
      state.websiteLink = state.speakerData[index].websiteLink;
      state.youtubeLink = state.speakerData[index].youtubeLink;

      if (state.speakerData[index].whiteLabel) {
        if (!isImageFromMyStorage(state.speakerData[index].whiteLabel)) {
          state.whiteLabel = state.speakerData[index].whiteLabel;
        }
      }
    },

    clearDataToEditSpeaker: (state, action) => {
      state.speakerName = "";
      state.designation = "";
      state.description = "";
      state.email = "";
      state.conatctNo = "";
      state.linkedinLink = "";
      state.twitterLink = "";
      state.facebookLink = "";
      state.instagramLink = "";
      state.websiteLink = "";
      state.youtubeLink = "";
      state.whiteLabel = undefined;
    },

    clearForm: (state, action) => {
      state.speakerData = [];

      state.speakerName = "";
      state.designation = "";
      state.description = "";
      state.email = "";
      state.conatctNo = "";
      state.linkedinLink = "";
      state.twitterLink = "";
      state.facebookLink = "";
      state.instagramLink = "";
      state.websiteLink = "";
      state.youtubeLink = "";
      state.whiteLabel = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSpeakersAsync.pending, (state, action) => {
        state.loading = true;
        state.found = true;
      })
      .addCase(getSpeakersAsync.fulfilled, (state, action) => {
        if (action.payload.length <= 0) {
          state.found = false;
          state.speakerData = [];
        } else {
          state.speakerData = [...action.payload];
        }
        state.loading = false;
      })
      .addCase(getSpeakersAsync.rejected, (state, action) => {
        state.loading = false;
        state.found = false;
      })
      .addCase(getDataSelectedSpeakerAsync.pending, (state, action) => {})
      .addCase(getDataSelectedSpeakerAsync.fulfilled, (state, action) => {
        const data = action.payload;
        state.speakerName = data.firstName;
        state.designation = data.headLine;
        state.description = data.bio;
        state.email = data.email;
        state.conatctNo = data.phoneNumber;
        state.linkedinLink = data.linkedin;
        state.twitterLink = data.twitter;
        state.facebookLink = data.facebook;
        state.websiteLink = data.website || "";
        state.youtubeLink = data.youtube || "";
      })
      .addCase(getDataSelectedSpeakerAsync.rejected, (state, action) => {})
      .addCase(createNewSpeakerAsync.pending, (state, action) => {
        state.creating_updatingNewSpeaker = true;
      })
      .addCase(createNewSpeakerAsync.fulfilled, (state, action) => {
        state.creating_updatingNewSpeaker = false;
      })
      .addCase(createNewSpeakerAsync.rejected, (state, action) => {
        state.creating_updatingNewSpeaker = false;
      })
      .addCase(updateSpeakerAsync.pending, (state, action) => {
        state.creating_updatingNewSpeaker = true;
      })
      .addCase(updateSpeakerAsync.fulfilled, (state, action) => {
        state.creating_updatingNewSpeaker = false;
      })
      .addCase(updateSpeakerAsync.rejected, (state, action) => {
        state.creating_updatingNewSpeaker = false;
      });

    algoliaUserAPICase(builder);
    getDataSelectedSpeakerAPICase(builder);
  },
});

export const {
  setSpeakerName,
  setDesignation,
  setDescription,
  setSpeakerEmail,
  setConatctNo,
  setLinkedinLink,
  setTwitterLink,
  setFacebookLink,
  setInstagramLink,
  setWebsiteLink,
  setYoutubeLink,
  setSpeakerNavPannel,
  setSelectedSpeaker,
  clearSelectedSpeaker,
  clearForm,
  setEditSpeaker,
  setDataToEditSpeaker,
  clearDataToEditSpeaker,
  setSerachUserQuery,
  setSelectedSpeakerIsSearched,
  setWhiteLabel,
} = eventSpeakersSlice.actions;

export const eventSpeakersState = (state) => state.eventSpeakers;

export default eventSpeakersSlice.reducer;
