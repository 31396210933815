import React, { useEffect } from "react";
import Banner from "../../Banner/App";
import { Button, Dropdown } from "react-bootstrap";
import { RiArrowDropDownLine } from "@react-icons/all-files/ri/RiArrowDropDownLine";
import { Link, useParams } from "react-router-dom";
import Discussion_details_card from "../../MyGroup_Props/Discussion_Detail_Card/App";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentDiscussionAsync,
  getGroupDiscussionAsync,
  getGroupOnlyDiscussionAsync,
  groupState,
  setComment,
} from "../../MyGroupOnBoard/reduxSlice";
import moment from "moment";
import { userState } from "../../../../../Main/Components/User/reduxSlice";

const DiscussionDetails = () => {
  const { discussionId } = useParams();
  const groupDiscussionId = discussionId;
  const {
    generatedGroupId,
    groupOnlyDiscussion,
    comment,
    groupOrganizerDetails,
  } = useSelector(groupState);

  const { user } = useSelector(userState);

  const { firstName, lastName } = groupOrganizerDetails;
  let groupId = generatedGroupId;
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addCommentDiscussionAsync({
        groupId,
        groupDiscussionId,
        comment,
        uid: user?.uid,
      })
    );
    dispatch(getGroupOnlyDiscussionAsync({ groupId, groupDiscussionId }));
  };
  useEffect(() => {
    dispatch(getGroupOnlyDiscussionAsync({ groupId, groupDiscussionId }));
  }, [groupId, groupDiscussionId, dispatch]);

  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center py-5">
          <div className="d-flex flex-column card rounded-sm col-lg-8 py-4 bg-dark text-light border">
            <div className="d-flex flex-column p-3">
              <div className="d-flex flex-column">
                <div className="d-flex flex-row">
                  <h5 className="fw-bold">{groupOnlyDiscussion?.title}</h5>
                </div>
                <div className="d-flex flex-row align-items-center align-content-center post-title">
                  <span className="text-muted">
                    Started by{" "}
                    <strong>
                      {firstName} {lastName}
                    </strong>
                  </span>
                  <span className="mx-1 dot"></span>
                  <time>
                    {moment(groupOnlyDiscussion?.createdAt?.toDate()).fromNow()}
                  </time>
                  <span className="mx-2 dot"></span>
                  <a className="text-danger delete-confirm text-decoration-none">
                    Delete discussion
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center text-left p-3">
              {groupOnlyDiscussion?.discussionDescription}
            </div>
            <div id="comments" className="p-2 px-3">
              <form>
                <div className="d-flex flex-row py-4 border-top">
                  <input
                    type="text"
                    className="form-control me-3 bg-dark text-light"
                    value={comment}
                    onChange={(e) => {
                      dispatch(setComment({ value: e.target.value }));
                    }}
                    placeholder="Add comment"
                  />
                  <Button
                    className="btn btn-primary rounded-sm"
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Comment
                  </Button>
                </div>
              </form>
              {groupOnlyDiscussion?.comments?.map((data) => (
                <Discussion_details_card data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscussionDetails;
