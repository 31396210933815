import React, { useState } from 'react'
import { QrReader } from 'react-qr-reader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { addCheckInCheckOutAsync, checkInCheckOutState, setLastCheck, setLocation, setShowScanQrModal } from './reduxSlice';
import { Icon } from '@iconify/react';
import { getCity } from './reduxAPI';
import { useParams } from 'react-router-dom';

const Scanner = () => {

    const dispatch = useDispatch();
    const { eventId } = useParams();

    const [delay, setDelay] = useState(500);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showScanQrModal } = useSelector(checkInCheckOutState);

    const previewStyle = {
        top: '0px',
        left: '0px',
        width: '100%',
        height: '60vh',
        display: 'block',
        overflow: 'hidden',
        position: 'inherit',
        borderRadius: '4px',
        margin: 'auto'
    };

    const videoContainerStyle = {
        width: 'auto',
        paddingTop: '0px'
    };



    const scanHandler = (result, error) => {


        if (!!result) {
            console.log(result)
            setResult(result?.text);
        }

        if (!!error) {
            // console.log(error);
        }
    };

    let address = undefined;

    const updateStatusHandler = async () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(async (position) => {
            var crd = position.coords;
            var lat = crd.latitude.toString();
            var lng = crd.longitude.toString();
            address = await getCity(lat, lng);


            dispatch(setLocation({ value: address }));

            const newCheckIn = new Date().toISOString();
            dispatch(setLastCheck({ value: newCheckIn }));

            try {
                await dispatch(addCheckInCheckOutAsync({ attendeeId: result, selectedEvent: eventId, adminUpdate: true }));
            } catch (error) {

            }

            setResult(null);
            setLoading(false);

        });

    }



    return (
        <Modal show={showScanQrModal} fullscreen={true} onHide={() => dispatch(setShowScanQrModal({ value: false }))
        }>
            <Modal.Header closeButton>
                <Modal.Title>CheckIn CheckOut Attendees</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='text-center'>
                    <QrReader
                        constraints={{ facingMode: "environment" }}
                        scanDelay={delay}
                        onResult={scanHandler}
                        videoStyle={previewStyle}
                        videoContainerStyle={videoContainerStyle}
                    />

                    {/* <div style={{
                        height: 240, width: 320, background: "black", margin: "auto", borderRadius: "4px"
                    }} ></div> */}

                    {result ? (
                        <>
                            {loading ? (<Button className='my-4'>
                                Loading Please Wait ...
                            </Button>) : (<Button className='my-4' onClick={updateStatusHandler} >
                                Update User Status <Icon icon="mdi:user" className="ms-1" fontSize={22} />
                            </Button>)}

                        </>) : null}
                </div>

            </Modal.Body>
        </Modal >

    )
}

export default Scanner