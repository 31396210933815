import React, {useState} from "react";
import {Link} from 'react-router-dom'
import './App.css'
//ModelPopUp
import AlertModal from "../../CommonComponents/AlertModal/App";
import {AdminNavigation} from "../../CommonComponents/AdminNavigation/App";
import Admin_Footer from "../../CommonComponents/Footer/App";





export function Subscription() {
    const [modalShow, setModalShow] = useState(false);
    return(
        <div className="vh-100">


        <div className="  position-relative">
            <div className="  ">
                <AdminNavigation/>
                <div className="container  mt-3 mb-3 ">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                <small>    Overview</small>
                                </div>
                                <h1 className="page-title h5 fw-bolder">
                                    Subscriptions
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-body">
                    <div className="container">
                        <div className="row row-deck row-cards">
                            <div className="col-12">
                                <form className="card bg-dark mb-3 text-light border">
                                    <div className="card-body">
                                        <strong className="card-title">Filter</strong>
                                        <div className="d-flex">
                                            <div className="form-group">
                                                <input   type="text" className="form-control bg-dark text-light" value=""
                                                       placeholder="Search user"/>
                                            </div>
                                            <div className="form-group ms-2">
                                                <select
                                                        className="form-control bg-dark text-light">
                                                    <option selected="" value="">Select Status</option>
                                                    <option value="active">Active</option>
                                                    <option value="cancelled">Cancelled</option>
                                                    <option value="on_trial">On trial</option>
                                                    <option value="on_grace">On grace</option>
                                                    <option value="ended">Ended</option>
                                                </select>
                                            </div>
                                            <div className="form-group ms-2">
                                                <select
                                                        className="form-control bg-dark text-light">
                                                    <option selected="" value="">Select Plan</option>
                                                    <option value="1">Basic Plan</option>
                                                    <option value="3">Startup Plan</option>
                                                    <option value="5">Growth Plan</option>
                                                </select>
                                            </div>
                                            <button type="submit" className="btn btn-link">

                                                Filter
                                            </button>
                                            <a className="btn btn-link">

                                                Clear
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="card bg-dark text-light border">
                                    <div className="card-header">
                                        <h3 className="card-title h6 fw-bolder ">Subscriptions</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table bg-dark border text-light card-table table-vcenter text-nowrap datatable">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>User</th>
                                                <th>Plan</th>
                                                <th>Status</th>
                                                <th>Subscribed at</th>
                                                <th>Ends at</th>
                                                <th className="text-end">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody className="list" id="subscriptions">
                                            <tr>
                                                <td>
                                                    <p className="mb-0">1</p>
                                                </td>
                                                <td>
                                                    <Link to = "#"className="mb-0 text-decoration-none " target="_blank">Rose VonRueden</Link>
                                                </td>
                                                <td>
                                                    <Link to = "#" className="mb-0 text-decoration-none " target="_blank">Basic Plan</Link>
                                                </td>
                                                <td>
                                                    <div className="badge bg-info">On trial</div>
                                                </td>
                                                <td>
                                                    2022-07-10
                                                </td>
                                                <td>
                                                    2022-08-17
                                                </td>
                                                <td className="text-end">
                                                     <span className="text-danger" onClick={() => setModalShow(true)}>cancel</span>
                                                    <AlertModal
                                                        show={modalShow}
                                                        onHide={() => setModalShow(false)}
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <Admin_Footer/>



            </div>

        </div>
        </div>
    )
}