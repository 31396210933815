import React from "react";

import FormGenerator from "./components/FormGenerator/App";

export default function Documents(props) {
  return (
    <div className="bg-dark text-light">
      <FormGenerator />
    </div>
  );
}
